angular
    .module('app')
    .controller('payBadController', payBadController);

function payBadController($state, loading, toastr, text) {
    var vm = this;

    // // --
    // vm.propiedad = {
    //     id: $stateParams.id
    // };
    init();

    function init() {
        loading.init();

        loading.end();
    }

}
