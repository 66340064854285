angular
    .module('app')
    .controller('dashboardController', dashboardController);

function dashboardController($state, $scope, $rootScope, $filter, dashboardDataService,
    storeService, months, toastr, text, years) {
    var vm = this;
    var date = new Date();
    // ---
    vm.filter = filter;
    vm.show = show;
    vm.selectMonth = selectMonth;
    vm.selectYear = selectYear;
    if ($rootScope.currentRole){
        vm.current_role = $rootScope.currentRole.name;
    } else{
        vm.current_role = "nada";
    }
    init();

    // inicio
    function init() {
        // --
        vm.months = months;
        vm.years = years(date);
        // -- date
        vm.year = storeService.get('year');
        vm.month = storeService.get('month');
        // --
        title();
        indicadores();
        indicadores_historial();
        indicadores_categories();
        indicadores_morosos();
        indicadores_bills();

    }

    // indicadores
    function indicadores() {
        dashboardDataService.get_indicadores(vm.month, vm.year)
            .then(function (response) {
                vm.indicadores = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // indicadores categorias
    function indicadores_categories() {
        dashboardDataService.get_indicadores_categories(vm.month, vm.year)
            .then(function (response) {
                vm.a1_data = response.data.data;
                vm.a1_labels = response.data.labels;
                vm.a1_series = 'response.data.labels';
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // indicadores hitorial
    function indicadores_historial() {
        dashboardDataService.get_indicadores_historial(vm.month, vm.year)
            .then(function (response) {
                vm.a2_data = response.data.data;
                vm.a2_labels = response.data.labels;
                vm.a2_series = ['egresos', 'ingresos'];
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // indicadores morosos
    function indicadores_morosos() {
        dashboardDataService.get_indicadores_morosos(vm.month, vm.year)
            .then(function (response) {
                vm.morosos = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // indicadores cuentas
    function indicadores_bills() {
        dashboardDataService.get_indicadores_facturas(vm.month, vm.year)
            .then(function (response) {
                vm.a3_data = response.data.data;
                vm.a3_labels = response.data.labels;
                vm.a3_series = ['cuentas'];
                vm.cuentas = response.data.value;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function filter() {
        indicadores();
        indicadores_historial();
        indicadores_categories();
        indicadores_morosos();
        indicadores_bills();
    }

    function show(item) {
        $state.go('app.informacion', {
            id: item.id
        });
    }


    function selectMonth() {
        storeService.set('month', vm.month);
        $rootScope.$broadcast('update-auth-data');
    }

    function selectYear() {
        storeService.set('year', vm.year);
        $rootScope.$broadcast('update-auth-data');
    }

    vm.options = {
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        return $filter('currency')(value, '$', 0);
                    }
                }
            }]
        }
    };

    vm.options_currency = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        return $filter('currency')(value);
                    },
                }
            }],
        }
    };

    // load title sub-toolbar
    function title() {
        vm.item = {
            icon: 'dashboard',
            backUrl: '',
            newUrl: '',
            backActive: false,
            newActive: false,
            label: 'Dashboard',
        };
    }
}
