angular
    .module('app')
    .controller('logsController', logsController);

function logsController(logDataService, log_types, access, toastr, text) {
    var vm = this;
    vm.query = {
        order: ['created_at'],
        limit: 10,
        page: 1
    };
    vm.permission = access;
    vm.types = log_types;

    // --

    init();

    // inicio
    function init() {

        title();
        logs();
    }

    // comunidades
    function logs() {
        logDataService.all()
            .then(function (response) {
                vm.logs = response.data;
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // title
    function title() {
        vm.item = {
            backUrl: '',
            newUrl: '',
            backActive: false,
            newActive: false,
            label: 'Logs',
        };
    }
}