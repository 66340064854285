angular
    .module('app')
    .controller('propertyModalController', propertyModalController)

function propertyModalController($scope, $mdDialog, comunidadDataService,
    usuarioDataService, toastr, text, status, data) {

    // --
    $scope.create = create;
    $scope.cancel = cancel;

    init();

    function init() {
        $scope.usuario = {
            id: null,
            user_id: data.id
        };
        comunidades();
    }

    function create() {
        usuarioDataService.post_properties({
                user_id: data.id,
                property: $scope.propiedad,
                role: $scope.role
            })
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // success
                    toastr.success(text.API_EXITO);
                    $mdDialog.hide({
                        data: response.data
                    });
                }
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function comunidades() {
        comunidadDataService.all()
            .then(function (response) {
                $scope.comunidades = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function cancel() {
        $mdDialog.hide()
    }

};