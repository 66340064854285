angular
    .module('app')
    .controller('forosRWDController', forosRWDController);

function forosRWDController($scope, $state, foroDataService, storeService,
    access, toastr, text) {
    var vm = this;
    vm.user = storeService.get('user');

    // --
    vm.edit = edit;

    init();

    // inicio
    function init() {
        vm.permission = access;
        title();
        foros();
    }

    // actualizar
    function edit(item) {
        $state.go('app.foro_rwd', {
            id: item ? item.id : null
        });
    }

    // get all
    function foros() {
        foroDataService.get_community({
                id: storeService.get('community').id
            }, {
                id: storeService.get('property').division_id
            })
            .then(function (response) {
                vm.foros = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.mensajeria_rwd',
            title: 'Foro',
        };
    }
}