angular
    .module('app')
    .controller('mailController', mailController);

function mailController($scope, $state, $stateParams, $rootScope, mailDataService,
    usuarioDataService, storeService, loading, access, toastr, text) {
    var vm = this;
    var notification = storeService.get('notification');
    vm.user = storeService.get('user');
    vm.mail = {
        id: $stateParams.id ? $stateParams.id : null,
        to_user_id: [],
    };
    // vm.is_processing = false;
    // --
    vm.send = send;
    vm.reply = reply;
    vm.add = add;

    init();

    // inicio
    function init() {
        vm.permission = access;

        if (vm.mail.id) {
            mailDataService.get(vm.mail)
                .then(function (response) {
                    title();
                    vm.mail = response.data.data;
                    notification.mails -= response.data.notifications;
                    storeService.set('notification', notification);
                    $rootScope.$broadcast('update-auth-data');
                })
                .catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            personas();
            title();
        }
    }

    // send
    function send() {
        loading.init();
        // vm.is_processing = true;
        mailDataService.post(vm.mail)
            .then(function (response) {
                toastr.success(text.API_EXITO);
                $state.go('app.mails');
                // vm.is_processing = false;
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                // vm.is_processing = false;
                loading.end();
            });
    }

    // send reply
    function reply() {
        loading.init();
        // vm.is_processing = true;
        mailDataService.post({
                mail_id: vm.mail.id,
                from_user_id: vm.user.id,
                // to_user_id: vm.mail.to_user.email,
                // vm.mail.from_user_id
                to_user_id: (vm.user.id == vm.mail.from_user_id) ? vm.mail.to_user_id : vm.mail.from_user.email,
                text: vm.mail.reply,
                subject: vm.mail.subject
            })
            .then(function (response) {
                toastr.success(text.API_EXITO);
                $state.go('app.mails');
                // vm.is_processing = false;
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                // vm.is_processing = false;
                loading.end();
            });
    }

    function add(item) {
        vm.mail.to_user_id.push(vm.user)
    }

    function personas() {
        if (access.isAdmin()) {
            usuarioDataService.get_company()
                .then(function (response) {
                    vm.personas = response.data;
                })
                .catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            usuarioDataService.get_admin()
                .then(function (response) {
                    vm.personas = response.data;
                })
                .catch(function () {
                    toastr.error(text.API_ERROR);
                });
        }
    }

    // title
    function title() {
        if (access.isCopropietario()) {
            $scope.$parent.vm.item = {
                back: true,
                backUrl: 'app.mails',
                title: 'Mail',
                name: vm.mail.id ? vm.mail.subject : null
            };
        } else {
            vm.item = {
                backUrl: 'app.mails',
                newUrl: '',
                backActive: true,
                newActive: false,
                label: 'Mail',
                name: vm.mail.id ? vm.mail.subject : null
            };
        }
    }
}