angular
    .module('app')
    .service('dashboardDataService', dashboardDataService);

function dashboardDataService(dataService, $rootScope) {
    return {
        get_indicadores: function (month, year) {
            return dataService.httpGet('indicadores/' + (($rootScope.currentCommunity.id == '') ? -1 : $rootScope.currentCommunity.id) + '/' + month + '/' + year);
        },
        get_indicadores_categories: function (month, year) {
            return dataService.httpGet('indicadores/categorias/' + (($rootScope.currentCommunity.id == '') ? -1 : $rootScope.currentCommunity.id) + '/' + month + '/' + year);
        },
        get_indicadores_historial: function (month, year) {
            return dataService.httpGet('indicadores/historial/' + (($rootScope.currentCommunity.id == '') ? -1 : $rootScope.currentCommunity.id) + '/' + month + '/' + year);
        },
        get_indicadores_morosos: function (month, year) {
            return dataService.httpGet('indicadores/morosos/' + (($rootScope.currentCommunity.id == '') ? -1 : $rootScope.currentCommunity.id) + '/' + month + '/' + year);
        },
        get_indicadores_facturas: function (month, year) {
            return dataService.httpGet('indicadores/facturas/' + (($rootScope.currentCommunity.id == '') ? -1 : $rootScope.currentCommunity.id) + '/' + month + '/' + year);
        },
    };
}