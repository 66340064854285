angular
    .module('app')
    .service('reemplazoDataService', reemplazoDataService);

function reemplazoDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('reemplazos', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (reemplazo) {
            return dataService.httpGet('reemplazos/' + reemplazo.id);
        },
        post: function (reemplazo) {
            return dataService.httpPost('reemplazos', reemplazo);
        },
        put: function (reemplazo) {
            return dataService.httpPut('reemplazos/' + reemplazo.id, reemplazo);
        },
        delete: function (reemplazo) {
            return dataService.httpDelete('reemplazos/' + reemplazo.id);
        },
        // --
        get_all: function (comunidad, month, year) {
            return dataService.httpGet('reemplazos/' + comunidad.id + '/' + month + '/' + year);
        },
        validate: function (reemplazos) {
            return dataService.httpPut('reemplazos/validar', reemplazos);
        },
    };
}