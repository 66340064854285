function capitalize(s){
  return s && s[0].toUpperCase() + s.slice(1);
}

function popupWindow(url, windowName, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}

function ResumeController($scope) {
  const ctrl = this;
  ctrl.$onInit = () => {
    $scope.$watch(
      () => ctrl.resume,
      () => {
        const resume = ctrl.resume;

        ctrl.suma_gastos_comunes = 0;
        ctrl.suma_total_gastos_comunes = 0;
        ctrl.suma_gastos_privados = 0;
        ctrl.suma_gastos_particular = 0;
        ctrl.suma_gastos_comunidad = 0;
        ctrl.suma_penalties = 0;

        if (!resume) {
          return;
        }

        ctrl.balance = resume.balance;
        ctrl.person = resume.person;
        ctrl.data = resume.data;
        ctrl.gasto_account_sueldo = resume.account_sueldo;
        ctrl.gasto_general = resume.general;
        ctrl.gastos = resume.details;
        ctrl.gastos_reserva = resume.reserva;
        ctrl.gastos_privados = resume.privates;
        ctrl.gastos_particulares = resume.particulares;
        ctrl.penalties = resume.penalties;
        ctrl.property = resume.property;
  
        ctrl.suma_prorateo = 0.0;
        angular.forEach(ctrl.property.properties, function (property) {
          ctrl.suma_prorateo += parseFloat(property.prorrateo);
        });
        ctrl.suma_prorateo = ctrl.suma_prorateo.toFixed(4);
        ctrl.prorateo_total = (parseFloat(ctrl.suma_prorateo) + parseFloat(ctrl.property.prorrateo)).toFixed(4);
    
        if(isNaN(ctrl.prorateo_total)){
          ctrl.prorateo_total = '';
        }
        
        ctrl.property.type = capitalize(resume.property.type);
        ctrl.credits = resume.credits;

        const sum = (prev, current) => prev + current;
        ctrl.suma_gastos_comunes = ctrl.gastos.map(p => parseFloat(p.amount)).reduce(sum, 0);
        ctrl.suma_total_gastos_comunes = ctrl.gastos.map(p => parseFloat(p.amount_bill)).reduce(sum, 0);
        ctrl.suma_gastos_privados = ctrl.gastos_privados.map(p => parseFloat(p.amount)).reduce(sum, 0);
        ctrl.suma_penalties = ctrl.penalties.map(p => p.amount).reduce(sum, 0);
        ctrl.sum_credit_available = ctrl.credits['avaliable'].map(p => parseFloat(p.amount)).reduce(sum, 0);
        ctrl.sum_credit_consumed = ctrl.credits['pays'].map(p => parseFloat(p.amount)).reduce(sum, 0);

        ctrl.date_of_payment = new Date(ctrl.gasto_general.expired_at);
        ctrl.unidades_incluidas = angular.forEach(ctrl.data.property.properties, function (item) {
          item.type = capitalize(item.type);
        });
        //console.log(ctrl.date_of_payment);
      }
    );
  };
}



angular.module('app').component('resume', {
  templateUrl: 'views/components/resume/resumeComponent.html',
  controller: ['$scope', ResumeController],
  bindings: {
    resume: '<',
    community: '<'
  }
});
