angular.module("app").controller("facturasController", facturasController);

function facturasController(
  $state,
  $rootScope,
  $mdDialog,
  $window,
  facturaDataService,
  confirmService,
  storeService,
  months,
  states_a,
  states_p,
  type_expense,
  access,
  loading,
  toastr,
  text,
  env,
  years
) {
  var vm = this;
  var date = new Date();
  vm.month = storeService.get("month");
  vm.year = storeService.get("year");
  vm.tipo_egresos = type_expense;
  vm.facturas = [];

  vm.query = {
    order: "index",
    limit: 100,
    page: 1,
    search: ""
  };
  // --
  vm.edit = edit;
  vm.approve = approve;
  vm.destroy = destroy;
  vm.filter = filter;
  vm.excel = excel;
  vm.excelParrotfy = excelParrotfy;
  vm.selectMonth = selectMonth;
  vm.selectYear = selectYear;
  vm.egresos_download_template = egresos_download_template;
  vm.approve_all_bills = approve_all_bills;
  vm.egresos_upload = egresos_upload;
  vm.suma_total = 0;

  init();

  // inicio
  function init() {
    title();
    vm.permission = access;
    vm.estado_a = "";
    vm.estado_p = "";
    // --
    vm.estados_a = states_a;
    vm.estados_p = states_p;
    vm.months = months;
    vm.years = years(date);
    // --
    filter();
  }

  // edit item
  function edit(item) {
    $state.go("app.egreso", {
      id: item ? item.id : null
    });
  }

  function approve(item) {
    console.log("approve");
    loading.init();
    try {
      const factura = {};
      const response = facturaDataService.approve(item);
      if(response){
        toastr.success("Egreso Aprobado");
        filter();
      }
    } catch(error) {
      console.log(error);
      toastr.error(error);
    } finally {
      filter();
      loading.end();
    }
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        disabled(item);
      },
      function() {}
    );
  }

  // disabled
  async function disabled(item) {
    item.is_active = false;
    loading.init();
    var answer = null;
    try {
      answer = await facturaDataService.delete(item);
      init();
    } catch (error) {
      toastr.error(text.API_ERROR);
    } finally {
      if (answer.data.state != 'OK'){
        toastr.error(answer.data.message);
      } else{
        toastr.success(text.API_EXITO);
      }
      loading.end();
    }
  }

  function selectMonth() {
    storeService.set("month", vm.month);
    $rootScope.$broadcast("update-auth-data");
  }

  function selectYear() {
    storeService.set("year", vm.year);
    $rootScope.$broadcast("update-auth-data");
  }

  // get filter
  function filter() {
    facturaDataService
      .get_filter(
        {
          estado_a: vm.estado_a,
          type: vm.estado_p,
          month: vm.month,
          year: vm.year
        },
        vm.query
      )
      .then(function(response) {
        vm.data = response.data;
        vm.facturas = response.data.data;
        vm.suma_total = 0;
        angular.forEach(vm.facturas, function (value) {
          vm.suma_total += value.amount;
        });
        //console.log(vm.suma_total);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // excel bills
  function excel() {
    document.location = env.DOMAIN + env.API_PATH + "files/export/facturas/" + $rootScope.currentCommunity.id + "/"+vm.month + "/"+vm.year;
  }

  // excelParrotfy bills
  function excelParrotfy() {
    document.location = env.DOMAIN + env.API_PATH + "files/export/facturas/parrot/" + $rootScope.currentCommunity.id + "/"+vm.month + "/"+vm.year;
  }

  function egresos_download_template() {
    document.location =
      env.DOMAIN +
      env.API_PATH +
      "egresos/template_egresos/" +
      $rootScope.currentCommunity.id;
  }


  function approve_all_bills() {
    console.log("Aprobar todos los egresos");
    loading.init();
    try {
      const factura = {};
      const response = facturaDataService.approve_all($rootScope.currentCommunity.id, vm.month, vm.year);
      if(response){
        toastr.success("Egresos Aprobados");
        filter();
      }
    } catch(error) {
      console.log(error);
      toastr.error(error);
    } finally {
      filter();
      loading.end();
    }
  }

  async function egresos_upload(items) {
    console.log("$state", $state);
    console.log("$rootScope", $rootScope);
    console.log("egresos_upload");
    loading.init();
    vm.data = [];
    for (const item of items) {
      const file = new FormData();
      file.append("file", item);
      // send file
      try {
        const response = await facturaDataService.egresos_import(file, $rootScope.currentCommunity, vm.year, vm.month);
        console.log(response.data);
        if(response.data.state == "ok"){
          toastr.success("Archivo cargado con Éxito");
          filter(0, 1);
          loading.end();
        } else{
          toastr.error(response.data.data[0]);
        }
      } catch (error) {
        console.log(error);
        toastr.error(text.API_ERROR);
      } finally {
        document.getElementById("egresos_file_upload").value = "";
        loading.end();
      }
    }
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "app.egreso",
      backActive: false,
      newActive: true,
      label: "Egresos"
    };
  }

  // table events
  vm.onReorder = function() {
    filter();
  };

  vm.onPaginate = function() {
    filter();
  };

  vm.onSearch = function() {
    filter();
  };
}
