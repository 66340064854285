angular.module("app").controller("propiedadController", propiedadController);

function propiedadController(
  $state,
  $stateParams,
  $rootScope,
  $mdDialog,
  propiedadDataService,
  categoriaDataService,
  comunidadDataService,
  usuarioDataService,
  unidadDataService,
  access,
  confirmService,
  auth_roles,
  property_type,
  community_types,
  months,
  loading,
  toastr,
  status,
  text,
  tooltips
) {
  // --
  var vm = this;
  vm.permission = access;
  vm.tooltips = tooltips;
  vm.property_type = property_type;
  vm.categorias = [];
  vm.propiedad = {
    id: $stateParams.id ? $stateParams.id : null
  };
  // vm.query = {
  //     order: 'name',
  //     limit: 10,
  //     page: 1
  // };

  // --
  vm.edit = edit;
  vm.create = create;
  vm.selectCommunity = selectCommunity;
  vm.selectDivision = selectDivision;
  vm.addUser = addUser;
  vm.addDependencies = addDependencies;
  vm.updateCategories = updateCategories;
  vm.createCategories = createCategories;
  vm.destroy = destroy;
  vm.isRole = isRole;
  vm.detail = detail;
  vm.getMonth = getMonth;

  init();

  // iniciar
  function init() {
    if (vm.propiedad.id) {
      propiedadDataService
        .get(vm.propiedad)
        .then(function(response) {
          vm.propiedad = response.data;

          vm.suma_prorateo = 0.0;
          angular.forEach(vm.propiedad.properties, function (property) {
            vm.suma_prorateo += parseFloat(property.prorrateo);
          });

          angular.forEach(vm.propiedad.cost_commons, function (cost_common) {
            cost_common.cds_sum = 0;
            cost_common.cds_payed_sum = 0;
            angular.forEach(cost_common.cost_common_details, function (cost_common_detail) {
              cost_common.cds_sum += parseInt(cost_common_detail.amount);
              cost_common.cds_payed_sum += parseInt(cost_common_detail.amount);
            }); 
            // console.log("cost_common.cds_sum",cost_common.cds_sum);
          });
          vm.suma_prorateo = vm.suma_prorateo.toFixed(4);
          vm.prorateo_total = (parseFloat(vm.suma_prorateo) + parseFloat(vm.propiedad.prorrateo)).toFixed(4);
          title();
          comunidades();
          categorias();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // editar
  function edit() {
    loading.init();
    propiedadDataService
      .put(vm.propiedad)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // success
          $state.go("app.propiedad", {
            id: response.data.id
          });
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // crear
  function create() {
    loading.init();
    propiedadDataService
      .post(vm.propiedad)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // success
          $state.go("app.propiedad", {
            id: response.data.id
          });
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // select comunidad
  function selectCommunity() {
    angular.forEach(vm.comunidades, function(item) {
      if (item.id == vm.propiedad.division.community_id) {
        vm.divisiones = item.divisions;
      }
    });
    if (vm.propiedad.id) selectDivision();
  }

  // select Division
  function selectDivision() {
    angular.forEach(vm.divisiones, function(item) {
      if (item.id == vm.propiedad.division_id) {
        getTiposPropiedades(item);
      }
    });
  }

  // tipos de propiedades
  function getTiposPropiedades(data) {
    vm.propiedad_tipos = [];
    angular.forEach(community_types, function(value) {
      if (value[0].name == data.type) {
        angular.forEach(value[0].property_type, function(item) {
          vm.propiedad_tipos.push(item);
        });
      }
    });
  }

  // add modal user
  function addUser(ev) {
    $mdDialog
      .show({
        controller: personModalController,
        templateUrl: "views/admin/propiedad/modal/user_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: vm.propiedad ? vm.propiedad : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // add modal user
  function addDependencies(ev) {
    $mdDialog
      .show({
        controller: dependencyModalController,
        templateUrl: "views/admin/propiedad/modal/dependency_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: vm.propiedad ? vm.propiedad : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  function createCategories() {
    var data = {
      data: vm.categorias,
      property_id: vm.propiedad.id
    };
    propiedadDataService
      .post_categorias(data)
      .then(function(res) {
        init();
        toastr.success(text.API_EXITO);
      })
      .catch(function(err) {
        console.log(err);
        toastr.error(text.API_ERROR);
      });
  }

  function updateCategories() {
    var data = {
      data: vm.propiedad.property_categories,
      property_id: vm.propiedad.id
    };
    propiedadDataService
      .put_categorias(vm.propiedad.id, data)
      .then(function(res) {
        init();
        toastr.success(text.API_EXITO);
      })
      .catch(function(err) {
        console.log(err);
        toastr.error(text.API_ERROR);
      });
  }

  function getMonth(item) {
    if (item == 1) return months.ENERO;
    else if (item == 2) return months.FEBRERO;
    else if (item == 3) return months.MARZO;
    else if (item == 4) return months.ABRIL;
    else if (item == 5) return months.MAYO;
    else if (item == 6) return months.JUNIO;
    else if (item == 7) return months.JULIO;
    else if (item == 8) return months.AGOSTO;
    else if (item == 9) return months.SEPTIEMBRE;
    else if (item == 10) return months.OCTUBRE;
    else if (item == 11) return months.NOVIEMBRE;
    else if (item == 12) return months.DICIEMBRE;
  }

  // confirmar para eliminar item
  function destroy(ev, item, option) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        if (option == 0) {
          trashProperties(item);
        } else {
          trashPersons(item);
        }
      },
      function() {}
    );
  }

  // eliminar Persona seleccionada
  function trashPersons(item) {
    usuarioDataService
      .delete_properties(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // eliminar Propiedad seleccionada
  function trashProperties(item) {
    propiedadDataService
      .delete_properties(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // details
  function detail(item) {
    $state.go("app.gasto_comun", {
      id: item.id
    });
  }

  // load categorias
  function categorias() {
    categoriaDataService
      .all({ page: -1, limit: -1, order: -1, search: -1 })
      .then(function(response) {
        vm.categorias = response.data;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // load comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        // --
        vm.divisiones =
          response.data.length > 0
            ? response.data[comunidad(response.data)].divisions
            : null;
        if (vm.propiedad.id) {
          selectCommunity();
        } else {
          vm.propiedad.division = {
            community_id:
              response.data.length > 0
                ? response.data[comunidad(response.data)].id
                : null
          };
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // validation role
  function isRole(item) {
    if (item && item["role"]) {
      if (item.role.name == auth_roles["COPROPIETARIO"]) {
        return true;
      }
    } else {
      return false;
    }
  }

  // load title
  function title() {
    vm.type = vm.propiedad.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "app.propiedades",
      newUrl: "",
      label: vm.type + " Propiedad",
      name: vm.propiedad.id ? vm.propiedad.number.toUpperCase() : ""
    };
  }
}
