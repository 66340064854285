angular
  .module("app")
  .controller("tiposDocumentoController", tiposDocumentoController);

function tiposDocumentoController(
  $state,
  $mdDialog,
  $rootScope,
  tipoDocumentoDataService,
  confirmService,
  loading,
  access,
  toastr,
  env,
  text
) {
  var vm = this;
  vm.query = {
    order: "code",
    limit: 10,
    page: 1,
    search: ""
  };
  // ---
  vm.edit = edit;
  vm.destroy = destroy;

  init();

  // inicio
  function init() {
    vm.permission = access;

    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      getData();
    }
    title();
  }

  // edit item
  function edit(item) {
    $state.go("app.tipoDocumento", {
      id: item.id
    });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function () {
        disabled(item);
      },
      function () { }
    );
  }

  // disabled
  function disabled(item) {
    item.is_active = false;
    loading.init();
    tipoDocumentoDataService
      .delete(item)
      .then(function (response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // load data
  function getData() {
    tipoDocumentoDataService
      .all(vm.query)
      .then(function (response) {
        vm.data = response.data;
        vm.documentos = response.data.data;
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
      });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "person",
      backUrl: "",
      newUrl: "app.tipoDocumento",
      backActive: false,
      newActive: true,
      label: "Tipos de documentos"
    };
  }

  // table events
  vm.onReorder = function () {
    getData();
  };

  vm.onPaginate = function () {
    getData();
  };

  vm.onSearch = function () {
    getData();
  };
}
