angular
  .module("app")
  .controller("repositoriosController", repositoriosController);

function repositoriosController(
  $state,
  $rootScope,
  $mdDialog,
  $window,
  repositorioDataService,
  confirmService,
  loading,
  access,
  toastr,
  env,
  text
) {
  var vm = this;
  vm.query = {
    order: ["title", "type", "size", "community.name"],
    limit: 10,
    page: 1
  };
  // ---
  vm.open = open;
  vm.edit = edit;
  vm.destroy = destroy;

  init();

  // inicio
  function init() {
    title();
    vm.permission = access;
    repositories();
  }

  // edit item
  function edit(item) {
    $state.go("app.repositorio", {
      id: item.id
    });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function () {
        disabled(item);
      },
      function () { }
    );
  }

  // disabled
  function disabled(item) {
    item.is_active = false;
    loading.init();
    repositorioDataService
      .delete(item)
      .then(function (response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // view file
  function open(item) {
    document.location = env.DOMAIN + env.API_PATH + 'repositorios/download/' + item.id;
  }

  // load repositories
  function repositories() {
    repositorioDataService
      .all()
      .then(function (response) {
        vm.repositorios = response.data;
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
      });
  }

  // socket
  $rootScope.$on("emit-socket", function (event, data) {
    repositories();
    console.log(data.data);
  });

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "person",
      backUrl: "",
      newUrl: "app.repositorio",
      backActive: false,
      newActive: true,
      label: "Repositorio"
    };
  }
}
