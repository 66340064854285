angular
    .module('app')
    .controller('empresaController', empresaController);

function empresaController($state, $stateParams, $mdDialog,
    empresaDataService, comunidadDataService, confirmService, loading,
    access, toastr, status, text) {
    var vm = this;
    vm.permission = access;
    vm.empresas = [];
    vm.empresa = {
        id: $stateParams.id
    };

    // ---
    vm.edit = edit;
    vm.create = create;
    vm.addUser = addUser;
    vm.confirm = confirm;

    init();

    // iniciar
    function init() {
        if (vm.empresa.id) {
            empresaDataService.get(vm.empresa)
                .then(function (response) {
                    vm.empresa = response.data;
                    title();
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            title();
        }
    }

    // editar
    function edit() {
        loading.init();
        empresaDataService.put(vm.empresa)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.empresa', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // crear
    function create() {
        loading.init();
        empresaDataService.post(vm.empresa)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.empresa', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // add modal user
    function addUser(ev, item) {
        $mdDialog.show({
                controller: userModalController,
                templateUrl: 'views/admin/empresa/modal/user_modal.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                fullscreen: false,
                locals: {
                    data: (vm.empresa) ? vm.empresa : null
                }
            })
            .then(function (response) {
                if (response) {
                    init();
                }
            });
    }


    // editar
    function updateCommunity(item) {
        loading.init();
        item.is_active = !item.is_active;
        comunidadDataService.put(item)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // confirmar para eliminar item
    function confirm(ev, item) {
        if (item.is_active) {
            // deshabilitar
            $mdDialog.show(confirmService.disabled(ev)).then(function () {
                updateCommunity(item);
            }, function () {});
        } else {
            // habilitar
            $mdDialog.show(confirmService.enabled(ev)).then(function () {
                updateCommunity(item);
            }, function () {});
        }

    }

    // load title
    function title() {
        vm.type = (vm.empresa.id) ? 'Editar' : 'Nueva';
        vm.item = {
            backUrl: 'app.empresas',
            newUrl: '',
            label: vm.type + ' Empresa',
            name: (vm.empresa.id) ? vm.empresa.name.toUpperCase() : '',
        };
    }
}