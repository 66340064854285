angular.module("app").controller("pagosModalController", pagosModalController);

function pagosModalController(
  $scope,
  $mdDialog,
  text,
  toastr,
  payDataService,
  propiedadDataService,
  data,
  loading
) {
  $scope.is_confirm = false;
  $scope.text = text;
  // --
  $scope.destroy = destroy;
  $scope.confirm = confirm;
  $scope.destroy_payment = destroy_payment;
  $scope.destroy_credit = destroy_credit;
  $scope.view_payment = view_payment;
  $scope.item_total_amount = item_total_amount;
  $scope.destroy_item = destroy_item;
  $scope.can_view = can_view;
  $scope.cancel = cancel;
  $scope.back = back;
  $scope.property = data.property;
  $scope.payments = null;
  $scope.payment = data.payment;

  init();

  // --
  async function init(){
    const response = await propiedadDataService.get_payments(data.property.id);
    $scope.web_pays_groups = [];
    $scope.totals = [];
    $scope.actives = [];
    angular.forEach(response.data, function (group, key) {
      // ver si es pago o webpay o credit
      if(group.hasOwnProperty('p_type')){
        var sum = group.web_pays.map(p => parseInt(p.amount)).reduce((prev, current) => prev + current, 0);
        var sum_active = group.web_pays.filter(p => p.is_active === 1).map(p => parseInt(p.amount)).reduce((prev, current) => prev + current, 0);
        var actives = group.web_pays.filter(p => p.is_active === 1).length;
        if (sum_active > 0){
          $scope.totals[key] = sum_active;
        } else {
          $scope.totals[key] = sum;
        }
        $scope.actives[key] = actives;
        $scope.web_pays_groups[key] = group;
      }
      if(group.hasOwnProperty('complete_group')){
        if(!group.payment_id){
          //console.log("TEST webpays fuera de payments");
          var sum = group.complete_group.map(p => parseInt(p.amount)).reduce((prev, current) => prev + current, 0);
          var sum_active = group.complete_group.filter(p => p.is_active === 1).map(p => parseInt(p.amount)).reduce((prev, current) => prev + current, 0);
          var actives = group.complete_group.filter(p => p.is_active === 1).length;
          if (sum_active > 0){
            $scope.totals[key] = sum_active;
          } else {
            $scope.totals[key] = sum;
          }
          $scope.actives[key] = actives;
          $scope.web_pays_groups[key] = group;
        }
      }
      if(group.hasOwnProperty('balance')){
        if(!group.payment_id){
          //console.log("TEST credits fuera de payments");
          group.web_pays = [];
          var sum = group.amount;
          var actives = sum;
          $scope.totals[key] = sum;
          $scope.actives[key] = actives;
          $scope.web_pays_groups[key] = group;
        }
      }
    });
    //console.log($scope.web_pays_groups);
    return $scope.web_pays_groups.filter(p => p.date != null);
  }

  function destroy() {
    payDataService
      .delete($scope.to_deleted)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
        //callback();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        //callback();
      });
  }

  function view_payment(ev, item) {
    if(item.hasOwnProperty('p_type')){
      $mdDialog.show({
        preserveScope: true,
        multiple: true,
        controller: paymentDetailModalController,
        templateUrl: 'views/admin/pago/modal/payment_details_modal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: item
        }
      })
      .then(function (response) {
        if (response) {
          // ev();
          //console.log("relodear modal de payments general de propiedad");
        }
      });
    }
  }

  function item_total_amount(item){
    if(item.hasOwnProperty('p_type') || item.credits || item.hasOwnProperty('balance')){
      if(item.hasOwnProperty('p_type') ){
        //console.log("SI");
        return item.amount; // + item.web_pays.map(p => parseInt(p.amount)).reduce((prev, current) => prev + current, 0);
      }
      if(item.credits){
        if(item.credits.length > 0 && !item.hasOwnProperty('p_type')){
          //console.log("NO");
          return item.complete_group.map(p => parseInt(p.amount)).reduce((prev, current) => prev + current, 0) + item.credits.map(p => parseInt(p.amount)).reduce((prev, current) => prev + current, 0);
          //return item.amount;
        }
      }
      if(item.hasOwnProperty('balance') ) {
        return item.amount;
      }
    } else {
      // es un descuento automático
      return item.amount;
    }
  }

  function can_view(item){
    if(item.hasOwnProperty('p_type') && item.is_active ){
      //console.log("SI");
      return true;
    }
    if(item.hasOwnProperty('complete_group') && item.is_active ){
      //console.log("NO");
      return false;
    }
    if(item.hasOwnProperty('balance') && item.is_active ) {
      //console.log("NO");
      return false;
    }
  }

  function destroy_payment(item) {
    payDataService
      .delete_payment(item)
      .then(function(response) {
        console.log(response.data.message);
        $mdDialog.hide(response.data.message);
        toastr.success(text.API_EXITO);
        //callback();
      })
      .catch(function() {
        $mdDialog.hide("Error");
        toastr.error(text.API_ERROR);
        // callback();
      });
  }

  function destroy_credit(item) {
    $mdDialog.show(payDataService
      .delete_credit(item)
      .then(function(response) {
        $mdDialog.hide(response.message);
        toastr.success(text.API_EXITO);
        // callback();
      })
      .catch(function() {
        $mdDialog.hide("Error");
        toastr.error(text.API_ERROR);
        // callback();
      }));
  }

  function destroy_webpay(item) {
    // payDataService
    //   .delete(item)
    //   .then(function(response) {
    //     $mdDialog.hide({
    //       data: response.data
    //     });
    //     toastr.success(text.API_EXITO);
    //     loading.end();
    //     callback();
    //   })
    //   .catch(function() {
    //     toastr.error(text.API_ERROR);
    //     loading.end();
    //     callback();
    //   });
  }

  function destroy_item(item){
    //console.log(item);
    if(item.hasOwnProperty('p_type') ){
      console.log('payment');
      destroy_payment(item);
    }
    if(item.hasOwnProperty('complete_group')){
      console.log('complete_group');
      confirm(item);
    }
    if(item.hasOwnProperty('balance')) {
      console.log('destroy_credit');
      destroy_credit(item);
    }
    loading.end();
  }

  // confirmar para eliminar item
  function confirm(item) {
    $scope.to_deleted = item;
    $scope.is_confirm = true;
  }

  function back() {
    $scope.to_deleted = null;
    $scope.is_confirm = false;
  }

  function cancel() {
    $mdDialog.hide();
  }
}
