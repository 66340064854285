angular.module("app").service("gastoDataService", gastoDataService);

function gastoDataService(dataService, $rootScope, $http) {
  return {
    all: function() {
      return dataService.httpGet("gastos");
    },
    get: function(propiedad) {
      return dataService.httpGet("gastos/" + propiedad.id);
    },
    post: function(propiedad) {
      return dataService.httpPost("gastos", propiedad);
    },
    put: function(propiedad) {
      return dataService.httpPut("gastos/" + propiedad.id, propiedad);
    },
    delete: function(propiedad) {
      return dataService.httpDelete("gastos/" + propiedad.id);
    },
    // --
    get_filter: function(community, division, month, year, cuenta, regla, factura) {
      return dataService.httpGet(
        "gastos/filter/" +
          community.id +
          "/" +
          division.id +
          "/" +
          month +
          "/" +
          year +
          "/" +
          (cuenta ? cuenta.id : -1) +
          "/" +
          (regla ? regla.id : -1) +
          "/" +
          (factura ? factura.id : -1)
      );
    },
    get_movimiento_general: function(community, division, month, year, account, rule) {
      return dataService.httpGet(
        "gastos/mediciones/" +
          community.id +
          "/" +
          division.id +
          "/" +
          month +
          "/" +
          year +
          "/" +
          (account ? account.id : -1) +
          "/" +
          (rule ? rule.id : -1)
      );
    },
    post_general: function(general) {
      return dataService.httpPost("gastos/generales", general);
    },
    // allByCommunity: function () {
    //     return dataService.httpGet('gastos', {
    //         headers: {
    //             'x-comunidad': $rootScope.currentCommunity.id
    //         },
    //     })
    // }

    import_gastos_particulares: function (file, community, division, year, month) {
      return dataService.httpPost('gastos_particulares/import/'+community.id+"/"+division.id+"/"+year+"/"+month, file, {
        withCredentials: false,
        headers: {
            'Content-Type': undefined
        },
        transformRequest: angular.identity
      });
    },

    get_egresos_division: function(community, month, year) {
      return dataService.httpGet(
        "gastos/division/" + community.id + "/" + month + "/" + year
      );
    },

    get_cost_divisions: function(community, factura_division) {
      return dataService.httpGet(
        "gastos/comunidad/" + community.id + "/" + factura_division
      );
    },

    save_cost_divisions: function(community, factura_division, cost_divisions) {
      return dataService.httpPost("gastos/cost_divisions/" + community.id + "/" + factura_division, cost_divisions);
    },
  };
}
