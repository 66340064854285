angular
    .module('app')
    .controller('bankModalController', bankModalController)

function bankModalController($scope, $mdDialog, bancoDataService,
    toastr, banks, bank_types, text, community, bank) {

    $scope.init = function () {
        if (bank) {
            $scope.bank = bank;
        } else {
            $scope.bank = {
                community_id: (community) ? community.id : null,
            };
        }
        $scope.banks = banks;
        $scope.bank_types = bank_types;
    }
    $scope.init();

    $scope.cancel = function () {
        $mdDialog.hide()
    }

    $scope.create = function () {
        bancoDataService.post($scope.bank)
            .then(function (response) {
                $mdDialog.hide({
                    bank: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    $scope.update = function () {
        bancoDataService.put($scope.bank)
            .then(function (response) {
                $mdDialog.hide({
                    bank: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    $scope.save = function () {
        if ($scope.bank.id) {
            $scope.update();
        } else {
            $scope.create();
        }
    }
};