angular.module("app").service("cartolaDataService", cartolaDataService);

function cartolaDataService(dataService) {
  return {
    find: function(propertyId, params) {
      return dataService.httpGet(
        `transaction/${propertyId}`,
        { params }
      );
    },
  };
}
