angular
    .module('app')
    .controller('filesRWDModalController', filesRWDModalController)

function filesRWDModalController($scope, $window, $mdDialog, env, data) {
    // --
    $scope.cancel = cancel;
    $scope.open = open;

    init();

    // init
    function init() {
        $scope.files = data;
    }

    // view file
    function open(ev, item) {
        document.location = env.DOMAIN + env.FILE_PATH + item.title + '.' + item.type;
        // $window.open(env.DOMAIN + env.FILE_PATH + item.title + '.' + item.type);
    }

    // cancel
    function cancel() {
        $mdDialog.hide()
    }

};