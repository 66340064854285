angular
  .module("app")
  .controller("horarioModalController", horarioModalController);

function horarioModalController(
  $scope,
  $rootScope,
  $mdDialog,
  usuarioDataService,
  horarioDataService,
  comunidadDataService,
  storeService,
  days,
  access,
  toastr,
  text,
  data,
  community,
  division
) {
  $scope.days = days;
  $scope.permission = access;
  $scope.horario = {};

  // --
  $scope.store = store;
  $scope.update = update;
  $scope.destroy = destroy;
  $scope.cancel = cancel;
  $scope.selectCommunity = selectCommunity;

  init();

  // inicio
  function init() {
    comunidades();
    personas();
    if (data) {
      $scope.horario = data;
    } else {
      $scope.horario.community_id = community ? community.id : null;
      $scope.horario.division_id = division ? division.id : null;
    }
  }

  // update
  function update() {
    horarioDataService
      .put($scope.horario)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // store
  function store() {
    horarioDataService
      .post($scope.horario)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // destroy
  function destroy() {
    horarioDataService
      .delete($scope.horario)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // close
  function cancel() {
    $mdDialog.hide();
  }

  // personas
  function personas() {
    usuarioDataService
      .get_comunidad()
      .then(function(response) {
        $scope.personas = response.data;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // select community
  function selectCommunity() {
    angular.forEach($scope.comunidades, function(value) {
      if (value.id == $scope.horario.community_id) {
        $scope.divisiones = value.divisions;
      }
    });
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        $scope.comunidades = response.data;
        selectCommunity();

        // } else {
        //     angular.forEach(response.data, function (item) {
        //         if (item.id == $scope.informacion.community_id) {
        //             $scope.divisiones = item.divisions;
        //         }
        //     })
        // }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          $scope.is_current = true;
        }
      });
    } else {
      $scope.is_current = false;
    }
    return index;
  }
}
