angular
    .module('app')
    .controller('costsModalController', costsModalController);

function costsModalController($scope, gastoPropiedadDataService, $mdDialog,
    access, toastr, text, data, cost, accounts) {
    // --
    $scope.query = {
        order: ['property.number']
    }
    $scope.is_detail = false;
    $scope.is_delete = false;
    $scope.is_change = false;

    // --
    $scope.edit = edit;
    $scope.update = update;
    $scope.destroy = destroy;
    $scope.confirm = confirm;
    $scope.back = back;
    $scope.cancel = cancel;


    $scope.accounts = accounts;
    init();

    // inicio
    function init() {
        gastoPropiedadDataService.get_costProperty(data, cost)
            .then(function (response) {
                $scope.gastos = response.data;
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
        $scope.text = text;
        $scope.permission = access;
    }

    // edit item
    function edit(item) {
        $scope.is_detail = true;
        var valor = item.amount;
        $scope.gasto = item;
        $scope.gasto.amount = Math.abs(item.amount);
        if (valor < 0) {
            $scope.is_cost = 0;
        } else {
            $scope.is_cost = 1;
        }
    }

    // update item
    function update() {
        if ($scope.is_cost == 0) {
            $scope.gasto.amount = $scope.gasto.amount * -1;
        }
        gastoPropiedadDataService.put($scope.gasto)
            .then(function (response) {
                $scope.is_detail = false;
                $scope.is_change = true;
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // confirmar item a eliminar
    function confirm(item) {
        $scope.gasto = item;
        $scope.is_delete = true;
    }

    // eliminar item
    function destroy() {
        gastoPropiedadDataService.delete($scope.gasto)
            .then(function (response) {
                init();
                $scope.is_delete = false;
                $scope.is_change = true;
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // back view
    function back() {
        $scope.is_detail = false;
        $scope.is_delete = false;

        if ($scope.is_cost == 0) {
            $scope.gasto.amount = $scope.gasto.amount * -1;
        }
    }

    // hide view
    function cancel() {
        $mdDialog.hide({
            data: $scope.is_change
        })
    }
}