angular
    .module('app')
    .service('facturaFileDataService', facturaFileDataService);

function facturaFileDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('facturas/files');
        },
        get: function (factura) {
            return dataService.httpGet('facturas/files/' + factura.id);
        },
        post: function (factura) {
            return dataService.httpPost('facturas/files', factura);
        },
        put: function (factura) {
            return dataService.httpPut('facturas/files/' + factura.id, factura);
        },
        delete: function (factura) {
            return dataService.httpDelete('facturas/files/' + factura.id);
        },
        // --
        upload: function (files) {
            return dataService.httpPost('facturas/files/upload', files, {
                withCredentials: false,
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity
            });
        },
    };
}