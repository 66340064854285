angular.module("app").service("cuentaDataService", cuentaDataService);

function cuentaDataService(dataService, $rootScope) {
  return {
    all: function(query) {
      return dataService.httpGet(
        `cuentas?page=${query.page}&limit=${query.limit}&order=${
          query.order
        }&search=${query.search}`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          }
        }
      );
    },
    get: function(cuenta) {
      return dataService.httpGet("cuentas/" + cuenta.id);
    },
    post: function(cuenta) {
      return dataService.httpPost("cuentas", cuenta);
    },
    put: function(cuenta) {
      return dataService.httpPut("cuentas/" + cuenta.id, cuenta);
    },
    delete: function(cuenta) {
      return dataService.httpDelete("cuentas/" + cuenta.id);
    },
    // --
    // --
    post_providers: function(providers) {
      return dataService.httpPost("cuentas/proveedores", providers);
    },
    delete_providers: function(provider) {
      return dataService.httpDelete(
        "cuentas/proveedores/" +
          provider.account_id +
          "/" +
          provider.provider_id
      );
    },
    post_communities: function(communities) {
      return dataService.httpPost("cuentas/comunidades", communities);
    },
    update_sueldo: function(data) {
      return dataService.httpPut("cuentas/sueldo", data);
    },
    get_sueldo: function(comunidad) {
      return dataService.httpGet("cuentas/sueldo/" + comunidad.id);
    }
  };
}
