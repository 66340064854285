angular
    .module('app')
    .service('bancoDataService', bancoDataService);

function bancoDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('bancos');
        },
        get: function (banco) {
            return dataService.httpGet('bancos/' + banco.id);
        },
        post: function (banco) {
            return dataService.httpPost('bancos', banco);
        },
        put: function (banco) {
            return dataService.httpPut('bancos/' + banco.id, banco);
        },
        delete: function (banco) {
            return dataService.httpDelete('bancos/' + banco.id);
        },
    };
}