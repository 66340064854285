angular
    .module('app')
    .controller('reservaRWDController', reservaRWDController);

function reservaRWDController($scope, $rootScope, $state, reservacionDataService,
    storeService, reserva_states, access, toastr, text) {
    var vm = this;
    var notification = storeService.get('notification');
    vm.query = ['date', 'hour_from', 'hour_to'];
    vm.states = reserva_states;
    // --
    vm.add = add;
    vm.date = new Date();
    vm.onDateChange = onDateChange;

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        reservas();
    }

    function add() {
        $state.go('app.reserva_rwd');
    }

    // load reservas
    function reservas() {
        reservacionDataService.get_division({
                id: storeService.get('property').division_id
            }, vm.date.toISOString().substr(0, 10))
            .then(function (response) {
                vm.reservas = response.data.data;
                notification.reservations -= response.data.notifications;
                storeService.set('notification', notification);
                $rootScope.$broadcast('update-auth-data');
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // socket
    var listenerCleanFn = $rootScope.$on('emit-socket', function (event, data) {
        notification.reservations++;
        storeService.set('notification', notification);
        $rootScope.$broadcast('update-auth-data');
        reservas();
        console.log(data.data);
    });

    $scope.$on('$destroy', function () {
        listenerCleanFn();
    });

    // load title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.servicios_rwd',
            title: 'Reservas'
        };
    }

    function onDateChange() {
        if (vm.date) {
            reservas();
        }
    }
}