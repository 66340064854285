angular
    .module('app')
    .service('reservacionDataService', reservacionDataService);

function reservacionDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('reservaciones', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (reserva) {
            return dataService.httpGet('reservaciones/' + reserva.id);
        },
        post: function (reserva) {
            return dataService.httpPost('reservaciones', reserva);
        },
        put: function (reserva) {
            return dataService.httpPut('reservaciones/' + reserva.id, reserva);
        },
        delete: function (reserva) {
            return dataService.httpDelete('reservaciones/' + reserva.id);
        },
        // --
        get_division: function (division, date) {
            return dataService.httpGet('reservaciones/division/' + division.id, {params:{date}});
        },

    };
}