angular
    .module('app')
    .controller('forgotController', forgotController);

function forgotController($state, $auth, $stateParams, $location, passwordDataService, status, toastr, text) {
    var vm = this;
    vm.data = {
        rut: null,
        user: !!$location.$$search.user,
        fromMobileApp: ($location.$$search.mobile == 'ok'),
        newUser: ($location.$$search.newuser == 'true')
    };
    // --
    vm.send = send;
    vm.back = back;
    init();

    // Inicio
    function init() {
        isLoading(false);
    }

    // send email
    async function send() {
        isLoading(true);
        try {
            await passwordDataService.forgot(vm.data);
            toastr.success(text.PASSWORD_FORGOT);
            if (vm.data.fromMobileApp){
                $state.go('app.forgotok');
            }
            else{
                back();
            }
        } catch (error) {
            if (error.data.state == status.ERROR) {
                toastr.error(error.data.msg);
            } else {
                toastr.error(text.PASSWORD_ERROR);
            }
        } finally {
            isLoading(false);
        }
    }

    // return 
    function back() {
        $state.go('app.login');
    }

    // set is_loading
    function isLoading(opt) {
        vm.is_loading = opt;
    }
}