angular
  .module("app")
  .controller("personModalController", personModalController);

function personModalController(
  $scope,
  $mdDialog,
  usuarioDataService,
  propiedadDataService,
  toastr,
  text,
  auth_roles,
  status,
  data
) {
  // --
  // $scope.role = auth_roles.COPROPIETARIO;
  $scope.usuarios = [];
  // --
  $scope.create = create;
  $scope.cancel = cancel;

  init();

  function init() {
    $scope.usuario = {
      id: null,
      property_id: data.id
    };
    usuarios();
  }

  function create() {
    propiedadDataService
      .post_user({
        division_id: data.division_id,
        property_id: data.id,
        user: $scope.usuario,
        role: $scope.role
      })
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // success
          toastr.success(text.API_EXITO);
          $mdDialog.hide({
            data: response.data
          });
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function usuarios() {
    usuarioDataService
      .all({ page: -1, limit: -1, search: -1, order: -1 })
      .then(function(response) {
        // $scope.usuarios = response.data;
        angular.forEach(response.data, function(value) {
          if (validateRole(value.roles)) {
            // add user
            $scope.usuarios.push(value);
          }
        });
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function validateRole(items) {
    var index = false;
    angular.forEach(items, function(value) {
      if (value.role.name == auth_roles.COPROPIETARIO) {
        index = true;
      }
    });
    return index;
  }

  function cancel() {
    $mdDialog.hide();
  }
}
