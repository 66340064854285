angular
    .module('app')
    .controller('balanceController', balanceController);

function balanceController($state, $window, $rootScope, comunidadDataService, balanceDataService,
    storeService, access, months, toastr, text, env, years) {
    var vm = this;
    var date = new Date();
    vm.permission = access;
    vm.saldo = [];
    vm.suma = 0;

    vm.query = {
        order: ['created_at'],
        limit: 10,
        page: 1
    };

    // ---
    vm.filter = filter;
    vm.setSaldo = setSaldo;
    vm.excel = excel;
    vm.selectMonth = selectMonth;
    vm.selectYear = selectYear;

    init();

    // inicio
    function init() {
        // --
        vm.months = months;
        vm.years = years(date);
        // -- date
        vm.month = storeService.get('month');
        vm.year = storeService.get('year');
        // --
        comunidades();
        title();
        // indicadores();
    }

    // indicadores
    function indicadores() {
        balanceDataService.get_indicadores(vm.comunidad, vm.month, vm.year)
            .then(function (response) {
                vm.indicadores = response.data;
            });
    }

    // filtrar 
    function filter(option) {
        vm.indicadores = [];
        if (option == 0) {
            if (vm.month != -1) {
                if (vm.year == -1) {
                    vm.year = date.getFullYear();
                    indicadores();
                } else {
                    indicadores();
                }
            } else {
                vm.year = -1;
                indicadores();
            }
        } else {
            if (vm.year != -1) {
                if (vm.month != -1) {
                    indicadores();
                }
            } else {
                vm.month = -1;
                indicadores();
            }
        }

    }

    // set saldo
    function setSaldo(item, key) {
        var suma = vm.suma + (item.provider ? (item.amount * -1) : item.amount);
        vm.suma = suma;
        vm.saldo[key] = suma;
    }

    // download file
    function excel() {
        document.location = env.DOMAIN + env.API_PATH + 'files/export/balance/' + (($rootScope.currentCommunity.id) ? $rootScope.currentCommunity.id : -1) + '/' + vm.month + '/' + vm.year;
    }

    function selectMonth() {
        storeService.set('month', vm.month);
        $rootScope.$broadcast('update-auth-data');
    }

    function selectYear() {
        storeService.set('year', vm.year);
        $rootScope.$broadcast('update-auth-data');
    }

    // comunidades
    function comunidades() {
        comunidadDataService.all()
            .then(function (response) {
                vm.comunidades = response.data;
                // vm.comunidad = response.data[0];
                // vm.division = response.data[0].divisions[0];
                // --
                var index = comunidad(response.data);
                vm.comunidad = response.data[index];
                vm.division = (response.data.length > 0) ? response.data[index].divisions[0] : null;
                if (response.data.length > 0) {
                    indicadores();
                }
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // get comunidad rootScope
    function comunidad(items) {
        var index = 0;
        if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
            angular.forEach(items, function (item, key) {
                if (item.id == $rootScope.currentCommunity.id) {
                    index = key;
                    vm.is_current = true;
                }
            });
        } else {
            vm.is_current = false;
        }
        return index;
    }

    // load title sub-toolbar
    function title() {
        vm.item = {
            icon: '',
            backUrl: '',
            newUrl: '',
            backActive: false,
            newActive: false,
            label: 'Balance',
        };
    }
}
