angular
    .module('app')
    .controller('homeRWDController', homeRWDController);

function homeRWDController($state, $scope, $rootScope, authService,
    usuarioDataService, propiedadDataService, horarioDataService,
    storeService, access, env, toastr, text) {
    var vm = this;
    var date = new Date();
    vm.comunidades = [];
    vm.user = storeService.get('user');
    vm.role = storeService.get('role');

    // --
    vm.show = show;
    vm.selectCommunity = selectCommunity;
    vm.selectProperty = selectProperty;

    init();

    // inicio
    function init() {
        title();
        comunidades();
        // -- date
        vm.year = date.getFullYear();
        vm.month = date.getMonth();
        // -- state cost
        vm.permission = access;
    }

    // redirect to
    function show(item) {
        $state.go(item);
    }

    // select community
    function selectCommunity() {
        storeService.set('community', {
            id: vm.comunidad.id,
            name: vm.comunidad.name,
            code: vm.comunidad.code
        });
        user();
    }

    function selectProperty() {
        storeService.set('property', vm.propiedad);
        horario();
    }

    function horario() {
        horarioDataService.get_people(vm.comunidad, {
                id: vm.propiedad.division_id
            })
            .then(function (response) {
                vm.turnos = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            })
    }

    // load communities
    function comunidades() {
        usuarioDataService.get_communities()
            .then(function (response) {
                vm.comunidades = response.data;
                user();
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // load user auth
    function user() {
        // -- rootScope
        if (authService.isAuthenticated()) {
            $rootScope.isAuthenticated = true;
            $rootScope.currentUser = storeService.get('user');
            $rootScope.currentCommunity = storeService.get('community');
            // --
            // change();
        } else {
            $rootScope.isAuthenticated = false;
            $rootScope.currentUser = null;
            $rootScope.currentCommunity = null;
        }
    }

    // load title sub-toolbar
    function title() {
        $scope.$parent.vm.item = {
            back: false,
            backUrl: '',
            title: 'Home',
        };
    }
}