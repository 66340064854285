angular.module("app").controller("tesoreriaController", tesoreriaController);

function tesoreriaController(
  $state,
  $rootScope,
  $mdDialog,
  comunidadDataService,
  tesoreriaDataService,
  confirmService,
  storeService,
  state_cost,
  access,
  months,
  env,
  states_p,
  loading,
  toastr,
  text,
  years
) {
  // --
  var vm = this;
  var date = new Date();
  vm.states_p = states_p;
  vm.query = {
    order: ["month", "year", "amount"]
  };

  vm.options = [['',''],[states_p.PAGADA,'Pagada'],[states_p.NO_PAGADA,'Pendiente']];

  // --
  vm.selectMonth = selectMonth;
  vm.selectYear = selectYear;
  vm.getData = getData;
  vm.addPaid = addPaid;
  vm.addPayList = addPayList;

  vm.FILE_URL = env.DOMAIN + env.API_PATH + 'facturas/files/download';

  init();

  // inicio
  function init() {
    title();
    comunidades();
    vm.months = months;
    vm.years = years(date);
    // -- date
    vm.year = storeService.get("year");
    vm.month = storeService.get("month");
    // -- state cost
    vm.permission = access;
    vm.estados = state_cost;
  }

  // mostrar modal manual
  function addPaid(ev, item) {
    if (item.cost_morosidad > 0) {
      $mdDialog.show(confirmService.morosidad(ev, item.cost_morosidad)).then(
        function() {},
        function() {}
      );
    } else {
      $mdDialog
        .show({
          controller: pagoTesoreriaModalController,
          templateUrl: "views/admin/tesoreria/modal/paid_modal.html",
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: false,
          fullscreen: false,
          locals: {
            data: item ? item : null
          }
        })
        .then(function(response) {
          if (response) {
            getData();
          }
        });
    }
  }

  // mostrar modal observation
  function addPayList(item) {
    $mdDialog
      .show({
        controller: pagosTesoreriaModalController,
        templateUrl: "views/admin/tesoreria/modal/payments_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: item
        }
      })
      .then(function(response) {
        if (response) {
          getData();
        }
      });
  }

  function selectMonth() {
    storeService.set("month", vm.month);
    $rootScope.$broadcast("update-auth-data");
  }

  function selectYear() {
    storeService.set('year', vm.year);
    $rootScope.$broadcast('update-auth-data');
  }

  // sumar totales
  function sum(data) {
    vm.suma_amount = parseFloat(0);
    vm.suma_amount_to_pay = parseFloat(0);
    vm.suma_amount_paid = parseFloat(0);
    angular.forEach(data, function (value){
      vm.suma_amount += value.amount;
      vm.suma_amount_to_pay += (value.amount - value.paid);
      vm.suma_amount_paid += value.paid;
    });
  }

  // gastos comunes
  function getData(flag = 0) {
    if (vm.comunidad && vm.division) {
      if (flag == 0) loading.init();
      vm.gastos = [];
      tesoreriaDataService
        .getEgresosByDivision(vm.comunidad, vm.division, vm.month, vm.year)
        .then(function(response) {
          vm.data = response.data;
          response.data.forEach(element => {
            element.paid = 0;
            element.bill_pays.forEach(value => {
              if (!value.deleted_at) {
                element.paid += parseInt(value.amount);
              }
            });
          });
          if(vm.filter_status){
            vm.data = vm.data.filter(
              (f) => f.state_p == vm.filter_status
            );
          }
          sum(vm.data);
          loading.end();
        })
        .catch(function() {
          loading.end();
          toastr.error(text.API_ERROR);
        });
    }
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .allWithDivisions()
      .then(function(response) {
        vm.comunidades = response.data;
        // vm.comunidad = response.data[0];
        // vm.division = response.data[0].divisions[0];
        // --
        var index = comunidad(response.data);
        vm.comunidad = response.data[index];
        // cargar todas las divisiones primero, por egresos que no tienen división asignada
        vm.division = -1;
        //vm.division = (response.data.length > 0 ? response.data[index].divisions[0] : null);
        if (response.data.length > 0) {
          getData();
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: true,
      label: "Tesoreria"
    };
  }
}
