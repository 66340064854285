angular.module("app").controller("horarioController", horarioController);

function horarioController(
  $state,
  $rootScope,
  $mdDialog,
  horarioDataService,
  comunidadDataService,
  confirmService,
  days,
  access,
  toastr,
  text
) {
  var vm = this;
  var color_index = -1;
  vm.permission = access;
  vm.horarios = [];
  vm.hours = Array.from(Array(23), (x, index) => index + 1);

  // --
  vm.add = add;
  vm.selectDivision = selectDivision;

  init();

  // inicio
  function init() {
    title();
    comunidades();
  }

  // select division
  function selectDivision() {
    vm.horarios = [];
    color_index = -1;
    filter();
  }

  // edit item
  function add(item) {
    $mdDialog
      .show({
        controller: horarioModalController,
        templateUrl: "views/admin/horario/modal/detail_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: item ? item : null,
          community: vm.comunidad,
          division: vm.division
        }
      })
      .then(function(response) {
        if (response) {
          selectDivision();
        }
      });
  }

  // validare in array
  function in_array(value, items) {
    var index = false;
    angular.forEach(items, function(item) {
      if (
        item.day == value.day &&
        item.id == value.id &&
        item.hour_from == value.hour_from &&
        item.hour_to == value.hour_to
      ) {
        index = true;
      }
    });
    return index;
  }

  // add color item
  function in_colors(value, items) {
    var colors = ["bg-primary", "bg-warning", "bg-default", "bg-accent"];
    if (!is_exist(value, items)) {
      color_index++;
      return colors[color_index];
    } else {
      return colors[color_index];
    }
  }

  // validare exist
  function is_exist(value, items) {
    var index = false;
    angular.forEach(items, function(item) {
      if (item.user == value.user_id) {
        index = true;
      }
    });
    return index;
  }

  // filter
  function filter() {
    if (vm.comunidad && vm.division) {
      horarioDataService
        .get_filter(vm.comunidad, vm.division)
        .then(function(response) {
          angular.forEach(response.data, function(value) {
            angular.forEach([1, 2, 3, 4, 5, 6, 7], function(day) {
              angular.forEach(vm.hours, function(item) {
                if (
                  parseInt(value.hour_from) <= item + 1 &&
                  parseInt(value.hour_to) >= item &&
                  day == parseInt(value.day)
                ) {
                  if (!in_array(value, vm.horarios)) {
                    vm.horarios.push({
                      day: day,
                      hour: item,
                      user: value.user_id,
                      id: value.id,
                      data: value,
                      color: in_colors(value, vm.horarios)
                    });
                    // console.log(vm.horarios);
                  }
                }
              });
            });
          });
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    }
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        // --
        vm.comunidad = response.data[comunidad(response.data)];
        // if (response.data.length > 0) {
        //     selectCommunity();
        // }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: false,
      label: "Horario"
    };
  }
}
