angular
  .module("app")
  .controller("pagoTesoreriaModalController", pagoTesoreriaModalController);

function pagoTesoreriaModalController(
  $scope,
  $mdDialog,
  tesoreriaDataService,
  method_pay,
  banks,
  bank_types,
  toastr,
  text,
  data
) {
  $scope.banks = banks;
  $scope.bank_types = bank_types;
  $scope.amount_pay = 0;
  $scope.item_to_pay = null;
  // --
  $scope.cancel = cancel;
  $scope.create = create;

  init();

  // --
  function init() {
    $scope.pays = method_pay;
    $scope.data = data;
    $scope.data.p_amount = parseInt(data.amount - data.paid);
    $scope.data.to_amount = parseInt(data.amount - data.paid);
  }

  function cancel() {
    $mdDialog.hide();
  }

  function create() {
    tesoreriaDataService
      .post($scope.data)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }
}
