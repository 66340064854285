angular
  .module("app")
  .controller("recordatorioController", recordatorioController);

function recordatorioController(
  $state,
  $stateParams,
  $rootScope,
  recordatorioDataService,
  comunidadDataService,
  remind_states,
  informations_type,
  remind_period,
  access,
  loading,
  toastr,
  text
) {
  // --
  var vm = this;
  vm.permission = access;
  vm.recordatorio = {
    id: $stateParams.id
  };
  vm.categorias = informations_type;
  vm.periodos = remind_period;
  vm.estados = remind_states;

  // ---
  vm.create = create;
  vm.edit = edit;
  vm.selectCommunity = selectCommunity;

  init();

  // iniciar
  function init() {
    if (vm.recordatorio.id) {
      recordatorioDataService
        .get(vm.recordatorio)
        .then(function(response) {
          vm.recordatorio = response.data;
          title();
          comunidades();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // editar
  function edit() {
    loading.init();
    recordatorioDataService
      .put(vm.recordatorio)
      .then(function(response) {
        $state.go("app.recordatorio", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // create
  function create() {
    loading.init();
    recordatorioDataService
      .post(vm.recordatorio)
      .then(function(response) {
        $state.go("app.recordatorio", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // select community
  function selectCommunity() {
    angular.forEach(vm.comunidades, function(value) {
      if (value.id == vm.recordatorio.community_id) {
        vm.divisiones = value.divisions;
      }
    });
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        var index = comunidad(response.data);
        // --
        if (!vm.recordatorio.id) {
          // var index = comunidad(response.data);
          vm.recordatorio.community_id =
            response.data.length > 0 ? response.data[index].id : null;
          vm.divisiones =
            response.data.length > 0 ? response.data[index].divisions : null;
          // vm.divisiones = (response.data.length > 0) ? response.data[comunidad(response.data)].divisions : null;
        } else {
          // var index = comunidad(response.data);
          angular.forEach(response.data, function(item) {
            if (item.id == vm.recordatorio.community_id) {
              vm.divisiones = item.divisions;
            }
          });
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // title
  function title() {
    vm.type = vm.recordatorio.id ? "Editar" : "Nuevo";
    vm.item = {
      backUrl: "app.recordatorios",
      newUrl: "",
      label: vm.type + " Recordatorio",
      name: vm.recordatorio.id ? vm.recordatorio.title.toUpperCase() : ""
    };
  }
}
