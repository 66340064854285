angular.module("app").controller("gastoComunObservationController", gastoComunObservationController );

function gastoComunObservationController(
  $scope,
  $mdDialog,
  gastoComunDataService,
  storeService,
  toastr,
  text,
  data,
  reserve_funds
) {
  // --
  $scope.cancel = cancel;
  $scope.regenerate = regenerate;
  $scope.reserve_funds = reserve_funds;

  init();

  $scope.minDate = new Date(storeService.get("year"), storeService.get("month"), 1);

  // --
  function init() {
    if (data) {
      $scope.data = Object.assign({}, data);
      $scope.data.penalty_types = ($scope.data.penalty_types || []).reduce((p, c) => {
        const factor = ['interest', 'fine_variable'].includes(c.type) ? 100 : 1;
        p[c.type] = c.amount * factor;
        return p;
      }, {});
      $scope.data.reserve_funds = $scope.data.reserve_funds.map((r) => r.id);
    } else {
      $scope.data = {
        description: null,
        expired_at: null,
        penalties_closed_at: null,
        penalty_types: {},
        month: storeService.get("month"),
        year: storeService.get("year"),
        reserve_funds: reserve_funds.map(r => r.id),
      };
    }
  }

  function cancel() {
    $mdDialog.hide();
  }

  function regenerate() {
    const penaltyTypes = {};
    for (const type in $scope.data.penalty_types) {
      if ($scope.data.penalty_types.hasOwnProperty(type)) {
        const amount = $scope.data.penalty_types[type];
        if (amount) {
          if (['interest', 'fine_variable'].includes(type)) {
            penaltyTypes[type] = amount / 100;
          } else {
            penaltyTypes[type] = amount;
          }
        }
      }
    }

    var penalties_closed_at = $scope.data.penalties_closed_at;
    var expired_at = $scope.data.expired_at;

    if(penalties_closed_at instanceof Date){
      penalties_closed_at.setHours(9, 0, 0);
    }
    if(expired_at instanceof Date){
      expired_at.setHours(9, 0, 0);
    }
    $mdDialog.hide({
      data: {
        description: $scope.data.description,
        expired_at: expired_at,
        penalties_closed_at: penalties_closed_at,
        penalty_types: penaltyTypes,
        reserve_funds: $scope.data.reserve_funds,
        month_costs: $scope.data.month_costs,
        month_income: $scope.data.month_income,
        month_bank_balance: $scope.data.month_bank_balance,
        month_cash_balance: $scope.data.month_cash_balance,
        complete_total: $scope.data.complete_total,
      }
    });
  }
}
