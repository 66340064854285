angular
  .module("app")
  .controller("informacionController", informacionController);

function informacionController(
  $state,
  $stateParams,
  $rootScope,
  $mdDialog,
  informacionDataService,
  informacionFileDataService,
  comunidadDataService,
  storeService,
  informations_category,
  informations_type,
  confirmService,
  access,
  loading,
  toastr,
  text,
  env
) {
  var vm = this;
  var notification = storeService.get("notification");
  vm.permission = access;
  vm.informacion = {
    id: $stateParams.id
  };
  vm.categories = informations_category;
  vm.types = informations_type;

  // ---
  vm.create = create;
  vm.edit = edit;
  vm.addFile = addFile;
  vm.viewFile = viewFile;
  vm.selectCommunity = selectCommunity;
  vm.destroy = destroy;

  init();

  // iniciar
  function init() {
    if (vm.informacion.id) {
      informacionDataService
        .get(vm.informacion)
        .then(function(response) {
          vm.informacion = response.data.data;
          notification.informations -= response.data.notifications;
          storeService.set("notification", notification);
          $rootScope.$broadcast("update-auth-data");
          title();
          comunidades();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // editar
  function edit() {
    loading.init();
    informacionDataService
      .put(vm.informacion)
      .then(function(response) {
        $state.go("app.informacion", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // create
  function create() {
    loading.init();
    informacionDataService
      .post(vm.informacion)
      .then(function(response) {
        $state.go("app.informacion", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // view file
  function viewFile(ev, id) {
    // console.log(id)
    document.location =
      env.DOMAIN + env.API_PATH + "informaciones/download/" + id;
  }

  // add file
  function addFile(ev, item) {
    $mdDialog
      .show({
        controller: informacionFileModalController,
        templateUrl: "views/admin/informacion/modal/file_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: vm.informacion,
          file: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // confirm delete file
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        disabled({ id: item });
      },
      function() {}
    );
  }

  // destroy file
  function disabled(item) {
    loading.init();
    informacionFileDataService
      .delete(item)
      .then(function() {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // select community
  function selectCommunity() {
    angular.forEach(vm.comunidades, function(value) {
      if (value.id == vm.informacion.community_id) {
        vm.divisiones = value.divisions;
      }
    });
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        var index = comunidad(response.data);
        vm.comunidades = response.data;
        // --
        if (!vm.informacion.id) {
          // var index = comunidad(response.data);
          vm.informacion.community_id =
            response.data.length > 0 ? response.data[index].id : null;
          vm.divisiones =
            response.data.length > 0
              ? response.data[comunidad(response.data)].divisions
              : null;
        } else {
          // var index = comunidad(response.data);
          angular.forEach(response.data, function(item) {
            if (item.id == vm.informacion.community_id) {
              vm.divisiones = item.divisions;
            }
          });
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // title
  function title() {
    vm.type = vm.informacion.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "app.informaciones",
      newUrl: "",
      label: vm.type + " Información",
      name: vm.informacion.id ? vm.informacion.title.toUpperCase() : ""
    };
  }
}
