angular.module("app").controller("fondosController", fondosController);

function fondosController(
  $state,
  fondoDataService,
  confirmService,
  $mdDialog,
  access,
  loading,
  toastr,
  text,
  reserve_fund_type
) {
  var vm = this;

  vm.permission = access;
  vm.types = reserve_fund_type;
  vm.fondos = [];
  vm.visibleFondos = vm.fondos;
  vm.selected = [];

  vm.query = {
    order: "order",
    limit: 10,
    page: 1,
    search: ""
  };

  // ---
  vm.edit = edit;
  vm.destroy = destroy;

  init();

  // inicio
  async function init() {
    title();
    await getData();
  }

  // edit item
  function edit(ev, item) {
    $state.go("app.fondo", {
      id: item.id
    });
  }

  // confirmar para eliminar item
  async function destroy(ev, item) {
    try {
      await $mdDialog.show(confirmService.delete(ev));
    } catch (e) {
      return;
    }
    try {
      loading.init();
      await fondoDataService.delete(item.id);
      await getData();
      toastr.success(text.API_EXITO);
    } catch (e) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  async function getData() {
    try {
      const response = await fondoDataService.all(vm.query);
      vm.fondos = response.data;
      vm.visibleFondos = filterFondos(vm.fondos);
    } catch (error) {
      vm.error = error;
      return [];
    }
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "person",
      backUrl: "",
      newUrl: "app.fondo",
      backActive: false,
      newActive: true,
      label: "Fondos"
    };
  }

  function filterFondos(fondos) {
    let search = vm.query.search;
    if (search) {
      search = search.toUpperCase();
      return fondos.filter(
        (f) => f.name.toUpperCase().indexOf(search) >= 0
      );
    }
    return [...fondos];
  }

  vm.onSearch = async function () {
    vm.visibleFondos = filterFondos(vm.fondos);
  };
}
