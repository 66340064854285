angular
    .module('app')
    .controller('accountController', accountController);

function accountController($scope, authService, authDataService, usuarioDataService,
    access, loading, toastr, text) {
    var vm = this;

    // inicio
    vm.init = function () {
        vm.usuario = authService.getUserAuthenticated();
        vm.permission = access;
        title();
    }
    vm.init();

    // actualizar
    vm.save = function () {
        loading.init();
        usuarioDataService.put(vm.usuario).then(function (response) {
            vm.usuario = response.data;
            //actualizar token
            authService.setUserAuthenticated(response.data);
            // authService.refresh();
            toastr.success(text.API_EXITO);
            loading.end();
        }).catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
        });
    }

    // title
    function title() {
        if (access.isCopropietario()) {
            $scope.$parent.vm.item = {
                back: true,
                backUrl: 'app.home_rwd',
                title: 'Cuenta',
            };
        } else {
            vm.item = {
                back: true,
                backUrl: access.isRoot() ? 'app.comunidades' : 'app.dashboard',
                newUrl: '',
                backActive: true,
                newActive: false,
                label: 'Cuenta'
            };
        }
    }
}