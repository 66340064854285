angular
    .module('app')
    .controller('gastoRWDController', gastoRWDController);

function gastoRWDController($scope, $state, $rootScope, $mdDialog, $window,
    gastoComunDataService, storeService, webpayDataService, toastr, text) {
    var vm = this;
    var date = new Date();
    var notification = storeService.get('notification');

    // --
    vm.show = show;
    vm.pay = pay;
    vm.detail = detail;

    init();

    // inicio
    function init() {
        title();
        vm.comunidad = storeService.get('community');
        vm.propiedad = storeService.get('property');
        // -- state cost
        vm.date = date;
        vm.month = date.getMonth() + 1;
        vm.year = date.getFullYear()
        loadMonto();
    }

    // redirect to 
    function show(item) {
        $state.go(item);
    }

    // redirect details
    function detail() {
        $state.go('app.gasto_detail_rwd', {
            month: date.getMonth() + 1,
            year: date.getFullYear()
        });
    }

    // load gasto comun
    function loadMonto() {
        gastoComunDataService.get_resume(vm.comunidad, vm.propiedad, date.getMonth() + 1, date.getFullYear())
            .then(function (response) {
                vm.resumen = response.data.amount;
                vm.moroso = {
                    amount: parseFloat(response.data.amount_all ? response.data.amount_all.amount : 0),
                    morosidad: parseFloat(response.data.amount_all ? response.data.amount_all.morosidad : 0),
                };
                vm.data = response.data;
                // notifications
                notification.costs = response.data.notifications;
                storeService.set('notification', notification);
                $rootScope.$broadcast('update-auth-data');
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // realizar el pago
    function pay(ev) {
        init_pay({
            id: vm.resumen.id,
            month: date.getMonth() + 1,
            year: date.getFullYear()
        });
        webpayDataService.init(vm.resumen)
            .then(function (response) {
                var form = document.createElement("form");
                form.name = 'form';
                form.method = 'post';
                form.target = '_self';
                form.action = response.data.url;
                // --
                var claveInput = document.createElement("input");
                claveInput.type = 'hidden';
                claveInput.name = 'TBK_TOKEN';
                claveInput.value = response.data.token;
                form.appendChild(claveInput);
                document.body.appendChild(form);
                form.submit();
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // create pay localstorage
    function init_pay(item) {
        storeService.set('pay', item);
    }

    // load title sub-toolbar
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.home_rwd',
            title: 'Gasto Común',
        };
    }
}