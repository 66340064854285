angular
    .module('app')
    .controller('resetController', resetController);

function resetController($state, $auth, $stateParams, $location, passwordDataService, status, toastr, text, env) {
    var vm = this;
    vm.data = {
        token: $stateParams.id,
        user: !!$location.$$search.user
    };
    vm.showUserMessage = false;
    vm.loginUserUrl = `${env.DOMAIN_USER}`
    // --
    vm.reset = reset;
    vm.back = back;

    init();

    // Inicio
    function init() {
        isLoading(false);
    }

    // update password
    async function reset() {
        isLoading(true);
        try {
            await passwordDataService.reset(vm.data);
            toastr.success(text.PASSWORD_RESET);
            back();
        } catch (error) {
            if (error.data.state == status.ERROR) {
                toastr.error(error.data.msg);
            } else {
                toastr.error(text.PASSWORD_ERROR);
            }
        } finally {
            isLoading(false);
        }
    }

    // return
    function back() {
        if (!vm.data.user) {
            $state.go('app.login');
        } else {
            vm.showUserMessage = true;
        }

    }

    // set is_loading
    function isLoading(opt) {
        vm.is_loading = opt;
    }
}