angular
  .module("app")
  .controller("gastosComunController", gastosComunController);

function gastosComunController(
  $state,
  $rootScope,
  $mdBottomSheet,
  $mdDialog,
  $window,
  comunidadDataService,
  gastoComunDataService,
  fondoDataService,
  confirmService,
  storeService,
  gaService,
  state_cost,
  access,
  months,
  env,
  loading,
  toastr,
  text,
  years
) {
  // --
  var vm = this;
  var date = new Date();
  vm.query = {
    order: ["property.number"]
  };

  // --
  // vm.create = create;
  vm.filter = filter;
  vm.show = show;
  vm.excel = excel;
  vm.excelParrotfy = excelParrotfy;
  vm.print = print;
  vm.printDetail = printDetail;

  vm.confirmGC = confirmGC;
  vm.sendGC = sendGC;
  
  vm.addPaid = addPaid;
  vm.addPayList = addPayList;
  vm.regenerateGC = regenerateGC;
  vm.selectMonth = selectMonth;
  vm.selectYear = selectYear;
  vm.ask_email_to_send = ask_email_to_send;
  vm.webpay_link = webpay_link;
  vm.cartaDeCobro = cartaDeCobro;
  vm.loaded = false;
  init();

  // inicio
  function init() {
    title();
    comunidades();
    vm.months = months;
    vm.years = years(date);
    // -- date
    vm.year = storeService.get("year");
    vm.month = storeService.get("month");
    // -- state cost
    vm.permission = access;
    vm.estados = state_cost;
  }

  // mostrar modal observations
  async function regenerateGC() {
    const responseReserveFunds = await fondoDataService.division_funds(vm.division.id);
    const response = await $mdDialog.show({
      controller: gastoComunObservationController,
      templateUrl: "views/admin/gasto_comun/modal/observation_modal.html",
      parent: angular.element(document.body),
      targetEvent: "ev",
      clickOutsideToClose: false,
      fullscreen: true,
      locals: {
        data: vm.gastos ? vm.gastos.cost : null,
        reserve_funds: responseReserveFunds.data
      }
    });

    if (response) {
      gaService.send_event('recalculo', 'ggcc');
      console.log("response", response);
      // if (vm.gastos && vm.gastos.cost) {
      //     vm.gastos.cost.description = response.data;
      // } else {
      vm.observations = response.data;
      // }
      //if response.
      if (vm.gastos.is_costofijo) {
        $mdDialog.show({
          controller: gastoComunUFController,
          templateUrl: "views/admin/gasto_comun/modal/uf_modal.html",
          parent: angular.element(document.body),
          targetEvent: "ev",
          clickOutsideToClose: false,
          fullscreen: false,
          locals: {
            data: vm.gastos ? vm.gastos.uf : null
          }
        }).then(function (response) {
          if (response) {
            generateGC(response.data);
          }
        });
      } else {
        generateGC(null);
      }
    }
  }

  // crear gastos
  async function generateGC(item) {
    try {
      loading.init();
      const response = await gastoComunDataService.post({
        month: vm.month,
        year: vm.year,
        division_id: vm.division.id,
        community_id: vm.comunidad.id,
        uf: item ? item.uf : vm.gastos.uf ? vm.gastos.uf.valor : 0,
        description: vm.observations.description,
        expired_at: vm.observations.expired_at,
        penalties_closed_at: vm.observations.penalties_closed_at,
        penalty_types: vm.observations.penalty_types,
        reserve_funds: vm.observations.reserve_funds,
        month_costs: vm.observations.month_costs,
        month_income: vm.observations.month_income,
        month_bank_balance: vm.observations.month_bank_balance,
        month_cash_balance: vm.observations.month_cash_balance,
        complete_total: vm.observations.complete_total,
      });
      console.log('response', response);
      if(response.data.state){
        if(response.data.state == "ok"){
          // se generó bien el GC
          gaService.send_event('generacion', 'ggcc');
        }
      }
      // const get_listado = await gastoComunDataService.get_listado({
      //   community_id: vm.comunidad.id,
      //   division_id : vm.division.id,
      //   month: vm.month,
      //   year: vm.year,
      // });
      // console.log('get_listado', get_listado);
      // vm.gastos = get_listado.data;
      // if(vm.gastos.cost){
      //   vm.gastos.cost.cost_commons.sort((a, b) => (a.type > b.type) ? -1 : (a.type === b.type) ? ((a.number > b.number) ? -1 : 1) : 1 );
      //   const cost = get_listado.data.cost;
      //   if (cost && cost.cost_commons && cost.cost_commons.length > 0) {
      //     sum(get_listado.data.cost.cost_commons);
      //   }
      // }

      getGastos();
      toastr.success(text.API_EXITO);
    } catch (error) {
      console.log('error', error);
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  // update and confirmar gastos
  function update(state) {
    loading.init();
    gastoComunDataService
      .put({
        state: state,
        month: vm.month,
        year: vm.year,
        division_id: vm.division.id,
        community_id: vm.comunidad.id
        // description: vm.observations.description,
        // expired_at: vm.observations.expired_at,
        // debt: vm.observations.debt
      })
      .then(function (response) {
        if (state == state_cost.CONFIRMADO){
          gaService.send_event('confirmacion', 'ggcc');
          toastr.success("Confirmación exitosa.");
        }
        if (state == state_cost.ENVIADO){
          gaService.send_event('envio', 'ggcc');
          toastr.success("Se realizó el envio masivo, te llegará el resumen de lo que pasó por email.");
        }
        vm.gastos = response.data;
        if (vm.gastos.cost){
          vm.gastos.cost.cost_commons.sort((a, b) => (a.type > b.type) ? -1 : (a.type === b.type) ? ((a.number > b.number) ? -1 : 1) : 1 )
          if (response.data.cost && response.data.cost.cost_commons.length > 0) {
            sum(response.data.cost.cost_commons);
          }
        } else{
          getGastos();
        }
        loading.end();
        filter(1);
      })
      .catch(function (error) {
        if (state == state_cost.CONFIRMADO){
          gaService.send_event('confirmacion_error', 'ggcc');
          toastr.error("Hubo un problema al confirmar el Gasto Común.");
        }
        if (state == state_cost.ENVIADO){
          gaService.send_event('envio_error', 'ggcc');
          toastr.error("Hubo un problema en el envío masivo, te llegará el resumen de lo que pasó por email.");
        }
        getGastos();
        //loading.end();
      });
  }

  // mostrar modal manual
  function addPaid(ev, item) {
    if (item.cost_morosidad > 0) {
      $mdDialog.show(confirmService.morosidad(ev, item.cost_morosidad)).then(
        function () { },
        function () { }
      );
    } else {
      $mdDialog
        .show({
          controller: gastoComunPaidController,
          templateUrl: "views/admin/gasto_comun/modal/paid_modal.html",
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: false,
          fullscreen: false,
          locals: {
            data: item ? item : null
          }
        })
        .then(function (response) {
          if (response) {
            getGastos();
          }
        });
    }
  }

  // mostrar modal observations
  function addPayList(item) {
    $mdDialog
      .show({
        controller: gastoComunPaysController,
        templateUrl: "views/admin/gasto_comun/modal/payments_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: item
        }
      })
      .then(function (response) { });
  }

  // gastos comunes
  async function getGastos(flag = 0) {
    vm.loaded = false;
    if (!vm.comunidad || !vm.division) {
      return;
    }
    try {
      if (flag == 0) {
        loading.init();
      }
      vm.gastos = [];
      const response = await gastoComunDataService.get_filter(vm.comunidad, vm.division, vm.month, vm.year);
      vm.gastos = response.data;
      if (response.data.message != ""){
        $mdDialog.show($mdDialog.confirm()
          .title("Alerta")
          .textContent(response.data.message)
          .ariaLabel("confirm_label")
          .targetEvent("ev")
          .ok("Regenerar")
          .cancel("No hacer nada")).then(
          function () {
            //update(state_cost.CONFIRMADO);
            regenerateGC();
          },
          function () { }
        );
      }
      if (
        response.data.cost &&
        response.data.cost.cost_commons.length > 0
      ) {
        sum(response.data.cost.cost_commons);
        response.data.cost.cost_commons.forEach(element => {
          element.saldo = 0;
          element.saldo_to_paid = element.amount;
          element.web_pay.forEach(value => {
            element.saldo += parseInt(value.amount);
            element.saldo_to_paid -= parseInt(value.amount);
          });
          element.cds_sum = 0
          element.cost_common_details.forEach(ccd => {
            element.cds_sum += parseInt(ccd.amount);
          });
        });
        response.data.cost.cost_commons.sort((a, b) => (a.type > b.type) ? -1 : (a.type === b.type) ? ((a.number > b.number) ? -1 : 1) : 1 )
      }
      vm.loaded = true;
    } catch (e) {
      toastr.error(text.API_ERROR);
    } finally {
      if (flag == 0) {
        vm.loaded = true;
        loading.end();
      }
    }
  }

  // sumar totales
  function sum(data) {
    // var data = data.data.cost_commons ? data.data.cost_commons : null;
    vm.suma_total = 0;
    vm.suma_paid = parseFloat(0);
    angular.forEach(data, function (value) {
      vm.suma_total += value.amount;
      angular.forEach(value.web_pay, function (element) {
        // if (value.is_paid)
        vm.suma_paid += parseFloat(element.amount);
      });
    });
  }

  // change select
  function filter(flag) {
    getGastos(flag);
  }

  function selectMonth() {
    storeService.set("month", vm.month);
    $rootScope.$broadcast("update-auth-data");
  }

  function selectYear() {
    storeService.set("year", vm.year);
    $rootScope.$broadcast("update-auth-data");
  }

  // ver costos
  function show(item) {
    $state.go("app.gasto_comun", {
      id: item.id
    });
  }

  // confirmar para cambiar estado
  function confirmGC() {
    $mdDialog.show(confirmService.confirm("ev")).then(
      function () {
        update(state_cost.CONFIRMADO);
      },
      function () { }
    );
  }

  // confirmar para cambiar estado
  function sendGC() {
    $mdDialog.show(confirmService.send("ev")).then(
      function () {
        update(state_cost.ENVIADO);
      },
      function () { }
    );
  }

  // generate excel
  function excel() {
    $mdBottomSheet
      .show({
        templateUrl: "views/layout/sheet_view.html",
        controller: "sheetController",
        clickOutsideToClose: true,
        locals: {
          data: null,
          items: [
            {
              id: 1,
              icon: "insert_drive_file",
              name: "Gastos",
              description: "textos a definir"
            },
            {
              id: 2,
              icon: "insert_drive_file",
              name: "Cobranza",
              description: "textos a definir"
            },
            {
              id: 3,
              icon: "insert_drive_file",
              name: "Circular",
              description: "textos a definir"
            },
            {
              id: 4,
              icon: "insert_drive_file",
              name: "Particulares",
              description: "textos a definir"
            }
          ]
        }
      })
      .then(function (item) {
        // scope.excel(item.id, id);
        if (item.id == 1) {
          document.location =
            env.DOMAIN +
            env.API_PATH +
            "files/export/gastos/" +
            vm.comunidad.id +
            "/" +
            vm.division.id +
            "/" +
            vm.month +
            "/" +
            vm.year;
        } else if (item.id == 2) {
          document.location =
            env.DOMAIN +
            env.API_PATH +
            "files/export/cobranza/" +
            vm.comunidad.id +
            "/" +
            vm.month +
            "/" +
            vm.year;
        } else if (item.id == 3) {
          const gastos = vm.gastos.cost;
          const gasto = gastos.cost_commons && gastos.cost_commons.length > 0
            ? gastos.cost_commons[0].id
            : null;
          if (gasto) {
            document.location =
              env.DOMAIN +
              env.API_PATH +
              "files/export/circular/" +
              gasto
          }
        } else {
          document.location =
            env.DOMAIN +
            env.API_PATH +
            "files/export/particular/" +
            vm.comunidad.id +
            "/" +
            vm.month +
            "/" +
            vm.year;
        }
      });
  }

  function excelParrotfy() {
    document.location = env.DOMAIN + env.API_PATH + "files/export/gastos/parrot/" + vm.comunidad.id + "/" + vm.division.id + "/" + vm.month + "/" + vm.year;
  }

  // imprimir
  function print() {
    $mdBottomSheet
      .show({
        templateUrl: "views/layout/sheet_view.html",
        controller: "sheetController",
        clickOutsideToClose: true,
        locals: {
          data: null,
          items: [
            {
              id: 1,
              icon: "insert_drive_file",
              name: "Gastos",
              description: "textos a definir"
            },
            {
              id: 2,
              icon: "insert_drive_file",
              name: "Circular",
              description: "textos a definir"
            }
          ]
        }
      })
      .then(function (item) {
        // scope.excel(item.id, id);
        if (item.id == 1) {
          gaService.send_event('print_gastos', 'ggcc');
          document.location =
            "#/auth/print/gastos/" +
            vm.comunidad.id +
            "/" +
            vm.division.id +
            "/" +
            vm.month +
            "/" +
            vm.year;
        } else {
          gaService.send_event('print_circular', 'ggcc');
          document.location =
            "#/auth/print/gastos/circular/" +
            (vm.gastos && vm.gastos.cost
              ? vm.gastos.cost.cost_commons.length > 0
                ? vm.gastos.cost.cost_commons[0].id
                : null
              : null);
        }
      });
  }

  function cartaDeCobro() {
    document.location = env.DOMAIN + env.API_PATH + "collect_letter_division/"+vm.division.id+"/"+vm.month+"/"+vm.year;
  }

  // generate print
  function printDetail(item) {
    $mdBottomSheet
      .show({
        templateUrl: "views/layout/sheet_view.html",
        controller: "sheetController",
        clickOutsideToClose: true,
        locals: {
          data: null,
          items: [
            {
              id: 1,
              icon: "insert_drive_file",
              name: "Resumen",
              description: "Resumen por categoría del gasto común"
            },
            {
              id: 2,
              icon: "insert_drive_file",
              name: "Detalle",
              description: "Detalle de facturas y cuentas del gasto común"
            },
            {
              id: 3,
              icon: "insert_drive_file",
              name: "Boleta",
              description: "textos a definir",
              hide: item.is_paid ? false : true
            }
          ]
        }
      })
      .then(function (response) {
        if (response.id == 1) {
          var url = "#/auth/print/gasto/" + item.id;
          gaService.send_event('view_resumen', 'ggcc');
          // document.location = "#/auth/print/gasto/" + item.id;
          window.open(url.toString(), "mypopup", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=576, height=820, left=10 top=100");
          // $window.open('#/auth/print/gasto/' + item.id);
        } else if (response.id == 2) {
          //var url = "#/auth/print/gastos/detalle/" + item.id;
          var url = env.DOMAIN + env.API_PATH + 'pdf/' + item.id;
          gaService.send_event('view_pdf', 'ggcc');
          // document.location = "#/auth/print/gastos/resumen/" + item.id;
          window.open(url.toString(), "mypopup", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1080, height=720, left=10 top=100");
          // $window.open('#/auth/print/gastos/resumen/' + item.id);
        } else {
          // document.location = "#/auth/print/gastos/boleta/" + item.id;
          var url = "#/auth/print/gastos/boleta/" + item.id;
          gaService.send_event('view_boleta', 'ggcc');
          window.open(url.toString(), "mypopup", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=576, height=820, left=10 top=100");
        }
      });
  }

  // ask for mail to send test
  function ask_email_to_send(ev, item) {
    $mdDialog.show({
      controller: gastoComunTestEmailController,
      templateUrl: "views/admin/gasto_comun/modal/test_email_modal.html",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: false,
      locals: {
        data: item ? item : null
      }
    })
    .then(function (response) {
      if (response) {
        //getGastos();
      }
    });
  }

  // create webpay transaction from item
  function webpay_link(ev, item) {
    //loading.init();
    var new_url = $state.href('app.webpay', {id: item.id}, {absolute: true});
    gaService.send_event('abrir_link_webpay', 'ggcc');
    window.location.href = new_url;
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function (response) {
        // console.log(response);
        vm.comunidades = response.data;
        // vm.comunidad = response.data[0];
        // vm.division = response.data[0].divisions[0];
        // --
        var index = comunidad(response.data);
        vm.comunidad = response.data[index];

        if(vm.comunidad.divisions.length > 0){
          var ejemplo = vm.comunidad.divisions.sort(dynamicSort("name"));
          vm.division = ejemplo[0];
        }
        if (comunidad) {
          getGastos();
        }
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function (item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: true,
      label: "Gastos Comunes"
    };
  }
}
