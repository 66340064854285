angular
    .module('app')
    .service('recordatorioDataService', recordatorioDataService);

function recordatorioDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('recordatorios', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (recordatorio) {
            return dataService.httpGet('recordatorios/' + recordatorio.id);
        },
        post: function (recordatorio) {
            return dataService.httpPost('recordatorios', recordatorio);
        },
        put: function (recordatorio) {
            return dataService.httpPut('recordatorios/' + recordatorio.id, recordatorio);
        },
        delete: function (recordatorio) {
            return dataService.httpDelete('recordatorios/' + recordatorio.id);
        },
    };
}