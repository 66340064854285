angular
  .module("app")
  .controller("cobranzaModalController", cobranzaModalController);

function cobranzaModalController(
  $scope,
  $mdDialog,
  text,
  toastr,
  tesoreriaDataService,
  data,
  env
) {
  $scope.is_confirm = false;
  $scope.text = text;
  // --
  $scope.cancel = cancel;

  init();

  // --
  function init() {
    $scope.sent_mails = data;
    console.log($scope);
  }

  function cancel() {
    $mdDialog.hide();
  }
}
