angular
    .module('app')
    .service('repositorioDataService', repositorioDataService);

function repositorioDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('repositorios', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            });
        },
        get: function (repositorio) {
            return dataService.httpGet('repositorios/' + repositorio.id);
        },
        post: function (repositorio) {
            return dataService.httpPost('repositorios', repositorio);
        },
        put: function (repositorio) {
            return dataService.httpPut('repositorios/' + repositorio.id, repositorio);
        },
        delete: function (repositorio) {
            return dataService.httpDelete('repositorios/' + repositorio.id);
        },
        // --
        // --
        upload: function (files) {
            return dataService.httpPost('repositorios/upload', files, {
                withCredentials: false,
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity
            });
        },
        get_community: function (community) {
            return dataService.httpGet('repositorios/comunidades/' + community.id);
        },
    };
}