angular
    .module('app')
    .service('fileDataService', fileDataService);

function fileDataService(dataService, $http) {
    return {
        upload: function (files) {
            return dataService.httpPost('files/upload', files, {
                withCredentials: false,
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity
            });
        },
        upload_employee: function (files) {
            return dataService.httpPost('files/empleados/upload', files, {
                withCredentials: false,
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity
            });
        },

        post: function (item) {
            return dataService.httpPost('files', item);
        },

    };
}