angular
    .module('app')
    .service('unidadDataService', unidadDataService);

function unidadDataService(dataService, $rootScope) {
    return {
        // all: function () {
        //     return dataService.httpGet('unidades', {
        //         headers: {
        //             'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
        //         },
        //     })
        // },
        // get: function (unidad) {
        //     return dataService.httpGet('unidades/' + unidad.id);
        // },
        // post: function (unidad) {
        //     return dataService.httpPost('unidades', unidad);
        // },
        // put: function (unidad) {
        //     return dataService.httpPut('unidades/' + unidad.id, unidad);
        // },
        // delete: function (unidad) {
        //     return dataService.httpDelete('unidades/' + unidad.id);
        // },
        // // --
        // get_cost: function (comunidad, division) {
        //     return dataService.httpGet('unidades/costofijo/' + comunidad.id);
        // },
    };
}