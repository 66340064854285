angular.module("app").service("medicionDataService", medicionDataService);

function medicionDataService(dataService, $rootScope) {
  return {
    upload: function (files) {
      return dataService.httpPost('gastos/upload_medition', files, {
          withCredentials: false,
          headers: {
              'Content-Type': undefined
          },
          transformRequest: angular.identity
      });
    },
  };
}
