angular
    .module('app')
    .service('confirmService', confirmService);

function confirmService($mdDialog, text) {
    return {
        delete: function (ev) {
            return $mdDialog.confirm()
                .title(text.DELETE_TITLE)
                .textContent(text.DELETE_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
                .cancel(text.CONFIRM_CANCEL);
        },
        disabled: function (ev) {
            return $mdDialog.confirm()
                .title(text.DISABLED_TITLE)
                .textContent(text.DISABLED_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
                .cancel(text.CONFIRM_CANCEL);
        },
        enabled: function (ev) {
            return $mdDialog.confirm()
                .title(text.ENABLED_TITLE)
                .textContent(text.ENABLED_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
                .cancel(text.CONFIRM_CANCEL);
        },
        expired: function (ev) {
            return $mdDialog.alert()
                .title(text.EXPIRED_TITLE)
                .textContent(text.EXPIRED_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
            // .escapeToClose(false)
        },
        unlink: function (ev) {
            return $mdDialog.confirm()
                .title(text.UNLINK_TITLE)
                .textContent(text.UNLINK_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
                .cancel(text.CONFIRM_CANCEL);
        },
        send: function (ev) {
            return $mdDialog.confirm()
                .title(text.SEND_TITLE)
                .textContent(text.SEND_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
                .cancel(text.CONFIRM_CANCEL);
        },
        confirm: function (ev) {
            return $mdDialog.confirm()
                .title(text.CONFIRM_TITLE)
                .textContent(text.CONFIRM_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
                .cancel(text.CONFIRM_CANCEL);
        },
        morosidad: function (ev, count) {
            return $mdDialog.alert()
                .title(text.MOROSO_TITLE + ' (' + count + ')')
                .textContent(text.MOROSO_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK);
            // .cancel(text.CONFIRM_CANCEL);
        },
        bill: function (ev, count) {
            return $mdDialog.alert()
                .title(text.BILL_TITLE + ' (' + count + ')')
                .textContent(text.BILL_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK);
            // .cancel(text.CONFIRM_CANCEL);
        },
        excel: function (ev) {
            return $mdDialog.confirm()
                .title(text.EXCEL_TITLE)
                .textContent(text.EXCEL_CONTENT)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK)
                .cancel(text.CONFIRM_CANCEL);
        },
        excel_import: function (ev, total, exito, error) {
            return $mdDialog.alert()
                .title(total + ' ' + text.EXCEL_IMPORT_TITLE)
                .textContent(exito + ' ' + text.EXCEL_IMPORT_EXITO + ' - ' + error + ' ' + text.EXCEL_IMPORT_ERROR)
                .ariaLabel(text.CONFIRM_LABEL)
                .targetEvent(ev)
                .ok(text.CONFIRM_OK);
            // .cancel(text.CONFIRM_CANCEL);
        },
    };
}