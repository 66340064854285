angular
    .module('app')
    .controller('payOkController', payOkController);

function payOkController($state, $stateParams, loading, toastr, text, webpayDataService) {
    var vm = this;

    // --
    vm.propiedad = {
        id: $stateParams.id
    };
    // --
    vm.pago = {
        id: $stateParams.p_id
    };
    vm.comunidad = null;
    init();

    function init() {
        getPayment();
        //console.log("init");
    }

    function getPayment(){
        webpayDataService
        .get_pay(vm.propiedad.id, vm.pago.id)
        .then(function(response) {
            vm.pago = response.data.payment;
            vm.propiedad = response.data.property;
            vm.comunidad = response.data.community_name;
        }).catch(function(error) {
            vm.error = error;
        });
    }

}
