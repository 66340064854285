angular
    .module('app')
    .controller('estacionamientosController', estacionamientosController);

function estacionamientosController($state, $scope, $mdDialog, $rootScope,
    estacionamientoDataService, confirmService, loading, access, toastr, text) {
    var vm = this;
    vm.query = {
        order: ['community.name', 'division.name', 'name'],
        limit: 10,
        page: 1
    };

    // ---
    vm.edit = edit;
    vm.destroy = destroy;

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        estacionamientos();
    }

    // go to edit
    function edit(item) {
        $state.go('app.estacionamiento', {
            id: item ? item.id : null
        });
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            disabled(item);
        }, function () {});
    }

    // disabled
    function disabled(item) {
        item.is_active = false;
        loading.init();
        estacionamientoDataService.put(item)
            .then(function (response) {
                init();
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load estacionamientos
    function estacionamientos() {
        estacionamientoDataService.all()
            .then(function (response) {
                var count = 0;
                vm.estacionamientos = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // socket
    var listenerCleanFn = $rootScope.$on('emit-socket', function (event, data) {
        estacionamientos();
        console.log(data.data);
    });

    $scope.$on('$destroy', function () {
        listenerCleanFn();
    });

    // load title
    function title() {
        vm.item = {
            backUrl: '',
            newUrl: 'app.estacionamiento',
            backActive: false,
            newActive: true,
            label: 'Estacionamientos',
        };

    }
}