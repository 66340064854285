angular
    .module('app')
    .controller('informacionFileModalController', informacionFileModalController);

function informacionFileModalController($scope, $mdDialog, informacionFileDataService,
    toastr, text, data, file) {

    // --
    $scope.save = save;
    $scope.load = load;
    $scope.cancel = cancel;

    init();

    // carga inicial
    function init() {
        if (file) {
            $scope.file = file;
        } else {
            $scope.file = {
                information_id: (data) ? data.id : null,
            };
        }
    }

    // salir del modal
    function cancel() {
        $mdDialog.hide()
    }

    // upload
    function load(item) {
        $scope.archivo = item;
    }

    // subir archivo
    function upload() {
        angular.forEach($scope.archivo, function (item) {
            // send file
            var file = new FormData();
            file.append("file", item);
            file.append("title", $scope.file.title);
            file.append("description", $scope.file.description);
            file.append("information_id", $scope.file.information_id);
            informacionFileDataService.upload(file)
                .then(function (response) {
                    document.getElementById('file').value = '';
                    console.log('data => ', response)
                    $mdDialog.hide({
                        file: response,
                    });
                    toastr.success(text.API_EXITO);
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                    document.getElementById('file').value = '';
                });
        });
    };


    // select update o create
    function save() {
        upload();
    }
}