angular
  .module("app")
  .controller("gastosPrintController", gastosPrintController);

function gastosPrintController(
  $state,
  $stateParams,
  $q,
  $window,
  gastoComunDataService,
  comunidadDataService,
  cost_type,
  months,
  toastr,
  text
) {
  var vm = this;
  vm.cost_types = cost_type;

  vm.gasto = {
    community_id: $stateParams.community_id,
    division_id: $stateParams.division_id,
    month: $stateParams.month,
    year: $stateParams.year
  };

  // --
  vm.getSum = getSum;
  vm.print = print;
  vm.close = close;
  vm.withBorders = false;
  init();

  // inicio
  function init() {
    gastos();
  }

  // gastos comunes
  async function gastos() {
    // --
    try {
      const responses = await Promise.all([
        await comunidadDataService.getCurrent(),
        await gastoComunDataService.get_details(vm.gasto)
      ])
      vm.community = responses[0].data;
      vm.prints = responses[1].data;
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  vm.amount_not_private = 0;
  function getSum(key, value) {
    // vm.suma_particulares += value;

    if (value != undefined) {
      vm.amount_not_private += parseInt(value);
      vm.prints[key]["amount_not_private"] += parseInt(value);
    }
  }

  // open dialog print
  function print() {
    $window.print();
  }

  // close window
  function close() {
    // $window.close();
    $window.history.back();
  }
}