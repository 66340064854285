angular.module("app").controller("gastoController", gastoController);

function gastoController(
  $state,
  $q,
  $window,
  $rootScope,
  $mdDialog,
  comunidadDataService,
  propiedadDataService,
  gastoDataService,
  confirmService,
  storeService,
  rule_types,
  state_cost,
  morosidad_types,
  movimiento_types,
  env,
  months,
  status,
  loading,
  access,
  toastr,
  text,
  years
) {
  var vm = this;
  var date = new Date();
  vm.rule_types = rule_types;
  vm.suma_current = 0;
  vm.suma_money_current = 0;
  vm.is_method = 0;
  vm.is_type = 1;
  vm.tab = 1;
  vm.rule = null;
  vm.query = {
    order: ["number"]
  };
  vm.current_role = $rootScope.currentRole.name;
  vm.gasto_general_state = '';
  // --
  vm.filter = filter;
  vm.create = create;
  vm.update = update;
  vm.confirm = confirm;
  vm.addCost = addCost;
  vm.addDesc = addDesc;
  vm.viewCost = viewCost;
  vm.addDescGen = addDescGen;
  vm.addMorosidad = addMorosidad;
  vm.addPaid = addPaid;
  vm.download = download;
  vm.viewGen = viewGen;
  // vm.confirmGen = confirmGen;
  vm.destroy = destroy;
  vm.resta = resta;
  vm.sum = sum;
  vm.validateBill = validateBill;
  vm.changeDate = changeDate;
  vm.selectCommunity = selectCommunity;
  vm.selectDivision = selectDivision;
  vm.selectAccount = selectAccount;
  vm.selectRule = selectRule;
  vm.selectTab = selectTab;
  vm.selectMonth = selectMonth;
  vm.selectYear = selectYear;

  vm.view_image = view_image;
  vm.factura = null;
  vm.regla = null;

  vm.save_multiple = save_multiple;
  vm.sum_particulare_amount = sum_particulare_amount;
  vm.particulares_sum = 0;
  vm.movimiento_types = movimiento_types;
  vm.accounts_to_select = [];
  vm.multiple_type = null;
  vm.multiple_account = null;
  vm.multiple_description = null;

  vm.division_method = 1;

  vm.download_template = download_template;
  vm.upload = upload;

  vm.sum_percent = 0;
  vm.sum_amount = 0;
  vm.sum_final_amount = 0;
  vm.sum_prorrateo_amount = 0;
  vm.sum_final_amount = 0;

  vm.sum_generales = sum_generales;
  vm.get_factura_cost_divisions = get_factura_cost_divisions;
  vm.assign_cost_divisions = assign_cost_divisions;
  vm.cost_divisions = [];

  vm.factura_division_check = false;

  init();

  // inicio
  function init() {
    title();
    comunidades();
    vm.months = months;
    vm.years = years(date);
    // -- date
    vm.year = storeService.get("year");
    vm.month = storeService.get("month");
    // -- state cost
    vm.state_cost = state_cost;
    vm.permission = access;
    vm.state_cost = state_cost;
  }

  // crear gastos
  function create(item) {
    loading.init();
    gastoDataService
      .post({
        data: item ? item : vm.gastos.data,
        month: vm.month,
        year: vm.year,
        // type: vm.gasto,
        is_method: vm.is_method,
        division_id: vm.division.id,
        community_id: vm.division.community_id,
        account_id: vm.cuenta ? vm.cuenta.id : null,
        rule_id: vm.regla ? vm.regla.id : null,
        bill_id: vm.factura ? vm.factura.id : null,
        suma_current: vm.suma_current,
        suma_bill: vm.monto_rule_bill
      })
      .then(function (response) {
        if (response.data.status == status.CLOSED) {
          loading.end();
          toastr.warning(response.data.message);
        } else {
          filter(vm.is_type, 1);
          toastr.success(text.API_EXITO);
        }
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  function chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      var myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push({
        amount: my,
        current: 0,
        description: null,
        diff: 0,
        last: 0,
        porcentaje: null,
        property_id: "0028ae768d4c495f829aa09cfa340ba5",
        size: 0
      });
    }

    return tempArray;
  }

  function clearArray(myArray) {
    myArray.forEach(element => {
      element.cost_id = null;
      element.properties = null;
    });

    return myArray;
  }

  // update gastos
  async function update(item) {
    loading.init();
    try {
      const response = await gastoDataService.put({
        data: item ? item : clearArray(vm.gastos.data),
        month: vm.month,
        year: vm.year,
        is_method: vm.is_method,
        state: vm.gastos.cost.state,
        division_id: vm.division.id,
        community_id: vm.division.community_id,
        account_id: vm.cuenta ? vm.cuenta.id : null,
        rule_id: vm.regla ? vm.regla.id : null,
        bill_id: vm.factura ? vm.factura.id : null,
        suma_current: vm.suma_current,
        suma_bill: vm.monto_rule_bill,
        id: vm.gastos.cost.id
      });
      if (response.data.status == status.CLOSED) {
        toastr.warning(response.data.message);
      } else {
        filter(vm.is_type, 1);
        toastr.success(text.API_EXITO);
      }
    } catch (error) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  // update general
  function updateGeneral(item) {
    loading.init();
    item.state = state_cost.CONFIRMADO;
    gastoDataService
      .put(item)
      .then(function (response) {
        movimientosGenerales(1);
        toastr.success(text.API_EXITO);
        // loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // download
  function download() {
    if (vm.comunidad && vm.division && vm.month && vm.year) {
      document.location =
        env.DOMAIN +
        env.API_PATH +
        "files/export/morosidad/" +
        vm.comunidad.id +
        "/" +
        vm.division.id +
        "/" +
        vm.month +
        "/" +
        vm.year;
    }
  }

  function download_template() {
    if (vm.comunidad && vm.division) {
      document.location =
        env.DOMAIN +
        env.API_PATH +
        "gastos/template_particulares/" +
        vm.comunidad.id +
        "/" +
        vm.division.id;
    }
  }

  async function upload(items) {
    loading.init();
    vm.data = [];
    for (const item of items) {
      const file = new FormData();
      file.append("file", item);
      // send file
      try {
        const response = await gastoDataService.import_gastos_particulares(file, vm.comunidad, vm.division, vm.year, vm.month);
        console.log(response.data);
        if(response.data.state == "ok"){
          toastr.success("Archivo cargado con Éxito");
          filter(0, 1);
          loading.end();
        } else{
          toastr.error(response.data.data[0]);
        }
      } catch (error) {
        console.log(error);
        toastr.error(text.API_ERROR);
      } finally {
        document.getElementById("file").value = "";
        loading.end();
      }
    }
  }


  // confirmar para cambiar estado
  function confirm(ev, item) {
    $mdDialog.show(confirmService.confirm(ev)).then(
      function () {
        if (item) {
          updateGeneral(item);
        } else {
          vm.gastos.cost.state = vm.state_cost.CONFIRMADO;
          update([]);
        }
      },
      function () { }
    );
  }

  // get filter
  function filter(option, flag = 0) {
    var rule = selectAmountRule();
    vm.suma_current = 0;
    vm.suma_money_current = 0;
    if (option == 1) {
      if (vm.comunidad && vm.division && vm.cuenta) {
        if (flag == 0) {
          loading.init();
        }
        gastoDataService
          .get_filter(
            vm.comunidad,
            vm.division,
            vm.month,
            vm.year,
            vm.cuenta,
            vm.regla,
            vm.factura
          )
          .then(function (response) {
            vm.gasto_general_state = response.data.state;
            vm.gastos = response.data;
            // console.log("GET FILTER", vm.gastos);
            // --
            angular.forEach(vm.gastos.data, function (value) {
              // vm.suma_current += value.current;
              vm.suma_current += value.diff;
              vm.suma_money_current += value.amount;
              value.amount = value.amount != null ? value.amount : 0;
            });

            if (vm.gastos.cost && !vm.factura){
              if (vm.gastos.cost.bill){
                var la_factura = vm.facturas.filter(
                  element => element.id == vm.gastos.cost.bill.id
                )[0];
                vm.factura = la_factura;
                // si la factura tiene reglas
                if (vm.factura.bill_shares.length > 0) {
                  angular.forEach(vm.factura.bill_shares, function (item) {
                    if (item.month == vm.month && item.year == vm.year) {
                      vm.monto_rule_bill = item.amount * (rule ? rule : 1);
                    }
                  });
                } else {
                  // si la factura no tiene reglas
                  vm.monto_rule_bill = (vm.factura ? (vm.factura.amount - vm.factura.common_amount) : 1) * (rule ? rule : 1);
                }
              }
            }
            loading.end();
          })
          .catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
          });
      }
    } else if (option == 0 && vm.is_method == 3) {
      if (vm.comunidad && vm.division) {
        propertiesMorosidad();
      }
    } else if (option == 0 && vm.is_method == 2) {
      if (vm.comunidad && vm.division) {
        movimientosGenerales();
      }
    } else if (option == 0 && vm.is_method == 0) {
      console.log("Particulares");
      vm.gastos = [];
      if (vm.comunidad && vm.division) {
        if (flag == 0) loading.init();
        gastoDataService
          .get_filter(
            vm.comunidad,
            vm.division,
            vm.month,
            vm.year,
            vm.cuenta,
            vm.regla,
            vm.factura
          )
          .then(function (response) {
            //console.log("CHECK uno");
            vm.gasto_general_state = response.data.state;
            vm.gastos = response.data;
            // --
            angular.forEach(vm.gastos.data, function (value) {
              vm.suma_current += value.diff;
              vm.suma_money_current += value.amount;
              //value.amount = value.amount != null ? value.amount : 0;
            });
            loading.end();
            // if (vm.gastos.cost && !vm.factura){
            //   if (vm.gastos.cost.bill){
            //     var la_factura = vm.facturas.filter(
            //       element => element.id == vm.gastos.cost.bill.id
            //     )[0];
            //     vm.factura = la_factura;
            //     if (vm.factura.bill_shares.length > 0) {
            //       angular.forEach(vm.factura.bill_shares, function (item) {
            //         if (item.month == vm.month && item.year == vm.year) {
            //           vm.monto_rule_bill = item.amount * (rule ? rule : 1);
            //         }
            //       });
            //     } else {
            //       vm.monto_rule_bill = (vm.factura ? vm.factura.amount : 1) * (rule ? rule : 1);
            //     }
            //   }
            // }
          })
          .catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
          });
      }
    }
    // get monto rule bill o share
    if (vm.factura) {
      if (vm.factura.bill_shares.length > 0) {
        angular.forEach(vm.factura.bill_shares, function (item) {
          if (item.month == vm.month && item.year == vm.year) {
            vm.monto_rule_bill = item.amount * (rule ? rule : 1);
          }
        });
      } else {
        vm.monto_rule_bill = (vm.factura ? (vm.factura.amount - vm.factura.common_amount) : 1) * (rule ? rule : 1);
      }
    }
  }

  // modal nuevo costo particular
  function addCost(ev, item) {
    $mdDialog
      .show({
        controller: costModalController,
        templateUrl: "views/admin/gasto/modal/particular_cost_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: "true",
        locals: {
          data: item,
          option: false,
          accounts: vm.accounts_to_select
        }
      })
      .then(function (response) {
        if (response) {
          response.data.is_type = "particular";
          console.log(response);
          create([response.data]);
        }
      });
  }

  // agregar nuevo costo
  function addDesc(ev, item) {
    $mdDialog
      .show({
        controller: costModalController,
        templateUrl: "views/admin/gasto/modal/particular_desc_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: item,
          option: false
        }
      })
      .then(function (response) {
        if (response) {
          //response.data.tipo = "particular";
          create([response.data]);
        }
      });
  }

  // agregar nuevo general
  function addDescGen(ev, item) {
    $mdDialog
      .show({
        controller: costGeneralModalController,
        templateUrl: "views/admin/gasto/modal/general_desc_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: {
            community_id: item ? item.community_id : vm.comunidad.id,
            division_id: item
              ? item.division
              : vm.division
                ? vm.division.id
                : null,
            month: item ? item.month : vm.month,
            year: item ? item.year : vm.year,
            is_method: item ? item.is_method : vm.is_method,
            amount: item ? item.amount : null,
            description: item ? item.description : null,
            id: item ? item.id : null
          }
        }
      })
      .then(function (response) {
        if (response) {
          movimientosGenerales();
        }
      });
  }

  // store update morosidad
  function addMorosidad() {
    loading.init();
    propiedadDataService
      .put_morosidad({
        data: vm.morosidades,
        community: vm.division.community_id,
        division: vm.division.id,
        month: vm.month,
        year: vm.year
      })
      .then(function (response) {
        toastr.success(text.API_EXITO);
        vm.morosidades = response.data;
        loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // mostrar modal manual
  function addPaid(ev, item) {
    if (item.cost_morosidad > 0) {
      $mdDialog
        .show(confirmService.morosidad(ev, item.cost_morosidad))
        .then(function () { }, function () { });
    } else {
      $mdDialog
        .show({
          controller: costPaidController,
          templateUrl: "views/admin/gasto/modal/paid_modal.html",
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: false,
          fullscreen: false,
          locals: {
            data: item ? item : null
          }
        })
        .then(function (response) {
          if (response) {
            propertiesMorosidad();
          }
        });
    }
  }

  // ver costos generales
  function viewGen(ev, item) {
    $mdDialog
      .show({
        controller: costGeneralesModalController,
        templateUrl: "views/admin/gasto/modal/general_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: item.cost_properties
        }
      })
      .then(function (response) {
        if (response) {
          create([response.data]);
        }
      });
  }

  // ver costos particulares
  function viewCost(ev, item) {
    $mdDialog
      .show({
        controller: costsModalController,
        templateUrl: "views/admin/gasto/modal/particular_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: item,
          cost: vm.gastos.cost,
          accounts: vm.accounts_to_select
        }
      })
      .then(function (response) {
        if (response.data) {
          filter(vm.is_method);
        }
      });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function () {
        disabled(item);
      },
      function () {}
    );
  }

  // disabled
  function disabled(item) {
    loading.init();
    gastoDataService
      .delete(item)
      .then(function (response) {
        movimientosGenerales();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // suma diferencias
  function resta(item) {
    vm.suma_current = 0;
    vm.suma_money_current = 0;
    if(!item.reset){
      if(item.current != 0){
        item.diff = item.current - item.last;
        item.porcentaje = (100 * item.diff) / sum_diff();
      } else{
        item.diff = item.last - item.last;
        item.porcentaje = (100 * item.diff) / sum_diff();
      }
    } else{
      item.diff = item.current - 0;
      item.porcentaje = (100 * item.diff) / sum_diff();
    }
  }

  function sum_diff() {
    var suma = 0;
    angular.forEach(vm.gastos.data, function (value) {
      suma += value.diff;
      vm.suma_current += value.diff;
      vm.suma_money_current += value.amount;
    });
    return suma;
  }

  // suma
  function sum(item) {
    item.amount_paid = (item.amount * item.value) / 100;
  }

  // select tabs
  function selectTab(item) {
    console.log(item);
    vm.tab = item;
    if (item == 6) {
      gastoDataService .get_egresos_division(vm.comunidad, vm.month, vm.year)
        .then(function (response) {
          vm.facturas_comunidad = response.data.bills;
        })
        .catch(function () {
          toastr.error(text.API_ERROR);
          loading.end();
        });
    } else if (item == 5) {
      propertiesMorosidad();
      vm.is_method = 3;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
      vm.factura_division = null;
    } else if (item == 4) {
      propertiesCost();
      vm.is_method = 0;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
      vm.factura_division = null;
    } else if (item == 3) {
      movimientosGenerales();
      vm.is_method = 2;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
      vm.factura_division = null;
    } else if (item == 2) {
      // OTRA
      vm.is_method = 0;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
      vm.factura_division = null;
      filter(0);
    } else if (item == 1) {
      vm.gastos = [];
      vm.is_method = 1;
      vm.is_type = 1;
      vm.factura_division = null;
      filter(1);
    } else {
      vm.gastos = [];
      // privado $
      vm.is_method = 0;
      vm.is_type = 1;
      vm.factura_division = null;
      selectCommunity();
      filter(1);
    }
  }

  // morosidad propierties
  function propertiesMorosidad() {
    if (vm.comunidad && vm.division) {
      loading.init();
      propiedadDataService
        .get_morosidad(vm.comunidad, vm.division, vm.month, vm.year)
        .then(function (response) {
          // vm.morosidades = response.data ? response.data.data : null;
          vm.gasto_general = response.data.cost_general;
          vm.morosidades = response.data.data;
          vm.morosidades = angular.forEach(response.data.data, function (value) {
            value.value =
              value.amount > 0
                ? value.id == 0
                  ? vm.comunidad.m_type == morosidad_types.VARIABLE
                    ? vm.comunidad.m_amount
                    : 0
                  : value.value
                : 0;
            value.amount_paid =
              value.amount > 0
                ? value.id == 0
                  ? vm.comunidad.m_type == morosidad_types.FIJO
                    ? vm.comunidad.m_amount
                    : Math.round((vm.comunidad.m_amount * value.amount) / 100)
                  : value.amount_paid
                : 0;
          });
          loading.end();
        })
        .catch(function () {
          loading.end();
          toastr.error(text.API_ERROR);
        });
    }
  }

  // gastos privados
  function propertiesCost() {
    loading.init();
    vm.suma_unidades = 0;
    propiedadDataService
      .get_cost(vm.comunidad, vm.division)
      .then(function (response) {
        vm.unidades = angular.forEach(response.data.data, function (item) {
          vm.suma_unidades += parseFloat(item.cost);
        });
        vm.uf = response.data.uf.valor;
        loading.end();
      })
      .catch(function () {
        loading.end();
        toastr.error(text.API_ERROR);
      });
  }

  // movimientos generales
  function movimientosGenerales(flag = 0) {
    if (flag == 0) loading.init();
    gastoDataService
      .get_movimiento_general(vm.comunidad, vm.division, vm.month, vm.year)
      .then(function (response) {
        vm.movimientos_generales =
          response.data.length > 0 ? response.data : null;
        loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  function get_factura_cost_divisions(){
    console.log('seleccion factura');
    gastoDataService
      .get_cost_divisions(vm.comunidad, vm.factura_division.id)
      .then(function (response) {
        console.log('get_factura_cost_divisions', true);
        console.log('response', response);
        vm.cost_divisions = response.data.cost_divisions;
        sum_generales();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  vm.containsComparator = function(expected, actual){ 
    return actual.indexOf(expected) > -1;
  };

  function assign_cost_divisions(){
    gastoDataService
      .save_cost_divisions(vm.comunidad, vm.factura_division.id, {method: vm.factura_division.division_method, bill: vm.factura_division, cost_divisions: vm.cost_divisions})
      .then(function (response) {
        toastr.success(text.API_EXITO);
        console.log('save_cost_divisions');
        console.log('response', response);
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  function sum_generales(){
    vm.sum_percent = 0;
    vm.sum_amount = 0;
    vm.sum_assigned = 0;
    vm.sum_prorrateo_amount = 0;
    vm.sum_final_amount = 0;
    vm.factura_division.total_assigned = 0;
    if (vm.factura_division.common_percent == ''){
      vm.factura_division.common_percent = 0;
    }
    if (vm.factura_division.common_amount == ''){
      vm.factura_division.common_amount = 0;
    }
    //   // borrar numeros
    // if(vm.factura_division.division_method == 1){
    // }
    // if(vm.factura_division.division_method == 2){
    //   // borrar numeros
    //   vm.factura_division.common_amount = 0;
    //   angular.forEach(vm.cost_divisions, function (item) {
    //     //item.percentage = 0;
    //     item.amount = 0;
    //   });
    // }
    // if(vm.factura_division.division_method == 3){
    //   // borrar numeros
    //   vm.factura_division.common_percent = 0;
    //   angular.forEach(vm.cost_divisions, function (item) {
    //     item.percentage = 0;
    //     //item.amount = 0;
    //   });
    // }
    angular.forEach(vm.cost_divisions, function (item) {
      if(vm.factura_division.division_method == 1){
        item.assigned = 0;
        item.prorrateo_amount = ((item.division.prorrateo/100)*vm.factura_division.amount);
        item.final_amount = item.prorrateo_amount;
        vm.factura_division.assigned = 0;
      }
      if(vm.factura_division.division_method == 2){
        item.assigned = ((item.percentage/100)*vm.factura_division.amount);
        vm.factura_division.assigned = ((vm.factura_division.common_percent/100)*vm.factura_division.amount);
        item.prorrateo_amount = ((item.division.prorrateo/100)*vm.factura_division.assigned);
        item.final_amount = parseFloat(item.assigned+item.prorrateo_amount);
      }
      if(vm.factura_division.division_method == 3){
        item.amount = parseInt(item.amount);
        item.assigned = item.amount;
        vm.factura_division.assigned = vm.factura_division.common_amount;
        item.prorrateo_amount = ((item.division.prorrateo/100)*vm.factura_division.assigned);
        item.final_amount = parseFloat(item.amount+item.prorrateo_amount);
      }
      vm.sum_percent += parseFloat(item.percentage);
      vm.sum_amount += parseFloat(item.amount);
      vm.sum_assigned += parseFloat(item.assigned);
      vm.sum_prorrateo_amount += parseFloat(item.prorrateo_amount);
      vm.sum_final_amount += parseFloat(item.final_amount);
      vm.factura_division.total_assigned += parseFloat(item.assigned);
    });
    vm.sum_percent += parseFloat(vm.factura_division.common_percent);
    vm.sum_assigned += parseFloat(vm.factura_division.assigned);
    vm.sum_amount += parseFloat(vm.factura_division.common_amount);
    vm.factura_division.total_assigned += parseFloat(vm.factura_division.assigned);
    if(vm.factura_division.division_method == 1){
      vm.factura_division_check = true;
    }
    if(vm.factura_division.division_method == 2){
      vm.factura_division_check = (vm.sum_percent==100);
    }
    if(vm.factura_division.division_method == 3){
      vm.factura_division_check = (vm.sum_amount==vm.factura_division.amount);
    }
  }


  // seleccionar regla
  function selectAmountRule(item) {
    var index = 0;
    if (vm.regla && vm.division) {
      if (vm.regla.type == rule_types.PRIVADO && vm.division.id == vm.regla.division_id ) {
        vm.rule = vm.regla.amount / 100;
        index = vm.regla.amount / 100;
      }
    }
    return index;
  }

  function selectRule(item) {
    //vm.factura = null;
    vm.gastos = [];
    vm.is_method = 0;
    vm.is_type = 1;
    filter(vm.is_type);
  }

  // seleccionar comunidad
  function selectCommunity() {
    vm.divisiones = vm.comunidad.divisions.length > 0 ? vm.comunidad.divisions : null;
    if(vm.divisiones.length == 1){
      vm.division = vm.divisiones[0];
      vm.cuentas = vm.comunidad.accounts.filter(
        element => element.rules.length > 0 && element.rules.filter(e => e.division_id === vm.division.id).length > 0
      );
      if(vm.cuentas.length == 1){
        vm.cuenta = vm.cuentas[0];
        selectAccount();
      }
    }
  }
  // seleccionar division
  function selectDivision() {
    // vm.divisiones = vm.comunidad.divisions.length > 0 ? vm.comunidad.divisions : null;
    vm.cuenta = null;
    vm.regla = null;
    if(vm.division){
      vm.cuentas = vm.comunidad.accounts.filter(
        element => element.rules.length > 0 && element.rules.filter(e => e.division_id === vm.division.id).length > 0
      );
    }
    if(vm.cuentas.length == 1){
      vm.cuenta = vm.cuentas[0];
      selectAccount();
    }
  }

  // seleccionar cuenta
  function selectAccount() {
    var index = 0;
    vm.regla = null;
    if (vm.cuenta) {
      vm.facturas = vm.cuenta.bills;

      // filtrar facturas segun mes en curso, no activo aún
      // vm.facturas = vm.cuenta.bills.filter(
      //   element => element.month == vm.month
      // );

      // ordenar facturas  por mes y año.
      vm.facturas.sort((a, b) => (a.year > b.year) ? -1 : (a.year === b.year) ? ((a.month > b.month) ? -1 : 1) : 1 );

      vm.reglas = vm.cuenta.rules.filter(
        e => e.type == "particular" && e.division_id == vm.division.id
      );
      angular.forEach(vm.cuenta.rules, function (item) {
        if (
          item.type == rule_types.PRIVADO &&
          vm.division.id == item.division_id
        ) {
          vm.rule = item.amount / 100;
          index = item.amount / 100;
        }
      });
      if(vm.reglas.length == 1){
        vm.regla = vm.reglas[0];
        selectRule();
      }
    }
    return index;
  }

  function view_image(ev, item) {
    //console.log(item);
    var url = (env.DOMAIN + env.API_PATH + "gastos/view_medition/" + item.id);
    // document.location = env.DOMAIN + env.API_PATH + "gastos/view_medition/" + item.id;
    // document.location  = env.DOMAIN + env.API_PATH + filename;
    // $mdDialog.show(url);
    // $mdDialog
    //   .show({
    //     controller: meditionModalController,
    //     templateUrl: "views/admin/medicion/modal/view_medition.html",
    //     parent: angular.element(document.body),
    //     targetEvent: ev,
    //     clickOutsideToClose: false,
    //     fullscreen: "true",
    //     locals: {
    //       url: url,
    //     }
    //   })
    //   .then(function (response) {
    //     if (response) {
    //       create([response.data]);
    //     }
    //   });
    window.open(url+"?window=yes", "_blank", "location=0,titlebar=0,toolbar=0,scrollbars=0,resizable=no,top=0,left=50%,width=500,height=500");
  }

  function sum_particulare_amount(){
    vm.particulares_sum = 0;
    angular.forEach(vm.gastos.data.filter(
      element => element.hasOwnProperty('amount')
    ), function (value) {
      vm.particulares_sum += parseInt(value.amount);
    });
  }

  function save_multiple(){
    // vm.multiple_type = null;
    // vm.multiple_account = null;
    // vm.multiple_description = null;
    
    // angular.forEach(vm.gastos.data, function (value) {
    //   value.account = vm.multiple_account;
    //   value.description = vm.multiple_description;
    //   value.diff = 0;
    // });
    
    // var items = vm.gastos.data.filter(
    //   element => element.hasOwnProperty('amount')
    // );
    // console.log(items);
    // loading.init();
    // gastoDataService
    //   .post({
    //     data: items,
    //     month: vm.month,
    //     year: vm.year,
    //     is_method: vm.is_method,
    //     division_id: vm.division.id,
    //     community_id: vm.division.community_id,
    //     account_id: vm.multiple_account
    //   })
    //   .then(function (response) {
    //     if (response.data.status == status.CLOSED) {
    //       loading.end();
    //       toastr.warning(response.data.message);
    //     } else {
    //       filter(vm.is_type, 1);
    //       toastr.success(text.API_EXITO);
    //     }
    //   })
    //   .catch(function () {
    //     toastr.error(text.API_ERROR);
    //     loading.end();
    //   });

  }

  function changeDate() {
    vm.gastos = [];
    //vm.regla = null;
    vm.factura = null;
    filter(1);
  }

  function selectMonth() {
    storeService.set("month", vm.month);
    $rootScope.$broadcast("update-auth-data");
  }

  function selectYear() {
    storeService.set("year", vm.year);
    $rootScope.$broadcast("update-auth-data");
  }

  function validateBill(item) {
    var index = false;
    if (item.month == vm.month && item.year == vm.year) {
      index = true;
    } else {
      angular.forEach(item.bill_shares, function (value) {
        if (value.month == item.month && value.year == vm.year) {
          index = true;
        }
      });
    }
    return index;
  }

  // comunidades
  async function comunidades() {
    try {
      const response = await comunidadDataService.all();
      const communities = response.data;
      vm.comunidades = communities;
      // --
      vm.comunidad = communities[comunidad(communities)];
      vm.accounts_to_select = vm.comunidad.accounts.sort((a, b) => (a.name > b.name) ? 1 : -1);
      if(vm.comunidad.divisions.length == 1){
        vm.division = vm.comunidad.divisions[0];
      }
      if (communities.length > 0) {
        selectCommunity();
      }
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function (item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: true,
      label: "Gastos Particulares"
    };
  }
}
