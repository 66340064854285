angular
    .module('app')
    .controller('recordatoriosController', recordatoriosController);

function recordatoriosController($state, $scope, $mdDialog, recordatorioDataService,
    confirmService, access, loading, toastr, text) {
    var vm = this;
    vm.query = {
        order: ['community.name', 'division.name', 'created_at', 'title'],
        limit: 10,
        page: 1
    };
    // ---
    vm.edit = edit;
    vm.destroy = destroy;

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        recordatorios();
    }

    // go to edit
    function edit(item) {
        $state.go('app.recordatorio', {
            id: item ? item.id : null
        });
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            disabled(item);
        }, function () {});
    }

    // disabled
    function disabled(item) {
        item.is_active = false;
        loading.init();
        recordatorioDataService.put(item)
            .then(function (response) {
                init();
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load recordatorios
    function recordatorios() {
        recordatorioDataService.all()
            .then(function (response) {
                vm.recordatorios = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // load title
    function title() {
        vm.item = {
            backUrl: '',
            newUrl: 'app.recordatorio',
            backActive: false,
            newActive: true,
            label: 'Recordatorios',
        };
    }
}