angular
    .module('app')
    .controller('payController', payController);

function payController($state, $stateParams, loading, webpayDataService, gastoComunDataService, toastr, text) {
    var vm = this;
    var date = new Date();
    // --
    vm.propiedad = {
        id: $stateParams.id
    };
    vm.errors = null;
    vm.morosity = 0;
    vm.cost_common = 0;
    vm.pay_all = true;
    vm.sum = sum;
    vm.property = null;
    vm.community = null;
    vm.cost_common_month = null;
    vm.method = "credit";
    vm.debt_rate = 1;
    vm.credit_rate = 1;
    vm.transaction_amount = 0;
    vm.to_pay = to_pay;
    init();

    function init() {
        vm.date = date;
        vm.month = date.getMonth() + 1;
        vm.year = date.getFullYear()
        loading.init();
        webpayDataService.status(vm.propiedad)
        .then(function (response) {
            loading.end();
            vm.error = response.data.error;
            //console.log(response.data);
            if (response.data.can_pay){
                window.location.href = response.data.webpay_link;
            } else {
                vm.morosity = response.data.morosity;
                vm.cost_common = response.data.cost_common_amount;
                vm.cost_common_month = response.data.cost_common_month;
                vm.pay_all = true;
                vm.message = response.data.message;
                vm.property = response.data.property;
                vm.community = response.data.community;
                vm.debt_rate = response.data.debt_rate;
                vm.credit_rate = response.data.credit_rate;
                sum();
                //toastr.error("No se puede pagar este ggcc con webpay");
            }
            loading.end();
        }).catch(function () {
            vm.errors = "Hay problemas con el código de pago";
        });
        loading.end();
    }

    function to_pay(){
        loading.init();
        webpayDataService.to_pay(vm.propiedad, vm.pay_all, vm.total, vm.method, vm.transaction_amount)
        .then(function (response) {
            loading.end();
            vm.error = response.data.error;
            //console.log(response.data);
            if (response.data.can_pay){
                window.location.href = response.data.webpay_link;
            } else {
                vm.morosity = response.data.morosity;
                vm.cost_common = response.data.cost_common_amount;
                vm.pay_all = true;
                vm.message = response.data.message;
                vm.property = response.data.property;
                sum();
            }
        }).catch(function () {
            vm.errors = "Hay problemas con el código de pago";
        });
        loading.end();
    }

    function sum(){
        // console.log(vm.method);
        var total = (vm.morosity + (vm.pay_all ? vm.cost_common : 0));
        // console.log(total);
        vm.transaction_amount = Math.round(total*((vm.method == "credit" ? vm.credit_rate : vm.debt_rate)/100));
        // console.log(vm.transaction_amount);
        vm.total = Math.round(total+vm.transaction_amount);
    }
}
