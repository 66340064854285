angular
    .module('app')
    .controller('liquidacionModalController', liquidacionModalController);

function liquidacionModalController($scope, $mdDialog, $rootScope,
    comunidadDataService, liquidacionDataService, storeService, states_a, states_p,
    months, access, status, toastr, text, employees, data, years) {
    // --
    var date = new Date();

    $scope.empleados = employees;
    // --
    $scope.update = update;
    $scope.create = create;
    $scope.validate = validate;
    $scope.cancel = cancel;
    $scope.selectEmployee = selectEmployee;
    $scope.permission = access;

    init();

    // carga inicial
    function init() {
        //console.log(access);
        $scope.months = months;
        $scope.years = years(date);
        $scope.states_a = states_a;
        $scope.states_p = states_p;
        if (data) {
            // $scope.liquidacion = data;
            getLiquidacion(data);
        } else {
            $scope.liquidacion = {
                month: storeService.get('month'),
                year: storeService.get('year'),
            };
            comunidades();
        }
    }

    // close modal
    function cancel() {
        $mdDialog.hide();
    }

    // crear item
    function create() {
        liquidacionDataService.post($scope.liquidacion)
            .then(function (response) {
                if (response.data.status == status.CLOSED) {
                    // closed
                    toastr.warning(response.data.message);
                } else {
                    $mdDialog.hide({
                        data: response.data
                    });
                    toastr.success(text.API_EXITO);
                    changeDate();
                }
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // update item
    function update() {
        liquidacionDataService.put($scope.liquidacion)
            .then(function (response) {
                if (response.data.status == status.CLOSED) {
                    // closed
                    toastr.warning(response.data.message);
                } else {
                    $mdDialog.hide({
                        data: response.data
                    });
                    toastr.success(text.API_EXITO);
                    changeDate();
                }
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // validate item
    function validate() {
        $scope.liquidacion.state_a = $scope.states_a.APROBADA;
        liquidacionDataService.put($scope.liquidacion)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data
                });
                toastr.success(text.API_EXITO);
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function selectEmployee() {
        if (!data) {
            angular.forEach($scope.empleados, function (item) {
                if (item.id == $scope.liquidacion.account_id) {
                    $scope.liquidacion.amount = item.account;
                }
            });
        }
    }

    function changeDate() {
        storeService.set('month', $scope.liquidacion.month);
        storeService.set('year', $scope.liquidacion.year);
        $rootScope.$broadcast('update-auth-data');
    }

    // data
    function getLiquidacion(item) {
        liquidacionDataService.get(item)
            .then(function (response) {
                $scope.liquidacion = response.data;
                if(!$scope.liquidacion.amount_social){
                    $scope.liquidacion.amount_social = 0;
                }
                comunidades();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // comunidades
    async function comunidades() {
        try {
            let communities = null;
            if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
                const response = await comunidadDataService.get($rootScope.currentCommunity);
                communities = [response.data];
            } else {
                const response = await comunidadDataService.all();
                communities = response.data;
            }
            $scope.comunidades = communities;
            var index = comunidad(communities);
            $scope.divisiones = communities[index].divisions;
            $scope.liquidacion.community_id = communities[index].id;
        } catch (error) {
            toastr.error(text.API_ERROR);
        }
    }

    // get comunidad rootScope
    function comunidad(items) {
        var index = 0;
        if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
            angular.forEach(items, function (item, key) {
                if (item.id == $rootScope.currentCommunity.id) {
                    index = key;
                    $scope.is_current = true;
                }
            });
        } else {
            $scope.is_current = false;
        }
        return index;
    }
}
