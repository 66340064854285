angular
    .module('app')
    .service('empleadoDataService', empleadoDataService);

function empleadoDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('empleados', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (empleado) {
            return dataService.httpGet('empleados/' + empleado.id);
        },
        post: function (empleado) {
            return dataService.httpPost('empleados', empleado);
        },
        put: function (empleado) {
            return dataService.httpPut('empleados/' + empleado.id, empleado);
        },
        delete: function (empleado, communityId) {
            return dataService.httpDelete('empleados/' + empleado.id + '/' + communityId);
        },
        // --
        get_community: function (comunidad) {
            return dataService.httpGet('empleados/comunidad/' + comunidad.id);
        },
        post_employees: function (empleados) {
            return dataService.httpPost('empleados/import', empleados);
        },
    };
}