angular
    .module('app')
    .service('cuentaComunidadDataService', cuentaComunidadDataService);

function cuentaComunidadDataService(dataService, localStorageService) {
    return {
        all: function () {
            return dataService.httpGet('cuentas/comunidades');
        },
        get: function (comunidad) {
            return dataService.httpGet('cuentas/comunidades/' + comunidad.id);
        }
    };
}