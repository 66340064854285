angular
    .module('app')
    .controller('repositorioRWDController', repositorioRWDController);

function repositorioRWDController($scope, $window, repositorioDataService,
    storeService, access, toastr, text, env) {
    var vm = this;

    // --
    vm.open = open;

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        repositorio();
    }

    // view file
    function open(item) {
        document.location  = env.DOMAIN + env.FILE_PATH + item.title + '.' + item.type;
        // $window.open(env.DOMAIN + env.FILE_PATH + item.title + '.' + item.type);
    }

    // load informations
    function repositorio() {
        repositorioDataService.get_community(storeService.get('community'))
            .then(function (response) {
                vm.files = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // load title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.administracion_rwd',
            title: 'Repositorio'
        };
    }
}