angular
  .module('app')
  .controller('reservacionModalController', reservacionModalController);

function reservacionModalController($scope, $mdDialog, $rootScope, usuarioDataService,
  reservacionDataService, storeService, reserva_states, toastr, text, data, value,
  availableRanges, date) {
  var notification = storeService.get('notification');
  $scope.reserva = {
    date: date,
    reservation_id: data.id,
    from: null,
    to: null
  }
  $scope.states = reserva_states;
  // --
  $scope.cancel = cancel;
  $scope.create = create;
  $scope.update = update;
  $scope.state = state;
  $scope.utcStringToMillis = utcStringToMillis;
  $scope.onFromChange = onFromChange;
  $scope.fromOptions = buildAvailablesFromHours(availableRanges);
  $scope.toOptions = [];
  if ($scope.fromOptions && $scope.fromOptions.length > 0) {
    $scope.reserva.from = $scope.fromOptions[0];
    onFromChange();
  }

  init();

  // carga inicial
  function init() {
    if (value) {
      $scope.reserva = value;
      show();
    }
    personas();
  }

  // salir del modal
  function cancel() {
    $mdDialog.hide({
      data: null
    });
  }

  // crear registro
  function create() {
    const reserva = {
      from: $scope.reserva.from.getTime() / 1000,
      to: $scope.reserva.to.getTime() / 1000,
      user_id: $scope.reserva.user_id,
      reservation_id: $scope.reserva.reservation_id,
    };
    reservacionDataService.post(reserva)
      .then(function (response) {
        $mdDialog.hide({
          data: response.data,
        });
        toastr.success(text.API_EXITO);
      }).catch(function () {
        toastr.error(text.API_ERROR);
      });
  }

  // update registro
  async function update() {
    const reserva = {
      id: $scope.reserva.id,
      state: $scope.reserva.state
    }
    try {
      $mdDialog.hide({
        data: reserva,
      });
      const response = await reservacionDataService.put(reserva);
      toastr.success(text.API_EXITO);
    } catch (error) {
      toastr.error(text.API_ERROR);

    }
  }

  // change state
  async function state(item) {
    $scope.reserva.state = item;
    await update();
  }

  function show() {
    reservacionDataService.get($scope.reserva)
      .then(function (response) {
        notification.reservations -= response.data.notifications;
        storeService.set('notification', notification);
        $rootScope.$broadcast('update-auth-data');
      }).catch(function () {
        toastr.error(text.API_ERROR);
      });
  }
  // personas
  function personas() {
    usuarioDataService.get_division(data.division)
      .then(function (response) {
        $scope.personas = response.data;
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
      });
  }

  function utcStringToMillis(date) {
    return new Date(date + ' UTC').getTime();
  }

  function onFromChange() {
    if ($scope.reserva.from) {
      $scope.toOptions = buildAvailableToHours(
        availableRanges,
        $scope.reserva.from
      );
    } else {
      $scope.toOptions = [];
    }
  }


  function addOneDay(date) {
    const d = new Date(date.getTime());
    d.setDate(d.getDate() + 1);
    return d;
  }

  function buildAvailablesFromHours(ranges, step = 15 * 60 * 1000) {
    if (!ranges) {
      return [];
    }
    const options = [];
    let now = new Date().getTime();
    now = now + (step - now % step);
    for (let index = 0; index < ranges.length; index++) {
      const range = ranges[index];
      const from = new Date(Math.max(range.from.getTime(), now));
      const to = new Date(range.to.getTime());
      if (from.getDate() != to.getDate()) {
        to.setHours(0, 0, 0, 0);
        to.setDate(from.getDate() + 1);
      }
      for (let time = from.getTime(); time < to.getTime(); time += step) {
        options.push(new Date(time));
      }
    }
    return options;
  }

  function buildAvailableToHours(ranges, from, step = 15 * 60 * 1000) {
    const range = ranges.find((r) => r.from <= from && from < r.to);
    if (!range) {
      return [];
    }
    const to = new Date(Math.min(addOneDay(from).getTime(), range.to.getTime()));
    const options = [];
    for (let time = from.getTime() + step; time <= to.getTime(); time += step) {
      options.push(new Date(time));
    }
    return options;
  }
}