angular
  .module("app")
  .controller("proveedoresController", proveedoresController);

function proveedoresController(
  $state,
  proveedorDataService,
  confirmService,
  $mdDialog,
  access,
  loading,
  toastr,
  text
) {
  var vm = this;
  vm.proveedores = [];
  vm.selected = [];

  vm.query = {
    order: "name",
    limit: 100,
    page: 1,
    search: ""
  };

  // ---
  vm.edit = edit;
  vm.destroy = destroy;

  init();

  // inicio
  function init() {
    title();
    getData();
    vm.permission = access;
  }

  // edit item
  function edit(item) {
    $state.go("app.proveedor", {
      id: item.id
    });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        disabled(item);
      },
      function() {}
    );
  }

  // disabled
  function disabled(item) {
    item.is_active = false;
    loading.init();
    proveedorDataService
      .put(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  function getData() {
    proveedorDataService
      .all(vm.query)
      .then(function(response) {
        vm.data = response.data;
        vm.proveedores = response.data.data;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "person",
      backUrl: "",
      newUrl: "app.proveedor",
      backActive: false,
      newActive: true,
      label: "Proveedores"
    };
  }

  // table events
  vm.onReorder = function() {
    getData();
  };

  vm.onPaginate = function() {
    getData();
  };

  vm.onSearch = function() {
    getData();
  };
}
