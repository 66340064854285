angular.module("app").controller("selectHeaderController", function($scope, $element) {
  $scope.searchTerm;
  
  $scope.clearSearchTerm = function() {
    console.log("clear search term");
    $scope.searchTerm = '';
  };
  $element.find('input').on('keydown', function(ev) {
    ev.stopPropagation();
  });
});