angular
    .module('app')
    .controller('gastoStatusRWDController', gastoStatusRWDController);

function gastoStatusRWDController($scope, $state, $window, webpayDataService,
    storeService, toastr, text) {
    var vm = this;
    var date = new Date();

    // --
    vm.back = back;

    init();

    // inicio
    function init() {
        title();
        // -- state cost
        vm.date = date;

        // --
        var hash = $window.location.hash.split('?');
        if (hash.length == 2) {
            if (hash[1] == 'error') {
                vm.is_error = 1;
            } else {
                vm.is_error = 0;
                // var hash = hash[1].split('&');
                // vm.pay = storeService.get('pay');
                loadData(hash[1].split('&'));
            }
        }
    }

    // redirect to 
    function back() {
        $state.go('app.gasto_rwd');
        remove_pay();
    }

    // load data
    function loadData(items) {
        // webpayDataService.get(storeService.get('community'),
        //         storeService.get('property'),
        //         vm.pay.month, vm.pay.year)
        //     .then(function (response) {
        //         vm.data = response.data;
        //         response.data.transactionDate = new Date(response.data.transactionDate);
        //     })
        //     .catch(function () {
        //         toastr.error(text.API_ERROR);
        //     });

        webpayDataService.get(items[0])
            .then(function (response) {
                vm.data = response.data;
                response.data.transactionDate = new Date(response.data.transactionDate);
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });

    }

    // create pay localstorage
    function remove_pay() {
        storeService.clearItem('pay');
    }

    // load title sub-toolbar
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.gasto_rwd',
            title: 'Estado de Transacción',
        };
    }
}