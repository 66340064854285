angular.module('app')
    .directive('dpzMenu', dpzMenu);

function dpzMenu($rootScope, $state, $mdSidenav, authService, access) {
    return {
        restrict: 'AE',
        templateUrl: 'views/layout/menu_view.html',
        scope: {
            menu: '=menuData',
            active: '=menuActive'
        },
        link: function (scope, element, attrs) {
            scope.goMenu = function (item) {
                $mdSidenav('left-xs').close();
                if (item.url == 'comunidades' || item.url == 'usuarios') {
                    if (!$rootScope.currentCommunity) {
                        $state.go('app.' + item.url);
                    } else {
                        if( access.isAdmin() || access.isAnalista()) {
                            //$state.go('app.' + item.url);
                            $state.go('app.comunidad', {
                                id: $rootScope.currentCommunity.id
                            });
                        } else {
                            $state.go('app.comunidad', {
                                id: $rootScope.currentCommunity.id
                            });
                        }
                    }
                } else {
                    $state.go('app.' + item.url);
                }
            };
            scope.logout = function () {};
            scope.isActive = function (item, url) {
                var items = item.split('/');
                if (items[2] == url) {
                    return true;
                }
            };
            scope.isPermission = function (item) {
                if (authService.isAuthenticated()) {
                    if (authService.getUserAuthenticated()) {
                        if (item.indexOf($rootScope.currentRole.name) > -1) {
                            return true;
                        }
                    }
                } else {
                    authService.expired();
                }
                return false;
            };
            scope.isNotification = function (item) {
                if (item.indexOf('foros') !== -1) {
                    if ($rootScope.currentNotification && $rootScope.currentNotification.forums > 0) {
                        return true;
                    }
                }
                if (item.indexOf('informaciones') !== -1) {
                    if ($rootScope.currentNotification && $rootScope.currentNotification.informations > 0) {
                        return true;
                    }
                }
                if (item.indexOf('repositorios') !== -1) {
                    if ($rootScope.currentNotification && $rootScope.currentNotification.repositories > 0) {
                        return true;
                    }
                }
                if (item.indexOf('instalaciones') !== -1) {
                    if ($rootScope.currentNotification && $rootScope.currentNotification.reservations > 0) {
                        return true;
                    }
                }
                if (item.indexOf('estacionamientos') !== -1) {
                    if ($rootScope.currentNotification && $rootScope.currentNotification.parkings > 0) {
                        return true;
                    }
                }
                return false;
            };

        }
    };
};