angular
    .module('app')
    .service('tipoDocumentoDataService', tipoDocumentoDataService);

function tipoDocumentoDataService(dataService, $rootScope) {
    return {
        all: function (query) {
            return dataService.httpGet('document_type', { params: query });
        },
        get: function (documento) {
            return dataService.httpGet('document_type/' + documento.id);
        },
        post: function (documento) {
            return dataService.httpPost('document_type', documento);
        },
        put: function (documento) {
            return dataService.httpPut('document_type/' + documento.id, documento);
        },
        delete: function (documento) {
            return dataService.httpDelete('document_type/' + documento.id);
        },
    };
}