angular
    .module('app')
    .controller('msgCommunityModalController', msgCommunityModalController)

function msgCommunityModalController($scope, $state, $mdDialog, $window, toastr, env, data) {
    // --
    $scope.redirect = redirect;
    $scope.cancel = cancel;

    function cancel() {
        $mdDialog.hide()
    }

    function redirect() {
        document.location = env.DOMAIN + env.API_PATH + 'files/export/book/' + data.id;
        // $window.open(env.DOMAIN + env.API_PATH + 'files/export/book/' + data.id);
    }
};