angular.module("app").controller("comunidadCreateController", comunidadCreateController);

function comunidadCreateController(
  $state,
  $stateParams,
  $mdDialog,
  comunidadDataService,
  bancoDataService,
  regionDataService,
  confirmService,
  access,
  toastr,
  account_types,
  text,
  loading,
  status,
  tooltips
) {
  var vm = this;
  vm.tooltips = tooltips;
  vm.permission = access;
  vm.tipos_cuenta_comunidad = account_types;
  vm.comunidad = {
    id: $stateParams.id ? $stateParams.id : null
  };
  vm.user = {
    rut: null,
    name: null,
  }
  // ---
  vm.create = create;
  vm.selectRegion = selectRegion;

  init();

  // iniciar
  function init() {
    if (vm.comunidad.id) {
      comunidadDataService
        .get(vm.comunidad)
        .then(function (response) {
          vm.comunidad = response.data;
          vm.community_softland = vm.comunidad.community_softland || {};
          title();
          regiones();
        })
        .catch(function () {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      regiones();
    }
  }

  // crear
  async function create() {
    loading.init();
    try {
      const response = await comunidadDataService.post({ community: vm.comunidad, user: vm.user });
      // validate status
      if (response.data.status == status.ERROR) {
        // error rr
        toastr.error(response.data.msg[0]);
      } else {
        // successul
        $state.go("app.comunidades", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
      }
    } catch (error) {
      console.error(error);
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();

    }
  }

  // al seleccionar region
  function selectRegion() {
    angular.forEach(vm.regiones, function (item) {
      if (item.name == vm.comunidad.region)
        vm.comunas = item.comunas.split(",");
    });
  }

  // load regiones
  function regiones() {
    vm.regiones = [];
    regionDataService
      .all()
      .then(function (response) {
        vm.regiones = response.data;
        vm.selectRegion();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
      });
  }

  // load title
  function title() {
    vm.type = vm.comunidad.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "",
      newUrl: "",
      excel: true,
      isOpen: false,
      label: vm.type + " Comunidad",
      name: vm.comunidad.id ? vm.comunidad.name.toUpperCase() : "",
      id: vm.comunidad.id ? vm.comunidad.id : null,
      items_excel: [
        {
          id: 1,
          icon: "insert_drive_file",
          name: "Compacto",
          description:
            "Incluye toda la informacion de la comunidad en una sola tabla."
        },
        {
          id: 2,
          icon: "book",
          name: "Libro",
          description:
            "Mantiene el formato para la subida de archivos y carga de propiedades, usuarios y unidades."
        }
      ]
    };
  }
}
