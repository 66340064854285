angular
    .module('app')
    .service('gastoComunDataService', gastoComunDataService);

function gastoComunDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('gastos_comunes');
        },
        get: function (gasto) {
            return dataService.httpGet('gastos_comunes/' + gasto.id);
        },
        post: function (gasto) {
            return dataService.httpPost('gastos_comunes', gasto);
        },
        put: function (gasto) {
            return dataService.httpPut('gastos_comunes', gasto);
        },
        // delete: function (gasto) {
        //     return dataService.httpDelete('gastos_comunes/' + gasto.id);
        // },

        // --
        post_pago_manual: function (gasto) {
            return dataService.httpPost('gastos_comunes/pago', gasto);
        },
        put_pago_manual: function (gasto) {
            return dataService.httpPut('gastos_comunes/pago', gasto);
        },
        get_resume: function (community, property, month, year) {
            return dataService.httpGet('gastos_comunes/resumen/' + community.id + '/' + property.id + '/' + month + '/' + year);
        },
        get_resume_details: function (community, property, month, year) {
            return dataService.httpGet('gastos_comunes/resumen/detalles/' + community.id + '/' + property.id + '/' + month + '/' + year);
        },
        get_resume_category: function (community, property, month, year) {
            return dataService.httpGet('gastos_comunes/resumen/categorias/' + community.id + '/' + property.id + '/' + month + '/' + year);
        },
        get_resume_hitorico: function (community, property) {
            return dataService.httpGet('gastos_comunes/resumen/historial/' + community.id + '/' + property.id);
        },
        get_filter: function (community, division, month, year) {
            return dataService.httpGet('gastos_comunes/' + community.id + '/' + division.id + '/' + month + '/' + year);
        },
        get_detail: function (cost) {
            return dataService.httpGet('gastos_comunes/detalle/' + cost.id);
        },
        get_details: function (gasto) {
            return dataService.httpGet('gastos_comunes/detalles/' + gasto.community_id + '/' + gasto.division_id + '/' + gasto.month + '/' + gasto.year);
        },
        get_listado: function (gasto) {
            return dataService.httpGet('gastos_comunes/listado/' + gasto.community_id + '/' + gasto.division_id + '/' + gasto.month + '/' + gasto.year);
        },
        get_category: function (gasto) {
            return dataService.httpGet('gastos_comunes/categorias/' + gasto.id);
        },
        get_debts: function (gasto) {
            return dataService.httpGet('gastos_comunes/deudas/' + gasto.id);
        },
        get_generate: function (bill) {
            return dataService.httpGet('gastos_comunes/factura/' + bill.community_id + '/' + bill.id);
        },
        allByCommunity: function () {
            return dataService.httpGet('gastos_comunes', {
                headers: {
                    'x_comunidad': $rootScope.currentCommunity.id
                },
            })
        },
        send_test_email: function (cost_common, property, email) {
            var all={
                cost_common: cost_common,
                property: property,
                email: email,
            }
            return dataService.httpPost('gastos_comunes/test_email', all);
        },
    };
}
