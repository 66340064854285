angular
    .module('app')
    .controller('costGeneralesModalController', costGeneralesModalController);

function costGeneralesModalController($scope, $mdDialog, unidadDataService, data) {
    $scope.query = {
        order: ['number'],
        limit: 10,
        page: 1
    };
    // --
    $scope.cancel = cancel;

    init();

    // inicio
    function init() {
        $scope.movimientos_generales = data;
    }

    function cancel() {
        $mdDialog.hide()
    }
}