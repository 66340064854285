angular.module("app").controller("gastoComunController", gastoComunController);

function gastoComunController(
  $state,
  $stateParams,
  gastoComunDataService,
  state_cost,
  toastr,
  text
) {
  var vm = this;
  vm.estados = state_cost;
  vm.gasto = {
    id: $stateParams.id
  };
  vm.query = {
    order: ["amount"]
  };

  // --

  init();

  // inicio
  function init() {
    gastos();
  }

  // gastos comunes
  function gastos() {
    gastoComunDataService
      .get(vm.gasto)
      .then(function(response) {
        vm.gastos = response.data.cost_common_details;
        vm.gasto = response.data;
        title();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // load title
  function title() {
    vm.item = {
      backUrl: "app.gastos_comunes",
      newUrl: "",
      excel_cost: true,
      label:
        "Gastos Comunes - Nº " + (vm.gasto.id ? vm.gasto.property.number : ""),
      name: "",
      id: vm.gasto.id ? vm.gasto.id : null
    };
  }
}
