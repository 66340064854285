angular.module("app").service("conciliacionDataService", conciliacionDataService);

function conciliacionDataService(dataService) {
  return {
    getFintocMovements: function (communityId) {
      return dataService.httpGet(`comunidad/get_movements_from_fintoc/${communityId}`);
    },
    listFintocMovements: function (communityId, query) {
      return dataService.httpGet(`comunidad/list_fintoc_movements/${communityId}?page=${query.page}&limit=${query.limit}&order=${query.order}`);
    },
    setFintocMovementsProperty: function (item) {
      return dataService.httpPost('comunidad/set_movement', item);
    },
    unsetFintocMovementsProperty: function (item) {
      return dataService.httpPost('comunidad/unset_movement', item);
    },
  };
}
