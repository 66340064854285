angular
    .module('app')
    .controller('mailsController', mailsController);

function mailsController($scope, $state, $rootScope, $mdDialog, mailDataService, storeService,
    confirmService, loading, access, toastr, text) {
    var vm = this;
    var user = storeService.get('user');
    vm.query = {
        order: ['created_at'],
        limit: 10,
        page: 1
    };

    // --
    vm.edit = edit;
    vm.destroy = destroy;

    init();

    // inicio
    function init() {
        vm.permission = access;
        title();
        mails();
    }

    // actualizar
    function edit(item) {
        $state.go('app.mail', {
            id: item ? item.id : null
        });
    }

    function mails() {
        mailDataService.all()
            .then(function (response) {
                vm.mails = angular.forEach(response.data, function (item) {
                    if (user.id != item.from_user_id)
                        item.is_send = 0;
                    else
                        item.is_send = 1;
                });
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            disabled(item);
        }, function () {});
    }

    // disabled
    function disabled(item) {
        loading.init();
        item.is_active = false;
        mailDataService.put(item)
            .then(function (response) {
                init();
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // socket
    $rootScope.$on('emit-socket', function (event, data) {
        mails();
        console.log(data.data);
    });

    // title
    function title() {
        if (access.isCopropietario()) {
            $scope.$parent.vm.item = {
                back: true,
                backUrl: 'app.home_rwd',
                title: 'Mails',
            };
        } else {
            vm.item = {
                backUrl: '',
                newUrl: 'app.mail',
                backActive: false,
                newActive: true,
                label: 'Mails',
            };
        }
    }
}