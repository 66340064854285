angular
    .module('app')
    .controller('unidadesController', unidadesController);

function unidadesController($state, unidadDataService, $mdDialog,
    confirmService, access, toastr, text) {
    var vm = this;
    vm.unidades = [];
    vm.selected = [];

    vm.query = {
        order: ['property.division.community.name', 'property.division.name', 'property.number', 'number', 'type'],
        limit: 10,
        page: 1
    };

    // --
    vm.edit = edit;
    vm.destroy = destroy;

    init();

    // inicio
    function init() {
        title();
        vm.permission = access;
        unidadDataService.all()
            .then(function (response) {
                vm.unidades = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // edit item
    function edit(item) {
        $state.go('app.unidad', {
            id: item.id
        });
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            trash(item);
        }, function () {});
    }

    // eliminar el item seleccionado
    function trash(item) {
        unidadDataService.delete(item).then(function (response) {
            init();
            toastr.success(text.API_EXITO);
        }).catch(function () {
            toastr.error(text.API_ERROR);
        });
    }

    // load title sub-toolbar
    function title() {
        vm.item = {
            icon: 'person',
            backUrl: '',
            newUrl: '',
            backActive: false,
            newActive: false,
            label: 'Unidades',
            service: unidadDataService
        };
    }
}