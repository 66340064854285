angular.module("app").service("fondoDataService", fondoDataService);

function fondoDataService(dataService, $rootScope) {
  return {
    all: function(config) {
      return dataService.httpGet("reserve_funds", config);
    },
    get: function(id) {
      return dataService.httpGet("reserve_funds/" + id);
    },
    post: function(fondo) {
      return dataService.httpPost("reserve_funds", fondo);
    },
    put: function(fondo) {
      return dataService.httpPut("reserve_funds/" + fondo.id, fondo);
    },
    delete: function(id) {
      return dataService.httpDelete("reserve_funds/" + id);
    },
    division_funds: function(div_id){
      return dataService.httpGet("reserve_funds/division/"+div_id);
    }
  };
}
