angular.module("app").service("categoriaDataService", categoriaDataService);

function categoriaDataService(dataService, $rootScope) {
  return {
    all: function(query) {
      return dataService.httpGet(
        `categorias?page=${query.page}&limit=${query.limit}&order=${
          query.order
        }&search=${query.search}`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          }
        }
      );
    },
    get: function(categoria) {
      return dataService.httpGet("categorias/" + categoria.id);
    },
    post: function(categoria) {
      return dataService.httpPost("categorias", categoria);
    },
    put: function(categoria) {
      return dataService.httpPut("categorias/" + categoria.id, categoria);
    },
    delete: function(categoria) {
      return dataService.httpDelete("categorias/" + categoria.id);
    },
    // --
    // --
    post_accounts: function(accounts) {
      return dataService.httpPost("categorias/cuentas", accounts);
    }
  };
}
