angular
    .module('app')
    .controller('archivoController', archivoController);

function archivoController($state, $stateParams, $mdDialog, fileDataService, confirmService,
    toastr, RutHelper, community_types, roles_copropietario, property_type, loading, text, env) {
    var vm = this;

    vm.selected = [];
    vm.row_error = 0;
    vm.row_none = 0;
    vm.prorrateo = 0;
    vm.prorrateo_division = 0;
    vm.morosidad = 0;
    vm.cantidad = 0;
    vm.is_processing = false;
    vm.is_valid = true;

    vm.query = {
        order: ['dname', 'rut', 'name', 'lastname', 'pcode', 'dcode'],
        limit: 10,
        page: 1
    };

    // --
    vm.upload = upload;
    vm.confirm = confirm;
    vm.process = process;
    vm.editRow = editRow;
    vm.validator = validator;
    vm.validate_name = validate_name;

    init();

    // iniciar
    function init() {
        vm.comunidad_id = $stateParams.id;
    }

    // subir archivos
    function upload(items) {
        loading.init();
        vm.data = [];
        vm.is_valid = true;
        vm.is_processing = true;
        vm.row_error = 0;
        vm.row_none = 0;
        vm.prorrateo = 0;
        vm.prorrateo_division = 0;
        vm.morosidad = 0;
        vm.cantidad = 0;
        angular.forEach(items, function (item) {
            var file = new FormData();
            file.append("file", item);
            // send file
            fileDataService.upload(file)
                .then(function (response) {
                    vm.data = validator(response.data.data);
                    validator_columns(vm.data);
                    vm.prorrateo = response.data.prorrateo;
                    vm.cantidad = response.data.cantidad;
                    if (response.data.state == 'invalid_alicuota') {
                        if (response.data.message) {
                            toastr.error(response.data.message);
                        } else {
                            toastr.error(text.EXCEL_MAX_MIN);
                        }
                    } else if (response.data.state == 'invalid_file') {
                        if (response.data.message) {
                            toastr.error(response.data.message);
                        }
                    } else if (response.data.data.length > 0) {
                        toastr.warning(text.EXCEL_EXITO);
                        getPendientes();
                    } else {
                        toastr.error(text.EXCEL_ERROR);
                    }
                    document.getElementById('file').value = '';
                    vm.is_processing = false;
                    angular.forEach(vm.data, function (item) {
                        vm.prorrateo_division += parseFloat(item.pprorrateotorre);
                        vm.morosidad += parseFloat(item.morosidad);
                    });
                    loading.end();
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                    document.getElementById('file').value = '';
                    vm.is_processing = false;
                    loading.end();
                });
        });
    };

    // confirmar para procesar registros
    function confirm(ev) {
        $mdDialog.show(confirmService.excel(ev)).then(function () {
            vm.process();
        }, function () {});
    }

    // enviar registros
    function process() {
        vm.is_processing = true;
        vm.row_none = (vm.row_error == 0) ? vm.row_none : vm.row_error;
        vm.row_error = 0;
        create();
    }

    // crear registro
    function create() {
        loading.init();
        fileDataService.post(vm.data)
            .then(function (response) {
                if (response.data.error) {
                    // --
                    toastr.error((response.data.row + 1) + ' - ' + response.data.error.original.msg[0]);
                    vm.is_processing = false;
                } else {
                    // mostrar notificacion
                    vm.data = response.data.data;
                    vm.row_error = response.data.error;
                    vm.row_none = response.data.total - response.data.success;
                    notify();
                }
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                vm.is_processing = false;
                loading.end();
            });
    }

    // obtiene cantidad de items pendiente de procesar
    function getPendientes() {
        angular.forEach(vm.data, function (value, key) {
            vm.data[key].community_id = vm.comunidad_id;
            if (value.proceso == 0 || value.proceso == 2) {
                vm.row_none++;
            }
        });
    }

    // editar registro
    function editRow(ev, item, key) {
        var item_before = item.proceso;
        $mdDialog.show({
                controller: archivoModalController,
                templateUrl: 'views/admin/archivo/modal/row_modal.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                fullscreen: false,
                locals: {
                    row: item,
                }
            })
            .then(function (response) {
                if (response) {
                    vm.data[key] = response.row;
                    vm.data[key].proceso = 0;
                    if (item_before == 1) {
                        vm.row_error++;
                    }
                    // valida
                    validator(vm.data);
                    validator_columns(vm.data);
                }
            });
    }

    // notificar del total de registros subidos
    function notify() {
        vm.is_processing = false;
        $mdDialog.show(confirmService.excel_import('ev', vm.data.length, (vm.data.length - vm.row_error), vm.row_error))
            .then(function () {
                $state.go('app.comunidades', {}, {}, {
                    reload: true
                });
            }, function () {});
    }

    // valida filas
    function validator(items) {
        console.log("------VALIDAR");
        vm.is_valid = true;
        return angular.forEach(items, function (item, key) {
            // validar division
            items[key].is_valid = true;
            if (!item.dname || !item.divtype || !validate_divtype(item.divtype)) {
                items[key].is_valid = false;
                console.log("no valida por division");
            } 
            // validar propiedad
            if (!item.pnumber || !item.ptype || !item.pprorrateo || !validate_ptype(item.ptype) || !validate_name(items, item.pnumber, item.dname, item.ptype)) {
                items[key].is_valid = false;
                vm.is_valid = false;
                console.log("no valida por propiedad");
            } 
            // validar persona
            // if (!item.rut || (item.rut && RutHelper.validate(item.rut) && item.email && item.name && item.lastname && item.rol && validate_rol(item.rol))) {
            if (!item.rut || (item.rut && RutHelper.validate(item.rut) && item.name && item.rol && validate_rol(item.rol))) {
                //items[key].is_valid = true;
            } else {
                items[key].is_valid = false;
                vm.is_valid = false;
                console.log("no valida por persona");
            }
        });
    }

    function validate_rol(item) {
        var index = false;
        // var item = item.trim();
        angular.forEach(roles_copropietario, function (value) {
            if (value == item) {
                index = true;
            }
        });
        return index;
    }

    function validate_ptype(item) {
        var index = false;
        // var item = item.trim();
        angular.forEach(property_type, function (value) {
            if (value == item) {
                index = true;
            }
        });
        return index;
    }

    function validate_divtype(item) {
        var index = false;
        angular.forEach(community_types, function (value) {
            angular.forEach(value, function (val) {
                if (val.name == item) {
                    index = true;
                }
            });
        });
        return index;
    }

    function validate_name(items, pnumber, dname, ptype) {
        var index = true;
        var count = 0;
        angular.forEach(items, function (value) {
            if (value.dname == dname && value.pnumber == pnumber && value.ptype == ptype) {
                count++;
            }
        });
        return count > 1 ? false : true;
    }


    function validator_columns(items) {
        angular.forEach(items, function (item) {
            // validar division name
            if (!item.dname) {
                item.flag_dname = false;
            }
            // validar division type
            if (!item.divtype || !validate_divtype(item.divtype)) {
                item.flag_dtype = false;
            }
            // validar propiedad number
            if (!item.pnumber) {
                item.flag_prnumber = false;
            }
            // validar propiedad prorrateo
            if (!item.pprorrateo) {
                item.flag_prprorrateo = false;
            }

            // validar propiedad prorrateo torre
            if (!item.pprorrateotorre) {
                item.flag_prprorrateotorre = false;
            }
            // validar propiedad type
            if (!item.ptype || !validate_ptype(item.ptype)) {
                item.flag_prtype = false;
            }
            // validar propiedad type
            if (!validate_name(items, item.pnumber, item.dname, item.ptype)) {
                item.flag_duplicate = false;
            }
            if (item.rut && !RutHelper.validate(item.rut)) {
                item.flag_prut = false;
            }
            if (item.rut && !item.name) {
                item.flag_pname = false;
            }
            if (item.rut && !item.rol) {
                item.flag_prol = false;
            }
            if (item.rol && !validate_rol(item.rol)) {
                item.flag_prol = false;
            }
        });
    }
}