angular
    .module('app')
    .service('reservaFileDataService', reservaFileDataService);

function reservaFileDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('reservas/files');
        },
        get: function (factura) {
            return dataService.httpGet('reservas/files/' + factura.id);
        },
        post: function (factura) {
            return dataService.httpPost('reservas/files', factura);
        },
        put: function (factura) {
            return dataService.httpPut('reservas/files/' + factura.id, factura);
        },
        delete: function (factura) {
            return dataService.httpDelete('reservas/files/' + factura.id);
        },
        // --
        upload: function (files) {
            return dataService.httpPost('reservas/files/upload', files, {
                withCredentials: false,
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity
            });
        },
    };
}