angular.module("app").controller("authController", authController);

function authController(
  $state,
  $auth,
  $rootScope,
  authDataService,
  authService,
  notificationDataService,
  storeService
) {
  var vm = this;

  // --
  vm.login = login;
  vm.forgot = forgot;

  vm.community_rut = "";

  // iniciar sesion
  async function login() {
    vm.error = null;
    vm.is_loading = true;
    try {
      await $auth.login({
        rut: vm.username,
        password: vm.password,
        community_rut: vm.community_rut
      });
      const response = await authDataService.get();
      authService.setUserAuthenticated(response.data);
      notifications();
    } catch (error) {
      vm.error = error.data.error ? error.data.error : true;
    } finally {
      vm.is_loading = false;
    }
  }

  function forgot() {
    $state.go("app.forgot");
  }

  function notifications() {
    notificationDataService.get().then(function(response) {
      // init notifications
      storeService.set("notification", response.data ? response.data : null);
      $rootScope.$broadcast("update-auth-data");
    });
  }
}
