angular.module("app").controller("paymentDetailModalController", paymentDetailModalController);

function paymentDetailModalController(
  $scope,
  $mdDialog,
  payDataService,
  toastr,
  text,
  months,
  storeService,
  data
) {
  // --
  $scope.cancel = cancel;
  $scope.payment_details = null;
  $scope.month_str = month_str;

  init();

  // --
  function init() {
    $scope.payment = data;
    getDetail(data);
  }

  function cancel() {
    $mdDialog.hide({
      row: $scope.row
    });
  }

  function getDetail(payment) {
    payDataService
      .getPaymentDetail(payment.id)
      .then(function(response) {
        $scope.payment_details = response.data;
        //toastr.success(text.API_EXITO);
      })
      .catch(function() {
        $mdDialog.hide();
        toastr.error(text.API_ERROR);
      })
  }

  // get month string
  function month_str(month) {
    if (month) {
      if (month == 1) return months.ENERO;
      else if (month == 1) return months.ENERO;
      else if (month == 2) return months.FEBRERO;
      else if (month == 3) return months.MARZO;
      else if (month == 4) return months.ABRIL;
      else if (month == 5) return months.MAYO;
      else if (month == 6) return months.JUNIO;
      else if (month == 7) return months.JULIO;
      else if (month == 8) return months.AGOSTO;
      else if (month == 9) return months.SEPTIEMBRE;
      else if (month == 10) return months.OCTUBRE;
      else if (month == 11) return months.NOVIEMBRE;
      else if (month == 12) return months.DICIEMBRE;
    }
  }
}
