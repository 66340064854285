angular
    .module('app')
    .controller('userModalController', userModalController)

function userModalController($scope, $mdDialog, usuarioDataService, roleDataService,
    auth_roles, auth_types, toastr, text, status, data) {

    $scope.rol = auth_roles.ADMIN;
    // --
    $scope.create = create;
    $scope.cancel = cancel;

    init();

    function init() {
        $scope.usuario = {
            id: null,
            company_id: data.id,
            roles: [],
        };
        roles();
    }

    function create() {
        $scope.usuario.roles.push({
            type: $scope.usuario.role.type,
            role: $scope.usuario.role
        });
        usuarioDataService.post($scope.usuario)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    $mdDialog.hide({
                        data: response.data,
                    });
                    toastr.success(text.API_EXITO);
                }
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // load roles
    function roles() {
        roleDataService.all()
            .then(function (response) {
                $scope.roles = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }


    function cancel() {
        $mdDialog.hide()
    }

};