angular
    .module('app')
    .service('authService', authService);

function authService($auth, $rootScope, $state, $mdDialog, authDataService,
    storeService, confirmService, auth_home, auth_types, auth_roles) {
    var authService = {};

    authService.isAuthenticated = function () {
        return !!$auth.isAuthenticated();
    };

    authService.setUserAuthenticated = function (data) {
        //console.log(data);
        const user = data.user;
        const community = data.community;
        if (user) {
            user.roles = user.roles.filter(r => r.role.type.name !== auth_types.COPROPIETARIO);
        }
        if (user == null || user == '' || !angular.isObject(user)) {
            console.log("NO hay USUARIO");
            authService.logout();
            return false;
        } else if(user && community && (!user.roles || user.roles.length <= 0) && !user.root ) {
            console.log("No hay ROLES para el usuario");
            authService.logout();
            return false;
        } else {
            storeService.set('month', new Date().getMonth() + 1);
            storeService.set('year', new Date().getFullYear());
            storeService.set('user', user);
            if (community) {
                if(user.roles.length == 0){
                    storeService.set('role', {name: auth_roles.ADMIN});
                } else {
                    storeService.set('role', (user.roles.length == 1) ? user.roles[0].role : null);
                }
            } else {
                if(user.root){
                    storeService.set('role', {name: auth_roles.ROOT});
                } else{
                    storeService.set('role', {name: auth_roles.SEMIROOT});
                }
            }
            storeService.set('community', community);
            $rootScope.$broadcast('update-auth-data');
            authService.routes();
            console.log('start session...' + new Date());
        }
        return user;
    };

    authService.getUserAuthenticated = function () {
        var user = storeService.get('user');
        if (user == null || user == '' || !angular.isObject(user)) {
            authService.logout();
            return false;
        }
        return user;
    };

    authService.refresh = function () {
        authDataService.get_refresh()
            .then(function (response) {
                $auth.setToken(response.data.new_token);
                // $rootScope.$broadcast('update-auth-data');
                // $rootScope.$broadcast('update-socket-data', {
                //     end: true,
                //     init: true,
                // });
            });
    };

    authService.logout = function () {
        $auth.logout().then(function () {
            storeService.clearAll();
            $rootScope.$broadcast('update-auth-data');
            $rootScope.$broadcast('update-socket-data', {
                end: true,
                init: false,
            });
            $state.go('app.login', {}, {
                reload: true
            });
            console.log('finished session...' + new Date());
        });
    };

    authService.routes = function () {
        if (storeService.get('role')) {
            for (const item of auth_home.HOME_PAGE) {
                if (item.rol.indexOf(storeService.get('role').name) > -1) {
                    // event.preventDefault();
                    $state.go(item.state, {}, {
                        reload: true,
                    });
                    return
                }
            }
        } else {
            $state.go('app.profile');
        }
    };

    authService.expired = function () {
        if (!authService.isAuthenticated()) {
            if (storeService.exist() && !storeService.get('expired')) {
                authService.logout();
                storeService.set('expired', 1);
                $mdDialog.show(confirmService.expired('ev')).then(function () {}, function () {});
            }
        }
    }

    return authService;
};
