angular
    .module('app')
    .service('cuentaProveedorDataService', cuentaProveedorDataService);

function cuentaProveedorDataService(dataService, localStorageService) {
    return {
        all: function () {
            return dataService.httpGet('cuentas/proveedores');
        },
        post: function (provider) {
            return dataService.httpPost('cuentas/proveedores', provider);
        },
        delete: function (provider) {
            return dataService.httpPost('cuentas/proveedores/' + provider.id);
        },
    };
}