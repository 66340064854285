angular.module("app").controller("reglaController", reglaController);

function reglaController(
  $state,
  $stateParams,
  $rootScope,
  reglaDataService,
  comunidadDataService,
  access,
  loading,
  toastr,
  text,
  status,
  type_categories,
  rule_types
) {
  var vm = this;
  vm.rule_types = rule_types;
  vm.type_categories = type_categories;
  vm.permission = access;
  vm.total = 0;
  vm.regla = {
    community_id: $stateParams.community_id,
    division_id: $stateParams.division_id,
    account_id: $stateParams.account_id
  };
  // ---
  vm.edit = edit;
  vm.create = create;
  vm.selectCommunity = selectCommunity;
  vm.sum = sum;
  vm.addRow = addRow;
  vm.deleteRow = deleteRow;
  vm.divisiones = []
  init();

  // iniciar
  function init() {
    if (vm.regla.community_id && vm.regla.account_id && vm.regla.division_id) {
      reglaDataService
        .get(vm.regla)
        .then(function(response) {
          vm.divisiones = response.data;
          vm.is_update = true;
          sum();
          title();
          comunidades();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
      vm.is_update = false;
    }
  }

  // editar
  function edit() {
    loading.init();
    reglaDataService
      .put({
        data: vm.regla,
        values: vm.divisiones
      })
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          $state.go("app.reglas");
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // crear
  function create() {
    loading.init();
    reglaDataService
      .post({
        data: vm.regla,
        values: vm.divisiones
      })
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          $state.go("app.reglas");
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // select comunidad
  function selectCommunity() {
    vm.divisiones = [];
    angular.forEach(vm.comunidades, function(item) {
      if (item.id == vm.regla.community_id) {
        var division_selected = vm.divisions.filter(
          div => div.id == vm.regla.division_id
        )[0];
        cuentas();
        // angular.forEach(item.divisions, function(value) {
        //   angular.forEach(rule_types, function(element) {
        //     if (
        //       element == rule_types.COMUN ||
        //       (element == rule_types.PRIVADO && isCuenta())
        //     ) {
        //       vm.divisiones.push({
        //         division_id: value.id,
        //         division: value,
        //         type: element,
        //         amount: 0,
        //         description: null,
        //         is_parent: 1,
        //         is_active: 1
        //       });
        //     }
        //   });
        // });
        angular.forEach(rule_types, function(element) {
          if (
            element == rule_types.COMUN || (element == rule_types.PRIVADO && isCuenta())
          ) {
            vm.divisiones.push({
              division_id: vm.regla.division_id,
              division: division_selected,
              type: element,
              amount: 0,
              description: null,
              is_parent: 1,
              is_active: 1
            });
          }
        });
      }
    });
  }

  // add row particulare type
  function addRow(value) {
    vm.divisiones.push({
      division_id: value.division_id || value.division.id,
      division: value.division,
      type: rule_types.PRIVADO,
      amount: 0,
      description: null,
      is_parent: 0,
      is_active: 1
    });
  }

  // reomve row particulare type
  function deleteRow(index) {
    vm.divisiones.splice(index, 1);
  }

  function isCuenta() {
    var index = false;
    angular.forEach(vm.cuentas, function(item) {
      // if (item.id == vm.regla.account_id && item.is_private) {
      if (item.id == vm.regla.account_id) {
        index = true;
      }
    });
    return index;
  }

  // sumar y validar cantidades
  function sum() {
    vm.total = 0;
    angular.forEach(vm.divisiones, function(item) {
      if (item.is_active) {
        vm.total += item.amount ? parseFloat(item.amount) : 0;
      }
    });
    vm.total = (Math.round(vm.total * 10) / 10);
  }

  // load cuentas
  function cuentas() {
    angular.forEach(vm.comunidades, function(item) {
      if (item.id == vm.regla.community_id) {
        vm.cuentas = item.accounts;
      }
    });
  }

  // load comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        vm.divisions = vm.comunidades[0].divisions;
        var index = comunidad(response.data);
        // --
        if (vm.regla.community_id && vm.regla.account_id) {
          cuentas();
        } else {
          vm.regla.community_id =
            response.data.length > 0 ? response.data[index].id : null;
          cuentas();
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title sub-toolbar
  function title() {
    vm.type = vm.regla.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "app.reglas",
      newUrl: "",
      label: "Regla",
      name: vm.regla.id ? vm.regla.name.toUpperCase() : ""
    };
  }
}
