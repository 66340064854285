angular
    .module('app')
    .controller('gastoDetailRWDController', gastoDetailRWDController);

function gastoDetailRWDController($scope, $state, $stateParams, gastoComunDataService, storeService,
    access, toastr, text) {
    var vm = this;
    var date = new Date();

    vm.total = 0;

    // --
    vm.show = show;
    vm.sum = sum;

    init();

    // inicio
    function init() {
        title();
        vm.comunidad = storeService.get('community');
        vm.propiedad = storeService.get('property');
        // -- state cost
        vm.permission = access;
        vm.date = date;
        loadDetalles();
    }

    function show(item) {
        $state.go('dev.' + item);
    }

    function loadDetalles() {
        gastoComunDataService.get_resume_details(vm.comunidad, vm.propiedad, ($stateParams.month) ? $stateParams.month : (date.getMonth() + 1), ($stateParams.year) ? $stateParams.year : date.getFullYear())
            .then(function (response) {
                vm.detalles = response.data.details;
                vm.resumen = response.data.amount;
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function sum(item) {
        vm.total += item;
    }

    // load title sub-toolbar
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.gasto_rwd',
            title: 'Detalle',
        };
    }
}