angular.module("app").controller("fondoController", fondoController);

function fondoController(
    $state,
    $stateParams,
    reserve_fund_type,
    fondoDataService,
    comunidadDataService,
    loading,
    access,
    toastr,
    text,
    status,
    tooltips
) {
    var vm = this;
    vm.tooltips = tooltips;
    vm.permission = access;
    vm.types = Object.keys(reserve_fund_type).map(k => {
        return {
            value: k,
            text: reserve_fund_type[k],
        }
    });
    vm.tooltips = tooltips;

    vm.fondo = {
        id: $stateParams.id,
        type: (vm.types && vm.types.length > 0 ? vm.types[0].value : null),
    };

    vm.loadDivisions = loadDivisions;

    // ---
    vm.edit = edit;
    vm.create = create;

    init();

    // iniciar
    async function init() {
        if (vm.fondo.id) {
            try {
                const response = await fondoDataService.get(vm.fondo.id);
                vm.fondo = response.data;
                loadCommunities();
                title();
            } catch (error) {
                toastr.error(text.API_ERROR);
            }
        } else {
            title();
            loadCommunities();
        }
    }

    // editar
    async function edit() {
        try {
            loading.init();
            const response = await fondoDataService.put(vm.fondo);
            $state.go("app.fondo", {
                id: response.data.id
            });
            toastr.success(text.API_EXITO);
        } catch (error) {
            toastr.error(text.API_ERROR);
        } finally {
            loading.end();
        }
    }

    // crear
    async function create(option) {
        try {
            loading.init();
            const response = await fondoDataService.post(vm.fondo);
            if (option == 1) {
                $state.go(
                    "app.fondo",
                    {},
                    {
                        reload: true
                    }
                );
            } else {
                $state.go("app.fondo", {
                    id: response.data.id
                });
            }
            toastr.success(text.API_EXITO);
        } catch (error) {
            toastr.error(text.API_ERROR);
        } finally {
            loading.end();
        }
    }

    async function loadCommunities() {
        if (vm.comunidades) {
            return vm.comunidades;
        }
        const response = await comunidadDataService.all();
        vm.comunidades = response.data;
        loadDivisions();
    }

    async function loadDivisions() {
        const response = await comunidadDataService.get(vm.comunidades[0]);
        vm.comunidad = response.data;
        vm.divisiones = response.data.divisions;
        console.log("divisiones", vm.divisiones);
        if (vm.divisiones.length == 1){
            vm.fondo.division_id = vm.divisiones[0].id;
        }
    }

    // load title
    function title() {
        vm.type = vm.fondo.id ? "Editar" : "Nuevo";
        vm.item = {
            backUrl: "app.fondos",
            newUrl: "",
            label: vm.type + " Fondo",
            name: vm.fondo.id ? vm.fondo.name.toUpperCase() : ""
        };
    }
}
