angular
    .module('app')
    .controller('servicioRWDController', servicioRWDController);

function servicioRWDController($scope, $state, access) {
    var vm = this;
    // --
    vm.show = show;

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
    }

    // show
    function show(item) {
        $state.go(item);
    }

    // load title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.home_rwd',
            title: 'Servicios',
        };
    }
}