angular
    .module('app')
    .service('estacionamientoReservaDataService', estacionamientoReservaDataService);

function estacionamientoReservaDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('estacionamientos/reservas', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (reserva) {
            return dataService.httpGet('estacionamientos/reservas/' + reserva.id);
        },
        post: function (reserva) {
            return dataService.httpPost('estacionamientos/reservas', reserva);
        },
        put: function (reserva) {
            return dataService.httpPut('estacionamientos/reservas/' + reserva.id, reserva);
        },
        delete: function (reserva) {
            return dataService.httpDelete('estacionamientos/reservas/' + reserva.id);
        },
        // --
        get_division: function (division) {
            return dataService.httpGet('estacionamientos/division/' + division.id);
        },

    };
}