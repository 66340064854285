angular
    .module('app')
    .service('logDataService', logDataService);

function logDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('logs');
        },
        get: function (log) {
            return dataService.httpGet('logs/' + log.id);
        },
        post: function (log) {
            return dataService.httpPost('logs', log);
        },
        put: function (log) {
            return dataService.httpPut('logs/' + log.id, log);
        },
        delete: function (log) {
            return dataService.httpDelete('logs/' + log.id);
        }
    };
}