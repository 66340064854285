angular.module("app").service("propiedadDataService", propiedadDataService);

function propiedadDataService(dataService, $rootScope) {
  return {
    all: function(query) {
      return dataService.httpGet(
        `propiedades?page=${query.page}&limit=${query.limit}&order=${
          query.order
        }&search=${query.search}`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          }
        }
      );
    },
    all_parents: function(query) {
      return dataService.httpGet(
        `propiedades_cobranza?page=${query.page}&limit=${query.limit}&order=${
          query.order
        }&search=${query.search}`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          }
        }
      );
    },
    get_collect_history: function(propiedad){
      return dataService.httpGet("historial_cobranza/" + propiedad.id);
    },
    send_collect_emails: function(listado) {
      return dataService.httpPost("envio_cobranza", listado);
    },
    get: function(propiedad) {
      return dataService.httpGet("propiedades/" + propiedad.id);
    },
    post: function(propiedad) {
      return dataService.httpPost("propiedades", propiedad);
    },
    put: function(propiedad) {
      return dataService.httpPut("propiedades/" + propiedad.id, propiedad);
    },
    delete: function(propiedad) {
      return dataService.httpDelete("propiedades/" + propiedad.id);
    },
    // --
    // --
    get_community_person: function(persona) {
      return dataService.httpGet(
        "propiedades/" + $rootScope.currentCommunity.id + "/" + persona.id
      );
    },
    get_division: function(division) {
      return dataService.httpGet("propiedades/division/" + division.id);
    },
    post_user: function(usuario) {
      return dataService.httpPost("propiedades/usuarios", usuario);
    },

    put_properties: function(id, property) {
      return dataService.httpPut("propiedades/propiedad/" + id, property);
    },
    delete_properties: function(property) {
      return dataService.httpDelete("propiedades/propiedad/" + property.id);
    },
    get_cost: function(comunidad, division) {
      return dataService.httpGet("propiedades/costofijo/" + comunidad.id);
    },
    get_morosidad: function(comunidad, division, month, year) {
      return dataService.httpGet(
        "propiedades/morosidad/" +
          comunidad.id +
          "/" +
          division.id +
          "/" +
          month +
          "/" +
          year
      );
    },
    put_morosidad: function(morosidad) {
      return dataService.httpPut("propiedades/morosidad", morosidad);
    },
    // --categorias propiedades
    post_categorias: function(data) {
      return dataService.httpPost("propiedades/categorias", data);
    },
    put_categorias: function(id, data) {
      return dataService.httpPut("propiedades/categorias/" + id, data);
    },
    // pagar morosidad
    post_pago_manual: function(morosidad) {
      return dataService.httpPost("propiedades/morosidad/pago", morosidad);
    },
    put_pago_manual: function(morosidad) {
      return dataService.httpPut("propiedades/morosidad/pago", morosidad);
    },
    get_payments: function(propertyId) {
      return dataService.httpGet(`propiedades/${propertyId}/pagos`);
    },
    get_has_cost_common_details: function(propertyId) {
      return dataService.httpGet(`propiedades/${propertyId}/has_common_details`);
    }
  };
}
