angular
  .module("app")
  .controller("comunidadesController", comunidadesController);

function comunidadesController(
  $state,
  comunidadDataService,
  conciliacionDataService,
  $mdDialog,
  confirmService,
  access,
  loading,
  toastr,
  text,
  storeService,
  $rootScope,
  authService,
  auth_roles,
  notificationDataService,
  authDataService
) {
  var vm = this;

  vm.comunidades = [];
  vm.selected = [];
  vm.query = {
    order: "name",
    limit: 50,
    page: 1,
    search: ""
  };
  // ---
  vm.edit = edit;
  vm.login = login;
  vm.destroy = destroy;
  
  vm.pullData = pullData;

  init();

  // inicio
  function init() {
    title();
    getData();
    vm.permission = access;
  }

  // edit item
  function edit(item) {
    // var user = storeService.get('user');
    // console.log(user);
    // authService.setUserAuthenticated({user: user, community: item});
    $state.go("app.comunidad", {
      id: item.id
    });
  }

  // edit item
  function login(item) {
    var user = storeService.get('user');
    console.log(user);
    var hash = {user: user, community: item};
    console.log("hash a mandar community_controller", hash);
    const response = authDataService.get_refresh(item);
    console.log("respuesta get_refresh", response);
    authService.setUserAuthenticated(hash);
    notifications();
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        disabled(item);
      },
      function() {}
    );
  }

  // disabled
  function disabled(item) {
    loading.init();
    item.is_active = false;
    comunidadDataService
      .put(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  function getData() {
    comunidadDataService
      .allFilter(vm.query)
      .then(function(response) {
        vm.data = response.data;
        vm.comunidades = angular.forEach(response.data.data, function(item) {
          item.prorrateo = 0;
          angular.forEach(item.divisions, function(division) {
            angular.forEach(division.properties, function(property) {
              item.prorrateo += parseFloat(property.prorrateo);
              angular.forEach(property.dependencies, function(value) {
                item.prorrateo += parseFloat(value.prorrateo);
              });
            });
          });
        });
      })
      .catch(function(error) {
        vm.error = error;
      });
  }

  // get data
  function pullData(community_id) {
    conciliacionDataService
      .getFintocMovements(community_id)
      .then(function(response) {
        getData();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "person",
      backUrl: "",
      newUrl: "app.comunidades_create",
      backActive: false,
      newActive: true,
      label: "Comunidades"
    };
  }

  // table events
  vm.onReorder = function() {
    getData();
  };

  vm.onPaginate = function() {
    getData();
  };

  vm.onSearch = function() {
    getData();
  };
}
