angular
    .module('app')
    .controller('passwordController', passwordController);

function passwordController($scope, authService, passwordDataService,
    loading, access, toastr, text) {
    var vm = this;

    // inicio
    vm.init = function () {
        // vm.usuario = authService.getUser();
        vm.permission = access;
        title();
    }
    vm.init();

    // actualizar
    vm.save = async function () {
        loading.init();
        try {
            await passwordDataService.put_password(vm.data);
            toastr.success(text.API_EXITO);
            loading.end();   
        } catch (error) {
            console.error(error);
            if (error.data.state == 'invalid_email') {
                toastr.error(text.PASSWORD_EMAIL);
            } else if (error.data.state == 'error') {
                toastr.error(text.PASSWORD_DATA);
            } else {
                toastr.error(text.API_ERROR);
            }
            loading.end();
        }
    }

    // title
    function title() {
        if (access.isCopropietario()) {
            $scope.$parent.vm.item = {
                back: true,
                backUrl: 'app.home_rwd',
                title: 'Contraseña',
            };
        } else {
            vm.item = {
                back: true,
                backUrl: access.isRoot() ? 'app.comunidades' : 'app.dashboard',
                newUrl: '',
                backActive: true,
                newActive: false,
                label: 'Contraseña'
            };
        }
    }

};