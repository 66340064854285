angular.module("app").controller("gastoPrintController", gastoPrintController);

function gastoPrintController(
  $state,
  $stateParams,
  $q,
  $window,
  gastoComunDataService,
  cost_type,
  state_cost,
  months,
  toastr,
  text
) {
  var vm = this;
  vm.estados = state_cost;
  vm.gasto = {
    id: $stateParams.id
  };
  vm.cost_types = cost_type;
  vm.amount_not_private = 0;

  // --
  vm.print = print;
  vm.capitalize = capitalize;
  vm.capitalize_words = capitalize_words;
  vm.close = close;
  vm.amountSum = amountSum;
  vm.withBorders = false;

  vm.previousVersion = false;

  init();

  // inicio
  function init() {
    gastos();
  }

  function capitalize(s){
      return s && s[0].toUpperCase() + s.slice(1);
  }

  function capitalize_words(text){
    const words = text.split(" ");
    for (let i = 0; i < words.length; i++) {
      if(words[i].length > 3){
        words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
      } else{
        switch(words[i]) {
          case "IVA":
          case "iva":
          case "Iva":
            words[i] = "Iva";
            break;
          case "gas":
          case "Gas":
          case "GAS":
            words[i] = "Gas";
            break;
          case "ana":
          case "Ana":
          case "ANA":
            words[i] = "Ana";
            break;
          default:
            words[i] = words[i].toLowerCase();
        }
      }
    }
    var string = words.join(" ");
    // var result_dos = string.replace(/ ana /gi, ' Ana ');
    // var result_tres = result_dos.replace(/ gas /gi, ' Gas ');

    return string;
  }

  // gastos comunes
  async function gastos() {
    vm.suma_gastos_comunes = 0;
    vm.suma_total_gastos_comunes = 0;
    vm.suma_gastos_privados = 0;
    vm.suma_gastos_particular = 0;
    vm.suma_gastos_comunidad = 0;
    vm.suma_penalties = 0;
    vm.sum_credit_available = 0;
    vm.sum_credit_consumed = 0;
    vm.cobros_particulares = [];
    vm.this_cost_common_details = [];
    vm.sum_diff = 0;
    var defered = [
      gastoComunDataService.get_detail(vm.gasto),
      gastoComunDataService.get(vm.gasto),
      gastoComunDataService.get_category(vm.gasto),
      gastoComunDataService.get_debts(vm.gasto)
    ];
    try {
      const response = await $q.all(defered);
      const detailResponse = response[0];
      const detailData = detailResponse.data;
      vm.data = response[1].data;
      vm.cost_common_details = vm.data.cost_common_details;
      vm.gastos = angular.forEach(detailData.details, function (item) {
        vm.suma_gastos_comunes += parseFloat(item.amount);
        vm.suma_total_gastos_comunes += parseFloat(item.amount_bill);
      });

      vm.person = detailData.person;
      vm.gasto_general = detailData.general;
      vm.date_of_payment = new Date(vm.gasto_general.expired_at);
      vm.gastos_privados = angular.forEach(
        detailData.privates,
        function (item) {
          vm.suma_gastos_privados += parseFloat(item.amount);
        }
      );
      vm.gastos_reserva = detailData.reserva;
      vm.penalties = detailData.penalties;
      vm.credits = detailData.credits;
      vm.suma_penalties = vm.penalties.map(p => parseFloat(p.amount)).reduce((prev, current) => prev + current, 0);
      vm.sum_credit_available = vm.credits['avaliable'].map(p => parseFloat(p.amount)).reduce((prev, current) => prev + current, 0);
      vm.sum_credit_consumed = vm.credits['pays'].map(p => parseFloat(p.amount)).reduce((prev, current) => prev + current, 0);
      vm.balance = detailData.balance;
      // --
      vm.gastos_particulares = detailData.particulares;
      vm.gasto_account_sueldo = detailData.account_sueldo;
      // --
      vm.unidades_incluidas = angular.forEach(vm.data.property.properties, function (item) {
        item.type = capitalize(item.type);
      });
      vm.bank = response[1].data.community.banks
        ? response[1].data.community.banks[0]
        : null;
      vm.circular = response[2].data;
      vm.circular_mixed_use = response[2].data;
      vm.debts = response[3].data;
    } catch (e) {
      toastr.error(text.API_ERROR);
      console.error(e);
    }
  }

  function amountSum(value) {
    if (value != undefined) {
      vm.amount_not_private += parseInt(value);
    }
  }

  // open dialog print
  function print() {
    $window.print();
  }

  // close window
  function close() {
    // $window.close();
    $window.history.back();
  }

}
