angular
    .module('app')
    .controller('meditionModalController', meditionModalController);

function meditionModalController($scope, $rootScope, $mdDialog, medicionDataService, movimiento_types, data, month, year, community, account, division, rule, loading, text, toastr, storeService, months, url) {
    $scope.types = movimiento_types;
    // --
    $scope.create = create;
    // $scope.create_desc = create_desc;
    $scope.cancel = cancel;

    $scope.load = load;
    $scope.url = url;
    $scope.uploadMedicion = uploadMedicion;
    $scope.lectura = null;

    $scope.month_str = null;
    var original_data = null;

    init();

    // inicio
    function init() {
        //
        //console.log(property);
        $scope.data = data;
        original_data = data;
        $scope.property_name = data.type +" "+data.number;
        $scope.community = community;
        //$scope.bill = bill;
        $scope.account = account;
        $scope.division = division;
        $scope.rule = rule;
        $scope.month = storeService.get("month");
        $scope.year = storeService.get("year");
        $scope.month_str = month_str(storeService.get("month"));
    }

    // crear gastos
    function create() {
        // $mdDialog.hide({
        //     data: {
        //         property_id: data.property_id,
        //         // amount: $scope.cost.amount,
        //         amount: ($scope.cost.type == movimiento_types.DESCUENTO) ? (-1 * $scope.cost.amount) : $scope.cost.amount,
        //         description: $scope.cost.description,
        //         current: 0,
        //         diff: 0,
        //     },
        // })
    }

    // upload file
    function load(item) {
        $scope.files = item;
        $scope.hasFile = $scope.files && $scope.files.length === 1;
        $rootScope.$apply();
    }

    // subir archivo
    function uploadMedicion() {
      //console.log($scope);
      if (($scope.files && $scope.files.length > 0) || true) {
        loading.init();
        var item = null;
        if ($scope.files){
          item = $scope.files[0];
          const fsize = item.size;
          const fileSize = Math.round(fsize / 1024);
          if (fileSize > 20480) {
            toastr.error("El archivo es muy grande. Intenta con un menor a 20MB");
            loading.end();
            return;
          }
        }
        var form = new FormData();
        if (item){
          form.append("file", item);
          form.append("month", $scope.month);
          form.append("year", $scope.year);
          form.append("medicion", $scope.data.size);
          form.append("property_id", $scope.data.property_id);
          form.append("community_id", $scope.community.id);
          // if($scope.bill){
          //   form.append("bill_id", $scope.bill.id);
          // }
          form.append("account_id", $scope.account.id);
          form.append("division_id", $scope.division.id);
          form.append("rule_id", $scope.rule.id);
          medicionDataService.upload(form).then(function (respuesta) {
            if (respuesta.status == 200) {
              toastr.success(text.API_EXITO);
              $mdDialog.hide({
                data: respuesta.data,
              });
            } else {
              data = original_data;
              toastr.error(text.API_ERROR);
            }
            loading.end();
          }).catch(function (response) {
            data = original_data;
            toastr.error(text.API_ERROR);
            loading.end();
          });
        } else{
          toastr.error("No hay archivo ingresado o no es compatible");
          loading.end();
        }
      }
    }

    function cancel() {
        $mdDialog.hide();
    }

    function month_str(data) {
    //var data = $scope.month;
      if (data) {
        if (data == 1) return months.ENERO;
        else if (data == 2) return months.FEBRERO;
        else if (data == 3) return months.MARZO;
        else if (data == 4) return months.ABRIL;
        else if (data == 5) return months.MAYO;
        else if (data == 6) return months.JUNIO;
        else if (data == 7) return months.JULIO;
        else if (data == 8) return months.AGOSTO;
        else if (data == 9) return months.SEPTIEMBRE;
        else if (data == 10) return months.OCTUBRE;
        else if (data == 11) return months.NOVIEMBRE;
        else if (data == 12) return months.DICIEMBRE;
      }
    }
}
