angular
    .module('app')
    .controller('fondosReservaController', fondosReservaController);

function fondosReservaController($state, $rootScope, $q, $mdDialog,
    comunidadDataService, fondoReservaDataService, access, toastr, text) {
    var vm = this;
    vm.permission = access;

    vm.query = {
        order: ['year', 'month'],
        limit: 10,
        page: 1
    };

    // ---
    vm.filter = filter;
    vm.viewPage = viewPage;
    vm.add = add;

    init();

    // inicio
    function init() {
        comunidades();
        title();
    }

    // filtrar 
    function filter() {
        var promises = [
            fondoReservaDataService.get_fondos(vm.comunidad),
            fondoReservaDataService.get_indicadores(vm.comunidad)
        ];
        $q.all(promises).then(function (response) {
                vm.fondos = response[0].data;
                vm.indicadores = response[1].data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }


    // agregar nuevo ingreso
    function add() {
        $mdDialog.show({
                controller: fondoModalController,
                templateUrl: 'views/admin/fondo_reserva/modal/fondo_modal.html',
                parent: angular.element(document.body),
                targetEvent: 'ev',
                clickOutsideToClose: false,
                fullscreen: false,
                locals: {
                    data: {
                        community_id: vm.comunidad.id,
                    },
                }
            })
            .then(function (response) {
                if (response) {
                    filter();
                }
            });
    }

    // view page
    function viewPage(item) {
        $state.go('app.fondo', {
            community: item.community_id,
            month: item.month,
            year: item.year,
        });
    }


    // comunidades
    function comunidades() {
        comunidadDataService.all()
            .then(function (response) {
                vm.comunidades = response.data;
                // vm.comunidad = response.data[0];
                // vm.division = response.data[0].divisions[0];
                // --
                var index = comunidad(response.data);
                vm.comunidad = response.data[index];
                filter(response.data[index]);
                // vm.division = (response.data.length > 0) ? response.data[index].divisions[0] : null;
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // get comunidad rootScope
    function comunidad(items) {
        var index = 0;
        if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
            angular.forEach(items, function (item, key) {
                if (item.id == $rootScope.currentCommunity.id) {
                    index = key;
                    vm.is_current = true;
                }
            });
        } else {
            vm.is_current = false;
        }
        return index;
    }

    // load title sub-toolbar
    function title() {
        vm.item = {
            icon: '',
            backUrl: '',
            newUrl: '',
            backActive: false,
            newActive: false,
            label: 'Fondo Reserva',
        };
    }
}