angular.module("app").controller("empleadoController", empleadoController);

function empleadoController(
  $state,
  $stateParams,
  $rootScope,
  $mdDialog,
  empleadoDataService,
  liquidacionDataService,
  comunidadDataService,
  confirmService,
  status,
  toastr,
  access,
  loading,
  text
) {
  // --
  var vm = this;
  var date = new Date();
  vm.permission = access;
  vm.status = status;
  vm.empleado = {
    id: $stateParams.id ? $stateParams.id : null
  };
  vm.query = {
    order: ["community.name", "name"],
    limit: 10,
    page: 1
  };

  // ---
  vm.edit = edit;
  vm.create = create;
  vm.addItem = addItem;
  vm.destroy = destroy;

  init();

  // iniciar
  function init() {
    if (vm.empleado.id) {
      empleadoDataService
        .get(vm.empleado)
        .then(function(response) {
          vm.empleado = Object.assign({}, response.data, {
            community_id: $rootScope.currentCommunity.id
          });
          comunidades();
          title();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // editar
  function edit() {
    loading.init();
    empleadoDataService
      .put(vm.empleado)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error
          toastr.error(response.data.msg[0]);
        } else {
          // success
          $state.go("app.empleado", {
            id: response.data.id
          });
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // crear
  function create(option) {
    loading.init();
    empleadoDataService
      .post(vm.empleado)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error
          toastr.error(response.data.msg[0]);
        } else {
          // success
          if (option == 1) {
            $state.go(
              "app.empleado",
              {},
              {
                reload: true
              }
            );
          } else {
            $state.go("app.empleado", {
              id: response.data.id
            });
          }
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // add item
  function addItem(item) {
    $mdDialog
      .show({
        controller: liquidacionModalController,
        templateUrl: "views/admin/remuneracion/modal/detail_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {
          employees: [vm.empleado],
          data: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // confirmar para eliminar item
  function destroy(item) {
    $mdDialog.show(confirmService.delete("ev")).then(
      function() {
        disabled(item);
      },
      function() {}
    );
  }

  // disabled empleado
  function disabled(item) {
    loading.init();
    item.is_active = false;
    liquidacionDataService
      .put(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // load comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        var index = comunidad(response.data);
        // --
        if (!vm.empleado.id) {
          vm.empleado.community_id =
            response.data.length > 0 ? response.data[index].id : null;
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title
  function title() {
    vm.type = vm.empleado.id ? "Editar" : "Nuevo";
    vm.item = {
      backUrl: "app.remuneraciones",
      newUrl: "",
      label: vm.type + " Empleado",
      name: vm.empleado.id ? vm.empleado.name.toUpperCase() : "",
      id: vm.empleado.id ? vm.empleado.id : null
    };
  }
}
