angular
    .module('app')
    .controller('divisionController', divisionController);

function divisionController($state, $stateParams, $rootScope, divisionDataService,
    comunidadDataService, access, community_types, loading, toastr, status, text) {
    var vm = this;
    vm.permission = access;
    vm.tipos_comunidad = community_types;
    vm.division = {
        id: ($stateParams.id) ? $stateParams.id : null
    };
    // -- config tablas
    vm.query = {
        order: ['name'],
        limit: 10,
        page: 1
    };

    // ---
    vm.edit = edit;
    vm.create = create;
    vm.active_communities = [];
    init();

    // iniciar
    function init() {
        if (vm.division.id) {
            divisionDataService.get(vm.division)
                .then(function (response) {
                    vm.division = response.data;
                    vm.properties = angular.forEach(response.data.properties, function (item) {
                        item.prorrateo = parseFloat(item.prorrateo);
                        angular.forEach(item.dependencies, function (value) {
                            item.prorrateo += parseFloat(value.prorrateo);
                        });
                    });
                    comunidades();
                    title();
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                });
            comunidadDataService.getActiveCommunities().then(function (response) {
                console.log(response.data.communities);
                vm.active_communities = response.data.communities
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
        } else {
            title();
            comunidades();
        }
    }

    // editar
    function edit() {
        loading.init();
        console.log(vm.division);
        divisionDataService.put(vm.division)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.division', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // crear
    function create() {
        loading.init();
        divisionDataService.post(vm.division)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.division', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load comunidades
    function comunidades() {
        comunidadDataService.all()
            .then(function (response) {
                vm.comunidades = response.data;
                // --
                if (vm.division.id == null) {
                    vm.division.community_id = response.data[comunidad(response.data)].id;
                }
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // get comunidad rootScope
    function comunidad(items) {
        var index = 0;
        if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
            angular.forEach(items, function (item, key) {
                if (item.id == $rootScope.currentCommunity.id) {
                    index = key;
                    vm.is_current = true;
                }
            });
        } else {
            vm.is_current = false;
        }
        return index;
    }

    // load title
    function title() {
        vm.type = (vm.division.id) ? 'Editar' : 'Nueva';
        vm.item = {
            backUrl: 'app.divisiones',
            newUrl: '',
            label: vm.type + ' División',
            name: (vm.division.id) ? vm.division.name.toUpperCase() : '',
            id: (vm.division.id) ? vm.division.id : null,
            service: divisionDataService
        };
    }

}