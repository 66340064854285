angular
    .module('app')
    .service('passwordDataService', passwordDataService);

function passwordDataService(dataService) {
    return {
        forgot: function (item) {
            return dataService.httpPost('password/rut', item);
        },
        reset: function (item) {
            return dataService.httpPost('password/reset', item);
        },
        put_password: function (item) {
            return dataService.httpPut('auth/password', item);
        },
    };
}