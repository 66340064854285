angular.module("app").service("facturaDataService", facturaDataService);

function facturaDataService(dataService, $rootScope) {
  return {
    all: function(query) {
      return dataService.httpGet(
        `facturas?page=${query.page}&limit=${query.limit}&order=${
          query.order
        }&search=${query.search}`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          }
        }
      );
    },
    get: function(factura) {
      return dataService.httpGet("facturas/" + factura.id);
    },
    post: function(factura) {
      return dataService.httpPost("facturas", factura);
    },
    put: function(factura) {
      return dataService.httpPut("facturas/" + factura.id, factura);
    },
    delete: function(factura) {
      return dataService.httpDelete("facturas/" + factura.id);
    },
    // --
    // --
    get_filter: function(filter, query) {
      return dataService.httpGet(
        "facturas/" +
          ($rootScope.currentCommunity.id == ""
            ? "-1"
            : $rootScope.currentCommunity.id) +
          "/" +
          (filter.estado_a == "" ? "-1" : filter.estado_a) +
          "/" +
          (filter.type == "" ? "-1" : filter.type) +
          "/" +
          filter.month +
          "/" +
          filter.year +
          `?page=${query.page}&limit=${query.limit}&order=${query.order}&search=${query.search}`
      );
    },
    // post_rules: function (factura) {
    //     return dataService.httpPost('facturas/reglas', factura);
    // },
    get_categories: function(filter) {
      return dataService.httpGet(
        "facturas/categorias/" +
          ($rootScope.currentCommunity.id == ""
            ? "-1"
            : $rootScope.currentCommunity.id) +
          "/" +
          (filter.estado == "" ? "-1" : filter.estado) +
          "/" +
          filter.from +
          "/" +
          filter.to
      );
    },

    post_shares: function(factura) {
      return dataService.httpPost("facturas/cuotas", factura);
    },

    egresos_import: function (file, community, year, month) {
      return dataService.httpPost('egresos/import/'+community.id+"/"+year+"/"+month, file, {
        withCredentials: false,
        headers: {
            'Content-Type': undefined
        },
        transformRequest: angular.identity
      });
    },

    approve: function(factura) {
      return dataService.httpPost("facturas/"+factura.id+"/aprobar");
    },
    
    approve_all: function(community_id, month, year) {
      return dataService.httpPost("facturas/"+community_id+"/"+month+"/"+year+"/aprobar");
    },
  };
}
