angular
    .module('app')
    .controller('empleadosModalController', empleadosModalController);

function empleadosModalController($scope, $mdDialog, $rootScope,
    empleadoDataService, toastr, text, community, data) {
    // --
    var date = new Date();

    $scope.comunidad = community;
    $scope.datos = data;
    // --
    $scope.cancel = cancel;
    $scope.process = process;

    init();

    // carga inicial
    function init() {
        // --
    }

    // close modal
    function cancel() {
        $mdDialog.hide();
    }

    // crear item
    function process() {
        empleadoDataService.post_employees({
                data: data,
                community: community,
            })
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data
                });
                toastr.success(text.API_EXITO);
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }
}