angular
    .module('app')
    .controller('fondoModalController', fondoModalController);

function fondoModalController($scope, $mdDialog, facturaDataService, type_expense,
    months, toastr, text, data,years ) {
    $scope.type_expense = type_expense;
    var date = new Date();

    // --
    $scope.create = create;
    $scope.cancel = cancel;


    init();

    // inicio
    function init() {
        $scope.data = {
            type: type_expense.INGRESO,
            community_id: data ? data.community_id : null,
            account_id: null,
            number: 0,
            id: null,
        }
        $scope.months = months;
        $scope.years = years(date);
    }

    // crear gastos
    function create() {
        $scope.data.number = date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes();
        facturaDataService.post($scope.data)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            })
    }

    function cancel() {
        $mdDialog.hide()
    }
}
