angular
    .module('app')
    .service('horarioDataService', horarioDataService);

function horarioDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('horarios');
        },
        get: function (horario) {
            return dataService.httpGet('horarios/' + horario.id);
        },
        post: function (horario) {
            return dataService.httpPost('horarios', horario);
        },
        put: function (horario) {
            return dataService.httpPut('horarios/' + horario.id, horario);
        },
        delete: function (horario) {
            return dataService.httpDelete('horarios/' + horario.id);
        },
        // --
        get_filter: function (comunidad, division) {
            return dataService.httpGet('horarios/' + comunidad.id + '/' + division.id);
        },
        get_people: function (comunidad, division) {
            return dataService.httpGet('horarios/personas/' + comunidad.id + '/' + division.id);
        },

    };
}