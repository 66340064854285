angular.module("app").directive("dpzTable", dpzTable);

function dpzTable($rootScope, $state, $mdSidenav, authService) {
  return {
    restrict: "AE",
    templateUrl: "views/layout/table_view.html",
    scope: {
      data: "=tableData"
    },
    link: function(scope, element, attrs) {
      scope.prev = function(item) {};
      scope.next = function(item) {};
      scope.sort = function(item) {};
      scope.itemDetail = function(item) {};
      scope.itemDelete = function(item) {};
    }
  };
}
