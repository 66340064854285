angular.module("app").controller("cartolaController", cartolaController);

function cartolaController(
  $state,
  comunidadDataService,
  cartolaDataService,
  divisionDataService,
  env,
  months,
  toastr,
  loading,
  text,
  years
) {
  var vm = this;
  const date = new Date();
  vm.years = years(date);
  vm.months = months;
  vm.excelCartola = excelCartola;
  vm.CARTOLA_FILE_URL = env.DOMAIN + env.API_PATH + 'files/export/cartola';

  init();

  // inicio
  function init() {
    title();
    vm.to_month = date.getMonth() + 1;
    // vm.from_month = date.getMonth() - 5;
    vm.from_year = (vm.to_month < 6 ? date.getFullYear() - 1 : date.getFullYear());
    vm.to_year = date.getFullYear();
    getCommunity();
  }

  vm.onDivisionChange = async function() {
    const {division_id} = vm;
    const response = await divisionDataService.get({id: division_id});
    vm.properties = response.data.properties
      .filter((p) => !p.property_id)
      .sort((a, b) => {
        if (a.number < b.number) {
          return -1;
        } else {
          return 1;
        }
      });
  }

  vm.refresh = async function() {
    vm.cartola = null;

    const { property_id, from_month, from_year, to_month, to_year } = vm;
    if (property_id && from_month && from_year && to_month && to_year) {
      loading.init();
      try {
        const response = await cartolaDataService.find(
          property_id,
          { from_month, from_year, to_month, to_year }
        );
        vm.cartola = response.data;
        loading.end();
      } catch (error) {
        vm.cartola = null;
        toastr.error(text.API_ERROR);
        loading.end();
      }
    } else {
      vm.cartola = null;
      // toastr.error(text.API_ERROR);
      // loading.end();
    }
  }

  async function getCommunity() {
    const communityResponse = await comunidadDataService.getCurrent();
    vm.community = communityResponse.data;
    const {divisions} = vm.community;
    if (divisions.length > 0) {
      const division = divisions[0];
      vm.division_id = division.id;
      vm.onDivisionChange();
    }
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "event",
      backUrl: "",
      backActive: false,
      label: "Cartola"
    };
  }
  // excel con cartola
  function excelCartola() {
    document.location =
      env.DOMAIN + env.API_PATH + "files/export/cartola/" + vm.property_id+"/"+vm.from_month+"/"+vm.to_month+"/"+vm.from_year+"/"+vm.to_year;
  }
}
