angular.module("app").controller("visitaController", visitaController);

function visitaController(
  $mdDialog,
  $rootScope,
  visitaDataService,
  comunidadDataService,
  confirmService,
  access,
  loading,
  toastr,
  text,
  getYearMonthDay
) {
  var vm = this;
  vm.query = {
    order: ["community.name", "division.name", "created_at"],
    limit: 10,
    page: 1
  };

  // --
  vm.add = add;
  vm.destroy = destroy;
  vm.filter = filter;
  vm.date = new Date();
  vm.maxDate = vm.date;

  init();

  // inicio
  function init() {
    vm.permission = access;
    title();
    comunidades();
  }

  // add item
  function add(item) {
    $mdDialog
      .show({
        controller: visitaModalController,
        templateUrl: "views/admin/visita/modal/detail_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: item ? item : null,
          community: vm.comunidad,
          division: vm.division
        }
      })
      .then(function(response) {
        if (response) {
          filter();
        }
      });
  }

  // filter
  async function filter() {
    if (vm.comunidad && vm.division) {
      try {
        const response = await visitaDataService.get_filter(
          vm.comunidad,
          vm.division,
          vm.date
        );
        vm.visitas = response.data;
      } catch (error) {
        toastr.error(text.API_ERROR);
      }
    }
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        disabled(item);
      },
      function() {}
    );
  }

  // disabled
  function disabled(item) {
    item.is_active = false;
    loading.init();
    visitaDataService
      .put(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        // --
        vm.comunidad = response.data[comunidad(response.data)];
        console.log(vm.comunidad);
        if (
          vm.comunidad &&
          vm.comunidad.divisions &&
          vm.comunidad.divisions.length > 0
        ) {
          vm.division = vm.comunidad.divisions[0];
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // title
  function title() {
    vm.item = {
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: false,
      label: "Acceso Visitas"
    };
  }
}
