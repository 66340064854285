angular.module("app").controller("foroController", foroController);

function foroController(
  $scope,
  $state,
  $rootScope,
  $stateParams,
  comunidadDataService,
  foroDataService,
  storeService,
  loading,
  access,
  toastr,
  text
) {
  var vm = this;
  var notification = storeService.get("notification");
  vm.foro = {
    id: $stateParams.id ? $stateParams.id : null
  };
  vm.user = storeService.get("user");
  // vm.is_processing = false;

  // --
  vm.send = send;
  vm.comment = comment;
  vm.selectCommunity = selectCommunity;

  init();

  // inicio
  function init() {
    vm.permission = access;
    if (vm.foro.id) {
      foroDataService
        .get(vm.foro)
        .then(function(response) {
          vm.foro = response.data.data;
          notification.forums -= response.data.notifications;
          storeService.set("notification", notification);
          $rootScope.$broadcast("update-auth-data");
          title();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // send
  function send() {
    loading.init();
    // vm.is_processing = true;
    foroDataService
      .post(vm.foro)
      .then(function(response) {
        toastr.success(text.API_EXITO);
        $state.go("app.foros");
        // vm.is_processing = false;
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        // vm.is_processing = false;
        loading.end();
      });
  }

  // send comment
  function comment() {
    loading.init();
    // vm.is_processing = true;
    foroDataService
      .post({
        forum_id: vm.foro.id,
        text: vm.foro.comment,
        title: vm.foro.title,
        community_id: vm.foro.community_id,
        division_id: vm.foro.division_id
      })
      .then(function(response) {
        toastr.success(text.API_EXITO);
        $state.go("app.foros");
        // vm.is_processing = false;
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        // vm.is_processing = false;
        loading.end();
      });
  }

  // select community
  function selectCommunity() {
    angular.forEach(vm.comunidades, function(value) {
      if (value.id == vm.foro.community_id) {
        vm.divisiones = value.divisions;
      }
    });
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        var index = comunidad(response.data);
        // --
        if (!vm.foro.id) {
          // var index = comunidad(response.data);
          vm.foro.community_id =
            response.data.length > 0 ? response.data[index].id : null;
          vm.divisiones =
            response.data.length > 0 ? response.data[index].divisions : null;
        } else {
          angular.forEach(response.data, function(item) {
            if (item.id == vm.foro.community_id) {
              vm.divisiones = item.divisions;
            }
          });
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // title
  function title() {
    vm.item = {
      backUrl: "app.foros",
      newUrl: "",
      backActive: true,
      newActive: false,
      label: "Foro",
      name: vm.foro.id ? vm.foro.title : null
    };
  }
}
