angular
    .module('app')
    .controller('reservacionRWDController', reservacionRWDController);

function reservacionRWDController($scope, $state, reservaDataService, reservacionDataService,
    storeService, access, loading, toastr, text) {
    var vm = this;
    vm.reserva = {
        user_id: storeService.get('user').id,
    }

    // --
    vm.create = create;

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        instalaciones();
    }

    // create 
    function create() {
        loading.init();
        reservacionDataService.post(vm.reserva)
            .then(function (response) {
                $state.go('app.reservas_rwd');
                toastr.success(text.API_EXITO);
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load instalacioness
    function instalaciones() {
        reservaDataService.get_division({
                id: storeService.get('property').division_id
            })
            .then(function (response) {
                vm.instalaciones = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // load title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.reservas_rwd',
            title: 'Reservar'
        };
    }
}