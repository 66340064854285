angular
    .module('app')
    .service('fondoReservaDataService', fondoReservaDataService);

function fondoReservaDataService(dataService, $rootScope) {
    return {
        get_fondos: function (community) {
            return dataService.httpGet('fondo/' + community.id);
        },
        get_indicadores: function (community) {
            return dataService.httpGet('fondo/indicadores/' + community.id);
        },
        get_fondos_detail: function (community, month, year) {
            return dataService.httpGet('fondo/detalle/' + community + '/' + month + '/' + year);
        },
        get_indicadores_detail: function (community, month, year) {
            return dataService.httpGet('fondo/detalle/indicadores/' + community + '/' + month + '/' + year);
        },
    };
}