angular
    .module('app')
    .controller('empresasController', empresasController);

function empresasController($state, $mdDialog, empresaDataService, confirmService,
    loading, status, toastr, text) {
    var vm = this;
    vm.empresas = [];
    vm.query = {
        order: ['name'],
        limit: 10,
        page: 1
    };

    // ---
    vm.goEdit = goEdit;
    vm.confirm = confirm;

    init();

    // inicio
    function init() {
        title();
        empresaDataService.all()
            .then(function (response) {
                vm.empresas = response.data;
            });
        // .catch(function () {
        //     vm.error = error;
        // });
    }

    // edit item
    function goEdit(item) {
        $state.go('app.empresa', {
            id: item.id
        });
    }

    // editar
    function edit(item) {
        loading.init();
        item.is_active = !item.is_active;
        empresaDataService.put(item)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // confirmar para eliminar item
    function confirm(ev, item) {
        if (item.is_active) {
            // deshabilitar
            $mdDialog.show(confirmService.disabled(ev)).then(function () {
                edit(item);
            }, function () {});
        } else {
            // habilitar
            $mdDialog.show(confirmService.enabled(ev)).then(function () {
                edit(item);
            }, function () {});
        }

    }

    // load title sub-toolbar
    function title() {
        vm.item = {
            icon: 'person',
            backUrl: '',
            newUrl: 'app.empresa',
            backActive: false,
            newActive: true,
            label: 'Empresas',
        };
    }
}