// angular
//     .module('app')
//     .factory('beforeUnload', beforeUnload);

// function beforeUnload($rootScope, $window) {
//     // Events are broadcast outside the Scope Lifecycle

//     $window.onbeforeunload = function (e) {
//         var confirmation = {};
//         var event = $rootScope.$broadcast('onBeforeUnload', confirmation);
//         if (event.defaultPrevented) {
//             return confirmation.message;
//         }
//     };

//     $window.onunload = function () {
//         $rootScope.$broadcast('onUnload');
//     };
//     return {};
// }