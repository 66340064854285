angular
    .module('app')
    .controller('horarioRWDController', horarioRWDController);

function horarioRWDController($scope, $mdDialog, horarioDataService,
    storeService, access, toastr, text) {
    var vm = this;
    var community = storeService.get('community');
    var property = storeService.get('property');

    // --

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        horario();
    }

    // load informations
    function horario() {
        horarioDataService.get_filter(community, {
                id: property.division_id
            })
            .then(function (response) {
                vm.horarios = angular.forEach(response.data, function (item) {
                    item.day_str = day(item.day);
                });
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function day(item) {
        if (item == 1) {
            return 'Lunes';
        } else if (item == 2) {
            return 'Martes';
        } else if (item == 3) {
            return 'Miercoles';
        } else if (item == 4) {
            return 'Jueves';
        } else if (item == 5) {
            return 'Viernes';
        } else if (item == 6) {
            return 'Sábado';
        } else {
            return 'Domingo';
        }
    }

    // load title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.administracion_rwd',
            title: 'Horario',
        };
    }
}