angular.module("app").controller("pagoController", pagoController);

function pagoController(
  $state,
  $rootScope,
  $mdDialog,
  comunidadDataService,
  propiedadDataService,
  confirmService,
  storeService,
  state_cost,
  access,
  months,
  env,
  loading,
  toastr,
  text,
  payDataService,
  years
) {
  // --
  var vm = this;
  var date = new Date();
  vm.query = {
    order: ["number"]
  };

  vm.query_dos = {
    order: ["created_at"]
  };

  // --
  vm.show = show;
  vm.addPaid = addPaid;
  vm.addPayList = addPayList;
  vm.excel = excel;
  vm.excelIngresos = excelIngresos;
  vm.go_to = go_to;
  vm.showPay = showPay;
  vm.gastos = gastos;
  vm.refresh = refresh;

  init();

  // inicio
  function init() {
    title();
    comunidades();
    vm.months = months;
    vm.years = years(date);
    // -- date
    vm.year = storeService.get("year");
    vm.month = storeService.get("month");
    // -- state cost
    vm.permission = access;
    vm.estados = state_cost;
  }

  // edit item
  function go_to(item) {
    $state.go("app.propiedad", {
      id: item.id
    });
  }

  // refresh ingresos data
  function refresh(item) {
    loading.init();
    console.log("item", item);
    payDataService
      .refresh({property_id: item})
      .then(function(response) {
        console.log(response);
        toastr.success(text.API_EXITO);
        loading.end();
        gastos();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // mostrar modal de agregar pago
  function addPaid(ev, item) {
    if (item.cost_morosidad > 0) {
      $mdDialog
        .show(confirmService.morosidad(ev, item.cost_morosidad))
        .then(function() {}, function() {});
    } else {
      $mdDialog
        .show({
          controller: pagoModalController,
          templateUrl: "views/admin/pago/modal/paid_modal.html",
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: false,
          fullscreen: false,
          locals: {
            data: (item ? item : null)
          }
        })
        .then(function(response) {
          if (response) {
            gastos();
          }
        });
    }
  }

  // mostrar modal de pagos realizados
  function addPayList(property) {
    $mdDialog
      .show({
        preserveScope: true,
        controller: pagosModalController,
        templateUrl: "views/admin/pago/modal/payments_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: {property: property}
        }
      })
      .then(function(response) {
          gastos();
      });
  }

  // gastos comunes
  async function gastos(flag = 0) {
    console.log("cargar ingresos");
    if (vm.comunidad){
      if (vm.comunidad.mixed_use){
          if (flag == 0) loading.init();
          vm.propiedades_ingresos = [];
          vm.ingresos = [];
          try {
            const response = await payDataService.getMorosidadByCommunity(vm.comunidad.id);
            vm.propiedades_ingresos = response.data[0];
            vm.ingresos = response.data[1];
            loading.end();
          } catch (error) {
            toastr.error(text.API_ERROR);
            loading.end();
          } finally {
            loading.end();
          }
          loading.end();
      } else {
        if (vm.comunidad && vm.division) {
          if (flag == 0) loading.init();
          vm.propiedades_ingresos = [];
          vm.ingresos = [];
          try {
            const response = await payDataService.getMorosidadByDivision(vm.division.id);
            vm.propiedades_ingresos = response.data[0];
            vm.ingresos = response.data[1];
            loading.end();
          } catch (error) {
            toastr.error(text.API_ERROR);
            loading.end();
          } finally {
            loading.end();
          }
          loading.end();
        }
      }
    }
    loading.end();
  }

  // ver costos
  function show(item) {
    $state.go("app.gasto_comun", {
      id: item.id
    });
  }

  // excel bills
  function excel() {
    document.location =
      env.DOMAIN + env.API_PATH + "files/export/morosidad/" + vm.comunidad.id;
  }

  // excel bills
  function excelIngresos() {
    document.location =
      env.DOMAIN + env.API_PATH + "files/export/ingresos/" + vm.comunidad.id+"/"+vm.month+"/"+vm.year;
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        // vm.comunidad = response.data[0];
        // vm.division = response.data[0].divisions[0];
        // --
        var index = comunidad(response.data);
        vm.comunidad = response.data[index];
        vm.division = response.data.length > 0 ? response.data[index].divisions[0] : null;
        if (response.data.length > 0) {
          gastos();
        }
      })
      .catch(function() {
        loading.end();
        toastr.error(text.API_ERROR);
      });
  }

  // mostrar modal de pagos realizados

  function showPay(property, payment) {
    $mdDialog
      .show({
        preserveScope: true,
        controller: pagosModalController,
        templateUrl: "views/admin/pago/modal/payments_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: {
            property: property,
            payment: payment
          }
        }
      })
      .then(function(response) {
        getData();
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: true,
      label: "Ingresos"
    };
  }
}
