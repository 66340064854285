angular
    .module('app')
    .service('empresaDataService', empresaDataService);

function empresaDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('empresas');
        },
        get: function (empresa) {
            return dataService.httpGet('empresas/' + empresa.id);
        },
        post: function (empresa) {
            return dataService.httpPost('empresas', empresa);
        },
        put: function (empresa) {
            return dataService.httpPut('empresas/' + empresa.id, empresa);
        },
        delete: function (empresa) {
            return dataService.httpDelete('empresas/' + empresa.id);
        },
    };
}