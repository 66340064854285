angular.module("app").controller("reservaController", reservaController);

function reservaController(
  $state,
  $stateParams,
  $scope,
  $rootScope,
  $mdDialog,
  reservaDataService,
  reservacionDataService,
  reservaFileDataService,
  comunidadDataService,
  reserva_states,
  confirmService,
  access,
  loading,
  toastr,
  text
) {
  var vm = this;
  vm.permission = access;
  vm.reserva = {
    id: $stateParams.id
  };
  vm.states = reserva_states;
  // --
  vm.create = create;
  vm.edit = edit;
  vm.addReserva = addReserva;
  vm.addFile = addFile;
  vm.viewFile = viewFile;
  vm.destroy = destroy;
  vm.selectCommunity = selectCommunity;
  vm.onDateChange = onDateChange;
  vm.date = new Date();
  vm.utcStringToMillis = utcStringToMillis;

  init();

  // iniciar
  function init() {
    if (vm.reserva.id) {
      reservaDataService
        .get(vm.reserva)
        .then(function(response) {
          vm.reserva = response.data;
          title();
          comunidades();
          getReserves();
          getPendingReserves();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // editar
  function edit() {
    loading.init();
    reservaDataService
      .put(vm.reserva)
      .then(function(response) {
        $state.go("app.instalacion", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // create
  function create() {
    loading.init();
    reservaDataService
      .post(vm.reserva)
      .then(function(response) {
        $state.go("app.instalacion", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // create o edit modal archivo
  async function addReserva(ev, item) {
    const date = new Date(vm.date.getTime());
    date.setHours(0, 0, 0, 0);
    let templateUrl = "views/admin/reserva/modal/reserve_modal.html";
    if (item) {
      templateUrl = "views/admin/reserva/modal/reserve_modal_update.html";
    }
    const response = await $mdDialog.show({
      controller: reservacionModalController,
      templateUrl: templateUrl,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: false,
      fullscreen: false,
      locals: {
        data: vm.reserva,
        value: item,
        date: vm.date,
        availableRanges: item
          ? null
          : await reservaDataService.getAvailableHours(
              vm.reserva.id,
              date.getTime() / 1000
            )
      }
    });
    if (response && response.data) {
      init();
    }
  }

  // create o edit modal archivo
  function addFile(ev, item) {
    $mdDialog
      .show({
        controller: reservaFileModalController,
        templateUrl: "views/admin/reserva/modal/file_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: vm.reserva,
          file: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // view file
  function viewFile(ev, item) {
    $mdDialog
      .show({
        controller: detailModalController,
        templateUrl: "views/admin/factura/modal/detail_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          file: item ? item : null
        }
      })
      .then(function(response) {});
  }

  // confirmar para eliminar item
  function destroy(ev, item, option) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        if (option == 0) {
          // eliminar reserva
          disabled(item);
        } else {
          // eliminar file
          trash(item);
        }
      },
      function() {}
    );
  }

  // disabled
  function disabled(item) {
    reservacionDataService
      .delete(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // disabled
  function trash(item) {
    loading.init();
    reservaFileDataService
      .delete(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // select community
  function selectCommunity() {
    angular.forEach(vm.comunidades, function(value) {
      if (value.id == vm.reserva.community_id) {
        vm.divisiones = value.divisions;
      }
    });
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        var index = comunidad(response.data);
        // --
        if (!vm.reserva.id) {
          // var index = comunidad(response.data);
          vm.reserva.community_id =
            response.data.length > 0 ? response.data[index].id : null;
          vm.divisiones =
            response.data.length > 0 ? response.data[index].divisions : null;
        } else {
          angular.forEach(response.data, function(item) {
            if (item.id == vm.reserva.community_id) {
              vm.divisiones = item.divisions;
            }
          });
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // socket
  var listenerCleanFn = $rootScope.$on("emit-socket", function(event, data) {
    init();
    console.log(data.data);
  });

  $scope.$on("$destroy", function() {
    listenerCleanFn();
  });
  // title
  function title() {
    vm.type = vm.reserva.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "app.instalaciones",
      newUrl: "",
      label: vm.type + " Instalación",
      name: vm.reserva.id ? vm.reserva.name.toUpperCase() : ""
    };
  }

  function onDateChange() {
    getReserves();
  }

  async function getReserves() {
    if (vm.reserva.id && vm.date) {
      const date = new Date(vm.date.getTime());
      date.setHours(0, 0, 0, 0);
      const response = await reservaDataService.getReserves(
        vm.reserva.id,
        date.getTime() / 1000
      );
      vm.reserva.reservation_reserves = response.data;
    }
  }

  async function getPendingReserves() {
    if (vm.reserva.id) {
      const response = await reservaDataService.getPendingReserves(
        vm.reserva.id
      );
      vm.reserva.pending_reservation_reserves = response.data;
    }
  }

  function utcStringToMillis(date) {
    return new Date(date + " UTC").getTime();
  }
}
