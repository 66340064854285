angular.module("app").controller("pagoModalController", pagoModalController);

function pagoModalController(
  $scope,
  $mdDialog,
  payDataService,
  gaService,
  method_pay,
  banks,
  bank_types,
  toastr,
  text,
  months,
  storeService,
  data
) {
  $scope.banks = banks;
  $scope.bank_types = bank_types;
  $scope.amount_pay = 0;
  $scope.sum_to_pay = 0;
  $scope.sum_to_be_payed = 0;
  $scope.item_to_pay = null;
  $scope.credit = {
    amount: 0
  };
  // --
  $scope.cancel = cancel;
  var date = new Date();

  $scope.create = create;
  $scope.sumToPaid = sumToPaid;

  init();

  // --
  function init() {
    $scope.pays = method_pay;
    $scope.data = data;
    $scope.data.date = moment();
    getMorosidad();
  }

  function cancel() {
    $mdDialog.hide();
  }


  function create() {
    const items = $scope.to_paid.filter((p) => p.p_amount);
    const credit = $scope.credit;
    const total_amount_payed = (credit.amount+items.map(p => parseInt(p.p_amount)).reduce((prev, current) => prev + current, 0));
    payDataService
      .post({ data: $scope.data, items, credit, total_amount_payed })
      .then(function(response) {
        gaService.send_event('ingreso_carga_manual', 'ingresos');
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function getMorosidad() {
    payDataService
      .getMorosidadByProperty(data.id)
      .then(function(res) {
        $scope.min_date = moment(res.data.min_date, "DD-MM-YYYY").toDate();
        $scope.max_date = moment().toDate();
        $scope.to_paid = angular.forEach(res.data.data, function(item) {
          item.p_amount = item.to_paid;
        });
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function sumToPaid(){
    $scope.sum_to_pay = $scope.to_paid.reduce((prev, cur) => prev + cur.p_amount, 0);
    $scope.sum_to_be_payed = $scope.to_paid.reduce((prev, cur) => prev + cur.to_paid, 0);
  }
}
