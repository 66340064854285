angular.module("app").controller("proveedorController", proveedorController);

function proveedorController(
  $state,
  $stateParams,
  $rootScope,
  proveedorDataService,
  comunidadDataService,
  cuentaDataService,
  access,
  loading,
  toastr,
  status,
  text
) {
  var vm = this;
  vm.permission = access;
  vm.proveedor = {
    id: $stateParams.id ? $stateParams.id : null
  };

  // ---
  vm.edit = edit;
  vm.create = create;

  init();

  // iniciar
  function init() {
    if (vm.proveedor.id) {
      proveedorDataService
        .get(vm.proveedor)
        .then(function(response) {
          vm.proveedor = response.data;
          title();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
    }
  }

  // editar
  function edit() {
    loading.init();
    proveedorDataService
      .put(vm.proveedor)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          $state.go("app.proveedor", {
            id: response.data.id
          });
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // crear
  function create(option) {
    loading.init();
    proveedorDataService
      .post(vm.proveedor)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          if (option == 1) {
            $state.go(
              "app.proveedor",
              {},
              {
                reload: true
              }
            );
          } else {
            $state.go("app.proveedor", {
              id: response.data.id
            });
          }
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // load title
  function title() {
    vm.type = vm.proveedor.id ? "Editar" : "Nuevo";
    vm.item = {
      backUrl: "app.proveedores",
      newUrl: "",
      label: vm.type + " Proveedor",
      name: vm.proveedor.id ? vm.proveedor.name.toUpperCase() : ""
    };
  }
}
