angular.module("app").controller("divisionesController", divisionesController);

function divisionesController(
  $state,
  divisionDataService,
  $mdDialog,
  confirmService,
  access,
  loading,
  toastr,
  text
) {
  var vm = this;
  vm.divisiones = [];
  vm.selected = [];

  vm.query = {
    order: "name",
    limit: 10,
    page: 1,
    search: ""
  };

  // ---
  vm.edit = edit;
  vm.destroy = destroy;

  init();

  // inicio
  function init() {
    title();
    getData();
    vm.permission = access;
  }

  // edit item
  function edit(division) {
    $state.go("app.division", {
      id: division.id
    });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        trash(item);
      },
      function() {}
    );
  }

  // eliminar el item seleccionado
  function trash(item) {
    loading.init();
    divisionDataService
      .delete(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  function getData() {
    divisionDataService
      .all(vm.query)
      .then(function(response) {
        vm.data = response.data;
        vm.divisiones = angular.forEach(response.data.data, function(item) {
          //item.prorrateo = 0;
          angular.forEach(item.properties, function(value) {
            item.prorrateo += parseFloat(value.prorrateo);
            angular.forEach(value.dependencies, function(valor) {
              item.prorrateo += parseFloat(valor.prorrateo);
            });
          });
        });
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: false,
      label: "Divisiones",
      service: divisionDataService
    };
  }

  // table events
  vm.onReorder = function() {
    getData();
  };

  vm.onPaginate = function() {
    getData();
  };

  vm.onSearch = function() {
    getData();
  };
}
