angular
    .module('app')
    .service('visitaDataService', visitaDataService);

function visitaDataService(dataService, $rootScope, getYearMonthDay) {
    return {
        all: function () {
            return dataService.httpGet('visitas', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            });
        },
        get: function (visita) {
            return dataService.httpGet('visitas/' + visita.id);
        },
        post: function (visita) {
            return dataService.httpPost('visitas', visita);
        },
        put: function (visita) {
            return dataService.httpPut('visitas/' + visita.id, visita);
        },
        delete: function (visita) {
            return dataService.httpDelete('visitas/' + visita.id);
        },
        // --
        get_filter: function (comunidad, division, date) {
            console.log(date);
            return dataService.httpGet(
                'visitas/' + comunidad.id + '/' + division.id,
                {params: {date: getYearMonthDay(date)}}
            );
        },
    };
}