angular
    .module('app')
    .controller('gastoComunUFController', gastoComunUFController);

function gastoComunUFController($scope, $mdDialog, gastoComunDataService, toastr, text, data) {

    // --
    $scope.cancel = cancel;
    $scope.accept = accept;

    init();

    // --
    function init() {
        $scope.data = {
            fecha: new Date(data.fecha),
            valor: data.valor,
        };
    }

    function cancel() {
        $mdDialog.hide()
    }

    function accept() {
        $mdDialog.hide({
            data: data.valor,
        });
    }

}