angular
  .module("app")
  .controller("communityModalController", communityModalController);

function communityModalController(
  $scope,
  $mdDialog,
  $rootScope,
  authDataService,
  comunidadDataService,
  storeService,
  months,
  access,
  toastr,
  text,
  data,
  years
) {
  var date = new Date();

  $scope.months = months;
  $scope.month = storeService.get("month");
  $scope.years = years(date);
  $scope.year = storeService.get("year");

  // --
  $scope.hide = hide;
  $scope.cancel = cancel;

  init();

  // inicio
  function init() {
    $scope.permission = access;
  }

  // hide view
  function hide() {
    storeService.set("month", $scope.month);
    storeService.set("year", $scope.year);
    //storeService.set("year", '2019');
    $mdDialog.hide({
      data: $scope.comunidad
    });
    $rootScope.$broadcast("update-auth-data");
  }

  function cancel() {
    $mdDialog.hide();
  }
}
