angular
    .module('app')
    .controller('profileController', profileController);

function profileController($state, $rootScope, storeService, auth_types, authService) {
    var vm = this;
    vm.roles = storeService.get('user') ? storeService.get('user').roles.filter(item => (auth_types.COPROPIETARIO !== item.role.type.name && $rootScope.currentCommunity.id == item.community_id)) : null;
    vm.auth_types = auth_types;

    // --
    vm.select = select;

    init();

    function init() {
        title();
    }

    // select role
    function select(item) {
        storeService.set('role', item.role);
        $rootScope.$broadcast('update-auth-data');
        authService.routes();
    }

    // title
    function title() {
        vm.item = {
            back: false,
            backUrl: '',
            newUrl: '',
            backActive: false,
            newActive: false,
            label: 'Seleccionar Perfil'
        };
    }
}