angular
    .module('app')
    .controller('reemplazoModalController', reemplazoModalController);

function reemplazoModalController($scope, $mdDialog, $rootScope,
    comunidadDataService, reemplazoDataService, storeService, states_a, states_p,
    months, status, toastr, text, employees, data, years) {
    // --
    var date = new Date();

    $scope.empleados = employees;
    // --
    $scope.update = update;
    $scope.create = create;
    $scope.validate = validate;
    $scope.cancel = cancel;
    $scope.selectEmployee = selectEmployee;

    init();

    // carga inicial
    function init() {
        $scope.months = months;
        $scope.years = years(date);
        $scope.states_a = states_a;
        $scope.states_p = states_p;
        if (data) {
            $scope.reemplazo = data;
            $scope.reemplazo.rut = data.provider.rut;
            $scope.reemplazo.name = data.provider.name;
        } else {
            $scope.reemplazo = {
                month: storeService.get('month'),
                year: storeService.get('year'),
            };
        }
        comunidades();
    }

    // close modal
    function cancel() {
        $mdDialog.hide();
    }

    // crear item
    async function create() {
        try {
            const response = await reemplazoDataService.post($scope.reemplazo);
            if (response.data.status == status.CLOSED) {
                // closed
                toastr.warning(response.data.message);
            } else {
                $mdDialog.hide({
                    data: response.data
                });
                toastr.success(text.API_EXITO);
                changeDate();
            }
        } catch (err) {
            toastr.error(text.API_ERROR);
        }
    }

    // update item
    async function update() {
        try {
            const response = await reemplazoDataService.put($scope.reemplazo);
            if (response.data.status == status.CLOSED) {
                toastr.warning(response.data.message);
            } else {
                $mdDialog.hide({
                    data: response.data
                });
                toastr.success(text.API_EXITO);
                changeDate();
            }
        } catch (err) {
            toastr.error(text.API_ERROR);
        }
    }

    async function validate(item) {
        $scope.reemplazo.state_a = $scope.states_a.APROBADA;
        try {
            const response = await reemplazoDataService.validate({
                data: [$scope.reemplazo]
            });
            $mdDialog.hide({
                data: response.data
            });
            toastr.success(text.API_EXITO);
        } catch (error) {
            toastr.error(text.API_ERROR);
        }
    }

    function selectEmployee() {
        if (!data) {
            if (!$scope.form.description.$touched) {
                $scope.reemplazo.description = `Reemplazo ${$scope.reemplazo.name}`;
            }
        }
    }

    function changeDate() {
        storeService.set('month', $scope.reemplazo.month);
        storeService.set('year', $scope.reemplazo.year);
        $rootScope.$broadcast('update-auth-data');
    }


    // comunidades
    async function comunidades() {
        try {
            let communities = null;
            if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
              const response = await comunidadDataService.get($rootScope.currentCommunity)
              communities = [response.data];
            } else {
              const response = await comunidadDataService.all();
              communities = response.data;
            }
            $scope.comunidades = communities;
            var index = comunidad(communities);
            $scope.reemplazo.community_id = communities[index].id
        } catch (err) {
            console.error(err);
            toastr.error(text.API_ERROR);
        }
    }

    // get comunidad rootScope
    function comunidad(items) {
        var index = 0;
        if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
            angular.forEach(items, function (item, key) {
                if (item.id == $rootScope.currentCommunity.id) {
                    index = key;
                    $scope.is_current = true;
                }
            });
        } else {
            $scope.is_current = false;
        }
        return index;
    }
}
