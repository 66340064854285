angular.module("app").controller("cuentaController", cuentaController);

function cuentaController(
  $state,
  $stateParams,
  $rootScope,
  $mdDialog,
  comunidadDataService,
  cuentaDataService,
  confirmService,
  access,
  loading,
  toastr,
  text,
  status,
  tooltips
) {
  // --
  var vm = this;
  vm.tooltips = tooltips;
  vm.permission = access;
  vm.cuenta = {
    id: $stateParams.id ? $stateParams.id : null
  };
  vm.query = {
    order: ["name"],
    limit: 10,
    page: 1
  };
  vm.type_ley_social = "leyes sociales";

  // ---
  vm.destroy = destroy;
  vm.edit = edit;
  vm.create = create;
  vm.selectCommunity = selectCommunity;
  vm.addItem = addItem;

  init();

  // iniciar
  function init() {
    if (vm.cuenta.id) {
      cuentaDataService
        .get(vm.cuenta)
        .then(function(response) {
          vm.cuenta = response.data;
          title();
          comunidades();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        cuentaDataService
          .delete_providers(item.pivot)
          .then(function(response) {
            init();
            toastr.success(text.API_EXITO);
          })
          .catch(function() {
            toastr.error(text.API_ERROR);
          });
      },
      function() {}
    );
  }

  // editar
  function edit() {
    loading.init();
    cuentaDataService
      .put(vm.cuenta)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          $state.go("app.cuenta", {
            id: response.data.id
          });
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // crear
  function create(option) {
    loading.init();
    cuentaDataService
      .post(vm.cuenta)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          if (option == 1) {
            $state.go(
              "app.cuenta",
              {},
              {
                reload: true
              }
            );
          } else {
            $state.go("app.cuenta", {
              id: response.data.id
            });
          }
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // seleccionar comunidad
  function selectCommunity() {
    angular.forEach(vm.comunidades, function(item) {
      if (item.id == vm.cuenta.community_id) {
        vm.categorias = item.categories;
        vm.proveedores = item.providers;
      }
    });
  }

  // add item modal
  function addItem() {
    $mdDialog
      .show({
        controller: providerModalController,
        templateUrl: "views/admin/cuenta/modal/provider_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: vm.cuenta ? vm.cuenta : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // load comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        var index = comunidad(response.data);
        // --
        if (!vm.cuenta.id) {
          vm.cuenta.community_id =
            response.data.length > 0 ? response.data[index].id : null;
        }
        selectCommunity();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title
  function title() {
    var type = vm.cuenta.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "app.cuentas",
      newUrl: "",
      label: type + " Cuenta",
      name: vm.cuenta.id ? vm.cuenta.name.toUpperCase() : ""
    };
  }
}
