angular
    .module('app')
    .service('estacionamientoDataService', estacionamientoDataService);

function estacionamientoDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('estacionamientos', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (estacionamiento) {
            return dataService.httpGet('estacionamientos/' + estacionamiento.id);
        },
        post: function (estacionamiento) {
            return dataService.httpPost('estacionamientos', estacionamiento);
        },
        put: function (estacionamiento) {
            return dataService.httpPut('estacionamientos/' + estacionamiento.id, estacionamiento);
        },
        delete: function (estacionamiento) {
            return dataService.httpDelete('estacionamientos/' + estacionamiento.id);
        },
        // --
        // get_division: function (division) {
        //     return dataService.httpGet('estacionamientos/division/' + division.id);
        // },
    };
}