angular.module("app").service("proveedorDataService", proveedorDataService);

function proveedorDataService(dataService, $rootScope) {
  return {
    all: function(query) {
      return dataService.httpGet(
        `proveedores`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          },
          params: query
        }
      );
    },
    get: function(proveedor) {
      return dataService.httpGet("proveedores/" + proveedor.id);
    },
    post: function(proveedor) {
      return dataService.httpPost("proveedores", proveedor);
    },
    put: function(proveedor) {
      return dataService.httpPut("proveedores/" + proveedor.id, proveedor);
    },
    delete: function(proveedor) {
      return dataService.httpDelete("proveedores/" + proveedor.id);
    },
    // --
    // --
    post_accounts: function(cuentas) {
      return dataService.httpPost("proveedores/cuentas", cuentas);
    }
  };
}
