angular.module("app").controller("comunidadController", comunidadController);

function comunidadController(
  $state,
  $rootScope,
  $stateParams,
  $mdDialog,
  comunidadDataService,
  bancoDataService,
  regionDataService,
  confirmService,
  access,
  toastr,
  account_types,
  text,
  loading,
  status,
  tooltips,
  env,
  storeService,
  roleDataService
) {
  var vm = this;
  vm.tooltips = tooltips;
  vm.permission = access;
  vm.tipos_cuenta_comunidad = account_types;
  vm.comunidad = {
    id: $stateParams.id ? $stateParams.id : null
  };
  // ---
  vm.edit = edit;
  vm.create = create;
  vm.selectRegion = selectRegion;
  vm.addBank = addBank;
  vm.destroy = destroy;
  vm.roles = [];
  vm.admins = [];
  vm.updateSoftland = updateSoftland;
  vm.softlandPasswordBlocked = true;
  vm.softlandPasswordBlockedChange = softlandPasswordBlockedChange;

  init();

  // iniciar
  async function init() {
    try {
      roles();
      const response = await comunidadDataService.getCurrent(vm.comunidad);
      vm.comunidad = response.data;
      vm.community_softland = vm.comunidad.community_softland || {};
      vm.admins = vm.comunidad.admins;
      angular.forEach(vm.admins, function (admin) {
        admin.role_name = vm.roles.find(e => e.id == admin.pivot.role_id).name;
      });
      title();
      regiones();
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  // load roles
    function roles() {
        roleDataService.all()
            .then(function (response) {
                vm.roles = response.data;
                console.log("roles", vm.roles);
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

  // editar
  function edit() {
    loading.init();
    comunidadDataService
      .put(vm.comunidad)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          $state.go("app.comunidad", {
            id: response.data.id
          });
          toastr.success(text.API_EXITO);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // crear
  function create() {
    loading.init();
    comunidadDataService
      .post(vm.comunidad)
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error rr
          toastr.error(response.data.msg[0]);
        } else {
          // successul
          $state.go("app.comunidad", {
            id: response.data.id
          });
          toastr.success(text.API_EXITO);
          viewMessage("ev", response.data);
        }
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // al seleccionar region
  function selectRegion() {
    angular.forEach(vm.regiones, function(item) {
      if (item.name == vm.comunidad.region)
        vm.comunas = item.comunas.split(",");
    });
  }

  // mostrar modal banco
  function addBank(ev, item) {
    $mdDialog
      .show({
        controller: bankModalController,
        templateUrl: "views/admin/comunidad/modal/bank_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          community: vm.comunidad,
          bank: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // mostrar modal message
  function viewMessage(ev, item) {
    $mdDialog
      .show({
        controller: msgCommunityModalController,
        templateUrl: "views/admin/comunidad/modal/message_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {
          data: item ? item : null
        }
      })
      .then(function(response) {});
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        trash(item);
      },
      function() {}
    );
  }

  // eliminar el item seleccionado
  function trash(item) {
    loading.init();
    bancoDataService
      .delete(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // load regiones
  function regiones() {
    vm.regiones = [];
    regionDataService
      .all()
      .then(function(response) {
        vm.regiones = response.data;
        vm.selectRegion();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // load title
  function title() {
    vm.type = vm.comunidad.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "",
      newUrl: "",
      excel: true,
      isOpen: false,
      label: vm.type + " Comunidad",
      name: vm.comunidad.id ? vm.comunidad.name.toUpperCase() : "",
      id: vm.comunidad.id ? vm.comunidad.id : null,
      items_excel: [
        {
          id: 1,
          icon: "insert_drive_file",
          name: "Compacto",
          description:
            "Incluye toda la informacion de la comunidad en una sola tabla."
        },
        {
          id: 2,
          icon: "book",
          name: "Libro",
          description:
            "Mantiene el formato para la subida de archivos y carga de propiedades, usuarios y unidades."
        }
      ]
    };
  }
  async function updateSoftland() {
    try {
      loading.init();
      console.log(vm.community_softland);
      const response = await comunidadDataService.updateSoftland(
        vm.comunidad.id,
        vm.community_softland
      );
      console.log(response);
      vm.community_softland = response.data;
      toastr.success(text.API_EXITO);
    } catch (err) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  function softlandPasswordBlockedChange() {
    if (vm.softlandPasswordBlocked) {
      vm.softlandPasswordBlocked = false;
    } else {
      vm.softlandPasswordBlocked = true;
      vm.community_softland.password = "";
    }
  }
}
