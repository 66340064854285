angular.module("app").service("tesoreriaDataService", tesoreriaDataService);

function tesoreriaDataService(dataService, $rootScope) {
  return {
    post: function(data) {
      return dataService.httpPost("bill_pays", data);
    },
    delete: function(data) {
      return dataService.httpDelete("bill_pays/" + data.id);
    },
    getEgresosByDivision: function(comunidad, division, month, year) {
      return dataService.httpGet(
        "bill_pays/" +
          comunidad.id +
          "/" +
          (division != "-1" ? division.id : "-1") +
          "/" +
          month +
          "/" +
          year
      );
    }
  };
}
