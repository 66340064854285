angular
    .module('app')
    .service('notificationDataService', notificationDataService);

function notificationDataService(dataService) {
    return {
        get: function () {
            return dataService.httpGet('notificaciones');
        },
    };
}