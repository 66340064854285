angular.module("app").controller("usuariosController", usuariosController);

function usuariosController(
  $state,
  $rootScope,
  $mdDialog,
  comunidadDataService,
  usuarioDataService,
  confirmService,
  loading,
  access,
  toastr,
  text
) {
  var vm = this;
  var community_id = $rootScope.currentCommunity
    ? $rootScope.currentCommunity.id
    : null;
  vm.usuarios = [];

  vm.query = {
    order: "name",
    limit: 10,
    page: 1,
    search: ""
  };

  // ---
  vm.edit = edit;
  vm.list_to_add = list_to_add;

  init();

  // inicio
  function init() {
    title();
    vm.permission = access;
    getAdmins();
  }

  // edit item
  function edit(item) {
    $mdDialog
      .show({
        preserveScope: true,
        controller: usuariosModalController,
        templateUrl: "views/admin/usuario/modal/user_detail_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: item,
        }
      })
      .then(function(response) {
        if (response == "OK") {
          getAdmins();
        }
      });
  }

  function list_to_add(item) {
    $mdDialog
      .show({
        preserveScope: true,
        controller: usuariosModalController,
        templateUrl: "views/admin/usuario/modal/user_add_role_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: item,
        }
      })
      .then(function(response) {
        if (response == "OK") {
          getAdmins();
        }
      });
  }

  // get data
  function getAdmins() {
    usuarioDataService
      .get_admins(vm.query)
      .then(function(response) {
        vm.usuarios = response.data.message;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "person",
      backUrl: "",
      newUrl: "app.persona",
      backActive: false,
      newActive: true,
      excel_people: true,
      label: "Usuarios Sistema"
    };
  }

  // table events
  vm.onReorder = function() {
    getData();
  };

  vm.onPaginate = function() {
    getData();
  };

  vm.onSearch = function() {
    getData();
  };
}
