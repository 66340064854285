angular
    .module('app')
    .controller('sheetController', sheetController);

function sheetController($scope, $mdBottomSheet, data, items) {
    var vm = this;
    // --
    $scope.listItemClick = listItemClick;

    $scope.items = items;

    // click item
    function listItemClick($index) {
        var clickedItem = $scope.items[$index];
        $mdBottomSheet.hide(clickedItem);
    };

}