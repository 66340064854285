angular
    .module('app')
    .controller('gastoComunPaidController', gastoComunPaidController);

function gastoComunPaidController($scope, $mdDialog, gastoComunDataService, method_pay, toastr, text, data) {

    $scope.amount_pay = 0;
    // --
    $scope.cancel = cancel;
    $scope.create = create;
    $scope.update = update;

    init();

    // --
    function init() {
        $scope.pays = method_pay;
        $scope.data = data;
        sum();
        // if (data['web_pay'] != null) {
        //     $scope.data = {
        //         p_description: data['web_pay']['description'],
        //         p_buy_order: data['web_pay']['buyOrder'],
        //         p_card_number: data['web_pay']['cardNumber'],
        //         p_commerce_code: data['web_pay']['commerceCode'],
        //         p_amount: data['web_pay']['amount'],
        //         amount: data['amount'],
        //         morosidad: data['morosidad'],
        //         is_paid: data['is_paid'],
        //     }
        // } else {
        // }
    }

    function cancel() {
        $mdDialog.hide()
    }

    function create() {
        gastoComunDataService.post_pago_manual($scope.data)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function update() {
        gastoComunDataService.put_pago_manual(data)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }


    function sum() {
        angular.forEach(data.web_pay, element => {
            $scope.amount_pay += parseFloat(element.amount);
        });
    }


}