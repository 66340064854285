angular
    .module('app')
    .service('reglaDataService', reglaDataService);

function reglaDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('reglas', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            });
        },
        all_division: function (division_id) {
            return dataService.httpGet('reglas_division/' + $rootScope.currentCommunity.id + '/' + division_id, {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            });
        },
        get: function (regla) {
            return dataService.httpGet('reglas/' + regla.community_id + '/' + regla.division_id + '/' + regla.account_id);
        },
        post: function (regla) {
            return dataService.httpPost('reglas', regla);
        },
        put: function (regla) {
            return dataService.httpPut('reglas/' + regla.id, regla);
        },
        delete: function (regla) {
            return dataService.httpDelete('reglas/' + regla.id);
        },
    };
}