angular
    .module('app')
    .controller('personaController', personaController);

function personaController($state, $stateParams, $mdDialog, usuarioDataService,
    tipoDataService, roleDataService, confirmService, loading, access, toastr, auth_types,
    auth_roles, status, text) {
    // --
    var vm = this;
    vm.persona = {
        id: ($stateParams.id) ? $stateParams.id : null,
        roles: []
    };
    vm.permission = access;
    vm.auth_types = auth_types;
    vm.auth_roles = auth_roles;
    vm.isRole = false;

    // --
    vm.query = {
        order: 'name',
        limit: 10,
        page: 1
    };
    // ---
    vm.edit = edit;
    vm.create = create;
    vm.addProperty = addProperty;
    vm.addRole = addRole;
    vm.destroy = destroy;
    // vm.isRole = isRole;

    init();

    // iniciar
    function init() {
        if (vm.persona.id) {
            usuarioDataService.get(vm.persona)
                .then(function (response) {
                    vm.persona = response.data;
                    vm.persona.roles = response.data.roles ? response.data.roles : [];
                    title();
                    isRole(response.data.roles ? response.data.roles : []);
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            title();
        }
        tipos();
        // roles();
    }

    // editar
    function edit() {
        loading.init();
        usuarioDataService.put(vm.persona)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.persona', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // crear
    function create(option) {
        loading.init();
        usuarioDataService.post(vm.persona)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    if (option == 1) {
                        $state.go('app.persona', {}, {
                            reload: true
                        });
                    } else {
                        $state.go('app.persona', {
                            id: response.data.id
                        });
                    }
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load tipos
    function tipos() {
        tipoDataService.all()
            .then(function (response) {
                vm.tipos = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // load roles
    function roles() {
        roleDataService.all()
            .then(function (response) {
                vm.roles = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function addRole(type, role) {
        if (!validateRole(role)) {
            vm.persona.roles.push({
                type: type,
                role: role
            });
            vm.role = vm.type = null;
        } else {
            toastr.error('Rol ya agregado');
        }
    }

    function validateRole(item) {
        var index = false;
        angular.forEach(vm.persona.roles, function (value) {
            if (item.id == value.role.id) {
                index = true;
            }
        });
        return index;
    }

    // add modal property
    function addProperty(ev) {
        $mdDialog.show({
                controller: propertyModalController,
                templateUrl: 'views/admin/persona/modal/property_modal.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                fullscreen: false,
                locals: {
                    data: (vm.persona) ? vm.persona : null
                }
            })
            .then(function (response) {
                if (response) {
                    init();
                }
            });
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            trash(item);
        }, function () {});
    }

    // eliminar el item seleccionado
    function trash(item) {
        usuarioDataService.delete_properties(item).then(function (response) {
            init();
            toastr.success(text.API_EXITO);
        }).catch(function () {
            toastr.error(text.API_ERROR);
        });
    }

    // validation role
    function isRole(item) {
        vm.isRole = false;
        if (item) {
            angular.forEach(item, function (value) {
                if (value.role.name == auth_roles['COPROPIETARIO']) {
                    vm.isRole = true;
                }
            })
        }
        return;
    }

    // load title
    function title() {
        vm.type = (vm.persona.id) ? 'Editar' : 'Nueva';
        vm.item = {
            backUrl: 'app.personas',
            newUrl: '',
            label: vm.type + ' Persona',
            name: (vm.persona.id) ? vm.persona.name.toUpperCase() : '',
        };
    }
}