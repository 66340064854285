angular
    .module('app')
    .controller('noticiaRWDController', noticiaRWDController);

function noticiaRWDController($scope, $mdDialog, informacionDataService,
    informations_category, storeService, access, toastr, text) {
    var vm = this;

    // --
    vm.viewImages = viewImages;

    init();

    // iniciar
    function init() {
        vm.permission = access;
        vm.categories = informations_category;
        title();
        informaciones();
    }

    // mostrar modal mensage
    function viewImages(ev, item) {
        $mdDialog.show({
                controller: filesRWDModalController,
                templateUrl: 'views/user/administracion/file/files_modal.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                fullscreen: false,
                locals: {
                    data: (item) ? item.information_files : null
                }
            })
            .then(function (response) {});
    }

    // load informations
    function informaciones() {
        informacionDataService.get_division(storeService.get('property'))
            .then(function (response) {
                vm.informaciones = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // load title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.administracion_rwd',
            title: 'Noticias'
        };
    }
}