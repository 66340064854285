angular
    .module('app')
    .service('reservaDataService', reservaDataService);

function reservaDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('reservas', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (reserva) {
            return dataService.httpGet('reservas/' + reserva.id);
        },
        post: function (reserva) {
            return dataService.httpPost('reservas', reserva);
        },
        put: function (reserva) {
            return dataService.httpPut('reservas/' + reserva.id, reserva);
        },
        delete: function (reserva) {
            return dataService.httpDelete('reservas/' + reserva.id);
        },
        // --
        get_division: function (division) {
            return dataService.httpGet('reservas/division/' + division.id);
        },

        getReserves: function (reservaId, date) {
            return dataService.httpGet('reservas/' + reservaId + '/reservas', {params: {date}});
        },
        
        getPendingReserves: function(reservaId) {
            return dataService.httpGet('reservas/' + reservaId + '/pending-reservas');
        },

        getAvailableHours: async function (reservaId, date) {
            const response = await dataService.httpGet('reservas/' + reservaId + '/available', {params: {date}});

            const availableHours = response.data.map((r) => {
                return {
                    from: new Date(r.from * 1000),
                    to: new Date(r.to * 1000)
                };
            });
            return availableHours;
        },
    };
}