angular
    .module('app')
    .controller('gastoTransparentRWDController', gastoTransparentRWDController);

function gastoTransparentRWDController($scope, $state, gastoComunDataService, storeService,
    $mdDialog, access, toastr, text) {
    var vm = this;
    var date = new Date();

    vm.total = 0;

    // --
    vm.show = show;
    vm.sum = sum;
    vm.viewImages = viewImages;

    init();

    // inicio
    function init() {
        title();
        // --
        vm.comunidad = storeService.get('community');
        vm.propiedad = storeService.get('property');
        // -- state cost
        vm.date = date;
        vm.permission = access;
        loadDetalles();
    }

    function show(item) {
        $state.go('dev.' + item);
    }

    function sum(item) {
        vm.total += parseFloat(item);
    }

    function loadDetalles() {
        gastoComunDataService.get_resume_category(vm.comunidad, vm.propiedad, date.getMonth() + 1, date.getFullYear())
            .then(function (response) {
                vm.detalles = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // mostrar modal mensage
    function viewImages(ev, item) {
        $mdDialog.show({
                controller: filesRWDModalController,
                templateUrl: 'views/user/file/files_modal.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                fullscreen: false,
                locals: {
                    data: (item && item.bill) ? item.bill.bill_files : null
                }
            })
            .then(function (response) {});
    }

    // load title sub-toolbar
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.gasto_rwd',
            title: 'Transparencia',
        };
    }
}