angular
    .module('app')
    .service('gastoPropiedadDataService', gastoPropiedadDataService);

function gastoPropiedadDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('gastos/propiedades');
        },
        get: function (propiedad) {
            return dataService.httpGet('gastos/propiedades/' + propiedad.id);
        },
        post: function (propiedad) {
            return dataService.httpPost('gastos/propiedades', propiedad);
        },
        put: function (propiedad) {
            return dataService.httpPut('gastos/propiedades/' + propiedad.id, propiedad);
        },
        delete: function (propiedad) {
            return dataService.httpDelete('gastos/propiedades/' + propiedad.id);
        },
        // --
        get_costProperty: function (property, cost) {
            return dataService.httpGet('gastos/propiedades/' + cost.id + '/' + property.property_id);
        },
        // allByCommunity: function () {
        //     return dataService.httpGet('gastos', {
        //         headers: {
        //             'x-comunidad': $rootScope.currentCommunity.id
        //         },
        //     })
        // }

    };
}