angular
  .module("app")
  .controller("propiedadesController", propiedadesController);

function propiedadesController(
  $state,
  $rootScope,
  comunidadDataService,
  propiedadDataService,
  $mdDialog,
  confirmService,
  access,
  env,
  loading,
  toastr,
  text
) {
  var vm = this;
  vm.propiedades = [];
  vm.selected = [];

  vm.query = {
    order: "number",
    limit: 50,
    page: 1,
    search: ""
  };

  // ---
  vm.edit = edit;
  vm.destroy = destroy;
  vm.excelAlicuotas = excelAlicuotas;

  init();

  // inicio
  function init() {
    title();
    getData();
    vm.permission = access;
  }

  // edit item
  function edit(item) {
    $state.go("app.propiedad", {
      id: item.id
    });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        trash(item);
      },
      function() {}
    );
  }

  // eliminar el item seleccionado
  function trash(item) {
    loading.init();
    propiedadDataService
      .delete(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // get data
  function getData() {
    propiedadDataService
      .all(vm.query)
      .then(function(response) {
        vm.data = response.data;
        vm.propiedades = angular.forEach(response.data.data, function(
          item,
          key
        ) {
          //   response.data[key].prorrateo_total = parseFloat(item.prorrateo);
          item.prorrateo_total = parseFloat(item.prorrateo);
          angular.forEach(item.dependencies, function(value) {
            // response.data[key].prorrateo_total += parseFloat(value.prorrateo);
            item.prorrateo_total += parseFloat(value.prorrateo);
          });
        });
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // excel bills
  function excelAlicuotas() {
    document.location = env.DOMAIN + env.API_PATH + "files/export/alicuotas/" + $rootScope.currentCommunity.id;
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "person",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: false,
      label: "Propiedades",
      service: propiedadDataService
    };
  }

  // table events
  vm.onReorder = function() {
    getData();
  };

  vm.onPaginate = function() {
    getData();
  };

  vm.onSearch = function() {
    getData();
  };
}
