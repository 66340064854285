angular
    .module('app')
    .controller('fondoReservaController', fondoReservaController);

function fondoReservaController($state, $stateParams, $q, $mdDialog,
    fondoReservaDataService, facturaDataService, confirmService, loading, access, toastr, text) {
    var vm = this;
    vm.permission = access;
    vm.comunidad = $stateParams.community;
    vm.month = $stateParams.month;
    vm.year = $stateParams.year;

    vm.query = {
        order: ['year', 'month'],
        limit: 10,
        page: 1
    };

    // ---
    vm.viewBack = viewBack;
    vm.destroy = destroy;

    init();

    // inicio
    function init() {
        filter();
        title();
    }

    // filtrar 
    function filter() {
        var promises = [
            fondoReservaDataService.get_fondos_detail(vm.comunidad, vm.month, vm.year),
            fondoReservaDataService.get_indicadores_detail(vm.comunidad, vm.month, vm.year),
        ];
        $q.all(promises).then(function (response) {
                vm.fondos = response[0].data;
                vm.indicadores = response[1].data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function viewBack() {
        $state.go(vm.item.backUrl);
    }

    // confirmar para eliminar item fondos
    function destroy(item) {
        $mdDialog.show(confirmService.delete('ev')).then(function () {
            disabled(item);
        }, function () {});
    }

    // disabled
    function disabled(item) {
        loading.init();
        facturaDataService.delete(item)
            .then(function (response) {
                init();
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load title sub-toolbar
    function title() {
        vm.item = {
            icon: '',
            backUrl: 'app.fondos',
            newUrl: '',
            backActive: true,
            newActive: false,
            label: 'Fondo Reserva',
        };
    }
}