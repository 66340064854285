angular
    .module('app')
    .controller('costGeneralModalController', costGeneralModalController);

function costGeneralModalController($scope, $mdDialog, gastoDataService, status,
    movimiento_types, toastr, text, data) {
    $scope.types = movimiento_types;
    // --
    $scope.create = create;
    $scope.cancel = cancel;

    init();

    // inicio
    function init() {
        $scope.cost = data;
        $scope.cost.type = data.amount > 0 ? movimiento_types.COBRO : movimiento_types.DESCUENTO;
        $scope.cost.amount = data.amount > 0 ? data.amount : (-1 * data.amount);
    }

    // crear gastos
    function create() {
        gastoDataService.post_general({
            community_id: data.community_id,
            division_id: data.division_id,
            is_method: data.is_method,
            amount: ($scope.cost.type == movimiento_types.DESCUENTO) ? (-1 * $scope.cost.amount) : $scope.cost.amount,
            description: $scope.cost.description,
            month: data.month,
            year: data.year,
            id: $scope.cost.id,
        }).then(function (response) {
            if (response.data.status == status.CLOSED) {
                // closed
                toastr.warning(response.data.message);
            } else {
                $mdDialog.hide({
                    data: response.data
                });
                toastr.success(text.API_EXITO);
            }
        }).catch(function () {
            toastr.error(text.API_ERROR);
        })
    }

    // crear gastos
    // function update() {
    //     gastoDataService.put_general({
    //         amount: ($scope.cost.type == movimiento_types.DESCUENTO) ? (-1 * $scope.cost.amount) : $scope.cost.amount,
    //         description: $scope.cost.description,
    //         id: $scope.cost.id,
    //     }).then(function (response) {
    //         $mdDialog.hide({
    //             data: response.data
    //         });
    //         toastr.success(text.API_EXITO);
    //     }).catch(function () {
    //         toastr.error(text.API_ERROR);
    //     })
    // }

    function cancel() {
        $mdDialog.hide()
    }
}