angular.module("app").controller("conciliacionModalController", conciliacionModalController);

function conciliacionModalController(
  $scope,
  $mdDialog,
  text,
  toastr,
  conciliacionDataService,
  data,
  loading
) {
  // --
  $scope.deassign = deassign;
  $scope.movement = data;
  $scope.cancel = cancel;

  function deassign(item) {
    conciliacionDataService
      .unsetFintocMovementsProperty(item)
      .then(function(response) {
        //console.log(response.data);
        toastr.success("Movimiento Des-Conciliado");
        $mdDialog.hide("OK");
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function cancel() {
    $mdDialog.hide("CANCEL");
  }
}
