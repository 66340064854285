angular
    .module('app')
    .controller('dependencyModalController', dependencyModalController)

function dependencyModalController($scope, $mdDialog, propiedadDataService,
    toastr, text, property_type, status, data) {
    // --
    // $scope.role = auth_roles.COPROPIETARIO;
    $scope.properties = [];
    // --
    $scope.create = create;
    $scope.cancel = cancel;
    $scope.confirmPropertyDebts = confirmPropertyDebts;

    init();

    function init() {
        $scope.property = {
            id: null,
            property_id: data.id
        };
        properties();
    }

    function create() {
        propiedadDataService.put_properties(data.id, {
                property_id: $scope.property.id,
            })
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // success
                    toastr.success(text.API_EXITO);
                    $mdDialog.hide({
                        data: response.data
                    });
                }
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function confirmPropertyDebts(ev) {
        var confirm = $mdDialog.confirm()
            .title('Advertencia al Asignar Propiedad')
            .textContent('La propiedad que se desea asignar contiene gastos particulares que no pertenecen a un gasto común confirmado. Si continua con la asignación estos gastos se borrarán. ¿Desea continuar?')
            .ariaLabel('Lucky day')
            .targetEvent(ev)
            .ok('Continuar')
            .cancel('Cancelar');
        propiedadDataService.get_has_cost_common_details(data.id)
            .then(function (response) {
                if (eval(response.data)) {
                    $mdDialog.show(confirm).then(function() {
                        create();
                    }, function() {
                    });
                } else {
                    create();
                }
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function properties() {
        propiedadDataService.get_division(data.division)
            .then(function (response) {
                // $scope.properties = response.data;
                angular.forEach(response.data, function (value) {
                    if (property_type.DEPARTAMENTO != value.type && !value.property_id && value.id != data.id) {
                        // add user
                        $scope.properties.push(value);
                    }
                });
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function cancel() {
        $mdDialog.hide()
    }

};
