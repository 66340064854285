angular
  .module('app')
  .filter('percentage', function () {
    return function (number, decimals) {
      if (typeof (number) === 'undefined' || typeof (decimals) === 'undefined') {
        return 0;
      }
      if(parseFloat(number) < 1){
        return (parseFloat(number) * 100).toFixed(decimals) + '%';
      } else {
        return (parseFloat(number)).toFixed(decimals) + '%';
      }
    };
  });
