angular
  .module("app")
  .controller("circularPrintController", circularPrintController);

function circularPrintController(
  $stateParams,
  $window,
  gastoComunDataService,
  comunidadDataService,
  toastr,
  text
) {
  var vm = this;

  vm.gasto = {
    id: $stateParams.id
  };

  // --
  vm.print = print;
  vm.close = close;

  init();

  // inicio
  function init() {
    gastos();
  }

  // gastos comunes
  async function gastos() {
    vm.suma_gastos_comunes = 0;
    vm.suma_gastos_privados = 0;

    try {
      var [responseGet, responseGetCategory, responseCommunity] = await Promise.all([
        await gastoComunDataService.get(vm.gasto),
        await gastoComunDataService.get_category(vm.gasto),
        await comunidadDataService.getCurrent(),
      ]);
      vm.month = responseGet.data.month;
      vm.year = responseGet.data.year;
      vm.community = responseCommunity.data;
      vm.circular = responseGetCategory.data;
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  // open dialog print
  function print() {
    $window.print();
  }

  // close window
  function close() {
    // $window.close();
    $window.history.back();
  }
}
