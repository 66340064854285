angular
  .module("app", [
    "ui.router",
    "satellizer",
    "ngAnimate",
    "ngMaterial",
    "LocalStorageModule",
    "md.data.table",
    "ngLetterAvatar",
    "platanus.rut",
    "angular-loading-bar",
    "angularFileUpload",
    "chart.js",
    // 'ngDesktopNotification',
    "angular-web-notification",
    "btford.socket-io"
  ])
  .config(function(
    $authProvider,
    $urlRouterProvider,
    $httpProvider,
    $provide,
    localStorageServiceProvider,
    env
  ) {
    // Parametros de configuración
    $urlRouterProvider.otherwise("login");
    // authenticathe
    $authProvider.loginUrl = env.DOMAIN + env.ENDPOINT_API + "/auth";
    $authProvider.tokenName = "token";
    $authProvider.tokenPrefix = "user";

    // config localstorage
    localStorageServiceProvider
      .setPrefix("app")
      .setStorageType("localStorage")
      .setNotify(true, true);

    // Push the new factory onto the $http interceptor array
    // $httpProvider.interceptors.push('redirectWhenLoggedOut');
    $httpProvider.interceptors.push('communityInterceptor');
  })
  .config(function($mdDateLocaleProvider) {
    $mdDateLocaleProvider.formatDate = function(date) {
      var tempDate = moment(date);
      return (tempDate.isValid() ? tempDate.format('DD-MM-YYYY') : '');
    };
  })
  .config(function($mdDateLocaleProvider) {
    // Example of a Spanish localization.
    $mdDateLocaleProvider.months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    $mdDateLocaleProvider.shortMonths = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ];
    $mdDateLocaleProvider.days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ];
    $mdDateLocaleProvider.shortDays = [
      "Do",
      "Lu",
      "Ma",
      "Mi",
      "Ju",
      "Vi",
      "Sá"
    ];
    // Can change week display to start on Monday.
    $mdDateLocaleProvider.firstDayOfWeek = 1;
    // Optional.
    //$mdDateLocaleProvider.dates = [1, 2, 3, 4, 5, 6, 7,8,9,10,11,12,13,14,15,16,17,18,19,
    //                               20,21,22,23,24,25,26,27,28,29,30,31];
    // In addition to date display, date components also need localized messages
    // for aria-labels for screen-reader users.
    $mdDateLocaleProvider.weekNumberFormatter = function(weekNumber) {
      return "Semana " + weekNumber;
    };
    $mdDateLocaleProvider.msgCalendar = "Calendario";
    $mdDateLocaleProvider.msgOpenCalendar = "Abrir calendario";
  })

  .run(function(
    $rootScope,
    $auth,
    $state,
    authService,
    storeService,
    channelService,
    gaService,
    env
  ) {
    gaService.config();
    $rootScope.$on("$stateChangeStart", function(event, toState, routes) {
      if (authService.isAuthenticated()) {
        if ("/login" == toState.url || "/" == toState.url) {
          event.preventDefault();
          authService.routes();
        } else if ("/secured/change" == toState.url) {
          // if exist rol => go to home
          if (storeService.get("role")) {
            event.preventDefault();
            authService.routes();
          }
        } else {
          // validate permises role
        if (storeService.get("role")) {
            if (toState.access.indexOf(storeService.get("role").name) === -1) {
              event.preventDefault();
              authService.routes();
            }
          }
        }
      } else {
        if (!storeService.exist()) {
          if (
            "/login" != toState.url &&
            "/password/reset" != toState.url &&
            "/password/reset/:id" != toState.url && 
            "/password/reset_success" != toState.url && 
            "/secured/webpay/:id" != toState.url &&
            "/secured/webpay/o/:id/:p_id" != toState.url &&
            "/secured/webpay/b/:id" != toState.url &&
            "/gasto/estado" != toState.url
          ) {
            event.preventDefault();
            $state.go("app.login");
          }
        } else {
          event.preventDefault();
          authService.expired();
        }
      }
    });
    $rootScope.$on("$stateChangeSuccess", function(event, toState, toParams, fromState, fromParams) {
      gaService.page_view(toState, toParams);
    });
  });
