angular
  .module("app")
  .controller("boletaPrintController", boletaPrintController);

function boletaPrintController(
  $state,
  $stateParams,
  $window,
  webpayDataService,
  storeService,
  months,
  toastr,
  text
) {
  var vm = this;

  vm.gasto = {
    id: $stateParams.id,
    month: storeService.get("month"),
    year: storeService.get("year")
  };

  // --
  vm.month_str = month_str;
  vm.print = print;
  vm.close = close;

  init();

  // inicio
  function init() {
    gastos();
  }

  // gastos comunes
  function gastos() {
    webpayDataService
      .get_gasto_comun(vm.gasto)
      .then(function(response) {
        vm.data = response.data;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // open dialog print
  function print() {
    $window.print();
  }

  // close window
  function close() {
    // $window.close();
    $window.history.back();
  }

  // get month string
  function month_str() {
    var data = vm.gasto.month;
    if (data) {
      if (data.month == 1) return months.ENERO;
      else if (data.month == 2) return months.FEBRERO;
      else if (data.month == 3) return months.MARZO;
      else if (data.month == 4) return months.ABRIL;
      else if (data.month == 5) return months.MAYO;
      else if (data.month == 6) return months.JUNIO;
      else if (data.month == 7) return months.JULIO;
      else if (data.month == 8) return months.AGOSTO;
      else if (data.month == 9) return months.SEPTIEMBRE;
      else if (data.month == 10) return months.OCTUBRE;
      else if (data.month == 11) return months.NOVIEMBRE;
      else if (data.month == 12) return months.DICIEMBRE;
    }
  }
}
