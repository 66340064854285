angular.module("app").controller("medicionController", medicionController);

function medicionController(
  $state,
  $q,
  $rootScope,
  $mdDialog,
  comunidadDataService,
  propiedadDataService,
  gastoDataService,
  medicionDataService,
  confirmService,
  storeService,
  rule_types,
  state_cost,
  morosidad_types,
  env,
  months,
  status,
  loading,
  access,
  toastr,
  text,
) {
  var vm = this;
  var date = new Date();
  vm.rule_types = rule_types;
  vm.suma_current = 0;
  vm.suma_money_current = 0;
  vm.is_method = 0;
  vm.is_type = 1;
  vm.rule = null;
  vm.query = {
    order: ["number"]
  };
  vm.current_role = $rootScope.currentRole.name;
  // --
  vm.filter = filter;
  vm.resta = resta;
  vm.sum = sum;
  vm.changeDate = changeDate;
  vm.selectCommunity = selectCommunity;
  vm.selectAccount = selectAccount;
  vm.selectRule = selectRule;
  vm.selectTab = selectTab;
  vm.selectMonth = selectMonth;
  vm.selectYear = selectYear;

  vm.addMedition = addMedition;
  vm.load = load;
  vm.view_image = view_image;
  vm.gasto_general_state = '';

  vm.month_str = month_str;

  init();

  // inicio
  function init() {
    title();
    comunidades();
    vm.months = months;
    vm.years = [date.getFullYear() - 1, date.getFullYear()];
    // -- date
    vm.year = storeService.get("year");
    vm.month = storeService.get("month");

  }

  function clearArray(myArray) {
    myArray.forEach(element => {
      element.cost_id = null;
      element.properties = null;
    });
    return myArray;
  }

  // get filter
  function filter(option, flag = 0) {
    var rule = selectAmountRule();

    vm.suma_current = 0;
    vm.suma_money_current = 0;

    // get monto rule bill o share
    // if (vm.factura) {
    //   if (vm.factura.bill_shares.length > 0) {
    //     angular.forEach(vm.factura.bill_shares, function (item) {
    //       if (item.month == vm.month && item.year == vm.year) {
    //         vm.monto_rule_bill = item.amount * (rule ? rule : 1);
    //       }
    //     });
    //   } else {
    //     vm.monto_rule_bill =
    //       (vm.factura ? vm.factura.amount : 1) * (rule ? rule : 1);
    //   }
    // }

    if (option == 1) {
      if (vm.comunidad && vm.division && vm.cuenta && vm.factura) {
        if (flag == 0) loading.init();
        gastoDataService
          .get_filter(
            vm.division,
            vm.month,
            vm.year,
            vm.cuenta,
            vm.regla,
            vm.factura
          )
          .then(function (response) {
            console.log(response);
            vm.gastos = response.data;
            // --
            angular.forEach(vm.gastos.data, function (value) {
              // vm.suma_current += value.current;
              vm.suma_current += value.diff;
              vm.suma_money_current += value.amount;
              value.amount = value.amount != null ? value.amount : 0;
            });
            loading.end();
          })
          .catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
          });
      }
    } else if (option == 0 && vm.is_method == 3) {
      if (vm.comunidad && vm.division) {
        propertiesMorosidad();
      }
    } else if (option == 0 && vm.is_method == 2) {
      if (vm.comunidad && vm.division) {
        movimientosGenerales();
      }
    } else if (option == 0 && vm.is_method == 0) {
      if (vm.comunidad && vm.division) {
        if (flag == 0) loading.init();
        console.log("cambio la regla y se cargan mediciones");
        gastoDataService
          .get_filter(
            vm.division,
            vm.month,
            vm.year,
            vm.cuenta,
            vm.regla,
            vm.factura
          )
          .then(function (response) {
            vm.gastos = response.data;
            console.log(response);
            // --
            angular.forEach(vm.gastos.data, function (value) {
              vm.suma_current += value.diff;
              vm.suma_money_current += value.amount;
            });
            loading.end();
          })
          .catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
          });
      }
    }
  }


  // disabled
  function disabled(item) {
    loading.init();
    gastoDataService
      .delete(item)
      .then(function (response) {
        movimientosGenerales();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // suma diferencias
  function resta(item) {
    vm.suma_current = 0;
    vm.suma_money_current = 0;
    item.diff = item.current - item.last;
    item.porcentaje = (100 * item.diff) / sum_diff();
    // set;
    // item.amount =
    //   ((((item.current - item.last) * 100) / vm.suma_current) *
    //     vm.monto_rule_bill) /
    //   100;
  }

  function sum_diff() {
    var suma = 0;
    angular.forEach(vm.gastos.data, function (value) {
      suma += value.diff;
      vm.suma_current += value.diff;
      vm.suma_money_current += value.amount;
    });
    return suma;
  }

  // suma
  function sum(item) {
    item.amount_paid = (item.amount * item.value) / 100;
  }

  // select tabs
  function selectTab(item) {
    if (item == 5) {
      propertiesMorosidad();
      vm.is_method = 3;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
    } else if (item == 4) {
      propertiesCost();
      vm.is_method = 0;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
    } else if (item == 3) {
      movimientosGenerales();
      vm.is_method = 2;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
    } else if (item == 2) {
      // OTRA
      vm.is_method = 0;
      vm.is_type = 0;
      vm.cuenta = null;
      vm.regla = null;
      vm.factura = null;
      filter(0);
    } else if (item == 1) {
      vm.gastos = [];
      vm.is_method = 1;
      vm.is_type = 1;
      filter(1);
    } else {
      vm.gastos = [];
      // privado $
      vm.is_method = 0;
      vm.is_type = 1;
      filter(1);
    }
  }

  // morosidad propierties
  function propertiesMorosidad() {
    if (vm.comunidad && vm.division) {
      loading.init();
      propiedadDataService
        .get_morosidad(vm.comunidad, vm.division, vm.month, vm.year)
        .then(function (response) {
          // vm.morosidades = response.data ? response.data.data : null;
          vm.gasto_general = response.data.cost_general;
          vm.morosidades = response.data.data;
          vm.morosidades = angular.forEach(response.data.data, function (value) {
            value.value =
              value.amount > 0
                ? value.id == 0
                  ? vm.comunidad.m_type == morosidad_types.VARIABLE
                    ? vm.comunidad.m_amount
                    : 0
                  : value.value
                : 0;
            value.amount_paid =
              value.amount > 0
                ? value.id == 0
                  ? vm.comunidad.m_type == morosidad_types.FIJO
                    ? vm.comunidad.m_amount
                    : Math.round((vm.comunidad.m_amount * value.amount) / 100)
                  : value.amount_paid
                : 0;
          });
          loading.end();
        })
        .catch(function () {
          loading.end();
          toastr.error(text.API_ERROR);
        });
    }
  }

  // gastos privados
  function propertiesCost() {
    loading.init();
    vm.suma_unidades = 0;
    propiedadDataService
      .get_cost(vm.comunidad, vm.division)
      .then(function (response) {
        vm.unidades = angular.forEach(response.data.data, function (item) {
          vm.suma_unidades += parseFloat(item.cost);
        });
        vm.uf = response.data.uf.valor;
        loading.end();
      })
      .catch(function () {
        loading.end();
        toastr.error(text.API_ERROR);
      });
  }

  // movimientos generales
  function movimientosGenerales(flag = 0) {
    if (flag == 0) loading.init();
    gastoDataService
      .get_movimiento_general(vm.comunidad, vm.division, vm.month, vm.year, vm.cuenta, vm.regla)
      .then(function (response) {
        vm.gasto_general_state = response.data.state;
        vm.movimientos_generales = (response.data.data.length > 0 ? response.data.data : null);
        if(vm.movimientos_generales){
          vm.movimientos_generales.map(function (m, index, array) {
            var ret = m;
            ret.size = parseInt(m.size);
            return ret;
          });
        }
        loading.end();
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // seleccionar cuenta
  function selectAccount() {
    var index = 0;
    if (vm.cuenta) {
      vm.facturas = vm.cuenta.bills.filter(
        element => element.month == vm.month && element.year == vm.year
      );
      if(vm.facturas.length == 1){
        vm.factura = vm.facturas[0];
      }
      vm.reglas = vm.cuenta.rules.filter(
        element => element.type == vm.rule_types.PRIVADO
      );
      angular.forEach(vm.cuenta.rules, function (item) {
        if (
          item.type == rule_types.PRIVADO &&
          vm.division.id == item.division_id
        ) {
          vm.rule = item.amount / 100;
          index = item.amount / 100;
        }
      });
      if(vm.reglas.length == 1){
        vm.regla = vm.reglas[0];
        selectRule();
      }
    }

    return index;
  }

  // seleccionar regla
  function selectAmountRule(item) {
    var index = 0;
    if (vm.regla) {
      if (
        vm.regla.type == rule_types.PRIVADO &&
        vm.division.id == vm.regla.division_id
      ) {
        vm.rule = vm.regla.amount / 100;
        index = vm.regla.amount / 100;
      }
    }
    return index;
  }

  function selectRule(item) {
    vm.factura = null;
    movimientosGenerales();
  }

  // seleccionar comunidad
  function selectCommunity() {
    vm.divisiones =
      vm.comunidad.divisions.length > 0 ? vm.comunidad.divisions : null;
    // --
    vm.cuentas = vm.comunidad.accounts.filter(
      element => element.rules.length > 0
    );
    if (vm.cuentas.length == 1){
      vm.cuenta = vm.cuentas[0];
      vm.selectAccount();
    }
  }

  function changeDate() {
    vm.gastos = null;
    vm.regla = null;
    vm.factura = null;
  }

  // upload file
  function load(item) {
    vm.files = item;
    vm.hasFile = vm.files && vm.files.length === 1;
    $rootScope.$apply();
  }

  function selectMonth() {
    storeService.set("month", vm.month);
    $rootScope.$broadcast("update-auth-data");
    selectCommunity();
  }

  function selectYear() {
    storeService.set("year", vm.year);
    $rootScope.$broadcast("update-auth-data");
    selectCommunity();
  }


  // comunidades
  async function comunidades() {
    try {
      const response = await comunidadDataService.all();
      const communities = response.data;
      vm.comunidades = communities;
      // --
      vm.comunidad = communities[comunidad(communities)];
      if(vm.comunidad.divisions.length == 1){
        vm.division = vm.comunidad.divisions[0];
        vm.propiedades = vm.division.properties.filter(
          element => element.type == "departamento"
        );
      }
      if (communities.length > 0) {
        selectCommunity();
      }
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function (item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  function addMedition(ev, item){
    $mdDialog
      .show({
        controller: meditionModalController,
        templateUrl: "views/admin/medicion/modal/add_medition.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: "true",
        locals: {
          data: item,
          month: vm.month,
          year: vm.year,
          community: vm.comunidad,
          account: vm.cuenta,
          division: vm.division,
          rule: vm.regla,
          option: false,
          url: ""
        },
        onRemoving: function (event, removePromise) {
          movimientosGenerales(1);
          loading.end();
        }
      }).then(function (response) {
        console.log(response);
      });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: true,
      label: "Mediciones"
    };
  }

  // view file
  function view_image(ev, item) {
    console.log(item);
    var url = (env.DOMAIN + env.API_PATH + "gastos/view_medition/" + item.id);
    // document.location = env.DOMAIN + env.API_PATH + "gastos/view_medition/" + item.id;
    // document.location  = env.DOMAIN + env.API_PATH + filename;
    // $mdDialog.show(url);
    // $mdDialog
    //   .show({
    //     controller: meditionModalController,
    //     templateUrl: "views/admin/medicion/modal/view_medition.html",
    //     parent: angular.element(document.body),
    //     targetEvent: ev,
    //     clickOutsideToClose: false,
    //     fullscreen: "true",
    //     locals: {
    //       url: url,
    //     }
    //   })
    //   .then(function (response) {
    //     if (response) {
    //       create([response.data]);
    //     }
    //   });
    window.open(url+"?window=yes", "_blank", "location=0,titlebar=0,toolbar=0,scrollbars=0,resizable=no,top=0,left=50%,width=500,height=500");
  }

  // get month string
  function month_str() {
    var data = vm.month;
    if (data) {
      if (data == 1) return months.ENERO;
      else if (data == 2) return months.FEBRERO;
      else if (data == 3) return months.MARZO;
      else if (data == 4) return months.ABRIL;
      else if (data == 5) return months.MAYO;
      else if (data == 6) return months.JUNIO;
      else if (data == 7) return months.JULIO;
      else if (data == 8) return months.AGOSTO;
      else if (data == 9) return months.SEPTIEMBRE;
      else if (data == 10) return months.OCTUBRE;
      else if (data == 11) return months.NOVIEMBRE;
      else if (data == 12) return months.DICIEMBRE;
    }
  }
}
