angular
    .module('app')
    .service('mailDataService', mailDataService);

function mailDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('mails');
        },
        get: function (mail) {
            return dataService.httpGet('mails/' + mail.id);
        },
        post: function (mail) {
            return dataService.httpPost('mails', mail);
        },
        put: function (mail) {
            return dataService.httpPut('mails/' + mail.id, mail);
        },
        delete: function (mail) {
            return dataService.httpDelete('mails/' + mail.id);
        }
    };
}