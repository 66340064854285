angular
  .module("app")
  .controller("estacionamientoController", estacionamientoController);

function estacionamientoController(
  $state,
  $stateParams,
  $scope,
  $rootScope,
  $mdDialog,
  estacionamientoDataService,
  estacionamientoReservaDataService,
  comunidadDataService,
  confirmService,
  loading,
  access,
  toastr,
  text
) {
  var vm = this;
  vm.permission = access;
  vm.estacionamiento = {
    id: $stateParams.id
  };
  // --
  vm.create = create;
  vm.edit = edit;
  vm.addReserva = addReserva;
  vm.destroy = destroy;
  vm.selectCommunity = selectCommunity;

  init();

  // iniciar
  function init() {
    if (vm.estacionamiento.id) {
      estacionamientoDataService
        .get(vm.estacionamiento)
        .then(function(response) {
          vm.estacionamiento = response.data;
          title();
          comunidades();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
      comunidades();
    }
  }

  // editar
  function edit() {
    loading.init();
    estacionamientoDataService
      .put(vm.estacionamiento)
      .then(function(response) {
        $state.go("app.estacionamiento", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // create
  function create() {
    loading.init();
    estacionamientoDataService
      .post(vm.estacionamiento)
      .then(function(response) {
        $state.go("app.estacionamiento", {
          id: response.data.id
        });
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // create o edit modal archivo
  function addReserva(ev, item) {
    $mdDialog
      .show({
        controller: estacionamientoModalController,
        templateUrl: "views/admin/estacionamiento/modal/reserve_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: vm.estacionamiento,
          value: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        // eliminar estacionamiento
        disabled(item);
      },
      function() {}
    );
  }

  // disabled
  function disabled(item) {
    loading.init();
    estacionamientoReservaDataService
      .delete(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // select community
  function selectCommunity() {
    angular.forEach(vm.comunidades, function(value) {
      if (value.id == vm.estacionamiento.community_id) {
        vm.divisiones = value.divisions;
      }
    });
  }

  // comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        var index = comunidad(response.data);
        // --
        if (!vm.estacionamiento.id) {
          // var index = comunidad(response.data);
          vm.estacionamiento.community_id =
            response.data.length > 0 ? response.data[index].id : null;
          vm.divisiones =
            response.data.length > 0 ? response.data[index].divisions : null;
        } else {
          angular.forEach(response.data, function(item) {
            if (item.id == vm.estacionamiento.community_id) {
              vm.divisiones = item.divisions;
            }
          });
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // socket
  var listenerCleanFn = $rootScope.$on("emit-socket", function(event, data) {
    init();
    console.log(data.data);
  });

  $scope.$on("$destroy", function() {
    listenerCleanFn();
  });

  // title
  function title() {
    vm.type = vm.estacionamiento.id ? "Editar" : "Nueva";
    vm.item = {
      backUrl: "app.estacionamientos",
      newUrl: "",
      label: vm.type + " Estacionamiento",
      name: vm.estacionamiento.id ? vm.estacionamiento.name.toUpperCase() : ""
    };
  }
}
