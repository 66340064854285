angular
    .module('app')
    .service('informacionDataService', informacionDataService);

function informacionDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('informaciones', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (informacion) {
            return dataService.httpGet('informaciones/' + informacion.id);
        },
        post: function (informacion) {
            return dataService.httpPost('informaciones', informacion);
        },
        put: function (informacion) {
            return dataService.httpPut('informaciones/' + informacion.id, informacion);
        },
        delete: function (informacion) {
            return dataService.httpDelete('informaciones/' + informacion.id);
        },

        // --
        get_division: function (propiedad) {
            return dataService.httpGet('informaciones/division/' + propiedad.division_id);
        },
    };
}