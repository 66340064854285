angular
    .module('app')
    .service('foroDataService', foroDataService);

function foroDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('foro', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (foro) {
            return dataService.httpGet('foro/' + foro.id);
        },
        post: function (foro) {
            return dataService.httpPost('foro', foro);
        },
        put: function (foro) {
            return dataService.httpPut('foro/' + foro.id, foro);
        },
        delete: function (foro) {
            return dataService.httpDelete('foro/' + foro.id);
        },
        // --
        get_community: function (community, division) {
            return dataService.httpGet('foro/' + community.id + '/' + division.id);
        },
    };
}