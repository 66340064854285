angular
  .module("app")
  .controller("cobranzasController", cobranzasController);

function cobranzasController(
  $state,
  $rootScope,
  comunidadDataService,
  propiedadDataService,
  $mdDialog,
  confirmService,
  gaService,
  access,
  env,
  loading,
  toastr,
  text
) {
  var vm = this;
  vm.propiedades = [];
  vm.selected = [];
  vm.mails_level_1 = [];
  vm.mails_level_2 = [];
  vm.mails_level_3 = [];
  vm.alert_colors = ['','#a5d46a', '#ffdf80', '#ffa080', '#ff8d67', '#ff7a4d', '#ff6734', '#ff541b', '#ff4101','#e73a00'];

  vm.query = {
    order: "number",
    limit: 500,
    page: 1,
    search: ""
  };

  // ---
  vm.edit = edit;
  vm.destroy = destroy;
  vm.excelAlicuotas = excelAlicuotas;

  vm.collect_mails = collect_mails;
  vm.send_mails = send_mails;
  vm.view_history = view_history;
  vm.addPayList = addPayList;
  vm.view_pdf = view_pdf;

  init();

  // inicio
  function init() {
    title();
    getData();
    vm.permission = access;
  }

  // edit item
  function edit(item) {
    $state.go("app.propiedad", {
      id: item.id
    });
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        trash(item);
      },
      function() {}
    );
  }


  // get data
  function getData() {
    propiedadDataService
      .all_parents(vm.query)
      .then(function(response) {
        console.log(response);
        vm.propiedades = response.data;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function collect_mails(){
    vm.mails_level_1 = [];
    vm.mails_level_2 = [];
    vm.mails_level_3 = [];
    // console.log(vm.propiedades);
    angular.forEach(vm.propiedades, function(propiedad, key){
      if(propiedad.send_mail_1){
        vm.mails_level_1.push(propiedad.id);
      }
      if(propiedad.send_mail_2){
        vm.mails_level_2.push(propiedad.id);
      }
      if(propiedad.send_mail_3){
        vm.mails_level_3.push(propiedad.id);
      }
    });
  }

  function send_mails(){
    loading.init();
    var listado = {
      level_1: vm.mails_level_1,
      level_2: vm.mails_level_2,
      level_3: vm.mails_level_3,
    }
    propiedadDataService.send_collect_emails(listado)
      .then(function(response) {
        //console.log(response.data);
        toastr.success(text.API_EXITO);
        gaService.send_event('envio_cartas_cobranza', 'cobranza');
        //reset values
        vm.mails_level_1 = [];
        vm.mails_level_2 = [];
        vm.mails_level_3 = [];
        getData();
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  function view_history(item){
    var collect_mails_sent = [];
    //console.log(item.id);
    propiedadDataService.get_collect_history(item)
      .then(function(response) {
        collect_mails_sent = response.data;
        $mdDialog
          .show({
            controller: cobranzaModalController,
            templateUrl: "views/admin/cobranzas/modal/history_modal.html",
            parent: angular.element(document.body),
            targetEvent: "ev",
            clickOutsideToClose: false,
            fullscreen: false,
            locals: {
              data: collect_mails_sent
            }
          })
          .then(function(response) {
            if (response) {
              getData();
            }
          });
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function view_pdf(nivel){
    if (nivel >= 1 && nivel <= 3){
      var url = env.DOMAIN + env.API_PATH + "carta_cobranza/" + $rootScope.currentCommunity.id+"/"+nivel;
      window.open(url, "_blank");
    } else{
      console.log("Nivel de carta equivocado");
    }
  }

  // excel bills
  function excelAlicuotas() {
    document.location = env.DOMAIN + env.API_PATH + "files/export/alicuotas/" + $rootScope.currentCommunity.id;
  }

  // mostrar modal de pagos realizados
  function addPayList(item) {
    var gastos = null;
    $mdDialog
      .show({
        preserveScope: true,
        controller: pagosModalController,
        templateUrl: "views/admin/pago/modal/payments_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: item,
          callback: gastos
        }
      })
      .then(function(response) {
        if (response) {
          //gastos();
          loading.end();
        }
      });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "calendar",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: false,
      label: "Cobranza",
      service: propiedadDataService
    };
  }

  // table events
  vm.onReorder = function() {
    getData();
  };

  vm.onPaginate = function() {
    getData();
  };

  vm.onSearch = function() {
    getData();
  };
}
