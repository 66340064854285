angular
    .module('app')
    .controller('costPaidController', costPaidController);

function costPaidController($scope, $mdDialog, propiedadDataService, method_pay, toastr, text, data) {

    // --
    $scope.cancel = cancel;
    $scope.create = create;
    $scope.update = update;

    init();

    // --
    function init() {
        $scope.pays = method_pay;
        if (data['web_pay'] != null) {
            $scope.data = {
                p_description: data['web_pay']['description'],
                p_buy_order: data['web_pay']['buyOrder'],
                p_card_number: data['web_pay']['cardNumber'],
                p_commerce_code: data['web_pay']['commerceCode'],
                p_amount: data['web_pay']['amount'],
                amount: data['amount'],
                morosidad: data['morosidad'],
                is_paid: data['is_paid'],
            }
        } else {
            $scope.data = data;
            
        }
    }

    function cancel() {
        $mdDialog.hide()
    }

    function create() {
        $sendData = $scope.data;
        if( !data['p_type'] == 'FL' && !data['p_type'] == 'CH')
        {
            $sendData['p_commerce_code'] = '00';
        }
 

        propiedadDataService.post_pago_manual($sendData)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    function update() {
        propiedadDataService.put_pago_manual(data)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }
}