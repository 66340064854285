angular
    .module('app')
    .factory('mySocket', mySocket);

function mySocket(socketFactory, storeService, env) {
    // function mySocket(socketFactory, storeService, env) {
    //     .factory('mySocket', mySocket);

    // function mySocket(socketFactory, storeService, env) {
    // var user = storeService.get('user');
    // var myIoSocket = io.connect(env.DOMAIN_SOCKET, {
    //     query: 'user=' + (user != null ? user.id : null),
    // });
    // mySocket = socketFactory({
    //     ioSocket: myIoSocket,
    // });
    // return mySocket;

    // return socketFactory();
    return {
        user: storeService.get('user'),
        socket: function () {
            try {
                var self = this;
                self.user = storeService.get('user');
                var myIoSocket = io.connect(env.DOMAIN_SOCKET, {
                    // secure: false,
                    // verify: false,
                    // reconnect: true,
                    // rejectUnauthorized: false,
                    query: 'user=' + (self.user != null ? self.user.id : null) + '&company=' + (self.user != null ? self.user.company_id : null),
                });
                mySocket = socketFactory({
                    ioSocket: myIoSocket,
                });
                return mySocket;
            } catch (error) {
                console.log(error)
            }
        },

    }
};