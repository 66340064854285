angular.module("app").service("comunidadDataService", comunidadDataService);

function comunidadDataService(dataService) {
  return {
    all: function () {
      return dataService.httpGet("comunidades");
    },
    allMin: function () {
      return dataService.httpGet("comunidades?min=true");
    },
    allFilter: function (query) {
      return dataService.httpGet(`comunidades`, { params: query });
    },
    get: function (comunidad) {
      return dataService.httpGet("comunidades/" + comunidad.id);
    },
    getCurrent: function () {
      return dataService.httpGet("comunidad");
    },
    getWithAllData: function (communityId) {
      return dataService.httpGet("comunidades", { params: { communityId } })
    },
    post: function (comunidad) {
      return dataService.httpPost("comunidades", comunidad);
    },
    put: function (comunidad) {
      return dataService.httpPut("comunidades/" + comunidad.id, comunidad);
    },
    delete: function (comunidad) {
      return dataService.httpDelete("comunidades/" + comunidad.id);
    },
    // --
    post_divisions: function (divisions) {
      return dataService.httpPost("comunidades/divisiones", divisions);
    },
    post_accounts: function (accounts) {
      return dataService.httpPost("comunidades/cuentas", accounts);
    },
    export_excel: function (name) {
      return dataService.httpGet("files/export/" + name);
    },
    allWithDivisions: function () {
      return dataService.httpGet('communities/divisions');
    },
    updateSoftland: function (communityId, data) {
      return dataService.httpPost(`comunidades/${communityId}/softland`, data);
    },
    getCategories: function (communityId) {
      return dataService.httpGet(`comunidades/${communityId}/categories`);
    },
    getDocumentTypes: function (communityId) {
      return dataService.httpGet(`comunidades/${communityId}/document-types`);
    },
    getActiveCommunities: function() {
      return dataService.httpPost("comunidades/get_active");
    },
  };
}
