angular.module("app").controller("navbarController", navbarController);

function navbarController(
  $scope,
  $state,
  $rootScope,
  $mdSidenav,
  $window,
  $mdDialog,
  authService,
  storeService,
  access,
  months,
  auth_roles,
  notification,
  toastr,
  text,
  env
) {
  var vm = this;
  vm.item = {
    title: null,
    back: false,
    backUrl: null
  };
  vm.env = env;

  // --
  vm.logout = logout;
  vm.show = show;
  vm.toogle = toogle;
  vm.active = active;
  vm.selectComunidad = selectComunidad;
  vm.goPerfil = goPerfil;
  vm.month = month;

  init();

  // carga inicio
  function init() {
    vm.sidevar_xs = true;
    vm.permission = access;
    $rootScope.$broadcast("update-auth-data");
    if (authService.isAuthenticated()) {
      $rootScope.$broadcast("update-socket-data", {
        end: true,
        init: true
      });
      if(storeService.isMobile()){
        // true for mobile device
      }else{
        notification.init();
      }
      //notification.init();
    }
  }

  // cerrar sesion
  function logout() {
    $state.go("logout");
  }

  function show(item) {
    $state.go(item);
  }

  function goPerfil(item) {
    storeService.set("role", null);
    $rootScope.$broadcast("update-auth-data");
    $state.go(item);
  }

  function month() {
    var index = 1;
    var month = null;
    angular.forEach(months, function (value) {
      if (index == $rootScope.currentMonth) {
        month = value;
        index++;
      } else {
        index++;
      }
    });
    return month;
  }
  // open menu
  function toogle() {
    $mdSidenav("left-xs").toggle();
  }

  // url active
  function active() {
    return $window.location.hash;
  }

  // seleccionar comunidad
  function selectComunidad(ev) {
    $mdDialog
      .show({
        controller: communityModalController,
        templateUrl: "views/layout/modal/detail_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: $rootScope.currentCommunity
        }
      })
      .then(function (response) {
        if (response) {
          $state.go(
            "app.dashboard",
            {},
            {
              reload: true
            }
          );
        }
        $rootScope.$broadcast("update-auth-data");
      });
  }

  // socket
  var listenerCleanFn = $rootScope.$on("emit-socket", function (event, data) {
  
    var notifications = storeService.get("notification");
  
    if (data.type == "message") {
      if ($window.location.hash.indexOf("chat/") === -1) {
        toastr.info(text.NOTIFICATION_MESSAGE);
        notification.show(text.NOTIFICATION_MESSAGE);
        notifications.messages++;
        storeService.set("notification", notifications);
        $rootScope.$broadcast("update-auth-data");
        console.log(data);
      }
    } else if (data.type == "information") {
      toastr.info(text.NOTIFICATION_INFORMATION);
      notification.show(text.NOTIFICATION_INFORMATION);
      notifications.informations++;
      storeService.set("notification", notifications);
      $rootScope.$broadcast("update-auth-data");
      console.log(data);
    } else if (data.type == "forum") {
      toastr.info(text.NOTIFICATION_FORUM);
      notification.show(text.NOTIFICATION_FORUM);
      notifications.forums++;
      storeService.set("notification", notifications);
      $rootScope.$broadcast("update-auth-data");
      console.log(data);
    } else if (data.type == "repository") {
      toastr.info(text.NOTIFICATION_REPOSITORY);
      notification.show(text.NOTIFICATION_REPOSITORY);
      notifications.repositories++;
      storeService.set("notification", notifications);
      $rootScope.$broadcast("update-auth-data");
      console.log(data);
    } else if (data.type == "cost") {
      toastr.info(text.NOTIFICATION_COSTS);
      notification.show(text.NOTIFICATION_COSTS);
      notifications.costs++;
      storeService.set("notification", notifications);
      $rootScope.$broadcast("update-auth-data");
      console.log(data);
    } else if (data.type == "reservation") {
      toastr.info(text.NOTIFICATION_RESERVATION);
      notification.show(text.NOTIFICATION_RESERVATION);
      notifications.reservations++;
      storeService.set("notification", notifications);
      $rootScope.$broadcast("update-auth-data");
      console.log(data);
    } else if (data.type == "reserva") {
      toastr.info(text.NOTIFICATION_RESERVATION);
      notification.show(text.NOTIFICATION_RESERVATION);
      notifications.reservations++;
      storeService.set("notification", notifications);
      $rootScope.$broadcast("update-auth-data");
      console.log(data);
    } else if (data.type == "parking") {
      // solo not users coprietarios view
      if (!access.isCopropietario()) {
        if (data.data.active > 0) {
          toastr.info(text.NOTIFICATION_PARKING);
          notification.show(text.NOTIFICATION_PARKING);
        }
        notifications.parkings = data.data.active;
        storeService.set("notification", notifications);
        $rootScope.$broadcast("update-auth-data");
        console.log(data);
      }
    } else if (data.type == "parking-reserve") {
      if (data.data.active > 0) {
        toastr.info(text.NOTIFICATION_PARKING);
        notification.show(text.NOTIFICATION_PARKING);
        notifications.parkings += data.data.active;
        storeService.set("notification", notifications);
        $rootScope.$broadcast("update-auth-data");
      }
      if (data.data.inactive > 0) {
        notifications.parkings -= data.data.inactive;
        storeService.set("notification", notifications);
        $rootScope.$broadcast("update-auth-data");
      }
      console.log(data);
    } else {
      toastr.info(text.NOTIFICATION_MAIL);
      notification.show(text.NOTIFICATION_MAIL);
      notifications.mails++;
      storeService.set("notification", notifications);
      $rootScope.$broadcast("update-auth-data");
      console.log(data);
    }
  });

  $scope.$on("$destroy", function () {
    listenerCleanFn();
  });

  // menu
  vm.menu = [
    // Empresas
    {
      title: "Empresas",
      icon: "store",
      url: "empresas",
      access: [
        // auth_roles.ROOT
      ],
      divider: false
    },
    // Dashboard
    {
      title: "Escritorio",
      icon: "dashboard",
      url: "dashboard",
      access: [
        //auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.CONCERJE,
      ],
      divider: true
    },
    // comunidades
    {
      title: "Comunidades",
      icon: "location_city",
      url: "comunidades",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    {
      title: "Usuarios",
      icon: "people_outline",
      url: "usuarios",
      access: [
        auth_roles.ROOT,
        // auth_roles.ADMIN,
        // auth_roles.ANALISTA,
        // auth_roles.CONTABILIDAD,
        // auth_roles.TESORERIA
      ],
      divider: false
    },
    // divisiones
    {
      title: "Divisiones",
      icon: "account_balance",
      url: "divisiones",
      access: [
        //auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    // Personas
    {
      title: "Personas",
      icon: "group",
      url: "personas",
      access: [
        //auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    // Propiedades
    {
      title: "Propiedades",
      icon: "domain",
      url: "propiedades",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    {
      title: "Fondos",
      icon: "bookmarks",
      url: "fondos",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: true
    },
    // Unidades
    // {
    //     title: 'Unidades',
    //     icon: 'storage',
    //     url: 'unidades',
    //     access: [auth_roles.ADMIN, auth_roles.ANALISTA, auth_roles.CONTABILIDAD, auth_roles.TESORERIA],
    //     divider: true,
    // },
    // Categorias cuentas
    {
      title: "Categorias",
      icon: "layers",
      url: "categorias",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    // Proveedores
    {
      title: "Proveedores",
      icon: "local_mall",
      url: "proveedores",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    // Cuentas
    {
      title: "Cuentas",
      icon: "local_offer",
      url: "cuentas",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    // Reglas de cuentas
    {
      title: "Reglas",
      icon: "tune",
      url: "reglas",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: true
    },
    // remuneraciones
    {
      title: "Remuneraciones",
      icon: "monetization_on",
      url: "remuneraciones",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    // Facturas
    {
      title: "Egresos",
      icon: "credit_card",
      url: "egresos",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // gastos privados
    {
      title: "Gastos Particulares",
      icon: "shopping_cart",
      url: "gastos",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // gastos privados
    {
      title: "Mediciones",
      icon: "settings_input_component",
      url: "mediciones",
      access: [
        // auth_roles.ADMIN,
        // auth_roles.ANALISTA,
        // auth_roles.CONTABILIDAD,
        // auth_roles.TESORERIA,
        // auth_roles.RECEPCION,
        // auth_roles.MAYORDOMO,
        auth_roles.CONCERJE
      ],
      divider: false
    },
    // ingresos
    {
      title: "Ingresos",
      icon: "account_balance_wallet",
      url: "ingresos",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Balance
    // {
    //   title: "Balance",
    //   icon: "equalizer",
    //   url: "balance",
    //   access: [
    //     auth_roles.ADMIN,
    //     auth_roles.ANALISTA,
    //     auth_roles.CONTABILIDAD,
    //     auth_roles.TESORERIA
    //   ],
    //   divider: false
    // },
    // Fondo Reserva
    // {
    //   title: "Fondo Reserva",
    //   icon: "money",
    //   url: "fondos",
    //   access: [
    //     auth_roles.ADMIN,
    //     auth_roles.ANALISTA,
    //     auth_roles.CONTABILIDAD,
    //     auth_roles.TESORERIA
    //   ],
    //   divider: true
    // },
    // Tesoreria
    {
      title: "Tesorería",
      icon: "money",
      url: "tesoreria",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
    },
    // Cartola
    {
      title: "Cartola",
      icon: "event",
      url: "cartola",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      divider: false
    },
    // gastos comunes
    {
      title: "Gastos Comunes",
      icon: "local_atm",
      url: "gastos_comunes",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: true
    },
    // Cobranzas
    {
      title: "Cobranzas",
      icon: "library_books",
      url: "cobranzas",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        //auth_roles.CONTABILIDAD,
        //auth_roles.TESORERIA,
        //auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Conciliacion
    {
      title: "Conciliacion Bancaria",
      icon: "monetization_on",
      url: "conciliacion",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        //auth_roles.RECEPCION,
        //auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: true
    },
    {
      title: "Reportería",
      icon: "list",
      url: "reportes",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        //auth_roles.RECEPCION,
        //auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: true
    },
    // Repositorio
    {
      title: "Repositorio",
      icon: "folder",
      url: "repositorios",
      access: [
       // auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Documentos
    {
      title: "Tipo documentos",
      icon: "file_copy",
      url: "tiposDocumento",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Informaciones
    {
      title: "Informaciones",
      icon: "rss_feed",
      url: "informaciones",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Recordatorio
    {
      title: "Recordatorio",
      icon: "notifications_active",
      url: "recordatorios",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Foro
    {
      title: "Foro",
      icon: "forum",
      url: "foros",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: true
    },
    // Horario
    {
      title: "Horario",
      icon: "schedule",
      url: "horario",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Reservas
    {
      title: "Reservas",
      icon: "event_note",
      url: "instalaciones",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      divider: false
    },
    // Estacionamiento
    /*     {
          title: "Estacionamientos",
          icon: "directions_car",
          url: "estacionamientos",
          access: [
            auth_roles.ADMIN,
            auth_roles.ANALISTA,
            auth_roles.CONTABILIDAD,
            auth_roles.TESORERIA,
            auth_roles.RECEPCION,
            auth_roles.MAYORDOMO,
            auth_roles.CONCERJE
          ],
          divider: false
        }, */
    // Ingreso
    // {
    //   title: "Acceso Visitas",
    //   icon: "import_contacts",
    //   url: "visitas",
    //   access: [
    //     auth_roles.ADMIN,
    //     auth_roles.ANALISTA,
    //     auth_roles.CONTABILIDAD,
    //     auth_roles.TESORERIA,
    //     auth_roles.RECEPCION,
    //     auth_roles.MAYORDOMO,
    //     auth_roles.CONCERJE
    //   ],
    //   divider: false
    // }
  ];
}
