angular
    .module('app')
    .controller('estacionamientoRWDController', estacionamientoRWDController);

function estacionamientoRWDController($scope, $rootScope, $state, estacionamientoReservaDataService,
    storeService, reserva_states, access, toastr, text) {
    var vm = this;
    // var notification = storeService.get('notification');
    vm.query = ['date', 'hour_from', 'hour_to'];
    vm.states = reserva_states;
    // --

    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        estacionamientos();
    }


    // load reservas
    function estacionamientos() {
        estacionamientoReservaDataService.get_division({
                id: storeService.get('property').division_id
            })
            .then(function (response) {
                vm.reservas = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // socket
    var listenerCleanFn = $rootScope.$on('emit-socket', function (event, data) {
        estacionamientos();
        console.log(data.data);
    });

    $scope.$on('$destroy', function () {
        listenerCleanFn();
    });

    // load title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.servicios_rwd',
            title: 'Estacionamiento'
        };
    }
}