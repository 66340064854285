angular
    .module('app')
    .service('gaService', gaService);

function gaService($rootScope, storeService,$location, env) {
    return {
        config: function(){
            console.log("gaService config");
            if(!(env.API_ENVIRONMENT == 'DEV')){
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-9SZZQ75WSF');
            }
        },
        page_view: function (toState, toParams) {
            console.log("gaService page_view", toState);
            if(!(env.API_ENVIRONMENT == 'DEV')){
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('event', 'page_view', {
                  page_title: toState.url.replace('/auth','').replace(':id',toParams.id),
                  page_location: toState.url
                });
            }
        },
        send_exception: function (message) {
            console.log("gaService message", message);
            if(!(env.API_ENVIRONMENT == 'DEV')){
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('event', 'exception', {
                  'description': message,
                  'fatal': false   // set to true if the error is fatal
                });
            }
            console.log("gaService finish", true);
        },
        send_event: function (e_name, category = '') {
            console.log("gaService e_name", e_name);
            console.log("gaService category", category);
            if(!(env.API_ENVIRONMENT == 'DEV')){
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('event', e_name, {
                  'event_label': ($rootScope.currentCommunity.name+" "+storeService.get("month")+"-"+storeService.get("year")),
                  'event_category': category,
                });
            }
        },
        send_exception: function (message) {
            console.log("gaService message", message);
            if(!(env.API_ENVIRONMENT == 'DEV')){
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('event', 'exception', {
                  'description': message,
                  'fatal': false   // set to true if the error is fatal
                });
            }
        },
        // getUrl: function() {
        //     return env.DOMAIN + env.BASE_PATH;
        // }
    };
};