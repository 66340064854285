angular
  .module("app")
  .controller("detailModalController", detailModalController);

function detailModalController($scope, $mdDialog, $sce, env, file) {
  // ---
  $scope.cancel = cancel;

  init();

  function init() {
    $scope.url = file
      ? $sce.trustAsResourceUrl(
          env.DOMAIN + env.FILE_PATH + file.id + "." + file.type
        )
      : "";
  }

  // salir del modal
  function cancel() {
    $mdDialog.hide();
  }
}
