angular
    .module('app')
    .controller('gastoHistorialRWDController', gastoHistorialRWDController);

function gastoHistorialRWDController($scope, $state, webpayDataService, gastoComunDataService,
    storeService, months, access, toastr, text) {
    var vm = this;
    var date = new Date();

    // --
    vm.show = show;
    vm.pay = pay;
    vm.detail = detail;
    vm.print_detail = print_detail;

    init();

    // inicio
    function init() {
        title();
        vm.comunidad = storeService.get('community');
        vm.propiedad = storeService.get('property');
        // -- state cost
        vm.permission = access;
        vm.date = date;
        loadDetalles();
    }

    function show(item) {
        $state.go('dev.' + item);
    }

    function loadDetalles() {
        gastoComunDataService.get_resume_hitorico(vm.comunidad, vm.propiedad)
            .then(function (response) {
                vm.detalles = angular.forEach(response.data, function (item, key) {
                    response.data[key].month_str = month_str(item.month);
                    response.data[key].transactionDate = new Date(item.transactionDate);
                });
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // realizar el pago
    function pay(ev, item) {
        init_pay(item);
        webpayDataService.init(item)
            .then(function (response) {
                // --
                var form = document.createElement("form");
                form.name = 'form';
                form.method = 'post';
                form.target = '_self';
                form.action = response.data.url;
                // --
                var claveInput = document.createElement("input");
                claveInput.type = 'hidden';
                claveInput.name = 'TBK_TOKEN';
                claveInput.value = response.data.token;
                form.appendChild(claveInput);
                document.body.appendChild(form);
                form.submit();
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });

    }

    // create pay localstorage
    function init_pay(item) {
        storeService.set('pay', item);
    }

    // redirect detail
    function detail(item) {
        $state.go('app.gasto_detail_rwd', {
            month: item.month,
            year: item.year
        });
    }

    // print detail pay
    function print_detail(item) {
        storeService.set('month', item.month);
        storeService.set('year', item.year);
        document.location = '#/auth/print/gastos/boleta/' + item.id;
    }

    // load title sub-toolbar
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.gasto_rwd',
            title: 'Historial',
        };
    }

    // get month string
    function month_str(month) {
        if (month) {
            if (month == 1) return months.ENERO;
            else if (month == 1) return months.ENERO;
            else if (month == 2) return months.FEBRERO;
            else if (month == 3) return months.MARZO;
            else if (month == 4) return months.ABRIL;
            else if (month == 5) return months.MAYO;
            else if (month == 6) return months.JUNIO;
            else if (month == 7) return months.JULIO;
            else if (month == 8) return months.AGOSTO;
            else if (month == 9) return months.SEPTIEMBRE;
            else if (month == 10) return months.OCTUBRE;
            else if (month == 11) return months.NOVIEMBRE;
            else if (month == 12) return months.DICIEMBRE;
        }
    }
}