angular
    .module('app')
    .controller('reglasController', reglasController);

function reglasController(
    $state,
    $rootScope,
    reglaDataService, 
    comunidadDataService,
    confirmService, 
    $mdDialog,
    loading, 
    access, 
    toastr, 
    text) {
    var vm = this;
    vm.selected = [];
    vm.is_selected = true;
    vm.query = {
        order: ['community.name', 'name', 'amount'],
        limit: 10,
        page: 1
    };

    // ---
    vm.edit = edit;
    vm.destroy = destroy;
    vm.get_reglas = get_reglas;
    vm.new_regla = new_regla;

    init();

    // iniciar
    function init() {
        title();
        comunidades();
        vm.permission = access;
    }

    function get_reglas(){
        vm.reglas = [];
        if(vm.division){
            reglaDataService.all_division(vm.division.id)
                .then(function (response) {
                    console.log(response);
                    vm.reglas = response.data;
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else{
            console.log("No hay division asignada aún");
        }
    }

    function new_regla() {
        var params = {
            community_id: vm.comunidad.id,
            division_id: vm.division.id,
        };
        $state.go(vm.item.newUrl, params);
    }

    // edit item
    function edit(item) {
        $state.go('app.regla', {
            community_id: item.community_id,
            division_id: item.division_id,
            account_id: item.account_id,
        });
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            trash(item);
        }, function () {});
    }

    // eliminar el item seleccionado
    function trash(item) {
        loading.init();
        reglaDataService.delete(item).then(function (response) {
            init();
            toastr.success(text.API_EXITO);
            loading.end();
        }).catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
        });
    }

    // load title sub-toolbar
    function title() {
        vm.item = {
            icon: '',
            backUrl: '',
            newUrl: 'app.regla',
            backActive: false,
            newActive: true,
            label: 'Reglas',
            //division: vm.division.id,
        };
    }

    // comunidades
    function comunidades() {
        comunidadDataService
          .all()
          .then(function(response) {
            vm.comunidades = response.data;
            // vm.comunidad = response.data[0];
            // vm.division = response.data[0].divisions[0];
            // --
            var index = comunidad(response.data);
            vm.comunidad = response.data[index];
            vm.division = response.data.length > 0 ? response.data[index].divisions[0] : null;
            get_reglas();
          })
          .catch(function() {
            loading.end();
            toastr.error(text.API_ERROR);
          });
    }

    // get comunidad rootScope
    function comunidad(items) {
        var index = 0;
        if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
          angular.forEach(items, function(item, key) {
            if (item.id == $rootScope.currentCommunity.id) {
              index = key;
              vm.is_current = true;
            }
          });
        } else {
          vm.is_current = false;
        }
        return index;
    }
}