angular
  .module("app")
  .controller("pagosTesoreriaModalController", pagosTesoreriaModalController);

function pagosTesoreriaModalController(
  $scope,
  $mdDialog,
  text,
  toastr,
  tesoreriaDataService,
  data,
  env
) {
  $scope.is_confirm = false;
  $scope.text = text;
  // --
  $scope.destroy = destroy;
  $scope.confirm = confirm;
  $scope.download_voucher = download_voucher;
  $scope.direct_print = direct_print;
  $scope.cancel = cancel;
  $scope.back = back;

  init();

  // --
  function init() {
    $scope.pays = data.bill_pays;
    //console.log(data.bill_pays);
  }

  function destroy() {
    tesoreriaDataService
      .delete($scope.to_deleted)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // confirmar para eliminar item
  function confirm(item) {
    $scope.to_deleted = item;
    $scope.is_confirm = true;
  }

  // bajar comprobante de egresos
  function download_voucher(item) {
    document.location = env.DOMAIN + env.API_PATH + "files/export/comprobante/" + item.id;
  }

  // bajar comprobante de egresos
  function direct_print(item) {
    var url = env.DOMAIN + env.API_PATH + "files/export/comprobante/" + item.id;
    w=window.open(url);
    w.print();
    w.close();
  }

  function back() {
    $scope.to_deleted = null;
    $scope.is_confirm = false;
  }

  function cancel() {
    $mdDialog.hide();
  }
}
