angular
  .module("app")
  .controller("conciliacionController", conciliacionController);

function conciliacionController(
  $state,
  $element,
  $rootScope,
  comunidadDataService,
  conciliacionDataService,
  $mdDialog,
  confirmService,
  access,
  env,
  loading,
  toastr,
  text
) {
  var vm = this;

  vm.is_current = $rootScope.currentCommunity && $rootScope.currentCommunity.id;
  vm.list = [];
  vm.movements = [];
  vm.properties = [];
  vm.alert_colors = ['','#a5d46a', '#ffdf80', '#ffa080', '#ff8d67', '#ff7a4d', '#ff6734', '#ff541b', '#ff4101','#e73a00'];
  vm.assignProperty = assignProperty;
  vm.deAssignProperty = deAssignProperty;
  vm.query = {
    order: "-post_date",
    limit: 10,
    page: 1,
    search: ""
  };

  vm.env = env;
  vm.filter_options = [['',''],["Conciliado",'Concilado'],["Pendiente",'Pendiente']];
  vm.filter_status = '';
  vm.clear = clear;
  vm.search = search;
  vm.pullData = pullData;
  vm.keydownen = keydownen;
  vm.showPay = showPay;

  init();

  $rootScope.hasFilter = function (item) {
    var search_result = true;
    var state_result = true;
    if (vm.query.search != ""){
      var search = vm.query.search.toLowerCase();
      search_result = ((item.reference_id == null ? "" : item.reference_id.toLowerCase() ).includes(search)
        || (item.sender_institution_name.toLowerCase() ).includes(search)
        || (item.description.toLowerCase() ).includes(search)
        || (item.sender_holder_id.toLowerCase() ).includes(search)
        || (item.sender_holder_name.toLowerCase() ).includes(search)
      );
    }
    if (vm.filter_status !== ""){
      if("Conciliado" == vm.filter_status){
        state_result =  (item.property_id || !item.is_assignable);
      } else if(("Pendiente" == vm.filter_status)){
        state_result =  (!item.property_id && item.is_assignable);
      }
    } 
    return (search_result && state_result);
  };

  // inicio
  function init() {
    title();
    getData();
    vm.permission = access;
  }

  // edit item
  function edit(item) {
    $state.go("app.propiedad", {
      id: item.id
    });
  }


  // get data
  function getData() {
    loading.init();
    conciliacionDataService
      .listFintocMovements($rootScope.currentCommunity.id, vm.query)
      .then(function(response) {
        vm.list = response.data[0]
        vm.movements = response.data[0].data;
        vm.properties_list = response.data[1];
        vm.fintoc_last_request = response.data[2];
        vm.fintoc_secret_key = response.data[3];
        vm.fintoc_account_key = response.data[4];
        angular.forEach(vm.movements, function (movement) {
          movement.new_property_id = '';
          movement.type = (movement.type == "transfer" ? "Transferencia" : ( (movement.type == "check" ? "Cheque" : ("Depósito") ) ) );
        });
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // get data
  function pullData() {
    conciliacionDataService
      .getFintocMovements($rootScope.currentCommunity.id)
      .then(function(response) {
        getData();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get data
  function assignProperty(item) {
    conciliacionDataService
      .setFintocMovementsProperty(item)
      .then(function(response) {
        //console.log(response.data);
        toastr.success("Movimiento Conciliado");
        reset_index();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }
  // get data
  function deAssignProperty(item) {
    $mdDialog
      .show({
        preserveScope: true,
        controller: conciliacionModalController,
        templateUrl: "views/admin/conciliacion/modal/deassign_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: item,
          // callback: gastos
        }
      })
      .then(function(response) {
        if (response == "OK") {
          getData();
        }
      });

      // conciliacionDataService
      // .unsetFintocMovementsProperty(item)
      // .then(function(response) {
      //   //console.log(response.data);
      //   toastr.success("Movimiento Conciliado");
      //   reset_index();
      // })
      // .catch(function() {
      //   toastr.error(text.API_ERROR);
      // });
  }

  
// mostrar modal de pagos realizados

  function showPay(property, payment) {
    $mdDialog
      .show({
        preserveScope: true,
        controller: pagosModalController,
        templateUrl: "views/admin/pago/modal/payments_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          data: {
            property: property,
            payment: payment
          }
        }
      })
      .then(function(response) {
        getData();
      });
  }

  function reset_index(){
    vm.movements = [];
    getData();
  }

  function clear(item){
    console.log("CLEAR");
    item.search = '';
  }

  function search(item){
   // item.search = '';
  }

  function keydownen(event, item){
    var key = event.keyCode || event.charCode;
    if( key == 8 || key == 46 ){
      item.search = item.search.slice(0, -1);
    } else {
      // if(!(event.keyCode >= 65 && event.keyCode <= 90) || !(event.keyCode >= 97 && event.keyCode <= 122)){
      if((key >= 48 && key <= 57)){
        if (key){
          if (item.search){
            item.search += String.fromCharCode(key);
          } else{
            item.search = String.fromCharCode(key);
          }
        }
      } else{
        console.log("Fuera de rango");
      }
    }
   // item.search = '';
  }

  $element.find('input').on('keydown', function(ev) {
    ev.stopPropagation();
  });


  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "calendar",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: false,
      label: "Conciliación Bancaria",
      service: comunidadDataService
    };
  }

  // table events
  vm.onReorder = function() {
    getData();
  };

  vm.onPaginate = function() {
    getData();
  };

  vm.onSearch = function() {
    getData();
  };
}
