angular
  .module("app")
  .controller("providerModalController", providerModalController);

function providerModalController(
  $scope,
  $mdDialog,
  $rootScope,
  comunidadDataService,
  cuentaProveedorDataService,
  proveedorDataService,
  toastr,
  text,
  status,
  data
) {
  // --
  $scope.create = create;
  $scope.cancel = cancel;

  init();

  function init() {
    $scope.usuario = {
      id: null,
      account_id: data.id
    };
    comunidades();
    proveedores();
  }

  function create() {
    cuentaProveedorDataService
      .post({
        account_id: data.id,
        provider_id: $scope.provider.id
      })
      .then(function(response) {
        // validate status
        if (response.data.status == status.ERROR) {
          // error
          toastr.error(response.data.msg[0]);
        } else {
          // success
          toastr.success(text.API_EXITO);
          $mdDialog.hide({
            data: response.data
          });
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function proveedores() {
    proveedorDataService
      .all({ page: -1, limit: -1, search: -1, order: -1 })
      .then(function(response) {
        $scope.proveedores = response.data;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        $scope.comunidades = response.data;
        var index = comunidad(response.data);
        // --
        $scope.comunidad =
          response.data.length > 0 ? response.data[index] : null;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          $scope.is_current = true;
        }
      });
    } else {
      $scope.is_current = false;
    }
    return index;
  }

  function cancel() {
    $mdDialog.hide();
  }
}
