angular.module("app").controller("facturaController", facturaController);

function facturaController(
  $state,
  $stateParams,
  $rootScope,
  $mdDialog,
  facturaDataService,
  facturaFileDataService,
  comunidadDataService,
  categoriaDataService,
  gastoComunDataService,
  gastoDataService,
  gaService,
  toastr,
  storeService,
  access,
  confirmService,
  months,
  types_gastos,
  type_expense,
  type_categories,
  states_a,
  states_p,
  status,
  loading,
  text,
  tooltips,
  proveedorDataService,
  env,
  years
) {
  // --
  var vm = this;
  var date = new Date();
  vm.permission = access;
  vm.tooltips = tooltips;
  vm.months = months;
  vm.estados_a = states_a;
  vm.estados_p = states_p;
  vm.tipo_gastos = types_gastos;
  vm.tipo_egresos = type_expense;
  vm.tipo_categorias = type_categories;

  vm.selected = [];
  vm.propiedades = [];
  vm.is_selected = true;
  vm.query = {
    order: ["community.name", "title", "size"],
    limit: 50,
    page: 1
  };
  // ---
  vm.edit = edit;
  vm.create = create;
  vm.addFile = addFile;
  vm.addShare = addShare;
  vm.viewFile = viewFile;
  vm.destroy = destroy;
  vm.selectGasto = selectGasto;
  vm.splitBill = splitBill;
  vm.selectImagenes = selectImagenes;
  vm.selectComunidad = selectComunidad;

  vm.loadCommunities = loadCommunities;
  vm.loadDivisions = loadDivisions;
  vm.loadCategories = loadCategories;
  vm.loadProviders = loadProviders;
  vm.loadDocumentTypes = loadDocumentTypes;

  vm.is_current = $rootScope.currentCommunity && $rootScope.currentCommunity.id;

  vm.FILE_URL = env.DOMAIN + env.API_PATH + 'facturas/files/download';

  vm.cost_divisions = [];
  vm.sum_percent = 0;
  vm.sum_amount = 0;
  vm.sum_final_amount = 0;
  vm.sum_prorrateo_amount = 0;
  vm.sum_final_amount = 0;
  vm.sum_generales = sum_generales;
  vm.assign_cost_divisions = assign_cost_divisions;
  vm.factura_division_check = false;
  vm.gasto_general_state = '';
  init();

  // iniciar
  async function init() {
    if ($stateParams.id) {
      try {
        const response = await facturaDataService.get({ id: $stateParams.id });
        vm.factura = response.data;
        vm.factura.category = vm.factura.account.category;
        vm.factura.synchronize = true;
        //vm.factura.synchronize = vm.factura.synchronize === 1;

        selectGasto();
        // comunidades();
        // categorias();
        title();
      } catch (error) {
        toastr.error(text.API_ERROR);
      }
    } else {
      vm.factura = {
        month: storeService.get("month"),
        year: storeService.get("year"),
        community: $rootScope.currentCommunity || null
      };
      vm.factura.synchronize = true;
      title();
      // categorias();
      // comunidades();
    }
    vm.years = years(date);
    loadDivisions();
  }

  // editar
  async function edit() {
    loading.init();
    try {
      const factura = {};
      angular.extend(factura, vm.factura, {
        document_type_id: vm.factura.document_type
          ? vm.factura.document_type.id
          : null,
        community_id: vm.factura.community.id,
        division_id: vm.factura.division ? vm.factura.division.id : null,
        account_id: vm.factura.account.id,
        provider_id: vm.factura.provider.id
      });
      const response = await facturaDataService.put(factura);
      // console.log(response.data);
      if (response.data.status == status.ERROR) {
        // error
        toastr.error(response.data.msg[0]);
      } else if (response.data.status == status.CLOSED) {
        // closed
        toastr.warning(response.data.message);
      } else {
        // success
        if(response.data.error){
          toastr.warning(response.data.message);
        } else{
          toastr.success(text.API_EXITO);
        }
        // messageInfo('ev', vm.factura.share);
        changeDate();
      }
    } catch (error) {
      console.log(error);
      toastr.error(error);
    } finally {
      $state.go("app.egreso", {
        id: vm.factura.id
      });
      init();
      loading.end();
    }
  }

  // crear
  async function create(option) {
    loading.init();
    try {
      const factura = {};
      angular.extend(factura, vm.factura, {
        document_type_id: vm.factura.document_type
          ? vm.factura.document_type.id
          : null,
        community_id: vm.factura.community.id,
        division_id: vm.factura.division ? vm.factura.division.id : null,
        account_id: vm.factura.account.id,
        provider_id: vm.factura.provider.id
      });
      const response = await facturaDataService.post(factura);
      // validate status
      if (response.data.status == status.ERROR) {
        // error
        toastr.error(response.data.msg[0]);
      } else if (response.data.status == status.CLOSED) {
        // closed
        toastr.warning(response.data.message);
      } else {
        gaService.send_event('egreso_creacion', 'egresos');
        // success
        if (option == 1) {
          $state.go("app.egreso", {}, {reload: true } );
        } else {
          $state.go("app.egreso", {
            id: response.data.id
          });
          viewMessage("ev", response.data);
        }
        toastr.success(text.API_EXITO);
        // messageInfo('ev', vm.factura.share);
        changeDate();
      }
    } catch (error) {
      if (error.status === 422) {
        let message = "";
        for (const key in error.data) {
          if (error.data.hasOwnProperty(key)) {
            const element = error.data[key];
            message = message.concat(element.join("\n"));
          }
        }
        toastr.error(message);
      } else {
        toastr.error(text.API_ERROR);
      }
    } finally {
      loading.end();
    }
  }

  // select tipo gasto
  function selectGasto() {
    vm.gastos = [];
    angular.forEach(vm.tipo_gastos, function(value) {
      if (value[0].name == vm.factura.type_a) {
        angular.forEach(value[0].types, function(element) {
          vm.gastos.push(element);
        });
      }
    });
  }

  // select community
  async function selectComunidad() {
    vm.factura.document_type = null;
    vm.factura.division = null;
    vm.factura.category = null;
    vm.factura.account = null;
    vm.factura.provider = null;
    vm.divisiones = null;
    vm.categorias = null;
    vm.cuentas = null;
    return;
  }

  // create o edit modal archivo
  function addFile(ev, item) {
    $mdDialog
      .show({
        controller: fileModalController,
        templateUrl: "views/admin/factura/modal/file_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: "true",
        locals: {
          bill: vm.factura,
          file: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          gaService.send_event('egreso_edicion', 'egresos');
          init();
        }
      });
  }

  // create o edit modal archivo
  function addShare(ev) {
    $mdDialog
      .show({
        controller: shareModalController,
        templateUrl: "views/admin/factura/modal/share_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          data: vm.factura
        }
      })
      .then(function(response) {
        init();
      });
  }

  // view file
  function viewFile(ev, item) {
    $mdDialog
      .show({
        controller: detailModalController,
        templateUrl: "views/admin/factura/modal/detail_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: "true",
        locals: {
          file: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          init();
        }
      });
  }

  // mostrar modal mensaje
  function viewMessage(ev, item) {
    $mdDialog
      .show({
        controller: msgBillModalController,
        templateUrl: "views/admin/factura/modal/message_modal.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {
          data: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          vm.factura = item;
          addFile("ev");
        }
      });
  }

  // select tabs add imagenes
  function selectImagenes() {
    init();
  }

  // confirmar para crear facturas cuotas
  function messageInfo(ev, count, option) {
    $mdDialog.show(confirmService.bill(ev, count)).then(
      function() {},
      function() {}
    );
  }

  // confirmar para eliminar item
  function destroy(ev, item) {
    $mdDialog.show(confirmService.delete(ev)).then(
      function() {
        trash(item);
      },
      function() {}
    );
  }

  // eliminar el item seleccionado
  function trash(item) {
    facturaFileDataService
      .delete(item)
      .then(function(response) {
        init();
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // --
  function splitBill() {
    gastoComunDataService
      .get_generate(vm.factura)
      .then(function(response) {
        vm.propiedades = response.data;
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function changeDate() {
    storeService.set("month", vm.factura.month);
    storeService.set("year", vm.factura.year);
    $rootScope.$broadcast("update-auth-data");
  }

  // load categorias
  // function categorias() {
  //     categoriaDataService.all()
  //         .then(function (response) {
  //             vm.categorias = response.data;
  //         })
  //         .catch(function () {
  //             toastr.error(text.API_ERROR);
  //         });
  // }

  // load title
  function title() {
    vm.type = vm.factura.id ? "Editar" : "Nuevo";
    vm.item = {
      backUrl: "app.egresos",
      newUrl: "",
      label: vm.type + " Egreso",
      name: vm.factura.id ? vm.factura.number.toUpperCase() : "",
      id: vm.factura.id ? vm.factura.id : null
    };
  }

  async function loadCommunities() {
    if (vm.comunidades) {
      return vm.comunidades;
    }
    const response = await comunidadDataService.all();
    vm.comunidades = response.data;
  }

  async function loadDocumentTypes() {
    if (vm.tipos_documento) {
      return vm.tipos_documento;
    }
    const response = await comunidadDataService.getDocumentTypes(
      vm.factura.community.id
    );
    vm.tipos_documento = response.data;
  }

  async function loadDivisions() {
    //console.log("Codeyx----------------------");
    if (
      vm.factura.community.divisions &&
      vm.factura.community.divisions.length > 0
    ) {
      vm.divisiones = vm.factura.community.divisions;
      if (vm.divisiones.length == 1){
        vm.factura.division = vm.divisiones[0];
      }
      return vm.divisiones;
    }
    if (vm.divisiones) {
      if (vm.divisiones.length == 1){

        vm.factura.division = vm.divisiones[0];
      }
      return vm.divisiones;
    }
    const response = await comunidadDataService.get(vm.factura.community);
    vm.divisiones = response.data.divisions;
    if (vm.divisiones.length == 1){
      //console.log("Si hay 1 division, asignar automáticamente");
      vm.factura.division = vm.divisiones[0];
    }
    //console.log("END Codeyx----------------------");
    if(vm.factura.community.mixed_use){
      console.log('Set cost divisions');
      gastoDataService
        .get_cost_divisions(vm.factura.community, vm.factura.id)
        .then(function (response) {
          // console.log('get_factura_cost_divisions', true);
          // console.log('response', response);
          vm.cost_divisions = response.data.cost_divisions;
          sum_generales();
        })
        .catch(function () {
          toastr.error(text.API_ERROR);
          loading.end();
        });
    }
  }


  async function loadCategories() {
    if (vm.categorias) {
      return vm.categorias;
    }
    const response = await comunidadDataService.getCategories(
      vm.factura.community.id
    );
    vm.categorias = response.data;
  }

  async function loadProviders() {
    if (vm.proveedores) {
      return vm.proveedores;
    }
    const response = await proveedorDataService.all({
      page: -1,
      limit: -1,
      search: -1,
      order: -1
    });
    vm.proveedores = response.data;
  }


  function assign_cost_divisions(){
        gastoDataService
        .save_cost_divisions(vm.factura.community, vm.factura.id, {method: vm.factura.division_method, bill: vm.factura, cost_divisions: vm.cost_divisions})
        .then(function (response) {
            toastr.success(text.API_EXITO);
            // console.log('save_cost_divisions');
            // console.log('response', response);
        })
        .catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
        });
    }

  function sum_generales(){
    //console.log("Calcular reparto", vm.factura.division_method);
    vm.sum_percent = 0;
    vm.sum_amount = 0;
    vm.sum_assigned = 0;
    vm.sum_prorrateo_amount = 0;
    vm.sum_final_amount = 0;
    vm.factura.total_assigned = 0;
    if (vm.factura.common_percent == ''){
      vm.factura.common_percent = 0;
    }
    if (vm.factura.common_amount == ''){
      vm.factura.common_amount = 0;
    }
    //   // borrar numeros
    // if(vm.factura.division_method == 1){
    // }
    // if(vm.factura.division_method == 2){
    //   // borrar numeros
    //   vm.factura.common_amount = 0;
    //   angular.forEach(vm.cost_divisions, function (item) {
    //     //item.percentage = 0;
    //     item.amount = 0;
    //   });
    // }
    // if(vm.factura.division_method == 3){
    //   // borrar numeros
    //   vm.factura.common_percent = 0;
    //   angular.forEach(vm.cost_divisions, function (item) {
    //     item.percentage = 0;
    //     //item.amount = 0;
    //   });
    // }
    angular.forEach(vm.cost_divisions, function (item) {
      console.log("percentage", item.percentage);
      console.log("amount",item.amount);
      if(item.percentage == "" || isNaN(item.percentage)){item.percentage = 0;}
      if(item.amount == "" || isNaN(item.amount)){item.amount = 0;}

      if(vm.factura.division_method == 1){
        item.assigned = 0;
        item.prorrateo_amount = ((item.division.prorrateo/100)*vm.factura.amount);
        item.final_amount = item.prorrateo_amount;
        vm.factura.assigned = 0;
      }
      if(vm.factura.division_method == 2){
        item.assigned = ((item.percentage/100)*vm.factura.amount);
        vm.factura.assigned = ((vm.factura.common_percent/100)*vm.factura.amount);
        item.prorrateo_amount = ((item.division.prorrateo/100)*vm.factura.assigned);
        item.final_amount = parseFloat(item.assigned+item.prorrateo_amount);
      }
      if(vm.factura.division_method == 3){
       item.amount = parseInt(item.amount);
       item.assigned = item.amount;
       vm.factura.assigned = vm.factura.common_amount;
       item.prorrateo_amount = ((item.division.prorrateo/100)*vm.factura.assigned);
       item.final_amount = parseFloat(item.amount+item.prorrateo_amount);
      }
      vm.sum_percent += parseFloat(item.percentage);
      vm.sum_amount += parseFloat(item.amount);
      vm.sum_assigned += parseFloat(item.assigned);
      vm.sum_prorrateo_amount += parseFloat(item.prorrateo_amount);
      vm.sum_final_amount += parseFloat(item.final_amount);
      vm.factura.total_assigned += parseFloat(item.assigned);
    });
    vm.sum_percent += parseFloat(vm.factura.common_percent);
    vm.sum_assigned += parseFloat(vm.factura.assigned);
    vm.sum_amount += parseFloat(vm.factura.common_amount);
    vm.factura.total_assigned += parseFloat(vm.factura.assigned);
    if(vm.factura.division_method == 1){
      vm.factura_division_check = true;
    }
    if(vm.factura.division_method == 2){
      vm.factura_division_check = (vm.sum_percent==100);
    }
    if(vm.factura.division_method == 3){
      vm.factura_division_check = (vm.sum_amount==vm.factura.amount);
    }
  }
}
