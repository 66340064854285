function CircularController() {
}

angular.module('app').component('circular', {
  templateUrl: 'views/components/circular/circularComponent.html',
  controller: CircularController,
  bindings: {
    circular: '<'
  }
});

angular.module('app').component('circularmixed', {
  templateUrl: 'views/components/circular/mixedCircularComponent.html',
  controller: CircularController,
  bindings: {
    circular: '<'
  }
});