angular.module("app").service("bonoDataService", bonoDataService);

function bonoDataService(dataService, $rootScope) {
  return {
    all: function() {
      return dataService.httpGet("bonos", {
        headers: {
          "x-comunidad": $rootScope.currentCommunity
            ? $rootScope.currentCommunity.id
            : null
        }
      });
    },
    get: function(bono) {
      return dataService.httpGet("bonos/" + bono.id);
    },
    post: function(bono) {
      return dataService.httpPost("bonos", bono);
    },
    put: function(bono) {
      return dataService.httpPut("bonos/" + bono.id, bono);
    },
    delete: function(bono) {
      return dataService.httpDelete("bonos/" + bono.id);
    },
    // --
    get_all: function(comunidad, month, year) {
      return dataService.httpGet(
        "bonos/" + comunidad.id + "/" + month + "/" + year
      );
    },
    validate: function(bonos) {
      return dataService.httpPut("bonos/validar", bonos);
    }
  };
}
