angular
    .module('app')
    .service('chatDataService', chatDataService);

function chatDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('chat');
        },
        get: function (chat) {
            return dataService.httpGet('chat/' + chat.id);
        },
        post: function (chat) {
            return dataService.httpPost('chat', chat);
        },
        put: function (chat) {
            return dataService.httpPut('chat/' + chat.id, chat);
        },
        delete: function (chat) {
            return dataService.httpDelete('chat/' + chat.id);
        },
        // --
        get_messages: function (user, chat) {
            return dataService.httpGet('chat/' + user.id + '/' + chat.id);
        },
        get_people: function () {
            return dataService.httpGet('chat/personas', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            });
        },
    };
}