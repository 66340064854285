angular
    .module('app')
    .service('liquidacionDataService', liquidacionDataService);

function liquidacionDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('liquidaciones', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (liquidacion) {
            return dataService.httpGet('liquidaciones/' + liquidacion.id);
        },
        post: function (liquidacion) {
            return dataService.httpPost('liquidaciones', liquidacion);
        },
        put: function (liquidacion) {
            return dataService.httpPut('liquidaciones/' + liquidacion.id, liquidacion);
        },
        delete: function (liquidacion) {
            return dataService.httpDelete('liquidaciones/' + liquidacion.id);
        },
        // --
        get_all: function (comunidad, month, year) {
            return dataService.httpGet('liquidaciones/' + comunidad.id + '/' + month + '/' + year);
        },
        validate: function (liquidaciones) {
            return dataService.httpPut('liquidaciones/validar', liquidaciones);
        },

    };
}