angular
    .module('app')
    .service('webpayDataService', webpayDataService);

function webpayDataService(dataService) {
    return {
        status: function (property) {
            var all = property;
            return dataService.httpPost('propiedades/status', all);
        },
        to_pay: function (property, pay_all, total_amount, method, transaction_amount) {
            var all={
                property: property,
                option: pay_all,
                amount: total_amount,
                method: method,
                transaction_amount: transaction_amount
            }
            return dataService.httpPost('propiedades/to_pay', all);
        },
        get_pay: function (property_id, payment_id) {
            var all = {
                property_id: property_id,
                payment_id: payment_id
            }
            return dataService.httpPost('propiedades/get_pay', all);
        },
    };
}
