angular.module("app").config(routes);

function routes($stateProvider, auth_roles) {
  // Configuración de las rutas/estados
  $stateProvider
    //  Navbar
    .state("app", {
      access: [auth_roles.PUBLIC],
      templateUrl: "views/layout/navbar_view.html",
      controller: "navbarController",
      controllerAs: "vm",
      abstract: true
    })

    //  Auth
    .state("app.login", {
      url: "/login",
      access: [auth_roles.PUBLIC],
      views: {
        content: {
          templateUrl: "views/auth/login_view.html",
          controller: "authController",
          controllerAs: "vm"
        }
      }
    })

    // password reset success
    .state("app.forgotok", {
      url: "/password/reset_success",
      access: [auth_roles.PUBLIC],
      views: {
        content: {
          templateUrl: "views/auth/forgot_view_ok.html"
        }
      }
    })     

    // password forget
    .state("app.forgot", {
      url: "/password/reset",
      access: [auth_roles.PUBLIC],
      views: {
        content: {
          templateUrl: "views/auth/forgot_view.html",
          controller: "forgotController",
          controllerAs: "vm"
        }
      }
    })  

    // password reset
    .state("app.reset", {
      url: "/password/reset/:id",
      access: [auth_roles.PUBLIC],
      views: {
        content: {
          templateUrl: "views/auth/reset_view.html",
          controller: "resetController",
          controllerAs: "vm"
        }
      }
    })     

    .state("app.gasto_status", {
      url: "/gasto/estado",
      access: [auth_roles.PUBLIC, auth_roles.ADMIN, auth_roles.COPROPIETARIO],
      views: {
        content: {
          templateUrl: "views/user/gasto_comun/status_view.html",
          controller: "gastoStatusRWDController",
          controllerAs: "vm"
        }
      }
    })

    // webpay public
    .state("app.webpay", {
      url: "/secured/webpay/:id",
      access: [auth_roles.PUBLIC, auth_roles.ADMIN, auth_roles.COPROPIETARIO],
      views: {
        content: {
          templateUrl: "views/secured/webpay_view.html",
          controller: "payController",
          controllerAs: "vm"
        }
      }
    })

    .state("app.webpayok", {
      url: "/secured/webpay/o/:id/:p_id",
      access: [auth_roles.PUBLIC, auth_roles.ADMIN, auth_roles.COPROPIETARIO],
      views: {
        content: {
          templateUrl: "views/secured/webpay_response_ok.html",
          controller: "payOkController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.webpaybad", {
      url: "/secured/webpay/b/:id",
      access: [auth_roles.PUBLIC, auth_roles.ADMIN, auth_roles.COPROPIETARIO],
      views: {
        content: {
          templateUrl: "views/secured/webpay_response_bad.html",
          controller: "payBadController",
          controllerAs: "vm"
        }
      }
    })

    // logout
    .state("logout", {
      url: "/auth/logout",
      access: [
        auth_roles.ROOT,
        auth_roles.SEMIROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.RECEPCION,
        auth_roles.TESORERIA,
        auth_roles.CONTABILIDAD,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE,
        auth_roles.COPROPIETARIO
      ],
      templateUrl: null,
      controller: "logoutController"
    })

    // secured
    .state("app.account", {
      url: "/auth/secured/account",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.RECEPCION,
        auth_roles.TESORERIA,
        auth_roles.CONTABILIDAD,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE,
        auth_roles.COPROPIETARIO
      ],
      views: {
        content: {
          templateUrl: "views/secured/account_view.html",
          controller: "accountController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.password", {
      url: "/auth/secured/password",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.RECEPCION,
        auth_roles.TESORERIA,
        auth_roles.CONTABILIDAD,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE,
        auth_roles.COPROPIETARIO
      ],
      views: {
        content: {
          templateUrl: "views/secured/password_view.html",
          controller: "passwordController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.profile", {
      url: "/auth/secured/profiles",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.RECEPCION,
        auth_roles.TESORERIA,
        auth_roles.CONTABILIDAD,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE,
        auth_roles.COPROPIETARIO
      ],
      views: {
        content: {
          templateUrl: "views/secured/profile_view.html",
          controller: "profileController",
          controllerAs: "vm"
        }
      }
    })

    // mail
    /*     .state("app.mails", {
          url: "/auth/mails",
          access: [
            auth_roles.ROOT,
            auth_roles.ADMIN,
            auth_roles.ANALISTA,
            auth_roles.RECEPCION,
            auth_roles.TESORERIA,
            auth_roles.CONTABILIDAD,
            auth_roles.MAYORDOMO,
            auth_roles.CONCERJE,
            auth_roles.COPROPIETARIO
          ],
          views: {
            content: {
              templateUrl: "views/mail/index_view.html",
              controller: "mailsController",
              controllerAs: "vm"
            }
          }
        })
        .state("app.mail", {
          url: "/auth/mails/:id",
          access: [
            auth_roles.ROOT,
            auth_roles.ADMIN,
            auth_roles.ANALISTA,
            auth_roles.RECEPCION,
            auth_roles.TESORERIA,
            auth_roles.CONTABILIDAD,
            auth_roles.MAYORDOMO,
            auth_roles.CONCERJE,
            auth_roles.COPROPIETARIO
          ],
          views: {
            content: {
              templateUrl: "views/mail/show_view.html",
              controller: "mailController",
              controllerAs: "vm"
            }
          }
        }) */

    // chat
    /*     .state("app.chats", {
          url: "/auth/chat",
          access: [
            auth_roles.ROOT,
            auth_roles.ADMIN,
            auth_roles.ANALISTA,
            auth_roles.RECEPCION,
            auth_roles.TESORERIA,
            auth_roles.CONTABILIDAD,
            auth_roles.MAYORDOMO,
            auth_roles.CONCERJE,
            auth_roles.COPROPIETARIO
          ],
          views: {
            content: {
              templateUrl: "views/chat/index_view.html",
              controller: "chatsController",
              controllerAs: "vm"
            }
          }
        })
        .state("app.chat", {
          url: "/auth/chat/:id",
          access: [
            auth_roles.ROOT,
            auth_roles.ADMIN,
            auth_roles.ANALISTA,
            auth_roles.RECEPCION,
            auth_roles.TESORERIA,
            auth_roles.CONTABILIDAD,
            auth_roles.MAYORDOMO,
            auth_roles.CONCERJE,
            auth_roles.COPROPIETARIO
          ],
          views: {
            content: {
              templateUrl: "views/chat/show_view.html",
              controller: "chatController",
              controllerAs: "vm"
            }
          }
        }) */

    // empresas
    .state("app.empresas", {
      url: "/auth/empresas",
      access: [auth_roles.ROOT],
      views: {
        content: {
          templateUrl: "views/admin/empresa/index_view.html",
          controller: "empresasController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.empresa", {
      url: "/auth/empresas/:id",
      access: [auth_roles.ROOT],
      views: {
        content: {
          templateUrl: "views/admin/empresa/show_view.html",
          controller: "empresaController",
          controllerAs: "vm"
        }
      }
    })

    // dashboard
    .state("app.dashboard", {
      url: "/auth/dashboard",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.CONCERJE,
      ],
      views: {
        content: {
          templateUrl: "views/admin/dashboard/index_view.html",
          controller: "dashboardController",
          controllerAs: "vm"
        }
      }
    })

    // comunidades
    .state("app.comunidades", {
      url: "/auth/comunidades",
      access: [
        auth_roles.ROOT,
        auth_roles.SEMIROOT,
      ],
      views: {
        content: {
          templateUrl: "views/admin/comunidad/index_view.html",
          controller: "comunidadesController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.comunidades_create", {
      url: "/auth/comunidades/crear",
      access: [
        auth_roles.ROOT
      ],
      views: {
        content: {
          templateUrl: "views/admin/comunidad/create_view.html",
          controller: "comunidadCreateController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.comunidad", {
      url: "/auth/comunidad",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/comunidad/show_view.html",
          controller: "comunidadController",
          controllerAs: "vm"
        }
      }
    })

    // divisiones
    .state("app.divisiones", {
      url: "/auth/divisiones",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/division/index_view.html",
          controller: "divisionesController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.division", {
      url: "/auth/divisiones/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/division/show_view.html",
          controller: "divisionController",
          controllerAs: "vm"
        }
      }
    })

    // usuarios admins
    .state("app.usuarios", {
      url: "/auth/usuarios",
      access: [
        auth_roles.ROOT,
      ],
      views: {
        content: {
          templateUrl: "views/admin/usuario/index_view.html",
          controller: "usuariosController",
          controllerAs: "vm"
        }
      }
    })
    

    // personas
    .state("app.personas", {
      url: "/auth/personas",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/persona/index_view.html",
          controller: "personasController",
          controllerAs: "vm"
        }
      }
    })

    .state("app.persona", {
      url: "/auth/personas/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/persona/show_view.html",
          controller: "personaController",
          controllerAs: "vm"
        }
      }
    })

    // propiedades
    .state("app.propiedades", {
      url: "/auth/propiedades",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/propiedad/index_view.html",
          controller: "propiedadesController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.propiedad", {
      url: "/auth/propiedades/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/propiedad/show_view.html",
          controller: "propiedadController",
          controllerAs: "vm"
        }
      }
    })

    .state("app.cobranzas", {
      url: "/auth/cobranzas",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/cobranzas/index_view.html",
          controller: "cobranzasController",
          controllerAs: "vm"
        }
      }
    })

    .state("app.conciliacion", {
      url: "/auth/conciliacion",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/conciliacion/index_view.html",
          controller: "conciliacionController",
          controllerAs: "vm"
        }
      }
    })

    .state("app.reportes", {
      url: "/auth/reportes",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/reportes/index_view.html",
          controller: "reportesController",
          controllerAs: "vm"
        }
      }
    })

    // unidades
    // .state('app.unidades', {
    //     url: '/auth/unidades',
    //     access: [auth_roles.ADMIN, auth_roles.ANALISTA, auth_roles.CONTABILIDAD, auth_roles.TESORERIA],
    //     views: {
    //         content: {
    //             templateUrl: 'views/admin/unidad/index_view.html',
    //             controller: 'unidadesController',
    //             controllerAs: 'vm'
    //         }
    //     }
    // })
    // .state('app.unidad', {
    //     url: '/auth/unidades/:id',
    //     access: [auth_roles.ADMIN, auth_roles.ANALISTA, auth_roles.CONTABILIDAD, auth_roles.TESORERIA],
    //     views: {
    //         content: {
    //             templateUrl: 'views/admin/unidad/show_view.html',
    //             controller: 'unidadController',
    //             controllerAs: 'vm'
    //         }
    //     }
    // })

    // categorias
    .state("app.categorias", {
      url: "/auth/categorias",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/categoria/index_view.html",
          controller: "categoriasController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.categoria", {
      url: "/auth/categorias/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/categoria/show_view.html",
          controller: "categoriaController",
          controllerAs: "vm"
        }
      }
    })

    // Fondos de Reserva
    .state("app.fondos", {
      url: "/auth/fondos",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/fondo/index_view.html",
          controller: "fondosController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.fondo", {
      url: "/auth/fondos/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/fondo/show_view.html",
          controller: "fondoController",
          controllerAs: "vm"
        }
      }
    })

    // cuentas
    .state("app.cuentas", {
      url: "/auth/cuentas",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/cuenta/index_view.html",
          controller: "cuentasController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.cuenta", {
      url: "/auth/cuentas/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/cuenta/show_view.html",
          controller: "cuentaController",
          controllerAs: "vm"
        }
      }
    })

    // proveedores
    .state("app.proveedores", {
      url: "/auth/proveedores",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/proveedor/index_view.html",
          controller: "proveedoresController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.proveedor", {
      url: "/auth/proveedores/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/proveedor/show_view.html",
          controller: "proveedorController",
          controllerAs: "vm"
        }
      }
    })

    // reglas
    .state("app.reglas", {
      url: "/auth/reglas",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/regla/index_view.html",
          controller: "reglasController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.regla", {
      url: "/auth/reglas/:community_id/:division_id/:account_id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/regla/show_view.html",
          controller: "reglaController",
          controllerAs: "vm"
        }
      }
    })

    // egresos
    .state("app.egresos", {
      url: "/auth/egresos",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/factura/index_view.html",
          controller: "facturasController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.egreso", {
      url: "/auth/egresos/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/factura/show_view.html",
          controller: "facturaController",
          controllerAs: "vm"
        }
      }
    })

    // gastos
    .state("app.gastos", {
      url: "/auth/gastos",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/gasto/index_view.html",
          controller: "gastoController",
          controllerAs: "vm"
        }
      }
    })

    // mediciones
    .state("app.mediciones", {
      url: "/auth/mediciones",
      access: [
        // auth_roles.ADMIN,
        // auth_roles.ANALISTA,
        // auth_roles.CONTABILIDAD,
        // auth_roles.TESORERIA,
        // auth_roles.RECEPCION,
        // auth_roles.MAYORDOMO,
        auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/medicion/index_view.html",
          controller: "medicionController",
          controllerAs: "vm"
        }
      }
    })

    // gastos comunes
    .state("app.gastos_comunes", {
      url: "/auth/gastos_comunes",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/gasto_comun/index_view_nuevo.html",
          controller: "gastosComunController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.gasto_comun", {
      url: "/auth/gastos_comunes/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/gasto_comun/show_view.html",
          controller: "gastoComunController",
          controllerAs: "vm"
        }
      }
    })

    // ingresos
    .state("app.ingresos", {
      url: "/auth/ingresos",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/pago/index_view.html",
          controller: "pagoController",
          controllerAs: "vm"
        }
      }
    })

    // balance
    .state("app.balance", {
      url: "/auth/balance",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/balance/index_view.html",
          controller: "balanceController",
          controllerAs: "vm"
        }
      }
    })


    // cartola
    .state("app.cartola", {
      url: "/auth/cartola",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/cartola/index_view.html",
          controller: "cartolaController",
          controllerAs: "vm"
        }
      }
    })

    // fondo reserva
    /* .state("app.fondos", {
      url: "/auth/fondos",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/fondo_reserva/index_view.html",
          controller: "fondosReservaController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.fondo", {
      url: "/auth/fondo/:community/:month/:year",
      access: [
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/fondo_reserva/show_view.html",
          controller: "fondoReservaController",
          controllerAs: "vm"
        }
      }
    }) */

    // tesoreria
    .state("app.tesoreria", {
      url: "/auth/tesoreria",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/tesoreria/index_view.html",
          controller: "tesoreriaController",
          controllerAs: "vm"
        }
      }
    })

    // remuneraciones
    .state("app.remuneraciones", {
      url: "/auth/remuneraciones",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/remuneracion/index_view.html",
          controller: "remuneracionesController",
          controllerAs: "vm"
        }
      }
    })

    // empleado
    .state("app.empleado", {
      url: "/auth/empleado/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA
      ],
      views: {
        content: {
          templateUrl: "views/admin/remuneracion/show_view.html",
          controller: "empleadoController",
          controllerAs: "vm"
        }
      }
    })

    // repositorio
    .state("app.repositorios", {
      url: "/auth/repositorios",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/repositorio/index_view.html",
          controller: "repositoriosController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.repositorio", {
      url: "/auth/repositorios/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/repositorio/show_view.html",
          controller: "repositorioController",
          controllerAs: "vm"
        }
      }
    })

    // documento
    .state("app.tiposDocumento", {
      url: "/auth/tipos-documento",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/tipo_documento/index_view.html",
          controller: "tiposDocumentoController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.tipoDocumento", {
      url: "/auth/tipo-documento/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/tipo_documento/show_view.html",
          controller: "tipoDocumentoController",
          controllerAs: "vm"
        }
      }
    })


    // imprimir detalle
    .state("print", {
      url: "/auth/print/gasto/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
      ],
      templateUrl: "views/admin/print/detail_view.html",
      controller: "gastoDetailPrintController",
      controllerAs: "vm"
    })

    // imprimir todos
    .state("prints", {
      url: "/auth/print/gastos/:community_id/:division_id/:month/:year",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
      ],
      templateUrl: "views/admin/print/index_view.html",
      controller: "gastosPrintController",
      controllerAs: "vm"
    })

    // imprimir todos compacto
    .state("prints_compact", {
      url: "/auth/print/gastos/detalle/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
      ],
      templateUrl: "views/admin/print/detalle_view.html",
      controller: "gastoPrintController",
      controllerAs: "vm"
    })

    // imprimir boleta
    .state("prints_boleta", {
      url: "/auth/print/gastos/boleta/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.DUEÑO,
        auth_roles.ARRENDATARIO,
        auth_roles.COPROPIETARIO
      ],
      templateUrl: "views/admin/print/boleta_view.html",
      controller: "boletaPrintController",
      controllerAs: "vm"
    })

    // imprimir todos compacto
    .state("prints_circular", {
      url: "/auth/print/gastos/circular/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
      ],
      templateUrl: "views/admin/print/circular_view.html",
      controller: "circularPrintController",
      controllerAs: "vm"
    })

    // informaciones
    .state("app.informaciones", {
      url: "/auth/informaciones",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/informacion/index_view.html",
          controller: "informacionesController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.informacion", {
      url: "/auth/informaciones/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/informacion/show_view.html",
          controller: "informacionController",
          controllerAs: "vm"
        }
      }
    })

    // foro
    .state("app.foros", {
      url: "/auth/foros",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/foro/index_view.html",
          controller: "forosController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.foro", {
      url: "/auth/foros/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/foro/show_view.html",
          controller: "foroController",
          controllerAs: "vm"
        }
      }
    })

    // horario
    .state("app.horario", {
      url: "/auth/horario",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/horario/index_view.html",
          controller: "horarioController",
          controllerAs: "vm"
        }
      }
    })

    // instalaciones
    .state("app.instalaciones", {
      url: "/auth/instalaciones",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/reserva/index_view.html",
          controller: "reservasController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.instalacion", {
      url: "/auth/instalaciones/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/reserva/show_view.html",
          controller: "reservaController",
          controllerAs: "vm"
        }
      }
    })

    // estacionamientos
    /*     .state("app.estacionamientos", {
          url: "/auth/estacionamientos",
          access: [
            auth_roles.ADMIN,
            auth_roles.ANALISTA,
            auth_roles.CONTABILIDAD,
            auth_roles.TESORERIA,
            auth_roles.RECEPCION,
            auth_roles.MAYORDOMO,
            auth_roles.CONCERJE
          ],
          views: {
            content: {
              templateUrl: "views/admin/estacionamiento/index_view.html",
              controller: "estacionamientosController",
              controllerAs: "vm"
            }
          }
        })
        .state("app.estacionamiento", {
          url: "/auth/estacionamientos/:id",
          access: [
            auth_roles.ADMIN,
            auth_roles.ANALISTA,
            auth_roles.CONTABILIDAD,
            auth_roles.TESORERIA,
            auth_roles.RECEPCION,
            auth_roles.MAYORDOMO,
            auth_roles.CONCERJE
          ],
          views: {
            content: {
              templateUrl: "views/admin/estacionamiento/show_view.html",
              controller: "estacionamientoController",
              controllerAs: "vm"
            }
          }
        }) */

    // acceso visitas
    .state("app.visitas", {
      url: "/auth/visitas",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/visita/index_view.html",
          controller: "visitaController",
          controllerAs: "vm"
        }
      }
    })

    // recordatorio
    .state("app.recordatorios", {
      url: "/auth/recordatorios",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/recordatorio/index_view.html",
          controller: "recordatoriosController",
          controllerAs: "vm"
        }
      }
    })
    .state("app.recordatorio", {
      url: "/auth/recordatorios/:id",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION,
        auth_roles.MAYORDOMO,
        //auth_roles.CONCERJE
      ],
      views: {
        content: {
          templateUrl: "views/admin/recordatorio/show_view.html",
          controller: "recordatorioController",
          controllerAs: "vm"
        }
      }
    })

    // log
    .state("app.logs", {
      url: "/auth/logs",
      access: [
        auth_roles.ROOT,
        auth_roles.ADMIN,
        auth_roles.ANALISTA,
        auth_roles.CONTABILIDAD,
        auth_roles.TESORERIA,
        auth_roles.RECEPCION
      ],
      views: {
        content: {
          templateUrl: "views/admin/log/index_view.html",
          controller: "logsController",
          controllerAs: "vm"
        }
      }
    })

  // --
  // view user mobile xs
  // --
  /*     .state("app.home_rwd", {
        url: "/auth/home",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/home_view.html",
            controller: "homeRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // resumen
  /*     .state("app.gasto_rwd", {
        url: "/auth/gasto",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/gasto_comun/index_view.html",
            controller: "gastoRWDController",
            controllerAs: "vm"
          }
        }
      })

      .state("app.gasto_detail_rwd", {
        url: "/auth/gasto/detalle/:month/:year",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/gasto_comun/detail_view.html",
            controller: "gastoDetailRWDController",
            controllerAs: "vm"
          }
        }
      })

      .state("app.gasto_historial_rwd", {
        url: "/auth/gasto/historial",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/gasto_comun/historial_view.html",
            controller: "gastoHistorialRWDController",
            controllerAs: "vm"
          }
        }
      })

      .state("app.gasto_transparente_rwd", {
        url: "/auth/gasto/transparencia",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/gasto_comun/transparent_view.html",
            controller: "gastoTransparentRWDController",
            controllerAs: "vm"
          }
        }
      })

      .state("app.gasto_status_rwd", {
        url: "/auth/gasto/estado",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/gasto_comun/status_view.html",
            controller: "gastoStatusRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // administracion
  /*     .state("app.administracion_rwd", {
        url: "/auth/administracion",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/administracion/index_view.html",
            controller: "administracionRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // informaciones
  /*     .state("app.informacion_rwd", {
        url: "/auth/administracion/informaciones",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/administracion/informacion_view.html",
            controller: "informacionRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // noticias
  /*     .state("app.noticia_rwd", {
        url: "/auth/administracion/noticias",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/administracion/noticia_view.html",
            controller: "noticiaRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // repositorio
  /*     .state("app.repositorio_rwd", {
        url: "/auth/administracion/repositorio",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/administracion/repositorio_view.html",
            controller: "repositorioRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // horario
  /*     .state("app.horario_rwd", {
        url: "/auth/administracion/horario",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/administracion/horario_view.html",
            controller: "horarioRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // mensajeria
  /*     .state("app.mensajeria_rwd", {
        url: "/auth/mensajeria",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/mensajeria/index_view.html",
            controller: "mensajeriaRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // forum
  /*     .state("app.foros_rwd", {
        url: "/auth/forum",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/mensajeria/foro/index_view.html",
            controller: "forosRWDController",
            controllerAs: "vm"
          }
        }
      })
      .state("app.foro_rwd", {
        url: "/auth/forum/:id",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/mensajeria/foro/show_view.html",
            controller: "foroRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // servicios
  /*     .state("app.servicios_rwd", {
        url: "/auth/servicios",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/servicio/index_view.html",
            controller: "servicioRWDController",
            controllerAs: "vm"
          }
        }
      }) */

  // reservas
  /*     .state("app.reservas_rwd", {
        url: "/auth/reservas",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/servicio/reserva/reservas_view.html",
            controller: "reservaRWDController",
            controllerAs: "vm"
          }
        }
      })
      .state("app.reserva_rwd", {
        url: "/auth/reservas/:id",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/servicio/reserva/reserve_view.html",
            controller: "reservacionRWDController",
            controllerAs: "vm"
          }
        }
      }) */
  // estacionamiento
  /*     .state("app.estacionamiento_rwd", {
        url: "/auth/parkings",
        access: [
          auth_roles.DUEÑO,
          auth_roles.ARRENDATARIO,
          auth_roles.COPROPIETARIO
        ],
        views: {
          content: {
            templateUrl: "views/user/servicio/estacionamiento_view.html",
            controller: "estacionamientoRWDController",
            controllerAs: "vm"
          }
        }
      }); */
}
