angular
    .module('app')
    .controller('gastoComunTestEmailController', gastoComunTestEmailController);

function gastoComunTestEmailController(
    $scope, 
    $rootScope,
    $mdDialog, 
    gastoComunDataService,
    gaService,
    method_pay,
    toastr,
    text,
    data, 
    loading
) {


    $scope.cancel = cancel;
    $scope.send_email = send_email;

    $scope.mail_to_send = $rootScope.currentUser.email;

    init();

    // --
    function init() {
        $scope.data = data;
        //console.log(data);
    }

    function cancel() {
        $mdDialog.hide();
    }


    function send_email(){
        loading.init();
        var mail_to_send = $scope.mail_to_send;
        gastoComunDataService.send_test_email($scope.data, $scope.data.property,mail_to_send)
        .then(function (response) {
            toastr.success(text.PASSWORD_FORGOT);
            gaService.send_event('enviar_mail_particular', 'ggcc');
            $mdDialog.hide();
            loading.end();
        }).catch(function () {
            toastr.error(text.API_ERROR);
            loading.end();
        });
    }



}
