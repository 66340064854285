angular
  .module("app")
  .controller("reportesController", reportesController);

function reportesController(
  $state,
  $element,
  $rootScope,
  comunidadDataService,
  conciliacionDataService,
  $mdDialog,
  confirmService,
  access,
  env,
  loading,
  toastr,
  text,
  storeService
) {
  var vm = this;

  vm.is_current = $rootScope.currentCommunity && $rootScope.currentCommunity.id;
 
  vm.reports = [];

  init();

  // inicio
  function init() {
    title();
    var month = storeService.get("month");
    var year = storeService.get("year");
    vm.reports = [];
    vm.reports.push({
      kind: "General",
      name: "Fechas Envío Ultimos 12 meses",
      description: "En este reporte se muestran las fechas de envío de GC de los últimos 12 meses",
      url: (env.DOMAIN + env.API_PATH + $rootScope.currentCommunity.id + "/fechas")
    });
    vm.reports.push({
      kind: "General",
      name: "Saldos Ultimos 12 meses",
      description: "En este reporte se muestran los saldos a inicio de cada unidad en los últimos 12 meses.",
      url: (env.DOMAIN + env.API_PATH + $rootScope.currentCommunity.id + "/saldos")
    });
    vm.reports.push({
      kind: "Softland",
      name: "GC y Egresos",
      description: "Export de GCs y egresos para ingreso en Softland",
      url: (env.DOMAIN + env.API_PATH + "files/export/softland_egresos/" + $rootScope.currentCommunity.id +"/"+month + "/"+year)
    });
    vm.reports.push({
      kind: "Softland",
      name: "Egresos y Pagos",
      description: "Export de egresos y pago de egresos para ingreso en Softland",
      url: (env.DOMAIN + env.API_PATH + "files/export/softland_gcs/" + $rootScope.currentCommunity.id +"/"+month + "/"+year)
    });
    vm.reports.push({
      kind: "Softland",
      name: "Pagos por Unidad",
      description: "Export de pagos por unidad para ingreso en Softland",
      url: (env.DOMAIN + env.API_PATH + "files/export/softland_payments/" + $rootScope.currentCommunity.id +"/"+month + "/"+year)
    });
    vm.reports.push({
      kind: "Softland",
      name: "Importacion GC",
      description: "Export de GC completo para ingreso en Softland",
      url: (env.DOMAIN + env.API_PATH + "files/export/softland_payments_bank/" + $rootScope.currentCommunity.id +"/"+month + "/"+year)
    });
    vm.reports.push({
      kind: "Softland",
      name: "Auxiliares",
      description: "Export de Auxiliares para ingreso en Softland",
      url: (env.DOMAIN + env.API_PATH + "files/export/softland_auxiliares/" + $rootScope.currentCommunity.id +"/"+month + "/"+year)
    });
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "calendar",
      backUrl: "",
      newUrl: "",
      backActive: false,
      newActive: false,
      label: "Reportes e Informes Comunidad",
      service: comunidadDataService
    };
  }

}
