angular
    .module('app')
    .controller('estacionamientoModalController', estacionamientoModalController);

function estacionamientoModalController($scope, $mdDialog, $rootScope, usuarioDataService,
    propiedadDataService, estacionamientoReservaDataService, storeService,
    reserva_states, toastr, text, data, value) {
    var notification = storeService.get('notification');
    $scope.reserva = {
        parking_id: data.id,
    }
    $scope.states = reserva_states;
    // --
    $scope.cancel = cancel;
    $scope.create = create;
    $scope.update = update;
    $scope.finish = finish;
    $scope.selectUser = selectUser;

    init();

    // carga inicial
    function init() {
        if (value) {
            $scope.reserva = value;
        }
        personas();
        // propiedades();
    }

    // salir del modal
    function cancel() {
        $mdDialog.hide({
            data: null
        });
    }

    // crear registro
    function create() {
        estacionamientoReservaDataService.post($scope.reserva)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // update registro
    function update() {
        estacionamientoReservaDataService.put($scope.reserva)
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // end
    function finish() {
        $scope.reserva.is_active = false;
        update();
        notification.parkings--;
        storeService.set('notification', notification);
        $rootScope.$broadcast('update-auth-data');
    }

    // personas
    function personas() {
        usuarioDataService.get_division(data.division)
            .then(function (response) {
                $scope.personas = response.data;
                if (value) {
                    selectUser();
                }
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // personas
    function selectUser() {
        // propiedadDataService.get_division(data.division)
        //     .then(function (response) {
        //         $scope.propiedades = response.data;
        //     })
        //     .catch(function () {
        //         toastr.error(text.API_ERROR);
        //     });
        angular.forEach($scope.personas, function (item) {
            if (item.user_id == $scope.reserva.user_id) {
                $scope.propiedades = item.user.properties;
            }
        });
    }
}