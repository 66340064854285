angular.module("app").controller("gastoDetailPrintController", gastoDetailPrintController);

function gastoDetailPrintController(
  $state,
  $stateParams,
  $q,
  $window,
  gastoComunDataService,
  comunidadDataService,
  cost_type,
  state_cost,
  loading,
  months,
  toastr,
  text
) {
  var vm = this;
  vm.estados = state_cost;
  vm.gasto = {
    id: $stateParams.id
  };
  vm.cost_types = cost_type;
  vm.amount_not_private = 0;

  // --
  vm.print = print;
  vm.capitalize = capitalize;
  vm.close = close;
  vm.amountSum = amountSum;

  vm.withBorders = false;

  init();

  // inicio
  function init() {
    gastos();
  }

  function capitalize(s){
    return s && s[0].toUpperCase() + s.slice(1);
  }

  // gastos comunes
  async function gastos() {
    loading.init();
    vm.suma_gastos_comunes = 0;
    vm.suma_total_gastos_comunes = 0;
    vm.suma_gastos_privados = 0;
    vm.suma_gastos_particular = 0;
    vm.suma_gastos_comunidad = 0;
    vm.suma_penalties = 0;
    var defered = [
      gastoComunDataService.get_detail(vm.gasto),
      comunidadDataService.getCurrent(),
    ];
    try {
      const response = await $q.all(defered);
      vm.detailData = response[0].data;
      vm.community = response[1].data;
      vm.gasto_general = vm.detailData.general;
      vm.penalties = vm.detailData.penalties;
      vm.credits = vm.detailData.credits;
      vm.suma_penalties = vm.penalties.map(p => parseFloat(p.amount)).reduce((prev, current) => prev + current, 0);
      vm.sum_credit_available = vm.credits['avaliable'].map(p => parseFloat(p.amount)).reduce((prev, current) => prev + current, 0);
      vm.sum_credit_consumed = vm.credits['pays'].map(p => parseFloat(p.amount)).reduce((prev, current) => prev + current, 0);
      vm.balance =  vm.detailData.balance;
      vm.unidades_incluidas = angular.forEach(response[0].data.property.properties, function (item) {
        item.type = capitalize(item.type);
      });
      loading.end();
    } catch (e) {
      toastr.error(text.API_ERROR);
      console.error(e);
      loading.end();
    }
  }

  function amountSum(value) {
    if (value != undefined) {
      vm.amount_not_private += parseInt(value);
    }
  }

  // open dialog print
  function print() {
    $window.print();
  }

  // close window
  function close() {
    // $window.close();
    $window.history.back();
  }
}
