angular
    .module('app')
    .controller('informacionesController', informacionesController);

function informacionesController($state, $scope, $rootScope, $mdDialog, informacionDataService,
    confirmService, informations_category, loading, access, toastr, text) {
    var vm = this;
    vm.query = {
        order: ['community.name', 'division.name', 'created_at', 'title'],
        limit: 10,
        page: 1
    };
    vm.categories = informations_category;

    // ---
    vm.edit = edit;
    vm.destroy = destroy;


    init();

    // iniciar
    function init() {
        vm.permission = access;
        title();
        informaciones();
    }

    // go to edit
    function edit(item) {
        $state.go('app.informacion', {
            id: item ? item.id : null
        });
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            disabled(item);
        }, function () {});
    }

    // disabled
    function disabled(item) {
        item.is_active = false;
        loading.init();
        informacionDataService.put(item)
            .then(function (response) {
                init();
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load informations
    function informaciones() {
        informacionDataService.all()
            .then(function (response) {
                vm.informaciones = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // socket
    $rootScope.$on('emit-socket', function (event, data) {
        informaciones();
        console.log(data.data);
    });

    // load title
    function title() {
        if (access.isCopropietario()) {
            $scope.$parent.vm.item = {
                back: true,
                backUrl: 'app.home_rwd',
                title: 'Informaciones',
            };
        } else {
            vm.item = {
                backUrl: '',
                newUrl: 'app.informacion',
                backActive: false,
                newActive: true,
                label: 'Informaciones',
            };
        }
    }
}