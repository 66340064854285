angular
    .module('app')
    .controller('foroRWDController', foroRWDController);

function foroRWDController($scope, $state, $stateParams, foroDataService,
    storeService, access, toastr, loading, text) {
    var vm = this;
    vm.foro = {
        id: $stateParams.id ? $stateParams.id : null,
        community_id: storeService.get('community').id,
        division_id: storeService.get('property').division_id,
    };
    vm.user = storeService.get('user');
    vm.is_processing = false;

    // --
    vm.send = send;
    vm.comment = comment;

    init();

    // inicio
    function init() {
        vm.permission = access;
        if (vm.foro.id) {
            foroDataService.get(vm.foro)
                .then(function (response) {
                    vm.foro = response.data;
                    title();
                })
                .catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            title();
        }
    }

    // send
    function send() {
        loading.init();
        // vm.is_processing = true;
        foroDataService.post(vm.foro)
            .then(function (response) {
                toastr.success(text.API_EXITO);
                $state.go('app.foros_rwd');
                // vm.is_processing = false;
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                // vm.is_processing = false;
                loading.end();
            });
    }

    // send comment
    function comment() {
        loading.init();
        // vm.is_processing = true;
        foroDataService.post({
                forum_id: vm.foro.id,
                text: vm.foro.comment,
                title: vm.foro.title,
                community_id: vm.foro.community_id,
                division_id: vm.foro.division_id,
            })
            .then(function (response) {
                toastr.success(text.API_EXITO);
                $state.go('app.foros_rwd');
                // vm.is_processing = false;
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                // vm.is_processing = false;
                loading.end();
            });
    }

    // title
    function title() {
        $scope.$parent.vm.item = {
            back: true,
            backUrl: 'app.foros_rwd',
            title: 'Foro',
            name: vm.foro.id ? vm.foro.title : null
        };
    }
}