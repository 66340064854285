angular
    .module('app')
    .controller('archivoModalController', archivoModalController);

function archivoModalController($scope, $mdDialog, roles_copropietario, property_type, row) {

    $scope.init = function () {
        $scope.row = row;
        $scope.auth_roles = roles_copropietario;
        $scope.property_type = property_type;
        // $scope.dependency_types = dependency_types;
    }
    $scope.init();

    $scope.cancel = function () {
        $mdDialog.hide()
    }

    $scope.save = function () {
        $mdDialog.hide({
            row: $scope.row,
        });
    }
};