angular
    .module('app')
    .controller('msgBillModalController', msgBillModalController)

function msgBillModalController($scope, $state, $mdDialog, $window, toastr, env, data) {
    // --
    $scope.redirect = redirect;
    $scope.cancel = cancel;

    function cancel() {
        $mdDialog.hide()
    }

    function redirect() {
        $mdDialog.hide({
            data: true
        });
    }
};