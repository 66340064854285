angular.module('app')
.factory('communityInterceptor', communityInterceptor);

function communityInterceptor ($q, $rootScope) {
  return {
    request: function (config) {
      if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
        config.headers['X-Community'] = $rootScope.currentCommunity.id;
      }
      return config || $q.when(config);
    },
  };
}