angular
    .module('app')
    .controller('forosController', forosController);

function forosController($scope, $state, $mdDialog, $rootScope,
    foroDataService, storeService, confirmService, loading, access, toastr, text) {
    var vm = this;
    vm.query = {
        order: ['community.name', 'division.name', 'created_at'],
        limit: 10,
        page: 1
    };
    vm.user = storeService.get('user');

    // --
    vm.edit = edit;
    vm.destroy = destroy;

    init();

    // inicio
    function init() {
        vm.permission = access;
        title();
        foros();
    }

    // actualizar
    function edit(item) {
        $state.go('app.foro', {
            id: item ? item.id : null
        });
    }

    // get all
    function foros() {
        foroDataService.all()
            .then(function (response) {
                vm.foros = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });

    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            disabled(item);
        }, function () {});
    }

    // disabled
    function disabled(item) {
        item.is_active = false;
        loading.init();
        foroDataService.put(item)
            .then(function (response) {
                init();
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // title
    function title() {
        vm.item = {
            backUrl: '',
            newUrl: 'app.foro',
            backActive: false,
            newActive: true,
            label: 'Foro',
        };
    }
}