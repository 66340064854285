angular
    .module('app')
    .controller('chatController', chatController);

function chatController($scope, $state, $stateParams, $rootScope, chatDataService, usuarioDataService,
    mySocket, storeService, access, toastr, text, $window) {
    var vm = this;
    vm.permission = access;
    vm.user = storeService.get('user');
    var notification = storeService.get('notification');

    vm.chat = {
        id: $stateParams.id ? $stateParams.id : null,
        to_user_id: $stateParams.id ? $stateParams.id : null,
    };
    vm.messages = [];
    vm.messageWindowHeight = parseInt($window.innerHeight - 250) + 'px';
    vm.is_processing = false;

    // --
    vm.create = create;

    init();

    // inicio
    function init() {
        user_to();
        messages();
    }

    // messages
    function messages() {
        chatDataService.get_messages(vm.user, vm.chat)
            .then(function (response) {
                vm.messages = response.data.data;
                notification.messages -= response.data.notifications;
                storeService.set('notification', notification);
                $rootScope.$broadcast('update-auth-data');
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // send
    function create() {
        vm.is_processing = true;
        chatDataService.post(vm.chat)
            .then(function (response) {
                vm.chat.text = '';
                vm.messages.push(response.data);
                vm.is_processing = false;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                vm.is_processing = false;
            });
    }

    // socket
    $rootScope.$on('emit-socket', function (event, data) {
        if (data.data.message.from_user_id == vm.chat.to_user_id) {
            vm.messages.push(data.data.message);
            console.log(data.data);
        }
    });

    // get user to
    function user_to() {
        usuarioDataService.get({
                id: $stateParams.id
            })
            .then(function (response) {
                vm.user_to = response.data;
                title();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            })
    }

    // title
    function title() {
        if (access.isCopropietario()) {
            $scope.$parent.vm.item = {
                back: true,
                backUrl: 'app.chats',
                title: 'chat',
            };
        } else {
            vm.item = {
                backUrl: 'app.chats',
                newUrl: '',
                backActive: true,
                newActive: false,
                label: 'Mensajes',
                name: vm.user_to.name.toUpperCase() + ' ' + vm.user_to.lastname.toUpperCase()
            };
        }
    }
}