angular
    .module('app')
    .service('loading', loading);

function loading($mdDialog) {

    this.init = function () {
        $mdDialog.show({
            templateUrl: 'views/layout/loading_view.html',
            parent: angular.element(document.body),
            targetEvent: 'ev',
            clickOutsideToClose: false,
            escapeToClose: false,
        });
    }

    this.end = function () {
        $mdDialog.hide();
    }
}