angular
    .module('app')
    .controller('unidadController', unidadController);

function unidadController($state, $stateParams, $rootScope, unidadDataService,
    comunidadDataService, access, dependency_types, toastr, text, status, tooltips) {
    var vm = this;
    vm.tooltips = tooltips;
    vm.permission = access;
    vm.unidad = {
        id: ($stateParams.id) ? $stateParams.id : null
    };
    // --
    vm.edit = edit;
    vm.create = create;
    vm.selectCommunity = selectCommunity;
    vm.selectDivision = selectDivision;

    init();

    // iniciar
    function init() {
        vm.tipos_unidad = dependency_types;
        if (vm.unidad.id) {
            unidadDataService.get(vm.unidad)
                .then(function (response) {
                    vm.unidad = response.data;
                    title();
                    comunidades();
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            title();
            comunidades();
        }
    }

    // editar
    function edit() {
        unidadDataService.put(vm.unidad)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.unidad', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // crear
    function create() {
        unidadDataService.post(vm.unidad)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.unidad', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // seleccionar comunidad
    function selectCommunity() {
        angular.forEach(vm.comunidades, function (value) {
            if (value.id == vm.unidad.property.division.community_id)
                vm.divisiones = value.divisions;
        });
        if (vm.unidad.id)
            vm.selectDivision();
    }

    // seleccionar division
    function selectDivision() {
        angular.forEach(vm.divisiones, function (value) {
            if (value.id == vm.unidad.property.division_id)
                vm.propiedades = value.properties;
        });
    }

    // load comunidades
    function comunidades() {
        comunidadDataService.all()
            .then(function (response) {
                vm.comunidades = response.data;
                // --
                vm.divisiones = (response.data.length > 0) ? response.data[comunidad(response.data)].divisions : null;
                if (vm.unidad.id) {
                    selectCommunity();
                } else {
                    vm.unidad.property = {
                        division: {
                            community_id: (response.data.length > 0) ? response.data[comunidad(response.data)].id : null,
                        }
                    };
                }
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // get comunidad rootScope
    function comunidad(items) {
        var index = 0;
        if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
            angular.forEach(items, function (item, key) {
                if (item.id == $rootScope.currentCommunity.id) {
                    index = key;
                    vm.is_current = true;
                }
            });
        } else {
            vm.is_current = false;
        }
        return index;
    }

    // load title
    function title() {
        vm.type = (vm.unidad.id) ? 'Editar' : 'Nueva';
        vm.item = {
            backUrl: 'app.unidades',
            newUrl: '',
            label: vm.type + ' Unidad',
            name: (vm.unidad.id) ? vm.unidad.number.toUpperCase() : '',
        };
    }
}