angular.module("app").controller("usuariosModalController", usuariosModalController);

function usuariosModalController(
  $scope,
  $mdDialog,
  text,
  toastr,
  usuarioDataService,
  comunidadDataService,
  roleDataService,
  data,
  loading
) {
  // --
  $scope.deassign = deassign;
  $scope.assign = assign;
  $scope.user = data;
  $scope.cancel = cancel;

  $scope.data = null;
  $scope.roles = null;
  $scope.comunidades = null;

  $scope.new_role = {
    role_id: null,
    community_id: null,
  }

  init();

  function init(){
    comunidadDataService
      .allMin(null)
      .then(function(response) {
        console.log(response);
        // $scope.data = response.data;
        $scope.comunidades = response.data;
    });
    roles();
  }

  function roles() {
    roleDataService
      .allOut()
      .then(function (response) {
        $scope.roles = response.data;
      })
      .catch(function () {
        toastr.error(text.API_ERROR);
      });
  }

  function deassign(user, role, community) {
    console.log(user);
    console.log(community);
    usuarioDataService
      .deassign(user.id, role.id, community.id)
      .then(function(response) {
        console.log(response.data);
        $mdDialog.hide("OK");
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function assign(user, role, community) {
    console.log($scope.new_role);
    usuarioDataService
      .assign($scope.user.id, $scope.new_role.role_id, $scope.new_role.community_id.id)
      .then(function(response) {
        console.log(response.data);
        $mdDialog.hide("OK");
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  function cancel() {
    $mdDialog.hide("CANCEL");
  }
}
