angular
  .module("app")
  .controller("remuneracionesController", remuneracionesController);

function remuneracionesController(
  $state,
  $rootScope,
  $window,
  $mdDialog,
  empleadoDataService,
  liquidacionDataService,
  reemplazoDataService,
  bonoDataService,
  aguinaldoDataService,
  comunidadDataService,
  fileDataService,
  cuentaDataService,
  confirmService,
  storeService,
  states_a,
  states_p,
  months,
  access,
  loading,
  toastr,
  status,
  text,
  env,
  years
) {
  var vm = this;
  var date = new Date();
  vm.month = storeService.get("month");
  vm.year = storeService.get("year");

  vm.query = {
    order: ["community.name", "name"],
    limit: 50,
    page: 1
  };
  // --
  vm.viewPage = viewPage;
  vm.addItem = addItem;
  vm.destroy = destroy;
  vm.selectTab = selectTab;
  vm.upload = upload;
  vm.download = download;
  vm.validate = validate;
  vm.configUpdate = configUpdate;
  vm.selectYear = selectYear;
  vm.selectMonth = selectMonth;
  vm.cuentas =[];
  vm.salary_account_id = 0;
  vm.laws_account_id = 0;

  init();

  // inicio
  function init() {
    title();
    vm.permission = access;
    vm.estado_a = "";
    vm.estado_p = "";
    vm.estados_p = states_p;
    // --
    vm.months = months;
    vm.years = years(date);
    // --
    comunidades();
  }
  // view page
  function viewPage(item) {
    $state.go("app.empleado", {
      id: item ? item.id : null
    });
  }

  // add item
  function addItem(item) {
    if (vm.selectedIndex == 1) {
      addLiquidacion(item);
    } else if (vm.selectedIndex == 2) {
      addReemplazo(item);
    } else if (vm.selectedIndex == 3) {
      addBono(item);
    } else {
      addAguinaldo(item);
    }
  }

  async function addLiquidacion(item) {
    const response = await $mdDialog.show({
      controller: liquidacionModalController,
      templateUrl: "views/admin/remuneracion/modal/detail_modal.html",
      parent: angular.element(document.body),
      targetEvent: "ev",
      clickOutsideToClose: false,
      fullscreen: false,
      locals: {
        employees: vm.empleados,
        data: item ? item : null
      }
    });
    if (response) {
      vm.month = storeService.get("month");
      vm.year = storeService.get("year");
      liquidaciones();
    }
  }

  async function addReemplazo(item) {
    const response = await $mdDialog.show({
      controller: reemplazoModalController,
      templateUrl: "views/admin/remuneracion/modal/reemplazo_modal.html",
      parent: angular.element(document.body),
      targetEvent: "ev",
      clickOutsideToClose: false,
      fullscreen: false,
      locals: {
        employees: vm.empleados,
        data: item ? item : null
      }
    });
    if (response) {
      vm.month = storeService.get("month");
      vm.year = storeService.get("year");
      reemplazos();
    }
  }

  async function addBono(item) {
    const response = await $mdDialog.show({
      controller: bonoModalController,
      templateUrl: "views/admin/remuneracion/modal/bono_modal.html",
      parent: angular.element(document.body),
      targetEvent: "ev",
      clickOutsideToClose: false,
      fullscreen: false,
      locals: {
        employees: vm.empleados,
        data: item ? item : null
      }
    });
    if (response) {
      vm.month = storeService.get("month");
      vm.year = storeService.get("year");
      bonos();
    }
  }

  async function addAguinaldo(item) {
    const response = await $mdDialog.show({
      controller: aguinaldoModalController,
      templateUrl: "views/admin/remuneracion/modal/aguinaldo_modal.html",
      parent: angular.element(document.body),
      targetEvent: "ev",
      clickOutsideToClose: false,
      fullscreen: false,
      locals: {
        employees: vm.empleados,
        data: item ? item : null
      }
    });
    if (response) {
      vm.month = storeService.get("month");
      vm.year = storeService.get("year");
      aguinaldos();
    }
  }

  // subir archivos
  async function upload(items) {
    loading.init();
    vm.data = [];
    for (const item of items) {
      const file = new FormData();
      file.append("file", item);
      // send file
      try {
        const response = await fileDataService.upload_employee(file);
        addFile(response.data.data);
      } catch (error) {
        toastr.error(text.API_ERROR);
      } finally {
        document.getElementById("file").value = "";
        loading.end();
      }
    }
  }

  // descargar file
  function download() {
    if (vm.selectedIndex == 0) {
      document.location =
        env.DOMAIN + env.API_PATH + "files/export/empleados/" + vm.comunidad.id;
    } else {
      document.location = `${env.DOMAIN}${env.API_PATH}files/export/liquidaciones/${vm.comunidad.id}/${vm.month}/${vm.year}`;
    }
  }

  // add file
  function addFile(item) {
    $mdDialog
      .show({
        controller: empleadosModalController,
        templateUrl: "views/admin/remuneracion/modal/file_modal.html",
        parent: angular.element(document.body),
        targetEvent: "ev",
        clickOutsideToClose: false,
        fullscreen: false,
        locals: {
          community: vm.comunidad,
          data: item ? item : null
        }
      })
      .then(function(response) {
        if (response) {
          empleados();
        }
      });
  }

  // select tabs
  function selectTab() {
    if (vm.selectedIndex == 0) {
      empleados();
    } else if (vm.selectedIndex == 1) {
      liquidaciones();
    } else if (vm.selectedIndex == 2) {
      reemplazos();
    } else if (vm.selectedIndex == 3) {
      bonos();
    } else {
      aguinaldos();
    }
  }

  function selectMonth() {
    storeService.set("month", vm.month);
    $rootScope.$broadcast("update-auth-data");
  }

  function selectYear() {
    storeService.set("year", vm.year);
    $rootScope.$broadcast("update-auth-data");
  }

  // validate all
  function validate() {
    $mdDialog.show(confirmService.confirm("ev")).then(
      function() {
        if (vm.selectedIndex == 1) {
          validateLiquidaciones();
        } else if (vm.selectedIndex == 2) {
          validateReemplazos();
        } else if (vm.selectedIndex == 3) {
          validateBonos();
        } else {
          validateAguinaldos();
        }
      },
      function() {}
    );
  }

  // validate all liquidaciones
  async function validateLiquidaciones() {
    loading.init();
    try {
      await liquidacionDataService.validate({
        data: vm.liquidaciones
      });
      vm.is_validate_liquidaciones = false;
      liquidaciones();
      toastr.success(text.API_EXITO);
    } catch (error) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  // validate all reemplazos
  async function validateReemplazos() {
    loading.init();
    try {
      await reemplazoDataService.validate({
        data: vm.reemplazos
      });
      vm.is_validate_reemplazos = false;
      reemplazos();
      toastr.success(text.API_EXITO);
    } catch (error) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  // validate all bonos
  async function validateBonos() {
    loading.init();
    try {
      await bonoDataService.validate({
        data: vm.bonos
      });
      vm.is_validate_bonos = false;
      bonos();
      toastr.success(text.API_EXITO);
    } catch (error) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  // validate all aguinaldos
  async function validateAguinaldos() {
    loading.init();
    try {
      await aguinaldoDataService.validate({
        data: vm.aguinaldos
      });
      vm.is_validate_aguinaldos = false;
      aguinaldos();
      toastr.success(text.API_EXITO);
    } catch (error) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  // configuracion
  async function configUpdate() {
    try {
      await cuentaDataService.update_sueldo({
        is_group: vm.is_group,
        comunidad: vm.comunidad.id,
        salary_account_id: vm.salary_account_id,
        laws_account_id: vm.laws_account_id,
      });
      toastr.success(text.API_EXITO);
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  // confirmar para eliminar item
  async function destroy(item) {
    try {
      await $mdDialog.show(confirmService.delete("ev"));
    } catch (error) {
      return;
    }

    loading.init();
    try {
      if (vm.selectedIndex === 0) {
        await disableEmpleado(item);
      } else if (vm.selectedIndex === 1) {
        await disableLiquidacion(item);
      } else if (vm.selectedIndex === 2) {
        await disableReemplazo(item);
      } else if (vm.selectedIndex === 3) {
        await disableBono(item);
      } else if (vm.selectedIndex === 4) {
        await disableAguinaldo(item);
      }
    } catch (error) {
      toastr.error(text.API_ERROR);
    } finally {
      loading.end();
    }
  }

  // disabled empleado
  async function disableEmpleado(item) {
    await empleadoDataService.delete(item, vm.comunidad.id);
    empleados();
  }

  // disabled liquidacion
  async function disableLiquidacion(item) {
    const response = await liquidacionDataService.delete(item);
    if (response.data.status == status.CLOSED) {
      toastr.warning(response.data.message);
    } else {
      liquidaciones();
      toastr.success(text.API_EXITO);
    }
  }

  async function disableReemplazo(item) {
    const response = await reemplazoDataService.delete(item);
    if (response.data.status == status.CLOSED) {
      toastr.warning(response.data.message);
    } else {
      reemplazos();
      toastr.success(text.API_EXITO);
    }
  }

  async function disableBono(item) {
    const response = await bonoDataService.delete(item);
    if (response.data.status == status.CLOSED) {
      toastr.warning(response.data.message);
    } else {
      bonos();
      toastr.success(text.API_EXITO);
    }
  }

  async function disableAguinaldo(item) {
    const response = await aguinaldoDataService.delete(item);
    if (response.data.status == status.CLOSED) {
      toastr.warning(response.data.message);
    } else {
      aguinaldos();
      toastr.success(text.API_EXITO);
    }
  }

  // get empleados
  async function empleados() {
    if (vm.comunidad) {
      try {
        const response = await empleadoDataService.get_community(vm.comunidad);
        vm.empleados = response.data;
      } catch (error) {
        toastr.error(text.API_ERROR);
      }
    }
  }

  // get liquidaciones
  async function liquidaciones() {
    if (vm.comunidad && vm.month && vm.year) {
      try {
        const response = await liquidacionDataService.get_all(
          vm.comunidad,
          vm.month,
          vm.year
        );
        vm.liquidaciones = response.data;
        angular.forEach(response.data, function(item) {
          if (item.state_a == states_a.INICIADA) {
            vm.is_validate_liquidaciones = true;
          }
        });
      } catch (error) {
        toastr.error("No existe cuenta de liquidaciones asociada, definir cuenta en pestaña Configuración");
        //toastr.error(text.API_ERROR);
      }
    }
  }

  // get reemplazos
  async function reemplazos() {
    if (vm.comunidad && vm.month && vm.year) {
      try {
        const response = await reemplazoDataService.get_all(
          vm.comunidad,
          vm.month,
          vm.year
        );
        vm.reemplazos = response.data;
        angular.forEach(response.data, function(item) {
          if (item.state_a == states_a.INICIADA) {
            vm.is_validate_reemplazos = true;
          }
        });
      } catch (error) {
        toastr.error(text.API_ERROR);
      }
    }
  }

  // get bonos
  async function bonos() {
    if (vm.comunidad && vm.month && vm.year) {
      try {
        const response = await bonoDataService.get_all(
          vm.comunidad,
          vm.month,
          vm.year
        );
        vm.bonos = response.data;
        angular.forEach(response.data, function(item) {
          if (item.state_a == states_a.INICIADA) {
            vm.is_validate_bonos = true;
          }
        });
      } catch (error) {
        toastr.error(text.API_ERROR);
      }
    }
  }

  // get aguinaldos
  async function aguinaldos() {
    if (vm.comunidad && vm.month && vm.year) {
      try {
        const response = await aguinaldoDataService.get_all(
          vm.comunidad,
          vm.month,
          vm.year
        );
        vm.aguinaldos = response.data;
        angular.forEach(response.data, function(item) {
          if (item.state_a == states_a.INICIADA) {
            vm.is_validate_aguinaldos = true;
          }
        });
      } catch (error) {
        toastr.error(text.API_ERROR);
      }
    }
  }

  // get cuenta sueldo
  async function getAccountSueldo() {
    if (vm.comunidad && vm.month && vm.year) {
      try {
        const response = await cuentaDataService.get_sueldo(vm.comunidad);
        vm.is_group = response.data.is_group;
      } catch (error) {
        toastr.error("No existe cuenta de sueldos asociada, definir cuenta en pestaña Configuración");
      }
    }
  }

  // comunidades
  async function comunidades() {
    try {
      let communities = null;
      const response = await comunidadDataService.all();
      communities = response.data;
      vm.comunidades = communities;
      // --
      var index = comunidad(communities);
      vm.comunidad = communities[index];
      vm.salary_account_id = vm.comunidad.salary_account_id;
      vm.laws_account_id = vm.comunidad.laws_account_id;
      // --
      vm.cuentas = vm.comunidad.accounts;
      empleados();
      getAccountSueldo();
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title sub-toolbar
  function title() {
    vm.item = {
      icon: "",
      backUrl: "",
      newUrl: "app.egreso",
      backActive: false,
      newActive: true,
      label: "Remuneraciones"
    };
  }
}
