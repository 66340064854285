angular.module('app')
    .directive('dpzToolbar', dpzToolbar);

function dpzToolbar($state, $rootScope, $window, $mdDialog, $mdBottomSheet, access, env, storeService) {
    return {
        restrict: 'AE',
        //TODO: RELATIVE PATH
        templateUrl: "views/layout/toolbar_view.html",
        scope: {
            item: '=itemData',
            selected: '=selectedData',
        },
        link: function (scope, element, attrs) {
            // back
            scope.back = function (url, params) {
                $state.go(url, params);
            };

            // new
            scope.new = function (url) {
                $state.go(url);
            };

            // excel community
            scope.excel = function (index, id) {
                if (index == 2) {
                    document.location = env.DOMAIN + env.API_PATH + 'files/export/book/' + id;
                    // document.location = 'http://localhost:8000/files/exports/export_asd.xlsx'
                } else {
                    document.location = env.DOMAIN + env.API_PATH + 'files/export/compact/' + id;
                    // $window.open(env.DOMAIN + env.API_PATH + 'files/export/compact/' + id);
                }
            };

            // excel people
            scope.excel_people = function () {
                document.location = env.DOMAIN + env.API_PATH + 'files/export/personas/' + $rootScope.currentCommunity.id;
                // $window.open(env.DOMAIN + env.API_PATH + 'files/export/personas/' + $rootScope.currentCommunity.id);
            };

            // excel cost
            scope.excel_cost = function (id) {
                document.location = env.DOMAIN + env.API_PATH + 'files/export/gasto/' + id;
                // $window.open(env.DOMAIN + env.API_PATH + 'files/export/gasto/' + id);
            };

              // Egresos CSV SOFTLAND
            scope.egresos_csv_softland = function() {
                var month = storeService.get("month");
                var year = storeService.get("year");
                document.location = env.DOMAIN + env.API_PATH + "files/export/softland_egresos/" + $rootScope.currentCommunity.id +"/"+month + "/"+year;
            }
            // Egresos CSV SOFTLAND
            scope.gcs_csv_softland = function() {
                var month = storeService.get("month");
                var year = storeService.get("year");
                document.location = env.DOMAIN + env.API_PATH + "files/export/softland_gcs/" + $rootScope.currentCommunity.id +"/"+month + "/"+year;
            }
            
            // Egresos CSV SOFTLAND
            scope.softland_payments = function() {
                var month = storeService.get("month");
                var year = storeService.get("year");
                document.location = env.DOMAIN + env.API_PATH + "files/export/softland_payments/" + $rootScope.currentCommunity.id +"/"+month + "/"+year;
            }
            
            // Egresos CSV SOFTLAND
            scope.softland_payments_bank = function() {
                var month = storeService.get("month");
                var year = storeService.get("year");
                document.location = env.DOMAIN + env.API_PATH + "files/export/softland_payments_bank/" + $rootScope.currentCommunity.id +"/"+month + "/"+year;
            }

            // Egresos CSV AUXILIARES SOFTLAND
            scope.softland_auxiliares = function() {
                var month = storeService.get("month");
                var year = storeService.get("year");
                document.location = env.DOMAIN + env.API_PATH + "files/export/softland_auxiliares/" + $rootScope.currentCommunity.id +"/"+month + "/"+year;
            }

            scope.saldos = function(id) {
                document.location = env.DOMAIN + env.API_PATH + $rootScope.currentCommunity.id + "/saldos";
                //document.location = env.DOMAIN + env.API_PATH + "files/export/softland_auxiliares/" + $rootScope.currentCommunity.id +"/"+month + "/"+year;
            }

            scope.fechas = function(id) {
                document.location = env.DOMAIN + env.API_PATH + $rootScope.currentCommunity.id + "/fechas";
                //document.location = env.DOMAIN + env.API_PATH + "files/export/softland_auxiliares/" + $rootScope.currentCommunity.id +"/"+month + "/"+year;
            }


            // options excel community
            scope.showGridBottomSheet = function (id, items) {
                $mdBottomSheet.show({
                    templateUrl: 'views/layout/sheet_view.html',
                    controller: 'sheetController',
                    clickOutsideToClose: true,
                    locals: {
                        data: id ? id : null,
                        items: items ? items : null,
                    }
                }).then(function (item) {
                    scope.excel(item.id, id);
                });
            };

            // permission
            scope.permission = function () {
                if (access.isAdmin() || access.isAnalista() || access.isMayordomo() || access.isConcerje()) {
                    return true;
                } else {
                    return false;
                }
            }
            scope.notPermission = function () {
                if (!access.isMayordomo() && !access.isConcerje()) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };
};