angular
    .module('app')
    .service('personaDataService', personaDataService);

function personaDataService(dataService, $rootScope) {
    return {
        all: function () {
            return dataService.httpGet('personas', {
                headers: {
                    'x-comunidad': $rootScope.currentCommunity ? $rootScope.currentCommunity.id : null
                },
            })
        },
        get: function (persona) {
            return dataService.httpGet('personas/' + persona.id);
        },
        post: function (persona) {
            return dataService.httpPost('personas', persona);
        },
        put: function (persona) {
            return dataService.httpPut('personas/' + persona.id, persona);
        },
        delete: function (persona) {
            return dataService.httpDelete('personas/' + persona.id);
        },
        // --
        // --
        get_communities: function () {
            return dataService.httpGet('personas/comunidades');
        },
        post_divisions: function (divisions) {
            return dataService.httpPost('personas/divisiones', divisions);
        },
        post_properties: function (properties) {
            return dataService.httpPost('personas/propiedades', properties);
        },
    };
}