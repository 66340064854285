angular
    .module('app')
    .service('apiUrlService', apiUrlService);

function apiUrlService($location, env) {
    return {
        getApiUrl: function () {
            return env.DOMAIN + env.API_PATH;
        },
        // getUrl: function() {
        //     return env.DOMAIN + env.BASE_PATH;
        // }
    };
};