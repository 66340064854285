angular
  .module("app")
  .controller("visitaModalController", visitaModalController);

function visitaModalController(
  $scope,
  $rootScope,
  $mdDialog,
  visitaDataService,
  comunidadDataService,
  divisionDataService,
  toastr,
  text,
  data,
  community,
  division
) {
  $scope.visita = {};

  // --
  $scope.store = store;
  $scope.update = update;
  $scope.cancel = cancel;
  $scope.selectDivision = selectDivision;
  $scope.selectCommunity = selectCommunity;

  init();

  // inicio
  function init() {
    if (data) {
      $scope.visita = data;
    } else {
      $scope.visita.community_id = community ? community.id : null;
      $scope.visita.division_id = division ? division.id : null;
    }
    comunidades();
  }

  // update
  function update() {
    visitaDataService
      .put($scope.visita)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // store
  function store() {
    visitaDataService
      .post($scope.visita)
      .then(function(response) {
        $mdDialog.hide({
          data: response.data
        });
        toastr.success(text.API_EXITO);
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // close
  function cancel() {
    $mdDialog.hide();
  }

  // personas
  async function selectDivision() {
    let divisionId = $scope.visita.division_id;
    if (!divisionId && $scope.divisiones && $scope.divisiones.length > 0) {
      divisionId = $scope.divisiones[0].id;
    } else if (
      divisionId &&
      !$scope.divisiones.find(d => d.id === divisionId)
    ) {
      divisionId = null;
    }
    $scope.visita.division_id = divisionId;
    if (divisionId) {
      const response = await divisionDataService.getProperties(divisionId);
      const properties = response.data;
      $scope.propiedades = properties;
      let propertyId = $scope.visita.property_id;
      if (!propertyId && properties && properties.length > 0) {
        $scope.visita.property_id = properties[0];
      } else if (propertyId && !properties.find(p => p.id === propertyId)) {
        $scope.visita.property_id = null;
      }
      $scope.visita.property_id = propertyId;
    }
  }

  // select community
  function selectCommunity() {
    const community = $scope.comunidades.find(
      c => c.id === $scope.visita.community_id
    );
    if (community) {
      $scope.divisiones = community.divisions;
      selectDivision();
    }
  }

  // comunidades
  async function comunidades() {
    try {
      const response = await comunidadDataService.all();
      $scope.comunidades = response.data;
      comunidad();
      selectCommunity();
    } catch (error) {
      toastr.error(text.API_ERROR);
    }
  }

  // comunidad rootScope
  function comunidad() {
    $scope.is_current = false;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      if ($rootScope.currentCommunity.id === $scope.visita.community_id) {
        $scope.is_current = true;
      }
    }
  }
}
