angular
    .module('app')
    .service('balanceDataService', balanceDataService);

function balanceDataService(dataService, $rootScope) {
    return {
        get_indicadores: function (community, month, year) {
            return dataService.httpGet('balance/indicadores/' + community.id + '/' + ((month == -1) ? -1 : month) + '/' + ((year == -1) ? -1 : year));
        },
        // get_indicadores_table: function (month, year) {
        //     return dataService.httpGet('balance/table/' + ($rootScope.currentCommunity.id == '') ? -1 : $rootScope.currentCommunity.id + '/' + ((month == -1) ? -1 : month) + '/' + ((year == -1) ? -1 : year));
        // },
    };
}