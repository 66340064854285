angular
    .module('app')
    .service('regionDataService', regionDataService);

function regionDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('regiones');
        },
    };
}