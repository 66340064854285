angular.module("app").service("divisionDataService", divisionDataService);

function divisionDataService(dataService, $rootScope) {
  return {
    all: function(query) {
      return dataService.httpGet(
        `divisiones?page=${query.page}&limit=${query.limit}&order=${
          query.order
        }&search=${query.search}`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          }
        }
      );
    },
    get: function(division) {
      return dataService.httpGet("divisiones/" + division.id);
    },
    post: function(division) {
      return dataService.httpPost("divisiones", division);
    },
    put: function(division) {
      return dataService.httpPut("divisiones/" + division.id, division);
    },
    delete: function(division) {
      return dataService.httpDelete("divisiones/" + division.id);
    },
    // --
    // --
    post_people: function(people) {
      return dataService.httpPost("divisiones/personas", people);
    },
    post_properties: function(properties) {
      return dataService.httpPut("divisiones/propiedades", properties);
    },
    getProperties: function(divisionId) {
      return dataService.httpGet("divisions/" + divisionId + "/properties");
    },
  };
}
