angular
    .module('app')
    .controller('reservaFileModalController', reservaFileModalController);

function reservaFileModalController($scope, $mdDialog, reservaFileDataService,
    toastr, text, data, file) {
    $scope.file = {
        reservation_id: (data) ? data.id : null,
    };
    // --
    $scope.save = save;
    $scope.load = load;
    $scope.cancel = cancel;

    init();

    // carga inicial
    function init() {
        if (file) {
            $scope.file = file;
        }
    }

    // salir del modal
    function cancel() {
        $mdDialog.hide()
    }

    // upload
    function load(item) {
        $scope.archivo = item;
    }

    // subir archivo
    function upload() {
        angular.forEach($scope.archivo, function (item) {
            // send file
            var file = new FormData();
            file.append("file", item);
            reservaFileDataService.upload(file)
                .then(function (response) {
                    document.getElementById('file').value = '';
                    create(response.data);
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                    document.getElementById('file').value = '';
                });
        });
    };

    // crear registro
    function create(items) {
        $scope.file.name = items.name;
        $scope.file.size = items.size;
        $scope.file.type = items.type;
        reservaFileDataService.post($scope.file)
            .then(function (response) {
                $mdDialog.hide({
                    file: response.data,
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // select update o create
    function save() {
        // if ($scope.file.id) {
        //     update();
        // } else {
        upload();
        // }
    }
}