angular
    .module('app')
    .service('toastr', toastr);

function toastr($state, $mdToast, authService, gaService, env) {

    this.success = function (message) {
        $mdToast.show(
            $mdToast.simple()
            .textContent(message)
            .position('bottom left')
            .theme('success-toast')
            .hideDelay(5000)
        );
    }

    this.error = function (message) {
        gaService.send_exception(message);
        $mdToast.show(
            $mdToast.simple()
            .textContent(message)
            .position('bottom left')
            .theme('danger-toast')
            .hideDelay(5000)
        );
    }

    this.info = function (message) {
        $mdToast.show(
            $mdToast.simple()
            .textContent(message)
            .position('bottom left')
            .theme('info-toast')
            .hideDelay(5000)
        );
    }

    this.warning = function (message) {
        $mdToast.show(
            $mdToast.simple()
            .textContent(message)
            .position('bottom left')
            .theme('warning-toast')
            .hideDelay(5000)
        );
    }
}
