angular.module("app").service("payDataService", payDataService);

function payDataService(dataService, $rootScope) {
  return {
    post: function(gasto) {
      return dataService.httpPost("pagos", gasto);
    },
    delete: function(gasto) {
      return dataService.httpDelete("pagos/" + gasto.id);
    },
    delete_payment: function(item) {
      return dataService.httpDelete("payment/" + item.id);
    },
    delete_credit: function(credit) {
      return dataService.httpDelete("credits/" + credit.id);
    },
    getPaymentDetail: function(id) {
      return dataService.httpGet("payment/" + id);
    },
    getMorosidadByProperty: function(id) {
      return dataService.httpGet("pagos/morosidad/propiedad/" + id);
    },
    getMorosidadByDivision: function(id) {
      return dataService.httpGet("pagos/morosidad/division/" + id);
    },
    getMorosidadByCommunity: function(id) {
      return dataService.httpGet("pagos/morosidad/comunidad/" + id);
    },
    allByCommunity: function() {
      return dataService.httpGet("gastos_comunes", {
        headers: {
          x_comunidad: $rootScope.currentCommunity.id
        }
      });
    },
    refresh: function(item) {
      return dataService.httpPost("refresh", item);
    },
  };
}
