angular.module("app").service("aguinaldoDataService", aguinaldoDataService);

function aguinaldoDataService(dataService, $rootScope) {
  return {
    all: function() {
      return dataService.httpGet("aguinaldos", {
        headers: {
          "x-comunidad": $rootScope.currentCommunity
            ? $rootScope.currentCommunity.id
            : null
        }
      });
    },
    get: function(aguinaldo) {
      return dataService.httpGet("aguinaldos/" + aguinaldo.id);
    },
    post: function(aguinaldo) {
      return dataService.httpPost("aguinaldos", aguinaldo);
    },
    put: function(aguinaldo) {
      return dataService.httpPut("aguinaldos/" + aguinaldo.id, aguinaldo);
    },
    delete: function(aguinaldo) {
      return dataService.httpDelete("aguinaldos/" + aguinaldo.id);
    },
    // --
    get_all: function(comunidad, month, year) {
      return dataService.httpGet(
        "aguinaldos/" + comunidad.id + "/" + month + "/" + year
      );
    },
    validate: function(aguinaldos) {
      return dataService.httpPut("aguinaldos/validar", aguinaldos);
    }
  };
}
