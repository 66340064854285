angular
    .module('app')
    .service('notification', notification);

function notification(webNotification) {

    this.init = function () {
        if (!webNotification.permissionGranted) {
            webNotification.showNotification('Mi Torre', {
                body: 'Bienvenido a Mi Torre!',
            });
        }
    }
    this.show = function ($message) {
        if (webNotification.permissionGranted) {
            webNotification.showNotification('Mi Torre', {
                body: $message,
            });
        }
    }

}