angular
    .module('app')
    .service('access', access);

function access($rootScope, auth_roles) {
    var service = {};
    service.isRoot = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.ROOT) {
            return true;
        } else {
            return false;
        }
    };
    service.isAdmin = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.ADMIN) {
            return true;
        } else {
            return false;
        }
    };
    service.isAnalista = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.ANALISTA) {
            return true;
        } else {
            return false;
        }
    };
    service.isRecepcion = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.RECEPCION) {
            return true;
        } else {
            return false;
        }
    };
    service.isTesoreria = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.TESORERIA) {
            return true;
        } else {
            return false;
        }
    };
    service.isContabilidad = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.CONTABILIDAD) {
            return true;
        } else {
            return false;
        }
    };
    service.isMayordomo = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.MAYORDOMO) {
            return true;
        } else {
            return false;
        }
    };
    service.isConcerje = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.CONCERJE) {
            return true;
        } else {
            return false;
        }
    };
    service.isCopropietario = function () {
        if ($rootScope.currentRole && $rootScope.currentRole.name == auth_roles.COPROPIETARIO) {
            return true;
        } else {
            return false;
        }
    };

    return service;
};