angular
    .module('app')
    .service('channelService', channelService);

function channelService($rootScope, $window, mySocket, storeService) {
    var service = {};
    var mySockete = null;

    service.init = function (end, init, is_process) {
        var self = this;
        var user = storeService.get('user');

        // end
        if (self.mySockete && self.mySockete.connect().connected && end) {
            self.mySockete.removeAllListeners();
            self.mySockete = self.mySockete.disconnect();
            console.log('end socket:', new Date());
        }

        // init
        if ((!self.mySockete || !self.mySockete.connect().connected) && init) {
            self.mySockete = mySocket.socket();
            self.mySockete.connected = true;
            console.log('init socket:', new Date());
        }

        // on
        if (self.mySockete) {
            self.mySockete.on('connect', function (event) {
                // messages
                // self.mySockete.on('private-message.' + (user ? user.id : null), function (response) {
                //     if (JSON.parse(response).data.message.to_user_id == user.id) {
                //         $rootScope.$broadcast('emit-socket', {
                //             data: JSON.parse(response).data,
                //             type: 'message',
                //         });
                //     }
                //     console.log('channel => private-message');
                // });
                // mails
                // self.mySockete.on('private-mail.' + (user ? user.id : null), function (response) {
                //     $rootScope.$broadcast('emit-socket', {
                //         data: JSON.parse(response).data,
                //         type: 'mail',
                //     });
                //     console.log('channel => private-mail');
                // });
                // informations
                self.mySockete.on('private-information.' + (user ? user.id : null), function (response) {
                    $rootScope.$emit('emit-socket', {
                        data: JSON.parse(response).data,
                        type: 'information',
                    });
                    console.log('channel => private-information');
                });
                // forum
                self.mySockete.on('private-forum.' + (user ? user.id : null), function (response) {
                    $rootScope.$emit('emit-socket', {
                        data: JSON.parse(response).data,
                        type: 'forum',
                    });
                    console.log('channel => private-forum');
                });
                // repositories
                self.mySockete.on('private-repository.' + (user ? user.id : null), function (response) {
                    $rootScope.$emit('emit-socket', {
                        data: JSON.parse(response).data,
                        type: 'repository',
                    });
                    console.log('channel => private-repository');
                });
                // costs
                self.mySockete.on('private-cost.' + (user ? user.id : null), function (response) {
                    $rootScope.$emit('emit-socket', {
                        data: JSON.parse(response).data,
                        type: 'cost',
                    });
                    console.log('channel => private-cost');
                });
                // reservation
                self.mySockete.on('private-reservation.' + (user ? user.id : null), function (response) {
                    $rootScope.$emit('emit-socket', {
                        data: JSON.parse(response).data,
                        type: 'reservation',
                    });
                    console.log('channel => private-reservation');
                });
                // reservation
                self.mySockete.on('private-reserva.' + (user ? user.id : null), function (response) {
                    $rootScope.$emit('emit-socket', {
                        data: JSON.parse(response).data,
                        type: 'reserva',
                    });
                    console.log('channel => private-reservation');
                });
                // parking check
                // self.mySockete.on('private-parking-check.' + (user ? user.company_id : null), function (response) {
                //     $rootScope.$broadcast('emit-socket', {
                //         data: JSON.parse(response).data,
                //         type: 'parking',
                //     });
                //     console.log('channel => private-parking-check');
                // });
                // parking reserve
                // self.mySockete.on('private-parking-reserve.' + (user ? user.id : null), function (response) {
                //     $rootScope.$broadcast('emit-socket', {
                //         data: JSON.parse(response).data,
                //         type: 'parking-reserve',
                //     });
                //     console.log('channel => private-parking-reserve');
                // });
            });
        }

    };

    // event on socket
    $rootScope.$on('update-socket-data', function (event, data) {
        service.init(data.end, data.init);
    });

    return service;
}