angular
    .module('app')
    .controller('shareModalController', shareModalController);

function shareModalController($scope, $mdDialog, facturaDataService, states_a, states_p,
    months, toastr, text, data, years) {
    var date = new Date();
    $scope.shares = [];
    $scope.bill = data;
    $scope.amount_total = 0;
    // --
    $scope.add = add;
    $scope.delete = destroy;
    $scope.change = change;
    $scope.create = create;
    $scope.cancel = cancel;

    init();

    // carga inicial
    function init() {
        $scope.shares = $scope.bill.bill_shares;
        angular.forEach($scope.bill.bill_shares, function (item) {
            $scope.amount_total += item.amount;
        });
        // --
        $scope.months = months;
        $scope.years = years(date);
        $scope.states_a = states_a;
        $scope.states_p = states_p;
    }

    // close modal
    function cancel() {
        $mdDialog.hide();
    }

    // add share
    function add() {
        $scope.shares.push({
            amount: $scope.cuota.amount,
            month: $scope.cuota.month,
            year: $scope.cuota.year,
            state_a: states_a.INICIADA,
            state_p: states_p.NO_PAGADA,
        });
        $scope.amount_total += $scope.cuota.amount;
    }

    // delete share
    function destroy(ev, key, item) {
        $scope.shares.splice(key, 1);
        $scope.amount_total -= item.amount;
    }

    // change amount share
    function change() {
        $scope.amount_total = 0;
        $scope.shares = angular.forEach($scope.shares, function (item) {
            $scope.amount_total += item.amount;
        });
    }

    // crear registro
    function create() {
        facturaDataService.post_shares({
                id: data.id,
                data: $scope.shares
            })
            .then(function (response) {
                $mdDialog.hide({
                    data: response.data
                });
                toastr.success(text.API_EXITO);
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }
}
