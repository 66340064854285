angular
    .module('app')
    .controller('gastoComunPaysController', gastoComunPaysController);

function gastoComunPaysController($scope, $mdDialog, data) {

    // --
    $scope.cancel = cancel;

    init();

    // --
    function init() {
        $scope.pays = data.web_pay;
    }

    function cancel() {
        $mdDialog.hide()
    }

}