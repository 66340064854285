angular
    .module('app')
    .controller('configController', configController);

function configController($state, $stateParams, $mdDialog, comunidadDataService,
    confirmService, access, toastr, text) {
    var vm = this;

    vm.comunidad = {
        id: ($stateParams.id) ? $stateParams.id : null
    };
    // ---
    vm.edit = edit;
    vm.create = create;

    init();

    // iniciar
    function init() {
        vm.settings = [{
                icon: 'message',
                name: 'Chat',
                description: 'asdjsidjisjd isjd ijaisodj djsid jasidjs disjd',
                enabled: true,
            },
            {
                icon: 'notifications',
                name: 'Notificaciones',
                description: 'asdjsidjisjd isjd ijaisodj djsid jasidjs disjd',
                enabled: true,
            }, {
                icon: 'perm_identity',
                name: 'Perfiles',
                description: 'asdjsidjisjd isjd ijaisodj djsid jasidjs disjd',
                enabled: true,
            },
            // {
            //     icon: 'timer',
            //     name: 'Estacionamientos',
            //     enabled: true,
            // }
        ];

    }

    // editar
    function edit() {
        comunidadDataService.put(vm.comunidad)
            .then(function (response) {
                $state.go('app.comunidad', {
                    id: response.data.id
                });
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // crear
    function create() {
        comunidadDataService.post(vm.comunidad)
            .then(function (response) {
                $state.go('app.comunidad', {
                    id: response.data.id
                });
                toastr.success(text.API_EXITO);
                viewMessage('ev', response.data);
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

}