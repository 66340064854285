angular
  .module("app")
  .controller("repositorioController", repositorioController);

function repositorioController(
  $state,
  $stateParams,
  $rootScope,
  comunidadDataService,
  repositorioDataService,
  storeService,
  repository_category,
  loading,
  toastr,
  text
) {
  var vm = this;
  var notification = storeService.get("notification");
  vm.repositorio = {
    id: $stateParams.id,
    is_public: 0
  };
  vm.categories = repository_category;

  // ---
  vm.load = load;
  vm.edit = edit;
  vm.upload = upload;
  vm.hasFile = false;

  init();

  // iniciar
  function init() {
    if (vm.repositorio.id) {
      repositorioDataService
        .get(vm.repositorio)
        .then(function(response) {
          vm.repositorio = response.data.data;
          notification.repositories -= response.data.notifications;
          storeService.set("notification", notification);
          $rootScope.$broadcast("update-auth-data");
          title();
        })
        .catch(function() {
          toastr.error(text.API_ERROR);
        });
    } else {
      title();
    }
    comunidades();
  }

  // editar
  function edit() {
    loading.init();
    repositorioDataService
      .put(vm.repositorio)
      .then(function(response) {
        $state.go("app.repositorios");
        toastr.success(text.API_EXITO);
        loading.end();
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
        loading.end();
      });
  }

  // upload file
  function load(item) {
    vm.files = item;
    vm.hasFile = vm.files && vm.files.length === 1;
    $rootScope.$apply();
  }

  // subir archivo
  async function upload() {
    if (vm.files && vm.files.length > 0) {
      loading.init();
      const item = vm.files[0];
      const fsize = item.size;
      const fileSize = Math.round(fsize / 1024);
      if (fileSize > 20480) {
        toastr.error("El archivo es muy grande. Intenta con un menor a 20mb");
        loading.end();
        return;
      }
      var file = new FormData();
      file.append("file", item);
      file.append("title", vm.repositorio.title);
      file.append("category", vm.repositorio.category);
      file.append("community_id", vm.repositorio.community_id);
      if (vm.repositorio.description) {
        file.append("description", vm.repositorio.description);
      }
      file.append("is_public", vm.repositorio.is_public);
      try {
        await repositorioDataService.upload(file);
        // create(response.data);
        toastr.success(text.API_EXITO);
      } catch (error) {
        toastr.error(text.API_ERROR);
      } finally {
        loading.end();
      }
    }
  }

  // load comunidades
  function comunidades() {
    comunidadDataService
      .all()
      .then(function(response) {
        vm.comunidades = response.data;
        // --
        var index = comunidad(response.data);
        if (!vm.repositorio.community_id) {
          vm.repositorio.community_id =
            response.data.length > 0 ? response.data[index].id : null;
        }
      })
      .catch(function() {
        toastr.error(text.API_ERROR);
      });
  }

  // get comunidad rootScope
  function comunidad(items) {
    var index = 0;
    if ($rootScope.currentCommunity && $rootScope.currentCommunity.id) {
      angular.forEach(items, function(item, key) {
        if (item.id == $rootScope.currentCommunity.id) {
          index = key;
          vm.is_current = true;
        }
      });
    } else {
      vm.is_current = false;
    }
    return index;
  }

  // load title
  function title() {
    vm.type = vm.repositorio.id ? "Editar" : "Nuevo";
    vm.item = {
      backUrl: "app.repositorios",
      newUrl: "",
      label: vm.type + " Archivo",
      name: vm.repositorio.id ? vm.repositorio.title.toUpperCase() : ""
    };
  }
}
