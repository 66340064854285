angular.module("app").service("usuarioDataService", usuarioDataService);

function usuarioDataService(dataService, $rootScope) {
  return {
    all: function(query) {
      return dataService.httpGet(
        `usuarios?page=${query.page}&limit=${query.limit}&order=${
          query.order
        }&search=${query.search}`,
        {
          headers: {
            "x-comunidad": $rootScope.currentCommunity
              ? $rootScope.currentCommunity.id
              : null
          }
        }
      );
    },
    get: function(usuario) {
      return dataService.httpGet("usuarios/" + usuario.id);
    },
    post: function(usuario) {
      return dataService.httpPost("usuarios", usuario);
    },
    put: function(usuario) {
      return dataService.httpPut("usuarios/" + usuario.id, usuario);
    },
    delete: function(usuario) {
      return dataService.httpDelete("usuarios/" + usuario.id);
    },
    // --
    // --
    // post_divisions: function (divisions) {
    //     return dataService.httpPost('usuarios/divisiones', divisions);
    // },
    get_communities: function() {
      return dataService.httpGet("personas/comunidades");
    },
    get_division: function(division) {
      return dataService.httpGet("personas/division/" + division.id);
    },
    get_admin: function() {
      return dataService.httpGet("personas/admin");
    },
    get_comunidad: function() {
      return dataService.httpGet("personas/comunidad");
    },
    get_company: function() {
      return dataService.httpGet("personas/compania", {
        headers: {
          "x-comunidad": $rootScope.currentCommunity
            ? $rootScope.currentCommunity.id
            : null
        }
      });
    },
    post_properties: function(property) {
      return dataService.httpPost("usuarios/propiedades", property);
    },
    delete_properties: function(property) {
      return dataService.httpDelete("usuarios/propiedades/" + property.id);
    },

    get_admins: function(){
      return dataService.httpGet("usuarios/get_admins");
    },

    get_admin: function(user_id){
      return dataService.httpGet("usuarios/get_admin", user_id);
    },

    deassign: function(user, role, community){
      return dataService.httpPost("usuarios/deassign", [user, role, community]);
    },

    assign: function(user, role, community){
      return dataService.httpPost("usuarios/assign", [user, role, community]);
    }

  };
}
