angular
    .module('app')
    .service('tipoDataService', tipoDataService);

function tipoDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('tipos');
        },
        get: function (tipo) {
            return dataService.httpGet('tipos/' + tipo.id);
        },
        post: function (tipo) {
            return dataService.httpPost('tipos', tipo);
        },
        put: function (tipo) {
            return dataService.httpPut('tipos/' + tipo.id, tipo);
        },
        delete: function (tipo) {
            return dataService.httpDelete('tipos/' + tipo.id);
        }
    };
}