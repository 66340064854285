angular
    .module('app')
    .config(theme);

function theme($mdThemingProvider, ChartJsProvider) {

    $mdThemingProvider.definePalette('palettePrimary', {
        '50': 'E0F7FA',
        '100': 'B2EBF2',
        '200': '80DEEA',
        '300': '4DD0E1',
        '400': '26C6DA',
        '500': '00BCD4',
        '600': '000000',
        '700': '0097A7',
        '800': '00838F',
        '900': '006064',
        'A100': '84FFFF',
        'A200': '18FFFF',
        'A400': '00E5FF',
        'A700': '00B8D4',
        'contrastDefaultColor': 'light', // whether, by default, text (contrast)
        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
            '200', '300', '400', 'A100'
        ],
        'contrastLightColors': undefined // could also specify this if default was 'dark'
    });

    $mdThemingProvider.definePalette('paletteSecond', {
        '50': 'F9FBE7',
        '100': 'F0F4C3',
        '200': 'E6EE9C',
        '300': 'DCE775',
        '400': 'D4E157',
        '500': 'CDDC39',
        '600': 'dc6a17',
        '700': 'AFB42B',
        '800': '9E9D24',
        '900': '827717',
        'A100': 'AFB42B',
        'A200': 'AFB42B',
        'A400': 'AFB42B',
        'A700': 'F9791B',
        'contrastDefaultColor': 'light', // whether, by default, text (contrast)
        // 'contrastDarkColors': ['900', '900', '900', '900', '900', '900'],
        // 'contrastLightColors': ['500', '600', '700', '800', '900', 'A100'], // could also specify this if default was 'dark'
    });

    // Paleta de colores material 
    $mdThemingProvider.theme('default')
        .primaryPalette('palettePrimary', {
            'default': '600', // by default use shade 400 from the pink palette for primary intentions
            'hue-1': '600', // use shade 100 for the <code>md-hue-1</code> class
            'hue-2': '600', // use shade 600 for the <code>md-hue-2</code> class
            'hue-3': '200' // use shade A100 for the <code>md-hue-3</code> class
        })
        .accentPalette('paletteSecond', {
            'default': '600',
        })
        .warnPalette('red', {
            'default': '600',
        });

    $mdThemingProvider.setDefaultTheme('default');

    // Themes para Toast
    $mdThemingProvider.theme('success-toast');
    $mdThemingProvider.theme('danger-toast');
    $mdThemingProvider.theme('warning-toast');
    $mdThemingProvider.theme('info-toast');

    // Paleta de colores Chart
    ChartJsProvider.setOptions({
        colors: ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360']
    });

}