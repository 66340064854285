angular
    .module('app')
    .service('roleDataService', roleDataService);

function roleDataService(dataService) {
    return {
        all: function () {
            return dataService.httpGet('roles');
        },
        allOut: function () {
            return dataService.httpGet('roles_out');
        },
        get: function (rol) {
            return dataService.httpGet('roles/' + rol.id);
        },
        post: function (rol) {
            return dataService.httpPost('roles', rol);
        },
        put: function (rol) {
            return dataService.httpPut('roles/' + rol.id, rol);
        },
        delete: function (rol) {
            return dataService.httpDelete('roles/' + rol.id);
        }
    };
}