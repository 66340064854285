angular
    .module('app')
    .controller('costModalController', costModalController);

function costModalController($scope, $mdDialog, gastoPropiedadDataService, movimiento_types, data, accounts) {
    $scope.types = movimiento_types;
    $scope.data = data;
    // --
    $scope.create = create;
    // $scope.create_desc = create_desc;
    $scope.cancel = cancel;

    $scope.accounts = accounts;
    init();

    // inicio
    function init() {
        //
        // console.log(data);
        // console.log(accounts);
    }

    // crear gastos
    function create() {
        $mdDialog.hide({
            data: {
                property_id: data.property_id,
                // amount: $scope.cost.amount,
                amount: ($scope.cost.type == movimiento_types.DESCUENTO) ? (-1 * $scope.cost.amount) : $scope.cost.amount,
                description: $scope.cost.description,
                account: $scope.cost.account,
                current: 0,
                diff: 0,
            },
        })
    }

    function cancel() {
        $mdDialog.hide()
    }
}