angular
    .module('app')
    .service('storeService', storeService);

function storeService(localStorageService, $auth, $rootScope) {
    var storeService = {};

    storeService.set = function (key, value) {
        return localStorageService.set(key, value);
    };

    storeService.get = function (key) {
        return localStorageService.get(key);
    };

    storeService.getToken = function () {
        return $auth.getToken();
    };

    storeService.clearItem = function (key) {
        localStorageService.remove(key);
    };

    storeService.clearAll = function () {
        localStorageService.clearAll();
    };

    storeService.exist = function () {
        var result = false;
        var array = ['user', 'community', 'role'];
        angular.forEach(array, function (item) {
            if (storeService.get(item))
                result = true;
        });
        return result;
    }

    storeService.isMobile = function(){
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    storeService.rootScope = function () {
        // -- rootScope
        if ($auth.isAuthenticated()) {
            $rootScope.isAuthenticated = true;
            $rootScope.currentUser = storeService.get('user');
            $rootScope.currentCommunity = storeService.get('community');
            $rootScope.currentRole = storeService.get('role');
            $rootScope.currentMonth = storeService.get('month');
            $rootScope.currentYear = storeService.get('year');
            $rootScope.currentNotification = storeService.get('notification');
        } else {
            $rootScope.isAuthenticated = false;
            $rootScope.currentUser = null;
            $rootScope.currentCommunity = null;
            $rootScope.currentRole = null;
            $rootScope.currentMonth = null;
            $rootScope.currentYear = null;
            $rootScope.currentNotification = null;
        }
    }

    // event on change in auth
    $rootScope.$on('update-auth-data', function () {
        storeService.rootScope();
    });

    return storeService;
};
