angular
  .module("app")
  .constant("env", ENV)
  .constant("auth_types", {
    ADMINISTRACION: "administracion",
    COMUNIDAD: "comunidad",
    COPROPIETARIO: "copropietario",
    ROOT: "root"
  })
  .constant("auth_roles", {
    ROOT: "root",
    SEMIROOT: "semiroot",
    ADMIN: "administrador",
    ANALISTA: "analista",
    RECEPCION: "recepcion",
    TESORERIA: "tesoreria",
    CONTABILIDAD: "contabilidad",
    MAYORDOMO: "mayordomo",
    CONCERJE: "conserje",
    COPROPIETARIO: "copropietario",
    INMOBILARIA: "Inmobilaria"
  })
  .constant("roles_copropietario", {
    DUEÑO: "dueño",
    ARRENDATARIO: "arrendatario",
    REPRESENTANTE: "representante",
    INMOBILARIA: "Inmobilaria"
  })
  .constant("auth_home", {
    HOME_PAGE: [
      {
        auth: "admin",
        rol: [
          "administrador",
          "analista",
          "recepcion",
          "tesoreria",
          "contabilidad",
          "mayordomo",
          "conserje"
        ],
        path: "/dashboard",
        state: "app.dashboard"
      },
      {
        auth: "root",
        rol: ["root","semiroot"],
        path: "/comunidades",
        state: "app.comunidades"
      }
    ]
  })
  .constant("community_types", {
    VIVIENDA: [
      {
        name: "vivienda",
        property_type: ["departamento", "casa", "bodega", "estacionamiento", "local", "retail", "oficina", "estudio"]
      }
    ],
    OFICINA: [
      {
        name: "oficina",
        property_type: ["oficina","local", "estudio", "piso"]
      }
    ],
    ESTUDIO: [
      {
        name: "estudio",
        property_type: ["estudio"]
      }
    ],
    CENTRO_COMERCIAL: [
      {
        name: "centro comercial",
        property_type: ["local", "supermercado", "retail", "corner o islas"]
      }
    ],
    HOTELERIA: [
      {
        name: "hoteleria",
        property_type: ["hotel"]
      }
    ],
    STRIP_CENTER: [
      {
        name: "strip center",
        property_type: ["local", "supermercado", "retail"]
      }
    ],
    BODEGAJE: [
      {
        name: "bodegaje",
        property_type: ["bodega"]
      }
    ],
    INDUSTRIAL: [
      {
        name: "industrial",
        property_type: ["galpon", "oficina"]
      }
    ]
  })
  .constant("morosidad_types", {
    VARIABLE: "variable",
    FIJO: "fijo"
  })
  .constant("account_types", {
    PLAZO_VENCIDO: "plazo vencido",
    GASTO_PRESUPUESTARIO: "gasto presupuestario",
    COSTO_FIJO: "costo fijo"
  })
  .constant("property_type", {
    BODEGA: "bodega",
    CASA: "casa",
    CORNER_ISLAS: "corner o islas",
    DEPARTAMENTO: "departamento",
    GALPON: "galpon",
    HOTEL: "hotel",
    LOCAL: "local",
    OFICINA: "oficina",
    RETAIL: "retail",
    SUPERMERCADO: "supermercado",
    // })
    // .constant('dependency_types', {
    // BODEGA: 'bodega',
    CAVA: "cava",
    ESTACIONAMIENTO: "estacionamiento",
    // ESTACIONAMIENTO_MOTOCICLETA: 'estacionamiento de motocicletas',
    ESTUDIO: "estudio"
  })
  .constant("banks", {
    BANCO_CHILE: "Banco Chile/Edwards",
    BANCO_BICE: "Banco Bice",
    BANCO_CONSORCIO: "Banco Consorcio",
    BANCO_ESTADO: "Banco del estado de Chile",
    BANCO_BRASIL: "Banco do brasil",
    BANCO_FALABELLA: "Banco Falabella",
    BANCO_INTERNACIONAL: "Banco Internacional",
    BANCO_PARIS: "Banco Paris",
    BANCO_RIPLEY: "Banco Ripley",
    BANCO_SANTANDER: "Banco Santander",
    BANCO_SECURITY: "Banco Security",
    BANCO_BBVA: "BBVA",
    BANCO_BCI: "BCI",
    BANCO_COOPEUCH: "COOPEUCH",
    BANCO_HSBC: "HSBC BANK",
    BANCO_ITAU: "ITAU Chile",
    BANCO_CORPBANCA: "ITAU Corpbanca",
    BANCO_SCOTIABANK: "Scotiabank"
  })
  .constant("bank_types", {
    CUENTA_AHORRO: "Cuenta de Ahorro",
    CUENTA_CORRIENTE: "Cuenta Corriente",
    CUENTA_VISTA: "Cuenta Vista",
    LIBRETA_AHORRO: "Libreta de Ahorro"
  })
  .constant("rule_types", {
    PRIVADO: "particular",
    COMUN: "comun"
  })
  .constant("movimiento_types", {
    DESCUENTO: "descuento",
    COBRO: "cobro"
  })

  .constant("status", {
    CLOSED: "closed",
    ERROR: "error",
    OK: "ok"
  })
  .constant("text", {
    PASSWORD_FORGOT: "Mensaje enviado!",
    PASSWORD_RESET: "Contraseña Actualizada!",
    PASSWORD_ERROR: "Ha ocurrido un error!",
    PASSWORD_EMAIL: "Email Incorrecto!",
    PASSWORD_DATA: "Datos ingrados incorrectos!",
    API_ERROR: "Ha ocurrido un error!",
    API_EXITO: "Guardado Correctamente!",
    DELETE_TITLE: "Desea eliminar el registro?",
    DELETE_CONTENT: "Si se elimina el registro no podrá ser recuperado.",
    DISABLED_TITLE: "Desea deshabilitar el registro?",
    DISABLED_CONTENT: "El registro podrá ser habilitado cuando lo desees.",
    ENABLED_TITLE: "Desea habilitar el registro?",
    ENABLED_CONTENT: "El registro podrá ser deshabilitado cuando lo desees.",
    MOROSO_TITLE: "Tiene pagos atrasados!",
    MOROSO_CONTENT: "Debe pagar los gastos atrasados.",
    BILL_TITLE: "La factura tiene Cuotas",
    BILL_CONTENT: "Se crearán nuevas facturas según el nº de cuotas.",
    EXPIRED_TITLE: "Su sesión ha expirado!",
    EXPIRED_CONTENT: "Vuelva a iniciar su sesión.",
    CONFIRM_TITLE: "Desea confirmar los registros?",
    CONFIRM_CONTENT:
      "Los registros serán confirmados, solo el Administrador podrá editarlos.",
    SEND_TITLE: "Desea enviar los Gastos Comunes?",
    SEND_CONTENT: "Los Gastos Comunes serán enviados a cada propiedad.",
    UNLINK_TITLE: "Desea desvincular el registro?",
    UNLINK_CONTENT:
      "Si se desvincula el registro podrá ser asignado a otra Propiedad.",
    EXCEL_TITLE: "Desea iniciar carga de datos?",
    EXCEL_CONTENT:
      "Todos las filas serán cargadas, las existentes serán actualizadas.",
    EXCEL_IMPORT_TITLE: "Registros importados!",
    EXCEL_IMPORT_EXITO: "Registros Exitosos",
    EXCEL_IMPORT_ERROR: "Registros Erroneos",
    CONFIRM_LABEL: "Buena Suerte!",
    CONFIRM_OK: "Aceptar",
    CONFIRM_CANCEL: "Cancelar",
    EXCEL_EXITO: "Archivo cargado!",
    EXCEL_ERROR: "Archivo invalido y/o sin datos!",
    EXCEL_MAX_MIN: "Valor total de alicuota distinto a 100%",
    NOTIFICATION_MESSAGE: "Nuevo Mensaje!",
    NOTIFICATION_MAIL: "Nuevo Mail!",
    NOTIFICATION_INFORMATION: "Nueva Información!",
    NOTIFICATION_FORUM: "Nuevo comentario en Foro!",
    NOTIFICATION_REPOSITORY: "Nuevo Archivo!",
    NOTIFICATION_COSTS: "Nuevo Gasto Común!",
    NOTIFICATION_RESERVATION: "Nueva Reservación!",
    NOTIFICATION_PARKING: "Estacionamiento en Reservación!"
  })
  .constant("months", {
    ENERO: "enero",
    FEBRERO: "febrero",
    MARZO: "marzo",
    ABRIL: "abril",
    MAYO: "mayo",
    JUNIO: "junio",
    JULIO: "julio",
    AGOSTO: "agosto",
    SEPTIEMBRE: "septiembre",
    OCTUBRE: "octubre",
    NOVIEMBRE: "noviembre",
    DICIEMBRE: "diciembre"
  })
  .constant("days", {
    LUNES: "lunes",
    MARTES: "martes",
    MIERCOLES: "miercoles",
    JUEVES: "jueves",
    VIERNES: "viernes",
    SABADO: "sábado",
    DOMINGO: "domingo"
  })
  .constant("states_a", {
    INICIADA: "iniciada",
    APROBADA: "aprobada",
    RECHAZADA: "rechazada"
  })
  .constant("states_p", {
    NO_PAGADA: "no pagada",
    PAGADA: "pagada",
    COBRADA: "cobrada"
  })
  .constant("type_expense", {
    BOLETA: "boleta",
    BOLETA_HONORARIOS: "boleta honorarios",
    FACTURA: "factura",
    LIQUIDACION: "liquidacion",
    PROVISION: "provision",
    FONDO_RESERVA: "contra fondo de reserva",
    INGRESO: "ingreso",
    COMPROBANTE_DE_EGRESO: "comprobante de egreso",
    DOCUMENTO: "documento",
  })
  .constant("type_categories", {
    REMUNERACION: "remuneraciones y honorarios"
  })
  .constant("types_gastos", {
    ACTIVO: [
      {
        name: "activo",
        types: ["circulante", "existencia", "activo fijo"]
      }
    ],
    PASIVO: [
      {
        name: "pasivo",
        types: ["circulante", "largo plazo"]
      }
    ],
    INGRESO: [
      {
        name: "ingreso",
        types: ["ingreso"]
      }
    ],
    GASTOS: [
      {
        name: "gastos",
        types: [
          "administracion",
          "mantencion",
          "uso o consumo",
          "reparaciones",
          "remuneraciones",
          "gastos con prorrateo particular",
          "servicios externos",
          "abonos a fondos"
        ]
      }
    ],
    PATRIMONIO: [
      {
        name: "patrimonio",
        types: ["resultados", "fondos operacionales"]
      }
    ]
  })
  .constant("cost_type", {
    GLOBAL: "global",
    PARTICULAR: "particular",
    COMUN: "comun",
    COSTO_FIJO: "costofijo",
    FONDO_RESERVA: "fondo reserva",
    OTRA: "otra"
  })
  .constant("reserve_fund_type", {
    fixed: "Fijo",
    variable: "Variable",
  })
  .constant("type_costs", {
    LUZ: "luz",
    AGUA_FRIA: "agua fria",
    AGUA_CALIENTE: "agua caliente",
    GAS: "gas",
    OTRA: "otra"
  })
  .constant("state_cost", {
    INICIADO: "iniciado",
    ESPERANDO: "esperando",
    CALCULANDO: "calculando",
    FALLO: "fallo",
    CONFIRMADO: "confirmado",
    ENVIADO: "enviado"
  })
  .constant("repository_category", {
    LEGAL: "legal",
    ADMINISTRATIVO: "administrativo",
    REGLAMENTO: "reglamento",
    CERTIFICACION: "certificaciones",
    OTROS: "otros"
  })
  .constant("informations_category", {
    INFORMACION: "informacion",
    NOTICIA: "noticia"
  })
  .constant("informations_type", {
    LIMPIEZA: "limpieza",
    MANTENCION: "mantencion",
    REPARACION: "reparacion",
    OTRO: "otros"
  })
  .constant("reserva_states", {
    SOLICITADA: "solicitada",
    CONFIRMADA: "confirmada",
    RECHAZADA: "rechazada",
    FINALIZADA: "finalizada"
  })
  .constant("remind_states", {
    POR_REALIZAR: "por realizar",
    REALIZADA: "realizada",
    CANCELADA: "cancelada"
  })
  .constant("method_pay", {
    FL: "efectivo",
    CH: "cheque",
    TE: "transferencia"
  })
  .constant("remind_period", {
    UNICA: "unica",
    DIARIA: "diaria",
    SEMANAL: "semanal",
    MENSUAL: "mensual"
  })
  .constant("log_types", {
    CREATED: "created",
    UPDATED: "updated",
    DELETED: "deleted"
  })
  .constant("tooltips", {
    CODIGO: "Representa un código único para la comunidad en el sistema",
    TIPO_CUENTA: "Método a pagar los copropetarios el Gasto Común",
    DIAS_PAGO: "El dia en el que se debería empezar a cobrar el Gasto Común",
    PRORRATEO:
      "Representa el porcentaje de repartición del gasto común, dado el tipo y tamaño de propiedad",
    PRORRATEO:
      "Representa el porcentaje de repartición del gasto común, dado el tipo y tamaño de unidad",
    PROPIEDAD: "Representa la propiedad asociada a esta unidad",
    ORDEN:
      "Representa el orden en el que se presentara en el Gasto Comun esta categoría",
    CATEGORIA: "Representa a la categoría que estará asociada a esta cuenta",
    N_CUOTAS:
      "Representa la cantidad de cuotas que serán repartidas para el pago de esta factura",
    PERIODO_GASTO:
      "Representa el mes desde el cual será cobrado el Gasto Comun",
    ESTADO: "Aprobación de la factura",
    N_COMPROBANTE: "Corresponde a un número interno de identificación",
    PREGUNTAR_:
      "Indicaciones como modal, en la página de carga o bien en los porcentajes."
  })
  .constant("getYearMonthDay", (date) => {
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    const month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  })
  .constant("years", (date) => {
    var date = new Date();
    const init = 2020;
    const this_month = date.getMonth()+1;
    var last_year = 0;
    if(this_month >= 11){
      last_year = date.getFullYear()+1;
    } else {
      last_year = date.getFullYear();
    }
    const last = last_year;
    return Array(last - init + 1).fill().map((_, idx) => init + idx);
  });
