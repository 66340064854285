angular
    .module('app')
    .controller('tipoDocumentoController', tipoDocumentoController);

function tipoDocumentoController($state, $stateParams,
    tipoDocumentoDataService, repository_category, loading, toastr, text) {
    var vm = this;

    vm.documento = {
        id: $stateParams.id,
    };
    vm.categories = repository_category;

    // ---
    vm.create = create;
    vm.edit = edit;

    init();

    // iniciar
    function init() {
        if (vm.documento.id) {
            tipoDocumentoDataService.get(vm.documento)
                .then(function (response) {
                    vm.documento = response.data;
                    title();
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            title();
        }
    }

    // editar
    function edit() {
        loading.init();
        tipoDocumentoDataService.put(vm.documento)
            .then(function (response) {
                $state.go('app.tiposDocumento');
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // create
    function create() {
        loading.init();
        tipoDocumentoDataService.post(vm.documento)
            .then(function (response) {
                $state.go('app.tiposDocumento');
                toastr.success(text.API_EXITO);
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load title
    function title() {
        vm.type = (vm.documento.id) ? 'Editar' : 'Nuevo';
        vm.item = {
            backUrl: 'app.tiposDocumento',
            newUrl: '',
            label: vm.type + ' Tipo de documento',
            name: (vm.documento.id) ? vm.documento.code.toUpperCase() : '',
        };
    }
}