angular
    .module('app')
    .controller('categoriaController', categoriaController);

function categoriaController($state, $stateParams, $rootScope, comunidadDataService,
    categoriaDataService, loading, access, toastr, text, status, tooltips) {
    var vm = this;
    vm.tooltips = tooltips;
    vm.permission = access;
    vm.categorias = [];
    vm.categoria = {
        id: $stateParams.id
    };
    vm.query = {
        order: ['order', 'name'],
        limit: 10,
        page: 1
    };

    // ---
    vm.edit = edit;
    vm.create = create;

    init();

    // iniciar
    function init() {
        if (vm.categoria.id) {
            categoriaDataService.get(vm.categoria)
                .then(function (response) {
                    vm.categoria = response.data;
                    title();
                }).catch(function () {
                    toastr.error(text.API_ERROR);
                });
        } else {
            title();
        }
    }

    // editar
    function edit() {
        loading.init();
        categoriaDataService.put(vm.categoria)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    $state.go('app.categoria', {
                        id: response.data.id
                    });
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            }).catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // crear
    function create(option) {
        loading.init();
        categoriaDataService.post(vm.categoria)
            .then(function (response) {
                // validate status
                if (response.data.status == status.ERROR) {
                    // error rr
                    toastr.error(response.data.msg[0]);
                } else {
                    // successul
                    if (option == 1) {
                        $state.go('app.categoria', {}, {
                            reload: true
                        });
                    } else {
                        $state.go('app.categoria', {
                            id: response.data.id
                        });
                    }
                    toastr.success(text.API_EXITO);
                }
                loading.end();
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
                loading.end();
            });
    }

    // load title
    function title() {
        vm.type = (vm.categoria.id) ? 'Editar' : 'Nueva';
        vm.item = {
            backUrl: 'app.categorias',
            newUrl: '',
            label: vm.type + ' Categoria',
            name: (vm.categoria.id) ? vm.categoria.name.toUpperCase() : '',
        };
    }
}