angular
    .module('app')
    .controller('chatsController', chatsController);

function chatsController($scope, $state, $mdDialog, $rootScope, chatDataService, storeService,
    confirmService, access, toastr, text) {
    var vm = this;
    vm.user = storeService.get('user');
    vm.query = {
        order: ['name'],
        limit: 10,
        page: 1
    };

    // --
    vm.edit = edit;
    vm.destroy = destroy;

    init();

    // inicio
    function init() {
        vm.permission = access;
        title();
        chats();
    }

    // actualizar
    function edit(item) {
        $state.go('app.chat', {
            id: item.to_user.id
        });
    }

    function chats() {
        if (access.isAdmin()) {
            getUserAll();
        } else {
            getUserAdmin();
        }
    }

    // confirmar para eliminar item
    function destroy(ev, item) {
        $mdDialog.show(confirmService.delete(ev)).then(function () {
            disabled(item);
        }, function () {});
    }

    // disabled
    function disabled(item) {
        item.is_active = false;
        mailDataService.put(item)
            .then(function (response) {
                init();
                toastr.success(text.API_EXITO);
            }).catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // get Admins
    function getUserAdmin() {
        chatDataService.all()
            .then(function (response) {
                vm.chats = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // get User Community
    function getUserAll() {
        chatDataService.get_people()
            .then(function (response) {
                vm.chats = response.data;
            })
            .catch(function () {
                toastr.error(text.API_ERROR);
            });
    }

    // socket
    $rootScope.$on('emit-socket', function (event, data) {
        chats();
        console.log(data.data);
    });

    // title
    function title() {
        if (access.isCopropietario()) {
            $scope.$parent.vm.item = {
                back: true,
                backUrl: 'app.mensajeria_rwd',
                title: 'chat',
            };
        } else {
            vm.item = {
                backUrl: '',
                newUrl: '',
                backActive: false,
                newActive: false,
                label: 'Chat',
            };
        }


    }
}